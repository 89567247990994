import axios from 'axios';

const makeRequest = async (url, method, data = null, params = null) => {
  try {
    const config = {
      method,
      url,
      ...(data && { data }), 
      ...(params && { params }), 
    };

    const response = await axios(config);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getGrowers = (params) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/growers/`,'get',null,params);
export const getTask = (params) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/task/`, 'get', null, params);
export const createTask = (data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/task/`, 'post', data);
export const getSummary = () => makeRequest(`${process.env.REACT_APP_BASEURL}/api/project/stats/`, 'get');
export const deleteTask = (id) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/task/${id}`, 'delete');
export const getTaskById = (id) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/task/${id}`, 'get');
export const getUsers = () => makeRequest(`${process.env.REACT_APP_BASEURL}/api/users`, 'get');
export const updateTask =(id, data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/task/${id}/`, 'patch',data,null,);