import {LayersControl, TileLayer} from "react-leaflet";
import React from "react";
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;


export function BaseLayerControl() {
    return (<LayersControl position="bottomright">
        <LayersControl.BaseLayer checked name="Satellite View">
            <TileLayer
                maxZoom={22}
                maxNativeZoom={19}
                url={`https://api.mapbox.com/styles/v1/kumail-09/clc0a4s9k000b14lmukbjuxvu/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
            />
        </LayersControl.BaseLayer>
            {/*<LayersControl.BaseLayer name="Satellite View (High Res)">*/}
            {/*    <TileLayer*/}
            {/*        maxZoom={22}*/}
            {/*        maxNativeZoom={19}*/}
            {/*        url={"http://{s}.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token=" + MAPBOX_TOKEN}/>*/}
            {/*</LayersControl.BaseLayer>*/}
            {/*<LayersControl.BaseLayer name="Street View">*/}
            {/*    <TileLayer*/}
            {/*        maxZoom={22}*/}
            {/*        maxNativeZoom={19}*/}
            {/*        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>*/}
            {/*</LayersControl.BaseLayer>*/}
        </LayersControl>
    )
}
