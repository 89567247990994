import React from 'react';
import './App.css';
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import Sidebar from './components/Sidebar';
import Router from './routers/Router';
import {BrowserRouter} from 'react-router-dom';
import {CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material';
import {useState} from 'react';
import {useMode, ColorModeContext} from './assets/Theme'
import useAuth from "./components/auth/useAuth";
import {Snackbar, Alert} from '@mui/material';
import Loader from 'components/Loader';
import axios from "axios";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {SurveyorsProvider} from "./components/AppStates/Surveyors";
function App() {
  
    const [snackPara, setSnackPara] = useState([]);
    const handleClose = () => {
        setSnackPara(null);
    };
    const snackcreate = (msg, severity, duration) => {
        setSnackPara({msg, severity, duration});
    };
    /*Axios Default Callbacks*/
    axios.interceptors.response.use((response) => {
        if (response.status === 200 && response?.data) {
            snackcreate('fetched', 'success', 2000);
        } else if (response.status === 201) {
            snackcreate('Successfully created', 'success', 2000);
        } else if (response.status === 204) {
            snackcreate('Successfully deleted', 'success', 2000);
        }
        return response;
    }, error => {
        error?.message === 'Network Error' && alert('Network Error! Please check your internet connection');

        if (error.response.status === 403) {
            snackcreate('Unauthenticated! Login Again to Continue', 'error', 5000);
            localStorage.clear()
            setTimeout(() => {
                window.location.href = "/"
            }, 3000);

        } else if (error.response.status === 401) {
            snackcreate('Login Failed! Invalid Mobile Number or Password', 'error', 5000);
        }
        else {
            let error_message = error.response?.data;
            console.log("t", typeof error_message)
            console.error("em", error_message)
            if (error_message.detail === undefined) {
                let arrayCount = 0;
                for (let key in error_message) {
                    if (Array.isArray(error_message[key])) {
                        arrayCount++;
                    }
                }
                if (arrayCount >= 1) {
                    let errorMessageStr = '';
                    for (let key in error_message) {
                        errorMessageStr += `${key}: ${error_message[key].join(',\n')}`;
                    }
                    snackcreate(errorMessageStr, 'error', 3000);
                } else {
                    snackcreate(`${error_message}`, 'error', 3000)
                }

            } else {
                snackcreate(`${error_message.detail}`, 'error', 3000)
                console.log('emm', error);
            }
        }

        return error;
    });

    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const {user, setUser} = useAuth();
    const isLoggedIn = user.auth === true;
    const [isLoading, setIsLoading] = useState(true);
    React.useEffect(() => {
        setIsLoading(false);
    }, []);

    return (

        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SurveyorsProvider>
                    <div className="App">
                        <ColorModeContext.Provider value={colorMode}>
                            <ThemeProvider theme={theme}>
                                <CssBaseline/>
                                <div className="app">
                                    {/* <NotificationContainer/> */}
                                    {snackPara && (
                                        <Snackbar open={true} autoHideDuration={snackPara.duration} onClose={handleClose}>
                                            <Alert onClose={handleClose} severity={snackPara.severity} sx={{width: '100%'}}>
                                                {snackPara.msg}
                                            </Alert>
                                        </Snackbar>)}
                                    {isLoggedIn && <Sidebar isSidebar={isSidebar} role={user.role}/>}
                                    <main className="content">
                                        {isLoading ? <Loader/> : <Router user={user} setUser={setUser}/>}
                                    </main>
                                </div>
                            </ThemeProvider>
                        </ColorModeContext.Provider>
                    </div>
                </SurveyorsProvider>
            </LocalizationProvider>
        </BrowserRouter>);
}

export default App;
