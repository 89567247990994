import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';
import { deleteProject } from 'api/develoPro/Projects';
import { deleteTask } from 'api/develoPro/Tasks';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

export default function DeleteTaskDialog({open, close, taskName, id}) {
    const handleConfirmDelete = async () => {
        try {
          await deleteTask(id);
        } catch (error) {
          console.error( error);
        } finally {
          close();
        }
      };
  return (
    <div>
        <Dialog
        className='delete-dialog'
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <Typography className='dialog-title'>{`Are you sure you want to delete `}</Typography> */}
        {/* <Typography className='task-name'>{`"${taskName}"`}</Typography> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className='message-text'>
          Are you sure you want to delete this Task?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button sx={{width: '50%', height: '100%', background: '#1E1D2A', borderRadius: '10px', color:'#fff', border:'1px solid' }} onClick={close}>Cancel</Button>
        <Button sx={{width: '50%', height: '100%', background: '#FF0000', borderRadius: '10px', color:'#fff' }} onClick={handleConfirmDelete}>Delete</Button>
          
        </DialogActions>
      </Dialog>
    </div>
  )
}
