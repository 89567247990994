import React, { useEffect, useState } from "react";
import { tokens } from "assets/Theme";

import {
  Button,
  Typography,
  TextField,
  useTheme,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { FilterSettingIcon } from "assets/Icons";
import { getCirclesById } from "api/allSurveys/GateCircles";
import { useParams } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import jsPDF from "jspdf";
import {CSVLink} from "react-csv";

export default function BoundaryDetailTable({
      headerNames,
      rowsData,
      title,
      Tabs,
      tabIndex,
      footer,
      ModalComponent,
      onSearch,
      isField,
      tableNav

}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const [selectedHeaders, setSelectedHeaders] = useState(
      headerNames.filter((header) => header.header !== "Grower ID")
  );
  const [isLoading, setIsLoading] = useState(true);
  const [summary, setSummary] = useState([]);
  const {id} = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const data = rowsData === null || undefined ? [] : rowsData;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleCloseModal = () => {
    setOpenModal(true);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch(searchQuery);
    }
  };
  const fetchGateSummary = async () => {
    try {
      const res = await getCirclesById(id);
      setSummary(res);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
      if (isField) {
          setSelectedHeaders(headerNames.map((header) => header));
          setSearchQuery("");
          fetchGateSummary();
      }
      if (rowsData) {
          setIsLoading(false);
      }
  }, [headerNames]);
  //   const handleActionClick = (variety) => {
  //     onActionButtonClick(variety);
  //     handleCloseModal()
  //   };
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };
  const handleHeaderCheckboxChange = (header) => (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const headerIndex = headerNames.findIndex(
        (item) => item.header === header.header
      );
      setSelectedHeaders((selectedHeaders) => [
        ...selectedHeaders.slice(0, headerIndex),
        header,
        ...selectedHeaders.slice(headerIndex),
      ]);
    } else {
      setSelectedHeaders(
        selectedHeaders.filter(
          (selectedHeader) => selectedHeader.header !== header.header
        )
      );
    }
  };

  const headers = selectedHeaders.map(header => ({ label: header.header, key: String(header.value) }));

  const exportPDF = () => {
      const doc = new jsPDF();
      const tableColumn = headerNames?.map((header) => header?.header);
      const tableRows = [];

      rowsData.forEach((row) => {
          const rowData = headerNames?.map((header) => row[header?.value]);
          tableRows.push(rowData);
      });

      doc.autoTable(tableColumn, tableRows, { startY: 20 });
      doc.text(`${title}`, 14, 15);
      doc.save(`${title}.pdf`);
  };

  return (
    <div
      style={{
        width: "100%",
        border: "1px solid #000",
        borderRadius: "12px",
        boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
        background: `${colors.customColor[500]}`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "97%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", width: "65%" }}>
          <Typography
            variant="h3"
            fontWeight="bold"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "20px",
              marginRight: "10px",
            }}
          >
            {title}
          </Typography>
          {Tabs}
        </div>
        <div style={{display: "flex", flexDirection: "row", width: "45%", justifyContent: "end" }}>
            {
                !isField && (
                    <div style={{display: "flex", flexDirection: "row", width: "35%", marginRight: "10px"}}>
                        <Typography
                            variant="h6"
                            color={colors.customColor[100]}
                            sx={{marginTop: "27px", marginRight: '55px', width: "2px",}}
                        >
                            Export
                        </Typography>

                        <button
                            style={{
                                width: "5px !important",
                                height: "3em",
                                marginTop: "20px",
                                background: "transparent",
                                boxShadow: "none",
                                paddingRight: "10px",
                                textDecoration: "underline",
                                borderColor: "transparent",
                                borderRight: `1px solid ${colors.customColor[800]}`,
                                color: `${colors.customColor[800]}`,
                                marginLeft: "13px",
                            }}
                        >
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : (
                                <CSVLink
                                    data={data} headers={headers} filename={`${title}.csv`}
                                    style={{
                                        textDecoration: "none",
                                        color: `${colors.customColor[800]}`,
                                        marginLeft: "10px",
                                    }}
                                >
                                    CSV
                                </CSVLink>
                            )}
                        </button>
                        <button
                            style={{
                                width: "5px !important",
                                background: "transparent",
                                boxShadow: "none",
                                textDecoration: "underline",
                                borderColor: "transparent",
                                color: `${colors.customColor[800]}`,
                                marginRight: "6px",
                                marginTop: "3px",
                            }}
                            onClick={exportPDF}
                        >
                            PDF
                        </button>
                    </div>
                )
            }
            <TextField
                label="Search here"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                variant="outlined"
                size="small"
                sx={{
                    position: "relative",
                    marginTop: "19px",
                    marginBottom: "20px",
                    marginRight: "10px",
                    width: "20em",
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: `${colors.customColor[800]} !important`,
                        borderRadius: "15px !important",
                    },
                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: `${colors.customColor[100]} !important`,
                        },
                    "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: "transparent !important",
                        },
                }}
            />
            {tabIndex === 0 && (
                <>
                    {
                        isField ? (
                            <Button
                                variant="text"
                                size="small"
                                sx={{marginTop: "20px", backgroundColor: "transparent", height: "3em"}}
                                onClick={() => setOpenModal(true)}
                            >
                                <FilterSettingIcon/>
                            </Button>
                        ) : (
                            <>
                                {tableNav ? tableNav : null}
                        </>
                    )
                }
              <Button
                variant="text"
                size="small"
                sx={{ marginTop: "20px", backgroundColor: "transparent", height: "3em" }}
                onClick={handleOpenMenu}
              >
                <FilterAltIcon  sx={{ color: `${colors.customColor[100]} !important`}}/>
              </Button>
            </>
          )}

          <Menu
            anchorEl={openMenu}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
          >
            {headerNames?.map((header) => (
              <MenuItem key={header.header}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedHeaders.some(
                        (selectedHeader) =>
                          selectedHeader.header === header.header
                      )}
                      onChange={handleHeaderCheckboxChange(header)}
                      sx={{
                        color: `${colors.customColor[800]} !important`,
                        "&.Mui-checked": {
                          color: `${colors.customColor[800]} !important`,
                        },
                      }}
                    />
                  }
                  label={header.header}
                />
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      {tabIndex  === 0 && isField && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "90%",
              position: "relative",
              left: "5%",
              paddingBottom: "2%",
            }}
          >
            <div
              style={{
                border: "1px solid #03A4BB",
                padding: "1%",
                borderRadius: "15px",
              }}
            >
              <Typography>Total Area:{`${Math.round(summary?.properties?.total_area)}`}</Typography>
            </div>

            <div
              style={{
                border: "1px solid #03A4BB",
                padding: "1%",
                borderRadius: "15px",
              }}
            >
              <Typography> Total eSurvey Completed:{`${summary?.properties?.surveyed_area === null ? 0 : summary?.properties?.surveyed_area.toFixed(2)}`}</Typography>
            </div>

            <div
              style={{
                border: "1px solid #03A4BB",
                padding: "1%",
                borderRadius: "15px",
              }}
            >
              <Typography> Total Fields Approved:{`${summary?.properties?.fields_approved}`}</Typography>
            </div>

            <div
              style={{
                border: "1px solid #03A4BB",
                padding: "1%",
                borderRadius: "15px",
              }}
            >
              <Typography> Total Fields Drawn:{`${summary?.properties?.total_fields}`}</Typography>
            </div>
          </div>
        </>
      )}

      <table>
        <thead>
          <tr
            style={{
              color: `${colors.customColor[100]}`,
              height: "30px",
              backgroundColor: "#ECF1FA",
              position: "sticky",
            }}
          >
            {selectedHeaders?.map((header, index) => (
              <th
                key={index}
                style={{
                  width: `${100 / selectedHeaders.length}%`,
                  padding: "8px",
                }}
              >
                {header?.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowsData?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {selectedHeaders.map((header, headerIndex) => (
                <td
                  key={headerIndex}
                  style={{
                    width: `${100 / selectedHeaders?.length}vw`,
                    padding: "8px",
                    borderBottom: "0.5px #5A6975 dotted",
                    height: "40px",
                    textAlign: "center",
                  }}
                >
                  {typeof header?.value === "function"
                    ? header?.value(row)
                    : row[header?.value]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {footer}
      {ModalComponent && (
        <ModalComponent open={openModal} onClose={() => setOpenModal(false)} />
      )}
    </div>
  );
}
