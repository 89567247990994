import {
  Button,
  TextField,
  useTheme,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import Topbar from "components/Topbar";
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { renderToString } from "react-dom/server";
import { InIcon, OutIcon } from "assets/Icons";
import { tokens } from "assets/Theme";
import { getAttendanceData } from "api/attendanceManagement/attendance";
import { getUsers } from "api/develoPro/Tasks";

export default function AttendanceManagement() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [attendanceData, setAttendanceData] = useState([]);
  const [action, setAction] = useState("");
  const [surveyorList, setSurveyorList] = useState([]);
  const [selectedSurveyors, setSelectedSurveyors] = useState([]);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");

  const inIcon = new L.divIcon({
    html: renderToString(<InIcon />),
    iconSize: [32, 32],
  });

  const outIcon = new L.divIcon({
    html: renderToString(<OutIcon />),
    iconSize: [32, 32],
  });
  const handleToDateChange = (event) => {
    const toDateValue = event.target.value;
    setToDate(toDateValue);
  };

  const handleFromDateChange = (event) => {
    const fromDateValue = event.target.value;
    setFromDate(fromDateValue);
  };
  const fetchAttendanceData = async () => {
    try {
      const queryParams = generateQueryParams();
      const response = await getAttendanceData(queryParams);
      setAttendanceData(response);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };
  const fetchSurveyor = async () => {
    try {
      const res = await getUsers();
      const surveyors = res?.filter((user) => user.role === "S");
      setSurveyorList(surveyors);
    } catch (error) {
      console.error(error);
    }
  };
  const handleParamChange = (event) => {
    const { value } = event.target;
    setSelectedSurveyors(value);
  };
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };
  const generateQueryParams = () => {
    const queryParams = {};

    if (selectedSurveyors.length > 0) {
      queryParams.user__in = selectedSurveyors.join(",");
    }

    if (action) {
      queryParams.action = action;
    }

    if (toDate) {
      queryParams.created_at__lte = formatDate(toDate);
    }

    if (fromDate) {
      queryParams.created_at__gte = formatDate(fromDate);
    }

    return queryParams;
  };
  useEffect(() => {
    fetchSurveyor();
  }, []);
  useEffect(() => {
    fetchAttendanceData();
  }, [action]);
  const handleCheckBox = (actions) => {
    setAction(actions);
  };

  return (
    <div>
      <Topbar />
      <div
        style={{
          width: "70%",
          height: "75vh",
          background: "white",
          boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
          borderRadius: 20,
          position: "relative",
          left: "16%",
          marginTop: "6%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "90%",
            position: "relative",
            left: "5%",
            top: "3%",
          }}
        >
          <Stack sx={{ width: "100%" }}>
            <Typography>Surveyor</Typography>
            <TextField
              select
              value={selectedSurveyors}
              onChange={handleParamChange}
              variant="outlined"
              SelectProps={{
                multiple: true,
              }}
              size="small"
              placeholder="Surveyor"
              sx={{
                position: "relative",
                top: "5px",
                width: "80%",
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${colors.customColor[100]}`,
                },
                "& label.Mui-focused": {
                  color: `${colors.customColor[100]} !important`,
                },
                "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  { borderColor: `${colors.customColor[100]} !important` },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${colors.customColor[100]} !important`,
                  borderRadius: "15px !important",
                },
              }}
            >
              {surveyorList.map((option) => (
                <MenuItem key={option?.id} value={option?.id}>
                  {option.username}
                </MenuItem>
              ))}
            </TextField>
          </Stack>

          <Stack sx={{ width: "100%" }}>
            <Typography>From Date</Typography>
            <TextField
              size="small"
              type="date"
              title="From Date"
              value={fromDate}
              onChange={handleFromDateChange}
              sx={{
                position: "relative",
                top: "5px",
                width: "80%",
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${colors.customColor[100]}`,
                },
                "& label.Mui-focused": {
                  color: `${colors.customColor[100]} !important`,
                },
                "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  { borderColor: `${colors.customColor[100]} !important` },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${colors.customColor[100]} !important`,
                  borderRadius: "15px !important",
                },
              }}
            />
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <Typography>To Date</Typography>
            <TextField
              size="small"
              type="date"
              title="to Date"
              value={toDate}
              onChange={handleToDateChange}
              sx={{
                position: "relative",
                top: "5px",
                width: "80%",
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${colors.customColor[100]}`,
                },
                "& label.Mui-focused": {
                  color: `${colors.customColor[100]} !important`,
                },
                "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  { borderColor: `${colors.customColor[100]} !important` },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${colors.customColor[100]} !important`,
                  borderRadius: "15px !important",
                },
              }}
            />
          </Stack>
          <Button
            sx={{
              position: "relative",
              top: "20px",
              background: `${colors.customColor[100]}`,
              width: "300px",
              height: "40px",
              borderRadius: "15px",
              fontWeight: "bold",
              color: "#fff",
            }}
            onClick={fetchAttendanceData}
          >
            Search
          </Button>
        </div>
        <div style={{ position: "relative", top: "30px", left: "40px" }}>
          <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={action === "si"}
                  sx={{
                    color: `${colors.customColor[800]} !important`,
                    "&.Mui-checked": {
                      color: `${colors.customColor[800]} !important`,
                    },
                  }}
                  onChange={() => handleCheckBox("si")}
                />
              }
              label="Currently Signed in"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={action === "so"}
                  sx={{
                    color: `${colors.customColor[800]} !important`,
                    "&.Mui-checked": {
                      color: `${colors.customColor[800]} !important`,
                    },
                  }}
                  onChange={() => handleCheckBox("so")}
                />
              }
              label="Currently Signed out"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={action === ""}
                  sx={{
                    color: `${colors.customColor[800]} !important`,
                    "&.Mui-checked": {
                      color: `${colors.customColor[800]} !important`,
                    },
                  }}
                  onChange={() => handleCheckBox("")}
                />
              }
              label="All"
            />
          </FormGroup>
        </div>
        <div style={{ marginTop: "4%", width: "90%" }}>
          <MapContainer
            center={[33.6844, 73.0479]}
            zoom={3}
            style={{
              minWidth: "100%",
              width: "100%",
              height: "55vh",
              borderRadius: "12px",
              marginLeft: "5%",
              position: "relative",
            }}
            attributionControl={false}
          >
            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
            {attendanceData?.map((data, index) => {
              if (!data.user_location) return null;
              const locationMatch = data.user_location?.geometry?.coordinates;
              if (!locationMatch) return null;
              const flippedCoordinates = [locationMatch[1], locationMatch[0]];

              const icon = data.action === "si" ? inIcon : outIcon;
              return (
                <Marker
                  key={index}
                  position={flippedCoordinates}
                  title={`Attendance at ${data.timestamp}`}
                  icon={icon}
                >
                  <Popup>
                    <Box component="section" sx={{ p: 1 }}>
                      <img
                        src={`${data?.picture_url}`}
                        width="140px"
                        height="140px"
                        alt={`${data?.user?.username}_profilepicture`}
                        loading="lazy"
                      />

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ marginTop: 1 }}
                      >
                        <Typography variant="h6" sx={{ color: "#1bc693" }}>
                          Name:
                        </Typography>
                        <Typography>{data?.user?.username}</Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ marginTop: 1 }}
                      >
                        <Typography variant="h6" sx={{ color: "#1bc693" }}>
                          Time:
                        </Typography>
                        <Typography>
                          {data?.timestamp.split("T")[1].split(".")[0]}
                        </Typography>
                      </Stack>

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ marginTop: 1 }}
                      >
                        <Typography variant="h6" sx={{ color: "#1bc693" }}>
                          Status:
                        </Typography>
                        <Typography>
                          {data?.action === "si" ? "Signed In" : "Signed Out"}
                        </Typography>
                      </Stack>
                    </Box>
                  </Popup>
                </Marker>
              );
            })}
          </MapContainer>
        </div>
      </div>
    </div>
  );
}
