import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useEffect, useState} from "react";
import {
    getBrokers,
} from "../../api/ginnerTraceability/broker";
import {getHeaps} from "../../api/ginnerTraceability/heap";
import {getBatches} from "../../api/ginnerTraceability/batch";
import {getDrivers} from "../../api/ginnerTraceability/driver";
import DataEntryTab from "./Tabs/DataEntryTab";
import BatchTab from "./Tabs/BatchTab";
import AllBatchesTab from "./Tabs/AllBatchesTab";
import {activeTabStyle} from "./helpers/css/styles";
import {useAllBatches} from "../AppStates/AllBatches";
import BaleTab from "./Tabs/BaleTab";
import {useBales} from "../AppStates/Bales";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

//tab handling
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {

    //value related to tabs
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /**-----------------------Data Entry Tab Fields-------------------------------*/

    const [brokerOptions, setBrokerOptions] = useState([]);
    const [heapOptions, setHeapOptions] = useState([]);
    const [broker, setBroker] = useState({});

    useEffect(() => {
        getBrokers().then((res) => {
            if (res === false ) {
                alert('Something went wrong Fetching Brokers! Please refresh.');
                throw new Error('Error getting Brokers');
            }else if (res.length === 0){
                alert('No Brokers found, Kindly refresh or add brokers!')
            }
            else {
                const addedValueBroker = res.map((b) => {
                    return {
                        value: b.id,
                        label: b.name + ' - ' + b.cnic,
                        ...b
                    }
                });
                setBrokerOptions(addedValueBroker);
            }

        }).catch((err) => {
                console.error('Error getting Brokers', err);
            }
        );

        getBatches().then( response => {
            if (response){
                response.map((batch) => {
                    if (batch?.status.toLowerCase() === 'in_progress' ){
                        batch.action = ['delete','Mark As Complete'];
                        batch.statusNew = 'In Progress';
                        batch.label = batch.id;
                        batch.value = batch.id;
                    }else if (batch?.status.toLowerCase() === 'not_started' ){
                        batch.action = ['delete','start'];
                        batch.statusNew = 'Not Started';
                        batch.label = batch.id;
                        batch.value = batch.id;
                    }
                })
                setBatchData(response);
            }else {
                alert('No Batches found, Kindly refresh or add batches!')
            }
        })
        getHeaps().then(response => {
            if (response.length > 0) {
                // Map and modify the response before setting the state
                const modifiedResponse = response.map(heap => {
                    heap.value = heap.id;
                    heap.label = heap.id;
                    return heap;
                });
                setHeapOptions(modifiedResponse);
            } else {
                alert('No Heaps found, Kindly refresh or add heaps!');
            }
        });

        getDrivers().then( response => {
            if (response){
                const driverArray = response.map((driver) => {
                    driver.value = driver.id;
                    driver.label = driver?.first_name + ' ' + driver?.last_name + ' '  + driver.id;
                    return driver;
                });
                setDriverOptions(driverArray);
            }else {
                alert('No Drivers found, Kindly refresh or add drivers!')
            }

        });

    }, []);



    /**-----------------------End of Data Entry Tab Fields-------------------------------*/

    /**-----------------------Batch Tab Fields-------------------------------*/
    const [driverOptions, setDriverOptions] = useState([]);
    const [batchData, setBatchData] = useState([]);
    const [growerOptions, setGrowerOptions] = useState([]);
    //for updating batches from modals
    useEffect( () => {
        console.log('Batch Data updated', batchData)
    }, [batchData]);


    /**-----------------------End of Batch Tab Fields-------------------------------*/

    const { allBatches, setAllBatches } = useAllBatches();
    const {bales, setBales, baleLoading, baleError} = useBales();
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Data Entry" sx={value === 0 ? activeTabStyle : {}} {...a11yProps(0)} />
                    <Tab label="Batch" sx={value === 1 ? activeTabStyle : {}} {...a11yProps(1)} />
                    <Tab label="All Batches" sx={value === 2 ? activeTabStyle : {}} {...a11yProps(2)} />
                    <Tab label="Bales" sx={value === 3 ? activeTabStyle : {}}  {...a11yProps(3)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
            {/*Data Entry Tab content*/}
                <DataEntryTab
                    heapOptions={heapOptions}
                    setHeapOptions={setHeapOptions}
                    brokerOptions={brokerOptions}
                    setBrokerOptions={setBrokerOptions}
                    driverOptions={driverOptions}
                    setDriverOptions={setDriverOptions}
                    growerOptions={growerOptions}
                    setGrowerOptions={setGrowerOptions}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
            {/*Batch Tab content*/}
                <BatchTab
                    driverOptions={driverOptions}
                    heapOptions={heapOptions}
                    batchData={batchData}
                    setBatchData={setBatchData}
                    brokerOptions={brokerOptions}
                    setAllBatches={setAllBatches}
                    allBatches={allBatches}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                    <AllBatchesTab
                        brokerOptions={brokerOptions}
                        heapOptions={heapOptions}
                        batchOptions={batchData}
                        driverOptions={driverOptions}
                        allBatches={allBatches}
                        setAllBatches={setAllBatches}
                        setBatchOptions={setBatchData}
                    />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <BaleTab />
            </CustomTabPanel>
        </Box>
    );
}