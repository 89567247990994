import React from 'react';
import { Skeleton, Stack } from '@mui/material';

const Loader = () => {
  return (
    <div>
        <Stack direction='row' spacing={100} >
      <Skeleton variant="rectangular" width='10%' height='5vh' animation="wave" sx={{ borderRadius: 5 ,position:'relative',top:'70px', left:'30px'}} />
      <Skeleton variant="rectangular" width='24.5%' height='5vh' animation="wave" sx={{ borderRadius: 5 ,position:'relative',top:'70px'}} />
      </Stack>
      <Skeleton variant="rectangular" width='95%' height='70vh' animation="wave" sx={{ borderRadius: 5 ,position:'relative',top:'80px', left:'30px'}} />
    </div>
  );
};

export default Loader;
