import React,{useEffect,useState} from 'react'
import Topbar from 'components/Topbar';
import ManagementTable from 'components/tables/ManagementTable';
import { Stack,Button } from '@mui/material';
import DiseaseModal from 'components/diseaseManagement/DiseaseModal';
import { getDisease } from 'api/surveySetup/Disease';
import { incrementDiseasePage,decrementDiseasePage } from 'reduxSetup/SurveySetupPages';
import { useSelector, useDispatch } from 'react-redux';
export default function DiseaseManagement() {
    const [modalOpen, setModalOpen] = useState(false);
    const [diseaseList, setDiseaselist] = useState([]);
    const [next, setNext] = useState()
    const dispatch = useDispatch();
    const currentPage = useSelector(state => state.disease.page);
    const headerNames = [{header:'Disease Name',value:'name'},{header:'Created by',value:'admin_details.name'},{header:'Creation Date',value:'created_at'}];
    const params = {p: currentPage}
    const fetchDisease = async () => {
      try {
      const res = await getDisease(params)
      setNext(res?.next)
      const formattedRes = res.map(item => ({
        ...item,
        created_at: new Date(item.created_at).toLocaleString(),
      }));
      setDiseaselist(formattedRes);
      console.log(res)
      } catch (error) {
        console.error(error)
      }
    }
    useEffect(()=>{
      fetchDisease();
    },[currentPage])

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSaveVariety = (variety) => {
    // Handle save variety logic
    console.log('Saving variety:', variety);
    handleCloseModal();
  };
  const handleNextPage = () => {
    dispatch(incrementDiseasePage());
  };

  const handlePreviousPage = () => {
    dispatch(decrementDiseasePage());
  };

  return (
    <div>
    <Topbar/>
  
    <div style={{width:'60%', marginTop:'10px'}}>
    <ManagementTable
        headerNames={headerNames}
        rowsData={diseaseList}
        title='Crop Health Management'
        actionButtonLabel='Add More'
        onActionButtonClick={handleOpenModal}
        ModalComponent={DiseaseModal} 
        footer={<Stack direction="row" spacing={2}
        sx={{marginTop: '5%', marginBottom:'3%', justifyContent: 'flex-end', position: 'relative', right: '7%'}}>
     <Button className='footerbtn-variety' variant='outlined' onClick={handlePreviousPage}
            >
         previous
     </Button>
     <Button className='footerbtn-variety' variant='outlined' disabled={next === null} onClick={handleNextPage}
            >
         next
     </Button>
</Stack>}
      />

      {/* Render the CropConditionModal component conditionally */}
      {modalOpen && (
        <DiseaseModal
          open={modalOpen}
          onClose={handleCloseModal}
          onSaveVariety={handleSaveVariety}
        />
      )}
    </div>
    </div>
  )
}