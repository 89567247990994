import {Button, Stack, Typography, useTheme} from "@mui/material";
import React from "react";
import {tokens} from "../../assets/Theme";
import {teamTrakDecrement, teamTrakIncrement} from "../../reduxSetup/TeamTrak";


export default function TeamTrakTableFooter({currentPage, record, next, dispatch}) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <Typography
                sx={{
                    position: "relative",
                    top: "35px",
                    left: "10px",
                    color: `${colors.customColor[800]}`,
                }}
            >{`Page: ${currentPage} - ${Math.ceil(record / 10)}`}</Typography>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    marginTop: "2%",
                    marginBottom: "1%",
                    justifyContent: "flex-end",
                    position: "relative",
                    right: "7%",
                }}
            >
                <Button
                    className="footerbtn-variety"
                    variant="outlined"
                    onClick={() => dispatch(teamTrakDecrement())}
                >
                    previous
                </Button>
                <Button
                    className="footerbtn-variety"
                    variant="outlined"
                    onClick={() => dispatch(teamTrakIncrement())}
                    disabled={next === null}
                >
                    next
                </Button>
            </Stack>
        </div>
    )
}