import React, { useState } from "react";
import { tokens } from "assets/Theme";

import { Button, Typography, TextField, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

// import CropTypeModal from './CroptypeModal';

export default function ManagementTable({
  headerNames,
  rowsData,
  title,
  actionButtonLabel,
  onActionButtonClick,
  footer,
  ModalComponent,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const headersCsv = headerNames?.map((header) => ({
    label: header.header,
    key: header.value,
  }));
  const csvReport = {
    data: rowsData,
    headers: headersCsv,
    filename: `${title}.csv`,
  };
  const exportPDF = () => {
    const doc = new jsPDF();
    const tableColumn = headerNames?.map((header) => header.header);
    const tableRows = [];

    rowsData.forEach((row) => {
      const rowData = headerNames?.map((header) => row[header.value]);
      tableRows.push(rowData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.text(`${title}`, 14, 15);
    doc.save(`${title}.pdf`);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenModal = (varietyData) => {
    setEditData(varietyData);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleActionClick = (variety) => {
    onActionButtonClick(variety);
    handleCloseModal();
  };

    const getNestedValue = (obj, path) => {
        const parts = path.split('.');
        let value = obj;
        for (const part of parts) {
            if (value[part] === undefined) return '';
            value = value[part];
        }
        return value;
    };

  return (
    <div
      style={{
        width: "100%",
        border: "1px solid #000",
        borderRadius: "12px",
        boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
        background: `${colors.customColor[500]}`,
        marginLeft: "35%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "98%",
        }}
      >
        <Typography
          variant="h3"
          fontWeight="bold"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          {title}
        </Typography>
        <TextField
          label="Search here"
          value={searchQuery}
          onChange={handleSearchChange}
          variant="outlined"
          size="small"
          sx={{
            position: "relative",
            marginTop: "20px",
            marginBottom: "20px",
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: `${colors.customColor[800]} !important`,
              borderRadius: "15px !important",
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: `${colors.customColor[100]} !important`,
            },
            "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "transparent !important",
              },
          }}
        />
        <Typography
          variant="h6"
          color={colors.customColor[100]}
          sx={{ marginTop: "27px", width: "2px" }}
        >
          Export
        </Typography>
        <div style={{ marginTop: "27px", marginLeft: "8px" }}>
          <button
            style={{
              width: "5px !important",
              background: "transparent",
              boxShadow: "none",
              textDecoration: "underline",
              borderColor: "transparent",
              borderRight: `1px solid ${colors.customColor[800]}`,
              color: `${colors.customColor[800]}`,
            }}
          >
            <CSVLink
              {...csvReport}
              style={{
                textDecoration: "none",
                color: `${colors.customColor[800]}`,
              }}
            >
              CSV
            </CSVLink>
          </button>
          <button
            style={{
              width: "5px !important",
              background: "transparent",
              boxShadow: "none",
              textDecoration: "underline",
              borderColor: "transparent",
              color: `${colors.customColor[800]}`,
            }}
            onClick={exportPDF}
          >
            PDF
          </button>
        </div>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon fontSize="small" />}
          onClick={() => handleOpenModal(null)}
          sx={{
            background: `${colors.customColor[100]}`,
            color: `#fff`,
            height: "40px",
            marginTop: "20px",
            borderRadius: "8px",
            "&:hover": {
              background: `green`,
            },
          }}
        >
          {actionButtonLabel}
        </Button>
      </div>
      <table width={'100%'}>
        <thead>
          <tr
            style={{
              color: `${colors.customColor[100]}`,
              height: "30px",
              backgroundColor: "#ECF1FA",
            }}
          >
            {headerNames?.map((header, index) => (
              <th key={index}>{header?.header}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rowsData?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headerNames?.map((header, headerIndex) => (
                <td
                  key={headerIndex}
                  style={{
                    width: "190px",
                    borderBottom: "0.5px #5A6975 dotted",
                    height: "40px",
                    textAlign: "center",
                  }}
                >
                    {getNestedValue(row, header.value)}
                </td>
              ))}
              <td
                style={{
                  width: "190px",
                  borderBottom: "0.5px #5A6975 dotted",
                  height: "40px",
                  textAlign: "center",
                }}
              >
                <Button
                  size="small"
                  variant="text"
                  sx={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    color: `${colors.customColor[800]}`,
                  }}
                  onClick={() => handleOpenModal(row)}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {footer}
      {ModalComponent && (
        <ModalComponent
          open={openModal}
          onClose={handleCloseModal}
          onSaveVariety={handleActionClick}
          editData={editData}
        />
      )}
    </div>
  );
}
