import { createSlice } from '@reduxjs/toolkit';

// Crop Type Slice
export const cropTypeSlice = createSlice({
  name: 'cropType',
  initialState: { page: 1 },
  reducers: {
    incrementPage: state => { state.page += 1 },
    decrementPage: state => { state.page = Math.max(1, state.page - 1) }
  }
});

// Crop Condition Slice
export const cropConditionSlice = createSlice({
  name: 'cropCondition',
  initialState: { page: 1 },
  reducers: {
    incrementPage: state => { state.page += 1 },
    decrementPage: state => { state.page = Math.max(1, state.page - 1) }
  }
});

// Crop Stage Slice
export const cropStageSlice = createSlice({
  name: 'cropStage',
  initialState: { page: 1 },
  reducers: {
    incrementPage: state => { state.page += 1 },
    decrementPage: state => { state.page = Math.max(1, state.page - 1) }
  }
});

// Disease Management Slice
export const diseaseSlice = createSlice({
  name: 'disease',
  initialState: { page: 1 },
  reducers: {
    incrementPage: state => { state.page += 1 },
    decrementPage: state => { state.page = Math.max(1, state.page - 1) }
  }
});

// Variety Management Slice
export const varietySlice = createSlice({
  name: 'variety',
  initialState: { page: 1 },
  reducers: {
    incrementPage: state => { state.page += 1 },
    decrementPage: state => { state.page = Math.max(1, state.page - 1) }
  }
});

// Form Customization Slice
export const formCustomizationSlice = createSlice({
  name: 'formCustomization',
  initialState: { page: 1 },
  reducers: {
    incrementPage: state => { state.page += 1 },
    decrementPage: state => { state.page = Math.max(1, state.page - 1) },
    baseCase: state => {state.page = 1}
  }
});



// Export Actions
export const { incrementPage: incrementCropTypePage, decrementPage: decrementCropTypePage } = cropTypeSlice.actions;
export const { incrementPage: incrementCropConditionPage, decrementPage: decrementCropConditionPage } = cropConditionSlice.actions;
export const { incrementPage: incrementCropStagePage, decrementPage: decrementCropStagePage } = cropStageSlice.actions;
export const { incrementPage: incrementDiseasePage, decrementPage: decrementDiseasePage } = diseaseSlice.actions;
export const { incrementPage: incrementVarietyPage, decrementPage: decrementVarietyPage } = varietySlice.actions;
export const { incrementPage: incrementFormCustomizationPage, decrementPage: decrementFormCustomizationPage, baseCase: formBaseCase } = formCustomizationSlice.actions;


