import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";

export default function EditVarietyField({varietyList, varietyAcreage, cropStageList, setVarietyAcreage, attributeData, handleChangeSelectedVariety}) {
  const [selectedVarieties, setSelectedVarieties] = useState([]);

  const handleAcreageChange = (event, index) => {
    const updatedVarieties = [...selectedVarieties];
    updatedVarieties[index].acres = parseFloat(event.target.value);
    setSelectedVarieties(updatedVarieties);
  };

  useEffect(() => {
    if (attributeData?.varieties && attributeData?.varieties.length > 0) {
      // Use varieties data from attributeData if available
      setSelectedVarieties(attributeData?.varieties?.map(variety => ({
        id: variety?.variety?.id,
        name: variety?.variety?.name,
        crop_stage: variety?.crop_stage?.id,
        acres: variety?.acres
      })));
      setVarietyAcreage(attributeData?.varieties?.map(variety => ({
        id: variety?.variety?.id,
        variety: variety?.variety?.name,
        crop_stage: variety?.crop_stage?.id,
        acres: variety?.acres
      })));

    } else {
      // Use default varieties from varietyAcreage
      const defaultVarieties = varietyAcreage.map((variety) => {
        const selectedVariety = varietyList.find((v) => v?.name === variety?.variety);
        return {
          id: variety?.id,
          name: selectedVariety?.name,
          crop_stage: variety?.crop_stage,
          acres: variety?.acres
        };
      });
      setSelectedVarieties(defaultVarieties);
    }
  }, [varietyList, attributeData]);

  useEffect(() => {
    handleChangeSelectedVariety(selectedVarieties);
  }, [selectedVarieties]);

  const handleSelectedVarietyChange = (event, value) => {
    setSelectedVarieties(value);
    const newVarietyAcreage = value.map((variety) => {
      const matchingCropStage = cropStageList.find(stage => stage.id === variety.crop_stage);
      return {
        id: variety?.variety?.id,
        variety: variety?.name,
        crop_stage: matchingCropStage ? matchingCropStage?.id : null,
        acres: variety?.acres
      };
    });
    setVarietyAcreage(newVarietyAcreage);
  };

  const handleCropStageChange = (event, newValue, index) => {
    const updatedVarieties = [...selectedVarieties];
    updatedVarieties[index].crop_stage = newValue ? newValue.id : null;
    setSelectedVarieties(updatedVarieties);

    const updatedVarietyAcreage = [...varietyAcreage];
    const selectedVarietyId = selectedVarieties[index].id;

    const varietyIndex = updatedVarietyAcreage.findIndex(v => v?.id === selectedVarietyId);
    if (varietyIndex !== -1) {
      updatedVarietyAcreage[varietyIndex].crop_stage = newValue ? newValue.id : null;
      setVarietyAcreage(updatedVarietyAcreage);
    }
  };

  return (
      <>
        {varietyList.length >= 1 && (
            <Autocomplete
                multiple
                id="tags-standard"
                options={varietyList}
                getOptionLabel={(option) => `${option?.name}(${option?.id})`}
                value={selectedVarieties}
                onChange={handleSelectedVarietyChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Varieties"
                        placeholder="Select Varieties"
                        sx={{ margin: 3 }}
                    />
                )}
            />
        )}
        {selectedVarieties.map((variety, index) => (
            <div key={index}>
              <TextField
                  label={variety?.name + ' (acres)'}
                  value={variety?.acres}
                   type="number" 
                  step="0.00001"
                  onChange={(event) => handleAcreageChange(event, index)}
                  sx={{ margin: 3 }}
              />
              <Autocomplete
                  id={`cropStage-${index}`}
                  options={cropStageList}
                  getOptionLabel={(option) => option.name}
                  value={cropStageList.find(stage => stage?.id === variety?.crop_stage) || null}
                  onChange={(event, newValue) => handleCropStageChange(event, newValue, index)}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Crop Stage"
                          variant="outlined"
                          placeholder="Select Crop Stage"
                      />
                  )}
                  sx={{ margin: 3, marginTop: 0 }}
              />
            </div>
        ))}
      </>
  );
}
