import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import { Button, Stack,Box, Modal } from '@mui/material';

export default function ChangeLogModal({ isOpen, onClose, changeLog }) {
    return (
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: '#fff', boxShadow: 24, p: 4, borderRadius:'12px' }}>
          <Typography variant="h4" margin="2%">Change Log Details</Typography>
  
          {changeLog?.crop_stage && (
            <Typography>{`Crop Stage: ${changeLog?.crop_stage}`}</Typography>
          )}
          {changeLog?.crop_type && (
            <Typography>{`Crop Type: ${changeLog?.crop_type}`}</Typography>
          )}
          {changeLog?.remarks && (
            <Typography>{`remarks: ${changeLog?.remarks}`}</Typography>
          )}
  
          {changeLog?.variety && changeLog?.variety?.length > 0 && (
            <div>
              <Typography variant="h6">Varieties:</Typography>
              {changeLog?.variety.map((variety, index) => (
                <div key={index}>
                  <Typography>{`Variety: ${variety?.variety}`}</Typography>
                  <Typography>{`Acres: ${variety?.acres}`}</Typography>
                </div>
              ))}
            </div>
          )}
  
        </Box>
      </Modal>
    );
  }
  
