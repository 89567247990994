import React, {useEffect, useState} from 'react';
import {Modal, Paper, Typography, Button} from '@mui/material';
import {FileIcon} from 'assets/Icons';
import {bulkUploadTasks} from "../../api/develoPro/Projects";

const ImportModal = ({open, handleClose}) => {
    const [file, setFile] = useState(null);
    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        setFile(droppedFile);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleFileInputChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    /** Async Function to Handle File Uploads to Backend. */
    const handleImport = async () => {
        bulkUploadTasks(file);
        handleClose();
        setFile(null);
    };
    /** Remove file each time the modal is closed */
    useEffect(() => {
        if (!open){
            setFile(null);
        }
    }, [open])

    return (<Modal open={open} onClose={handleClose}>
        <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
            <Paper style={{padding: '8px', maxWidth: '400px', borderRadius: '10px'}}>
                <Paper
                    elevation={3}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    style={{
                        width: '320px',
                        height: '180px',
                        borderRadius: '10px',
                        border: '2px dashed #1BC795 ',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        padding: '20px',
                    }}
                >
                    {file ? (<div style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <Typography variant="subtitle1">File selected: {file.name}</Typography>
                        <Button
                            variant="text"
                            sx={{color: '#1BC795'}}
                            component="span"
                            onClick={handleImport}
                        >
                            Upload Data
                        </Button>
                    </div>) : (<React.Fragment>
                        <input
                            accept=".csv"
                            style={{display: 'none'}}
                            id="file-upload-input"
                            type="file"
                            onChange={handleFileInputChange}
                        />
                        <label htmlFor="file-upload-input" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <div style={{paddingBottom: '10px'}}>
                                <FileIcon/>
                            </div>
                            <Typography variant="subtitle">Drop your document here, or select</Typography>
                            <Button
                                variant="text"
                                sx={{color: '#1BC795'}}
                                component="span"
                            >
                                Click to browse
                            </Button>
                        </label>
                    </React.Fragment>)}
                </Paper>
            </Paper>
        </div>
    </Modal>);
};

export default ImportModal;
