import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { tokens } from "assets/Theme";
import { useTheme } from "@mui/material";

const AutocompleteCustom = ({
  value,
  id,
  onChange,
  name = "",
  label = "",
  options,
  width = "50%",

  ...otherProps
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Autocomplete
      {...otherProps}
      options={options}
      value={value}
      sx={{ width: width, position: "relative", marginTop: 2 }}
      onChange={(event, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          label={label}
          name={name}
          sx={{
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: `${colors.customColor[100]}`,
            },
            "& label.Mui-focused": {
              color: `${colors.customColor[100]} !important`,
            },
            "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
              {
                borderColor: `${colors.customColor[100]} !important`,
              },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: `${colors.customColor[100]} !important`,
              borderRadius: "px !important",
            },
          }}
        />
      )}
    />
  );
};

export default AutocompleteCustom;
