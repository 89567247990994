import { getFields } from "api/allSurveys/Fields";
import Topbar from "components/Topbar";
import BoundaryDetailTable from "components/tables/BoundaryDetailTable";
import React, { useEffect, useState } from "react";
import { tokens } from "assets/Theme";
import { useTheme, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { FieldsHeaders, UsersHeaders } from "components/allSurvey/Helper";
import { assignUsers, getUsers, unassignUsers } from "api/allSurveys/User";
import { clearPage, clearRowsIds } from "reduxSetup/AllSurveyPages";
import { useDispatch, useSelector } from "react-redux";
import { fieldIncrement, fieldDecrement } from "reduxSetup/AllSurveyPages";
import FilterModal from "components/allSurvey/FilterModal";
import {VillagesProvider} from "../../components/AppStates/Villages";

export default function BoundaryDetail() {
  const [fieldsData, setFieldsData] = useState([]);
  const [record, setRecord] = useState();
  const [next, setNext] = useState();
  const [assignedUser, setAssignedUser] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const selectedRows = useSelector((state) => state.rowsIdsSlice);
  const currentPage = useSelector((state) => state.fieldsSlice.fieldPage);
  const filteredData = useSelector((state) => state.filterData);
  const [search, setSearch] = useState('');
  const [gateName, setGateName] = useState("");

  const fetchFieldsData = async (params) => {
    try {
      const res = await getFields(params);

      const gridData = res?.results?.features.map((ft) => {
        return {
          id: ft.id,
          passbook: ft.grower?.passbook_number,
          grower: ft.grower?.grower_name,
          surveyor: ft.properties?.user,
          createdBy: ft.properties?.user,
          fieldName: ft.properties?.field_name,
          created_at: new Date(ft.properties?.created_at).toLocaleString(),
          cropType: ft.properties?.crops_type?.name,
          total_area: ft.properties?.total_area.toFixed(2),
          status: ft.properties?.status,
        };
      });

      setFieldsData(gridData);
      setGateName(res?.results.features[0].gate_circle.properties.name);
      // setFieldsData(res?.data?.results);
      setNext(res?.next);
      setRecord(res?.count);
    } catch (error) {
      console.error(error);
    }
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleSearch=(value) => {
    setSearch(value);
    dispatch(clearPage())
  }
  const fetchUsers = async () => {
    try {
      if (tabIndex === 1) {
        const res = await getUsers({ gate_circle: id,search: search });
        const assignedUsers = res.map((user) => ({
          id: user.id,
          fullname: user.fullname,
          role: user.role === "S" ? "Surveyor" : "Auditor",
          mobile_number: user?.mobile_number,
        }));
        setAssignedUser(assignedUsers);

      } else if (tabIndex === 2) {
        const res = await getUsers({ gate_circle: id, exclude: "true",search: search });
        const assignedUsers = res.map((user) => ({
          id: user.id,
          fullname: user.fullname,
          role: user.role === "S" ? "Surveyor" : "Auditor",
          mobile_number: user?.mobile_number,
        }));
        setAssignedUser(assignedUsers);

      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    dispatch(clearRowsIds());
  };
  const tabStyles = {
    "&.Mui-selected": {
      color: "#000",
      borderBottom: "2px solid blue",
    },
  };
  const handleAssignUsers = async () => {
    try {
      await assignUsers({ user_ids: selectedRows, gate_circle_id: id });
      fetchUsers();
      dispatch(clearRowsIds());
    } catch (error) {
      console.error(error);
    }
  };
  const handleUassignUsers = async () => {
    try {
      await unassignUsers({ user_ids: selectedRows, gate_circle_id: id });
      fetchUsers();
      dispatch(clearRowsIds());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const params = { gate_circle_id: id, p: currentPage, ...filteredData,search: search };
    fetchFieldsData(params);
    fetchUsers();

    // #on unmount
    return () => {
      setFieldsData([]);
      setAssignedUser([]);
    };
  }, [tabIndex, currentPage, filteredData,search]);

  return (
    <div>
      <VillagesProvider>
      <Topbar />
      <div style={{ width: "98%", position: "relative", left: "1%" }}>
        <BoundaryDetailTable
          headerNames={tabIndex === 0 ? FieldsHeaders : UsersHeaders}
          rowsData={tabIndex === 0 ? fieldsData : assignedUser}
          isField={true}
          Tabs={
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Tabs"
              sx={{ position: "relative", top: "12px" }}
            >
              <Tab label="Fields" value={0} sx={tabStyles} />
              <Tab label="Users" value={1} sx={tabStyles} />
              <Tab label="Assign" value={2} sx={tabStyles} />
            </Tabs>
          }
          title={gateName}
          onSearch={handleSearch}
          tabIndex={tabIndex}
          headerButtonLabel="Add"
          footer={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  position: "relative",
                  top: "35px",
                  left: "10px",
                  color: `${colors.customColor[800]}`,
                }}
              >{`Page: ${currentPage} - ${Math.ceil(record / 10)}`}</Typography>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  marginTop: "2%",
                  marginBottom: "1%",
                  justifyContent: "flex-end",
                  position: "relative",
                  right: "7%",
                }}
              >
                {selectedRows.length > 0 && (
                  <Button
                    className="footerbtn-variety"
                    variant="outlined"
                    onClick={
                      tabIndex === 1 ? handleUassignUsers : handleAssignUsers
                    }
                  >
                    {tabIndex === 1 ? "Remove" : "Add"}
                  </Button>
                )}
                <Button
                  className="footerbtn-variety"
                  variant="outlined"
                  onClick={() => dispatch(fieldDecrement())}
                >
                  previous
                </Button>
                <Button
                  className="footerbtn-variety"
                  variant="outlined"
                  onClick={() => dispatch(fieldIncrement())}
                  disabled={next === null}
                >
                  next
                </Button>
              </Stack>
            </div>
          }
          ModalComponent={FilterModal}
        />
        {modalOpen && (
          <FilterModal open={modalOpen} onClose={() => setModalOpen(false)} />
        )}
      </div>
      </VillagesProvider>
    </div>
  );
}
