import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import {Grid, MenuItem, Select, TextField} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {Unstable_NumberInput as BaseNumberInput} from "@mui/base/Unstable_NumberInput";
import {createBale, generateBaleQR} from "../../../api/ginnerTraceability/bale";
import {createBatchQrCode, deleteBatch, updateBatch} from "../../../api/ginnerTraceability/batch";
import {Delete, PlayArrow} from "@mui/icons-material";
import {deleteObjectById, updateObjectById} from "../helpers/js/ginnerHelper";
import {StyledInputElement, StyledInputRoot, StyledButton, inputStyle} from "../helpers/css/styles";
import SimpleBackdrop from "../../Backdrop";
import Loading from "../../BeatLoader";
import AddBatchModal from "../AddBatch";
import {CustomMenuProps} from "../helpers/css/styles";
import {useBales} from "../../AppStates/Bales";
const BASEURL = process.env.REACT_APP_BASEURL;

export default function BatchTab({driverOptions, heapOptions, batchData, setBatchData, brokerOptions, setAllBatches, allBatches}){

    const [pageSize, setPageSize] = useState(10);
    const [batchRecord, setBatchRecord] = useState(batchData.length);
    const [pageNumber, setPageNumber] = useState(1);
    let [viewBatch, setViewBatch] = useState([]);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [batchesToView, setBatchesToView] = useState(batchData.length ? batchData.slice(0, pageSize) : []);
    const {bales, setBales, loading: baleLoading, error: baleError} = useBales();
    const batches = batchData;

    // Batch Table Data
    const columns = [
        {
            field: 'id', headerName: 'Batch Number', width: 420,
        }, {
            field: 'statusNew', headerName: 'Batch status', width: 420,
        },
        {
            field: 'created_at', headerName: 'Created At', width: 460,
        },
        {
            field: 'action', headerName: 'Action', width: 420,
            renderCell: (params) => {
                const actions = params.value;
                return (
                    <div>
                        {actions.map((action, index) => (
                            <Button
                                key={index}
                                variant="outlined"
                                color= {action === 'delete' ? 'error' : 'success'}
                                style={{ marginRight: "10px" }}
                                onClick={() => handleAction(action,params)}
                            >
                                {action === 'delete' ? <Delete sx={{mr:1}}  fontSize={'small'} /> : <PlayArrow sx={{mr:1}}  fontSize={'small'} />}
                                {action}
                            </Button>
                        ))}
                    </div>
                )
            }
        }
    ];

    const pageOptions = Array.from(
        { length: Math.ceil(batchData / pageSize) },
        (_, index) => index + 1
    ).map((page) => (
        <MenuItem key={page} value={page}>
            {page}
        </MenuItem>
    ));

    const handleNextpage = () => {
        const totalPages = Math.ceil(batchRecord / pageSize);
        if (pageNumber < totalPages) {
            setPageNumber((prevPage) => prevPage + 1);
            setBatchesToView(batchData.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize));
        }
    }

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber((prevPage) => prevPage - 1);
            setBatchesToView(batchData.slice((pageNumber - 2) * pageSize, (pageNumber - 1) * pageSize));
        }
    }

    const handlePageChange = (e) => {
        setPageNumber(e.target.value);
        setBatchesToView(batchData.slice((e.target.value - 1) * pageSize, e.target.value * pageSize));
    }

    const handleRowClick = async (params, event) => {
        if (event?.target?.attributes[2]?.nodeValue !== 'action' && event?.target?.attributes[2]?.nodeValue !== 'button') {
            const updatedViewBatch = [];
            updatedViewBatch.push(params.row);
        }

    }
    const handleAction = (action,params) => {
        setShowBackdrop(true);
        if (action === 'delete') {
           deleteBatch(params.row.id).then((response) => {
               if (response) {
                   const newBatchData = deleteObjectById(batchData, params.row.id);
                   setBatchData(newBatchData);
                   setShowBackdrop(false);
                   alert('Batch Deleted Successfully');
               } else {
                   setShowBackdrop(false)
                   alert('Something went wrong! Please try again');
               }
           });

        } else if (action === 'start') {
            const data = {
                status: 'in_progress'
            }
            updateBatch(data, params.row.id,).then((response) => {
                if (response) {
                    response.action = ['delete', 'Mark As Complete'];
                    response.statusNew = 'In Progress';
                    const updatedBatchData = updateObjectById(batchData, response.id, response);
                    setBatchData(updatedBatchData);
                    setShowBackdrop(false);
                    alert('Batch Updated Successfully');
                } else {
                    setShowBackdrop(false)
                    alert('Something went wrong! Please try again');
                }
            });
        }else if ('Mark As Complete') {
            setViewBatch(params.row)
            setShowBackdrop(false);
            handleMarkAsCompleteModalOpen();
        }
    }

    const [markAsCompleteModal, setMarkAsCompleteModal] = useState(false);
    const handleMarkAsCompleteModalOpen = () => {
        setMarkAsCompleteModal(true);
    }
    const handleMarkAsCompleteModalClose = () => {
        setMarkAsCompleteModal(false);
    }

    const MarkAsCompleteModal = function (batch) {


        const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
            return (
                <BaseNumberInput
                    slots={{
                        root: StyledInputRoot,
                        input: StyledInputElement,
                        incrementButton: StyledButton,
                        decrementButton: StyledButton,
                    }}
                    slotProps={{
                        incrementButton: {
                            children: '▴',
                        },
                        decrementButton: {
                            children: '▾',
                        },
                    }}
                    {...props}
                    ref={ref}
                />
            );
        });

        const [numberOfBales, setNumberOfBales] = useState();
        const [baleIds, setBaleIds] = useState(Array(numberOfBales).fill(''));
        const [baleWeights, setBaleWeights] = useState(Array(numberOfBales).fill());
        const [isLoading, setIsLoading] = useState(false);


        const handleMarkAsCompleteModalSave = async () => {
            setIsLoading(true);
            if (!numberOfBales || baleWeights.length === 0) {
                alert('Please enter Number of bales you want to create and assign weights to them');
                setIsLoading(false);
            }else {
                const data =  {
                    "batch": batch.batch.id,
                    "bale_count": numberOfBales
                }
                if (baleIds[0] !== '' && baleIds.length === numberOfBales) {
                   data.bale_ids = baleIds;
                }else if (baleIds[0] !== '' && baleIds.length < numberOfBales) {
                    alert('Please enter all the bale ids');
                    setIsLoading(false);
                    return;
                }
               createBale(data).then((response) => {
                   if (response) {
                       const balePromises = [];
                       const baleIds = response.map((bale, index) => {
                           response[index].action = ['Export QR'];
                           return bale.id;
                       });
                       setBales([...response ,...bales]);
                       baleIds.map((baleId) => {
                           balePromises.push(
                               generateBaleQR(baleId).then((response) => {
                                   if (response) {
                                       // updatedViewBatch[0].batchQrCode = response;
                                       // setViewBatch(updatedViewBatch);
                                       return response;
                                   }
                               })
                           );
                       });
                       Promise.all(balePromises).then((response) => {
                           const baleQrCodes = response.map((bale) => {
                               window.open(bale.downloadLink, '_blank');
                           });

                           createBatchQrCode(batch.batch.id).then( (responseBatch) => {

                               /** ---------------- Download Link ***************** */
                               // document.body.appendChild(response.downloadLink);
                               // response.downloadLink.click();
                               // document.body.removeChild(response.downloadLink);
                               // URL.revokeObjectURL(response.imageURL);
                               /** ---------------- Download Link ***************** */

                               window.open(responseBatch.downloadLink, '_blank');
                               const batchComplete = {
                                   status: 'completed'
                               }

                               updateBatch(batchComplete, batch.batch.id).then( (response) => {
                                   if (response) {
                                        const updatedBatchData = updateObjectById(batchData, response.id, response);
                                        updatedBatchData[0].action = ['Export QR'];
                                        updatedBatchData[0].batchLink = `${BASEURL}/batch/${updatedBatchData[0]?.id}`;
                                        setAllBatches([updatedBatchData[0], ...allBatches]);
                                        const newBatchData = deleteObjectById(batchData, response.id);
                                        setBatchData(newBatchData);
                                       setIsLoading(false);
                                       alert('Batch Updated Successfully');
                                       setMarkAsCompleteModal(false);
                                   } else {
                                       alert('Something went wrong! Please try again');
                                   }
                               });
                           });
                       });
                   } else {
                       setIsLoading(false);
                       alert('Something went wrong! Please try again');
                   }
               });
            }
        }

        // Function to handle changes in the input fields
        const handleBaleWeightChange = (index, value) => {
            // const { value } = event.target;
            const updatedWeights = [...baleWeights];
            updatedWeights[index] = value;
            setBaleWeights(updatedWeights);
        };

        const handleBaleIdChange = (index, value) => {
            const updatedIds = [...baleIds];
            updatedIds[index] = value;
            setBaleIds(updatedIds);
        };

        const generateInputFields = () => {
            const inputFields = [];
            for (let i = 0; i < numberOfBales; i++) {
                inputFields.push(
                    <div key={i} style={{background: "transparent"}}>
                        <TextField
                            label={`Enter ID for Bale ${i + 1}`}
                            value={baleIds[i] || ''}
                            fullWidth
                            onChange={(e) => handleBaleIdChange(i, e.target.value)}
                            sx={{...inputStyle,
                                '& fieldset': {
                                    border: 'none',
                                },
                                '&:not(:placeholder-shown) label': {
                                    position: 'absolute',
                                    top: 10,
                                    zIndex: 1,
                                    color: 'white',
                                },
                            }} style={{width: '100%', marginBottom: '10px', height: '55px' }}
                        />

                        <NumberInput
                            type="text"
                            placeholder={`Enter weight for Bale ${i + 1}`}
                            value={baleWeights[i]}
                            style={{ width: '100%', marginBottom: '10px' }}
                            onChange={(event, val) => handleBaleWeightChange(i, val)}
                        />
                    </div>
                );
            }
            return inputFields;
        };

        return (
            <div>
                <Modal
                    open={markAsCompleteModal}
                    onClose={handleMarkAsCompleteModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            overflow: 'scroll',
                        }}
                    >

                        <NumberInput
                            aria-label="Demo number input"
                            placeholder="Enter Number of Bales"
                            value={numberOfBales}
                            onChange={(event, val) => setNumberOfBales(val)}
                        />
                        <Button
                            variant="outlined"
                            color="success"
                            style={{ marginTop: '10px' }}
                            onClick={() => {
                                setBaleIds(Array(numberOfBales).fill(''));
                                setBaleWeights(Array(numberOfBales).fill(''));
                            }}
                        >
                            Load
                        </Button>


                        <div>
                            <p>Weights: {baleWeights.join(", ")}</p>
                        </div>

                        <Paper
                            sx={{
                                maxHeight: "200px",
                                overflowY: "auto",
                            }}
                        >
                            {generateInputFields()}
                        </Paper>


                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                            <Button variant="outlined" color="error" onClick={handleMarkAsCompleteModalClose}>
                                Close
                            </Button>
                            <Button variant="outlined" color="success" onClick={handleMarkAsCompleteModalSave} disabled={isLoading}>
                                Save
                                {
                                    isLoading && (
                                        <div style={{marginTop: '5px'}}>
                                        <Loading size={15} />
                                        </div>
                                    )
                                }
                            </Button>
                        </Box>

                    </Box>
                </Modal>

            </div>
        );
    }


    return(
        <div>
            {showBackdrop && (<SimpleBackdrop open={showBackdrop} setOpen={setShowBackdrop}/>)}

            <Stack direction={'row'} spacing={2} style={{marginBottom: "30px"}}>
                <div>
                    {AddBatchModal({options: heapOptions, batches: batchData, setBatches: setBatchData})}
                </div>
            </Stack>
            <div style={{height: 600, width: '100%'}}>
                <Grid container>
                    <Grid item xs={12} style={{flex: '1',position: 'relative'}}>
                        <DataGrid
                            sx={{minHeight: '55vh'}}
                            rows={batches}
                            columns={columns}
                            pageSize={pageSize}
                            // onPageChange={(params) => handlePageChange(params.page + 1)}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            onRowClick={handleRowClick}
                            components={{
                                Footer: () => (
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '8px' }}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handlePreviousPage}
                                            style={{marginRight: "8px"}}
                                            disabled={pageNumber === 1}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            onClick={handleNextpage}
                                            style={{marginRight: "8px"}}
                                            disabled={pageNumber === Math.ceil(batchData.length / pageSize)}
                                        >
                                            Next
                                        </Button>
                                        <p style={{marginRight: "8px"}}>Total Batches:{batchRecord}</p>
                                        <p style={{margin:'10px'}}>Items: {batchesToView.length}</p>
                                        <p>Page:
                                            <Select value={pageNumber} onChange={handlePageChange} MenuProps={CustomMenuProps} style={{marginRight: "8px",}}>
                                                {pageOptions}
                                            </Select>
                                        </p>
                                    </div>
                                ),
                            }}

                        />

                    </Grid>
                </Grid>
            </div>
            {
                markAsCompleteModal && (
                    <MarkAsCompleteModal batch={viewBatch}/>
                )
            }
        </div>
    )
}
