import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, {useEffect} from "react";


export default function EditDisease({diseaseList, fieldDiseases, selectedDiseases, setSelectedDiseases}) {

    const handleSelectedDiseaseChange = (event, value) => {
        const newSelectedDiseases = value.map(disease => ({
            id: disease?.id,
            name: disease?.name,
            percentage: disease?.percentage ? parseFloat(disease.percentage) : 0
        }));
        setSelectedDiseases(newSelectedDiseases);
    };

    const handleDiseaseChange = (event, index) => {
        const newSelectedDiseases = [...selectedDiseases];
        newSelectedDiseases[index].percentage = parseFloat(event.target.value);
        setSelectedDiseases(newSelectedDiseases);
    }

    useEffect(() => {
        if (fieldDiseases && fieldDiseases.length > 0) {
            setSelectedDiseases(fieldDiseases.map(disease => ({
                id: disease?.disease?.id,
                name: disease?.disease?.name,
                percentage: parseFloat(disease?.percentage)
            })));
        } else {
            const defaultDiseases = diseaseList.map((disease) => {
                return {
                    id: disease?.id,
                    name: disease?.name,
                    percentage: 0
                }
            });
            setSelectedDiseases(defaultDiseases);
        }
    }, []);


    return(
        <>
            {diseaseList.length > 0 ? (
                <Autocomplete
                    multiple
                    id="tags-standard"
                    options={diseaseList}
                    getOptionLabel={(option) => `${option?.name}(${option?.id})`}
                    value={selectedDiseases}
                    onChange={handleSelectedDiseaseChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Diseases"
                            placeholder="Select Diseases"
                            sx={{ margin: 3, marginLeft: 2 }}
                        />
                    )}
                />
            ) : (
                <div style={{margin: 3, marginLeft: 20}}>No Diseases Found, Kindly create new disease</div>
            )}

            {
                selectedDiseases.map((disease, index) => {
                    return (
                        <div key={index}>
                            <TextField
                                label={disease?.name + ' %'}
                                value={disease?.percentage}
                                onChange={(event) => handleDiseaseChange(event, index)}
                                sx={{ margin: 1, marginLeft: 2}}
                            />
                        </div>
                    )
                })
            }
        </>

    )
}