const UserTrackingPopup = ({feature}) => {
    const headingStyle = { color: "#1bc693" };

    return (
        <div>
            {(feature?.properties?.picture_url) && <img width={'125px'} src={feature?.properties?.picture_url} alt={'Attendance Picture'}/>}
            <div><strong style={headingStyle}>Timestamp:</strong> {feature?.properties?.timestamp}</div>
            {(feature?.properties?.status) &&
                <div><strong style={headingStyle}>Status:</strong> {feature.properties?.status === 'so' ? 'Signed Out' : 'Signed In'}</div>}
            <div><strong style={headingStyle}>Location:</strong> {feature?.geometry?.coordinates}</div>


        </div>
    );
};

export default UserTrackingPopup;
