import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Tabs, Tab, Checkbox, Stack,  Popover,} from '@mui/material';
import {BaseLayerControl} from "../components/map-utils/BaseLayerControl";
import {
    CircleMarker, FeatureGroup, MapContainer, Polygon, ZoomControl, LayersControl, TileLayer, LayerGroup, WMSTileLayer
} from "react-leaflet";
import {ReCenter} from "../components/map-utils/ReCenter";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {bbox} from "@turf/turf";
import L from "leaflet";
import openPopupAt from "../components/map-utils/FieldPopup";
import {Button, TextField, MenuItem, Select} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {flipCoords} from "./territoryManagement/ManageGateCircles";
import {Search} from "../components/map-utils/Search";
import Popup from 'components/Popup';
import {tokens} from '../assets/Theme';
import {useTheme} from '@mui/material';
import Topbar from 'components/Topbar';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Cookies from 'js-cookie';







const mapHeight = '50vh';

const fieldsColumns = [
  {
    field:'id', headerName:'ID', width:150
},{
    field: 'fieldName', headerName: 'Field name', width: 150,
}, {
    field: 'status', headerName: 'Status', width: 150, renderCell: (params) => {
        if (params.value === 'PD') {
            return 'Pending';
        } else if (params.value === 'RE') {
            return 'Rejected';
        } else {
            return 'Approved';
        }
    }
},

    {
        field: 'createdBy', headerName: 'Created By', width: 150,
    }, {
        field: 'created_at', headerName: 'Created At', width: 150, renderCell: (params) => {
            const createdAt = params.value;
            const dateTimeOptions = {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true
            };
            const formattedCreatedAt = new Date(createdAt).toLocaleString(undefined, dateTimeOptions);
            return formattedCreatedAt;
        },
    }, {
        field: 'cropType', headerName: 'Crop Type', width: 150,
    }, {
        field: 'total_area', headerName: 'Total Area', width: 150,
    }, {
        field: 'action',
        headerName: 'Action',
        width: 150,
        renderCell: (params) => (
            <Link to={`../gate-circles/details/fields/${params.id}`} style={{color: "#000", textDecoration: 'none'}}>
                View
            </Link>),
    },];

const userColumns = [{
    field: 'id',
    headerName: 'User Id',
    width: 150,
    renderCell: (params) => (<Link to={`/manage-employees/${params.value}`} style={{color: "#000"}}>
        {params.value}
    </Link>),
}, {
    field: 'mobile_number', headerName: 'Mobile Number', width: 150,
}, {
    field: 'username', headerName: 'Username', width: 150,
}, {
    field: 'role',
    headerName: 'Role',
    width: 150,
    renderCell: (params) => (params.value === 'S' ? 'Surveyor' : 'Auditor'),
},];

const assgnUserColumns = [{
    field: 'id', headerName: 'ID', width: 100
}, {field: 'username', headerName: 'Name', width: 200}, {
    field: 'role',
    headerName: 'Role',
    width: 150,
    renderCell: (params) => (params.value === 'S' ? 'Surveyor' : 'Auditor'),
}];


function GateCircleDetail() {
    const mapRef = useRef();
    const navigate = useNavigate();
    const {id} = useParams();
    const {pageNumber} = useParams();
    const [fieldsData, setFieldsData] = React.useState([]);
    const [fieldsGrid, setFieldsGrid] = React.useState([]);
    const [userData, setUserData] = React.useState([]);
    const [gateData, setGateData] = React.useState(null);
    const [gateName, setGateName] = React.useState(null);
    const [gateId, setGateId] = React.useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [listData, setListData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedAssignedUser, setSelectedAssignedUser] = useState([]);
    const [searchValue, setSearchValue] = React.useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [filterModel, setFilterModel] = React.useState({items: []});
    const [userLocation, setUserLocation] = React.useState([30.3753, 69.3451])
    const map_center = [30.3753, 69.3451];
    const [tabIndex, setTabIndex] = useState(0);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [page, setPage] = React.useState(1)
    const [next, setNext] = React.useState();
    const [record, setRecord] = React.useState();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [pageSize, setPageSize] = useState(5);
    const [focus, setFocused] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [endFocus, setEndFocused] = useState(false);
    const [hasValue, setHasValue] = useState(false);
    const [hasEndValue, setHasEndValue] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const onFocus = () => setFocused(true);
    const onEndFocus = () => setEndFocused(true);
    const onBlur = () => setFocused(false);
    const onEndBlur = () => setEndFocused(false);

    const handleOpenConfirmDialog = () => {
        setConfirmationOpen(true);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    
    function handleRowClick(params) {
        let rowId = params.row.id;
        let data = fieldsData;
        let geojsonPoly = null;
        for (let i = 0; i <= data.features.length; i++) {
            if (data.features[i]?.id === rowId) {
                geojsonPoly = data.features[i].geometry;
                let coordinates = {
                    coordinates: [geojsonPoly.coordinates[0].map((c) => {
                        return [c[1], c[0]]
                    })]
                }
                geojsonPoly = {...geojsonPoly, ...coordinates}
            }
        }
        try {
            if (geojsonPoly) {
                const bboxArray = bbox(geojsonPoly);
                let bounds = L.latLngBounds(L.latLng([bboxArray[0], bboxArray[1]]), L.latLng([bboxArray[2], bboxArray[3]]))
                mapRef.current.fitBounds(bounds, true)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    const handleSearchChange = () => {
        try {
          let queryParameters = {
            gate_circle: id,
            p: pageNumber,
          };
      
          if (startDate !== null && endDate !== null) {
            queryParameters.created_after = startDate;
            queryParameters.created_before = endDate;
          }
      
          if (searchValue !== null) {
            queryParameters.search = searchValue;
          }
      
          if (selectedStatus !== null) {
            queryParameters.status = selectedStatus;
          }
      
          axios
            .get(`${process.env.REACT_APP_BASEURL}/api/fields/`, {
              params: queryParameters,
            })
            .then((res) => {
              console.log("res:", res);
              if (res && res.data) {
      
                let gridData = res?.data?.results?.features?.map((ft) => ({
                  id: ft.id,
                  fieldName: ft.properties?.field_name,
                  createdBy: ft.properties?.user,
                  status: ft.properties?.status,
                  cropType: ft.properties?.crop_type,
                  created_at: ft.properties?.created_at,
                  total_area: ft.properties?.total_area?.toFixed(2),
                }));
      
                console.log("Res 2000");
                console.log("data", gridData);
      
                if (gridData.length >= 1) {
                  setFieldsGrid(gridData);
                  setFieldsData(res?.data?.results);
                  setNext(res?.data?.date?.next);
                  setRecord(res?.data?.count);
                } else {
                  alert("No fields found.");
                  setSearchValue('');
                }
              }
            })
            .catch((error) => {
              console.error(error);
              // Handle the error appropriately, e.g., show an error message to the user
            });
        } catch (error) {
          console.error(error);
          // Handle the error appropriately, e.g., show an error message to the user
        }
      };
      
    const filteredRole = userData.filter((assigned) => {
        return assigned.username?.toLowerCase().includes(searchValue.toLowerCase()) || assigned.role?.toUpperCase().includes(searchValue.toUpperCase()) || assigned.id?.toString().includes(searchValue)
    });
    const filteredUnassign = listData.filter((unassigned) => {
        return unassigned?.username.toLowerCase().includes(searchValue.toLowerCase()) || unassigned.role?.toUpperCase().includes(searchValue.toUpperCase()) || unassigned.id?.toString().includes(searchValue)
    });

    const handlePolygonClick = (event) => {
        event.target.setStyle({color: 'green'}); // update polygon style
        let polygonData = {data: event?.target?.options?.id, coords: event?.target?._bounds}
        console.log("polydata", polygonData.data)
        openPopupAt(mapRef.current, getCenter(polygonData.coords), polygonData.data.properties, polygonData.data.id, navigate)
    };
    const handleUserChange = event => {
        setSelectedUser(event.target.value);
        axios.post(`${process.env.REACT_APP_BASEURL}/api/users/`, {selectedUser})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
    };
    const handleAddClick = async () => {
        try {
            if (!selectedRows || selectedRows.length === 0) {
                alert('No user selected');
                return;
            }

            let response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/gates/assign_users/`, {
                "user_ids": selectedRows, "gate_circle_id": id
            });
            console.log(response);
            if (response.status === 201) {
                window.location.reload();

            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleRemove = async () => {
        // If no rows are selected, do nothing
        console.log("selection:", selectedAssignedUser)
        if (!selectedAssignedUser || selectedAssignedUser.length === 0) {
            alert('No user selected');
            return;
        }

        console.log("selectedrowIndex", id)
        // Make the API call to delete the selected rows
        try {
            let response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/gates/unassign_users/`, {
                "user_ids": selectedAssignedUser, "gate_circle_id": id

            })
            console.log(response.status)
            if (response.status === 204) {
                window.location.reload();
            }
        } catch (error) {
            console.log(error);


        }
    }; // handle remove button


    const getCenter = (coords) => {
        const bounds = L.latLngBounds(coords);
        return bounds.getCenter();

    };
    const fetchFieldsData = () => {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/fields/?gate_circle=${id}&p=${pageNumber}`)
            .then((res) => {
                console.log("res:", res);
                if (res && res.data) {

                    let gridData = res.data?.results?.features.map((ft) => {
                        return {
                            id: ft.id,
                            fieldName: ft.properties?.field_name,
                            createdBy: ft.properties?.user,
                            status: ft.properties?.status,
                            cropType: ft.properties?.crop_type,
                            created_at: ft.properties?.created_at,
                            total_area: ft.properties?.total_area.toFixed(2),
                        };
                    });
                    console.log('Res 2000')
                    console.log('data', gridData);
                    setFieldsGrid(gridData);
                    setFieldsData(res?.data?.results);
                    setNext(res?.data?.next);
                    setRecord(res?.data?.count);
                }
            })
            .catch((error) => {
                // Handle error if necessary
                console.error(error);
            });
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
      };
      console.log(selectedStatus)
    const handleNextpage = () => {
        if (next !== null) {
            const nextPage = parseInt(pageNumber, 10) + 1; // Increment the page number
            navigate(`/gate-circles/details/${id}/page/${nextPage}`);
        }
    }
    const handlePreviousPage = () => {
if (parseInt(pageNumber, 10) > 1) {
            const previousPage = parseInt(pageNumber, 10) - 1; // Decrement the page number
            navigate(`/gate-circles/details/${id}/page/${previousPage}`);
        }
    }
    
    useEffect(() => {
        if (searchValue === undefined && startDate === null && endDate === null && selectedStatus === null) {
        fetchFieldsData();
        
        }
        else{
            handleSearchChange();
        }
    }, [pageNumber]);
    const handlePageChange = (event) => {
       const newPage = event.target.value;
        navigate(`/gate-circles/details/${id}/page/${newPage}`)
      };
        const pageOptions = [];
      for (let i = 1; i <= Math.ceil(record / 10); i++) {
        pageOptions.push(
          <MenuItem key={i} value={i} >
           {i}
          </MenuItem>
        );
      }
      const CustomMenuProps = {
        PaperProps: {
          style: {
            maxHeight: 200, // Set the maximum height for the dropdown
          },
        },
      };
// assigned user api
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/gates/users/?gate_circle=${id}`).then((res) => {
            if (res && res.data) {
                console.log("data", res.data)
                setUserData(res.data);
            }
        })
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/gates/${id}`).then((res) => {
            if (res && res.data) {
                const geometry = flipCoords(res.data.geometry.coordinates[0]);
                setGateData(geometry);
                setGateName(res?.data?.properties?.name)
                setGateId(res?.data?.id)
                let geojsonPoly = res.data.geometry;

                let bboxArray = bbox(geojsonPoly);
                bboxArray = [bboxArray[1], bboxArray[0], bboxArray[3], bboxArray[2]]
                let bounds = L.latLngBounds(L.latLng([bboxArray[0], bboxArray[1]]), L.latLng([bboxArray[2], bboxArray[3]]))
                mapRef.current.fitBounds(bounds, true)

            }
        })
    }, [])

    useState(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/gates/users/?gate_circle=${id}&exclude=true`).then((res) => {
            if (res && res.data) {
                console.log(res.data)
                setListData(res.data)
            }
        })

    }, [])
   
  

    const handleFilterModelChange = (model) => {
        const filteredRows = fieldsGrid.filter((row) => {
            let matches = true;
            for (const item of model.items) {
                if (!matches) break;
                const value = row[item.columnField];
                if (item.operatorValue === 'contains') {
                    matches = value.toLowerCase().includes(item.value.toLowerCase());
                } else if (item.operatorValue === 'equals') {
                    matches = value === item.value;
                }
            }
            return matches;
        });
        setFilterModel(model);
        setFieldsGrid(filteredRows);
    };

    return (<div style={{width: '100%', padding: 5}}>
        <Topbar gateCId={id} page={pageNumber}/>
        <Grid container>
            <Grid item xs={11}>
                <Typography variant="h3" style={{color: 'white', left: 'px', top: '25px', position: 'absoulte'}}>
                    {gateName}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Button variant={"contained"}
                        style={{color: 'white', border: '1px solid blue', margin: 1, width: '100%'}} onClick={() => {
                    navigate(`../gate-circles/${id}/edit`)
                }}>Edit</Button>
            </Grid>
        </Grid>
        <Grid container>
            {/*<Grid item xs={12} style={{flex: '1',}}>*/}
            <MapContainer center={map_center}
                          zoom={5}
                          maxZoom={18}
                          scrollWheelZoom={true}
                          zoomControl={false}
                          attributionControl={false}
                          style={{height: mapHeight}}
                          ref={mapRef}
            >
                <ZoomControl prepend position="topright"/>
                <CircleMarker center={userLocation}/>
                <ReCenter user_location={userLocation}
                          updateLocation={setUserLocation}/>
                <Search/>
                <LayersControl position="bottomright">
                    <LayersControl.BaseLayer checked name="Satellite View">
                        <TileLayer
                            url='https://mt1.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                            attribution="Google"
                        />
                    </LayersControl.BaseLayer>
                    {/*{(fieldsData?.features?.length >= 1) && (<LayersControl.Overlay checked name="Fields">*/}
                    {/*    <LayerGroup>*/}

                    {/*        {(fieldsData?.features?.length >= 1) && (fieldsData?.features?.map((data) => {*/}
                    {/*            let coordinates = flipCoords(data.geometry.coordinates[0]);*/}
                    {/*            return <Polygon positions={coordinates}*/}
                    {/*                            eventHandlers={{click: handlePolygonClick}}*/}
                    {/*                            id={data} key={data.id}>*/}
                    {/*            </Polygon>*/}
                    {/*        }))}*/}
                    {/*    </LayerGroup>*/}
                    {/*</LayersControl.Overlay>)}*/}
                    <LayersControl.Overlay name="Fields" checked>
                        <LayerGroup>
                            <WMSTileLayer
                                url="https://dev.farmdar.ai/geoserver/farmdar/wms"
                                layers="farmdar:fields"
                                format="image/png"
                                transparent={true}
                                attribution="Your attribution"
                                cql_filter={`gate_circle=${id}`}
                            />
                        </LayerGroup>
                    </LayersControl.Overlay>


                    {(gateData) && (<LayersControl.Overlay checked name="Gate Circle">

                        <Polygon positions={gateData} id={154} pathOptions={{
                            fillColor: 'red', fillOpacity: 0.1, weight: 0, color: 'transparent', opacity: 0,
                        }}/>
                    </LayersControl.Overlay>)}
                </LayersControl>
            </MapContainer>
            <Grid container width='95%'>
                <Grid item xs={8} style={{flex: '1',}}>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Tabs">
                        <Tab label="Fields" value={0} sx={tabStyles}/>
                        <Tab label="Surveyors / Auditors" value={1} sx={tabStyles}/>
                        <Tab label="Assign User" value={2} sx={tabStyles}/>
                    </Tabs>

                </Grid>
                <Grid item xs={12} style={{flex: '1.5', width: '100%'}}>

                    {tabIndex === 2 && (<>
                        <TextField label="Search" variant="outlined" size="small" value={searchValue}
                                   onChange={(event) => {
                                       setSearchValue(event.target.value)
                                   }}
                                   sx={{
                                       position: 'relative',
                                       top: '9px',
                                       left: '40%',
                                       '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: "white !important",},
                                       '& label.Mui-focused': {color: colors.grey[100]},
                                       '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {borderColor: colors.grey[100]},
                                   }}/>

                        <Button variant="contained" sx={{
                            color: '#fff',
                            border: '1px solid blue',
                            margin: "10px 10px",
                            position: 'relative',
                            left: '40%'
                        }} onClick={handleOpenConfirmDialog}>Add</Button>
                    </>)}
                    {tabIndex === 1 && (<>
                        <TextField label="Search" variant="outlined" size="small" value={searchValue}
                                   onChange={(event) => {
                                       setSearchValue(event.target.value)
                                   }}
                                   sx={{
                                       position: 'relative',
                                       top: '9px',
                                       left: '40%',
                                       '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: "#000 !important",},
                                       '& label.Mui-focused': {color: colors.grey[100]},
                                       '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {borderColor: colors.grey[100]},
                                   }}/>

                        <Button variant="contained" sx={{
                            color: '#fff',
                            border: '1px solid red',
                            margin: "10px 10px",
                            position: 'relative',
                            left: '40%'
                        }} onClick={handleOpenConfirmDialog}>Remove</Button>

                    </>)}
                    {tabIndex === 0 && (<>
                        <FormControl>
                            <InputLabel>Status</InputLabel>
                            <Select sx={{width: 100, position: 'relative',
                                       top: '9px', left:'5%'}} size='small' value={selectedStatus || ''} onChange={(e) =>{

                                setSelectedStatus(e.target.value)
                            }}>
                              <MenuItem value = {null}>non</MenuItem>
                              <MenuItem value="PD">Pending</MenuItem>
                              <MenuItem value="AP">Approved</MenuItem>
                              <MenuItem value="RE">Rejected</MenuItem>
                            </Select>
                          </FormControl>
                           
                            <TextField 
                            label="Start Date"
                            variant='outlined'
                            onFocus={onFocus}
                            onBlur={onBlur}
                            size='small'  
                            type={hasValue || focus ? "date" : "text"} 
                            value={startDate} 
                            onChange={(e) => {
                                if (e.target.value){
                                     setHasValue(true);
                                     setStartDate(e.target.value)
                                }
                                else setHasValue(false);
                              }}
                            sx={{
                                position: 'relative',
                                top: '9px',
                                width: '150px',
                                left: '2%',
                                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: "#000 !important",},
                                '& label.Mui-focused': {color: colors.grey[100]},
                                '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {borderColor: colors.grey[100]},
                            }}
                            />
                          
                            <TextField 
                            label="End Date" 
                            variant='outlined' 
                            onFocus={onEndFocus}
                            onBlur={onEndBlur}
                            size='small'
                            type={hasEndValue || endFocus ? "date" : "text"}
                            value={endDate} 
                            
                            onChange={(e) => {
                                if (e.target.value){
                                     setHasEndValue(true);
                                     setEndDate(e.target.value)
                                }
                                else setHasValue(false);
                              }}
                            sx={{
                                position: 'relative',
                                top: '9px',
                                left: '3%',
                                width:'150px',
                                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: "#000 !important",},
                                '& label.Mui-focused': {color: colors.grey[100]},
                                '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {borderColor: colors.grey[100]},
                            }}
                            />
                        


                        <TextField label="Search" variant="outlined" size="small" value={searchValue}
                                   onChange={(event) => {
                                       setSearchValue(event.target.value)
                                   }}
                                   sx={{
                                       position: 'relative',
                                       top: '9px',
                                       left: '4%',
                                       '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: "#000 !important",},
                                       '& label.Mui-focused': {color: colors.grey[100]},
                                       '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {borderColor: colors.grey[100]},
                                   }}/>

                        <Button variant="contained" sx={{
                            color: '#fff',
                            border: '1px solid blue',
                            margin: "10px 10px",
                            position: 'relative',
                            left: '3.5%'
                        }} onClick={() =>{
                            navigate(`/gate-circles/details/${id}/page/1`)
                            handleSearchChange();
                           
                          
                             }}>search</Button>
                        <Button variant="contained" sx={{
                            color: '#fff',
                            border: '1px solid blue',
                            margin: "10px 10px",
                            position: 'relative',
                            left: '2%'
                        }} onClick={() =>{ 
                            navigate(`/gate-circles/details/${id}/page/1`);
                           fetchFieldsData();
                            setSearchValue('');
                            setEndDate('')
                            setStartDate('')
                        }}>All fields</Button>
                    </>)}
                </Grid>
            </Grid>
            <Grid container>

                <Grid item xs={12}>
                    {tabIndex === 0 && (<DataGrid
                            sx={{color: '#000', minHeight: '40vh'}}
                            rows={fieldsGrid}
                            columns={fieldsColumns}
                            pageSize={20}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            // onPageChange={(params) => handlePageChange(params.page + 1)}
                            rowsPerPageOptions={[10]}
                            filterMode="server"
                            filterModel={filterModel}
                            onFilterModelChange={handleFilterModelChange}
                            onRowClick={handleRowClick}
                            components={{
                                Footer: () => (<div style={{
                                    display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '8px'
                                }}>
                                    <Button variant="contained" color="primary" onClick={handlePreviousPage}>
                                        Back
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handleNextpage}>
                                        Next
                                    </Button>
                                    < p>Total Fields: {record}</p>
                                    <p style={{margin: '10px'}}>Items: {fieldsGrid?.length}</p>
                                    <p>Page:
                                        <Select value={pageNumber} onChange={handlePageChange} MenuProps={CustomMenuProps}>
                                            {pageOptions}
                                        </Select>
                                    </p>
                                </div>),
                            }}
                        />

                    )}
                    {tabIndex === 1 && (<DataGrid
                        sx={{color: '#000', minHeight: '40vh'}}
                        rows={filteredRole}
                        columns={userColumns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 20]}
                        filterMode="server"
                        filterModel={filterModel}
                        onFilterModelChange={handleFilterModelChange}
                        checkboxSelection
                        onSelectionModelChange={(selectionAssignedModel) => {

                            setSelectedAssignedUser(selectionAssignedModel);
                            console.log("assigned", selectionAssignedModel)
                        }}
                    />)}
                    {tabIndex === 2 && (<DataGrid
                        sx={{color: '#000 !important', minHeight: '50vh'}}
                        rows={filteredUnassign}
                        columns={assgnUserColumns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 20]}
                        checkboxSelection
                        onSelectionModelChange={(selectionModel) => {

                            setSelectedRows(selectionModel);
                            console.log("selected user:", selectionModel)
                        }}

                    />)}

                </Grid>
            </Grid>
        </Grid>
        {tabIndex === 1 && (<Popup
            title="Warning!"
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            onConfirm={handleRemove}
            className="title_container"
            btnColor="red"
            message={`Are you sure you want to remove ${selectedAssignedUser} ?`}
        />)}
        {tabIndex === 2 && (<Popup
            title="Warning!"
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            onConfirm={handleAddClick}
            className="add_title_container"
            btnColor="green"
            message={`Are you sure you want to add ${selectedRows} ?`}
        />)}
    </div>);
}

const tabStyles = {
    "&.Mui-selected": {
        color: "#000", borderBottom: "2px solid #000"
    }
}


export default GateCircleDetail;

