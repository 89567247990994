import React from "react";
import Topbar from "../components/Topbar";
import Tabs from 'components/ginnerTraceability/Tabs';
import {AllBatchesProvider} from "../components/AppStates/AllBatches";
import {BalesProvider} from "../components/AppStates/Bales";


function GinnerTraceability() {
    return (
        <div>
            <Topbar page={'Traceability'}/>
            <h1 style={{marginLeft: '2%'}}>Traceability</h1>
            <AllBatchesProvider>
                <BalesProvider>
                    <Tabs />
                </BalesProvider>
            </AllBatchesProvider>
        </div>
    );
}

export default GinnerTraceability;