import Topbar from "../../components/Topbar";
import React, {useEffect, useState} from "react";
import BoundaryDetailTable from "../../components/tables/BoundaryDetailTable";
import Btn from "../../components/allGrowers/Btn";
import {Button, Stack, Typography, useTheme} from "@mui/material";
import {growerIncrement, growerDecrement, growerClear} from "../../reduxSetup/AllGrowers";
import {tokens} from "../../assets/Theme";
import {useDispatch, useSelector} from "react-redux";
import {getGrowers} from "../../api/grower/grower";
import AddGrowerModal from "../../components/allGrowers/AddGrowerModal";
import ImportGrowerModal from "../../components/allGrowers/ImportGrowerModal";
import AllGrowersTopNav from "../../components/allGrowers/AllGrowersTopNav";



export default function AllGrowers() {

    const [growersData, setGrowersData] = useState();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const [record, setRecord] = useState();
    const [next, setNext] = useState();
    const currentPage = useSelector( state => state.growersSlice.page);
    const [search, setSearch] = useState('');
    const [addGrowerModalOpen, setAddGrowerModalOpen] = useState(false);
    const [importGrowerModalOpen, setImportGrowerModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(
        {
            title: null,
            data: {}
        }
    )


    const headerNames = [
        {header: "Grower ID", value: "id"},
        {header: "Passbook No", value: "passbook_number"},
        {header: "CNIC", value: "grower_cnic"},
        {header: "Status", value: "grower_type"},
        {header: "Grower Name", value: "grower_name"},
        {header: "Father Name", value: "father_name"},
        {header: "Circle Name", value: "circle_code"},
        {header: "Village Name", value: "village_code"},
        { header: `Action`,
            value: (rowData) =>{
                return (
                    <>
                        <Btn rowData={{...rowData, action: 'View Grower'}} title={'View'} func={handleAddGrowerModalOpen} />
                        |
                        <Btn rowData={{...rowData, action: 'Edit Grower'}} title={'Edit'} func={handleAddGrowerModalOpen} />
                    </>
                )
            }

        },
    ];

    const fetchGrowersData = async (params) => {
        try {
            const res = await getGrowers(params);
            const data = res?.results?.map((item) => {
                return {
                    ...item,
                    grower_type: item?.loanee ? "Loanee" : "Non-Loanee",
                }
            });
            setGrowersData(data);
            setNext(res?.next);
            setRecord(res?.count);
        } catch (error) {
            console.error(error);
            alert("Something went wrong! Please try again.");
        }
    }

    useEffect(() => {
        if (search) {
            fetchGrowersData({p: currentPage, search: search});
        }
        fetchGrowersData({p: currentPage});
    }, [currentPage, search]);

    const handleSearch=(value) => {
        dispatch(growerClear());
        setSearch(value);
    }

    const handleAddGrowerModalOpen= (add, data , action) => {
        console.log('data', action);
        if (add){
            setSelectedRow({
                title: 'Add Grower',
                data: data
            })
        }else {
            setSelectedRow({
                title: action,
                data: data
            })
        }
        setAddGrowerModalOpen(true);
    }

    const handleImportGrowerModalOpen = () => {
      setImportGrowerModalOpen(true);
    }



    return (
        <>
            <Topbar />
            <div style={{ width: "98%", position: "relative", left: "1%" }}>
                <BoundaryDetailTable
                    headerNames={headerNames}
                    rowsData={growersData}
                    title={'Grower Management'}
                    tabIndex={0}
                    onSearch={handleSearch}
                    tableNav={<AllGrowersTopNav handleModalOpen={{handleAddGrowerModalOpen, handleImportGrowerModalOpen}} />}
                    footer={
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    position: "relative",
                                    top: "35px",
                                    left: "10px",
                                    color: `${colors.customColor[800]}`,
                                }}
                            >{`Page: ${currentPage} - ${Math.ceil(record / 10)}`}</Typography>
                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{
                                    marginTop: "2%",
                                    marginBottom: "1%",
                                    justifyContent: "flex-end",
                                    position: "relative",
                                    right: "7%",
                                }}
                            >
                                <Button
                                    className="footerbtn-variety"
                                    variant="outlined"
                                    onClick={() => dispatch(growerDecrement())}
                                >
                                    previous
                                </Button>
                                <Button
                                    className="footerbtn-variety"
                                    variant="outlined"
                                    onClick={() => dispatch(growerIncrement())}
                                    disabled={next === null}
                                >
                                    next
                                </Button>
                            </Stack>
                        </div>
                    }
                    handleModalOpen={{handleAddGrowerModalOpen, handleImportGrowerModalOpen}}
                    />
                {
                    addGrowerModalOpen && (
                        <AddGrowerModal title={selectedRow?.title} open={addGrowerModalOpen}  onClose={() => setAddGrowerModalOpen(false)} growerData={selectedRow?.data} />
                    )
                }
                {
                    importGrowerModalOpen && (
                        <ImportGrowerModal title={'Import Grower'} open={importGrowerModalOpen} onClose={() => setImportGrowerModalOpen(false)} />
                    )
                }
            </div>
        </>
    )
}