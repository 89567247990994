import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import InputCustom from "../components/InputCustom";

const CompanyAddModal = ({
  open,
  onClose,
  isAddingCompetitor = false,
  initialValue = null,
  addCompetitor,
  deleteCompetitor,
  editCompany,
}) => {
  const [companyName, setCompanyName] = useState(initialValue?.name || "");
  const [latitude, setLatitude] = useState(initialValue?.latitude || "");
  const [longitude, setLongitude] = useState(initialValue?.longitude || "");
  // const [address, setAddress] = useState(initialValue?.address || ""); 
  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
    if (!companyName) tempErrors.companyName = "Company name is required";
    if (!latitude) {
      tempErrors.latitude = "Latitude is required";
    } else if (!validateLatitude(latitude)) {
      tempErrors.latitude = "Invalid latitude value";
    }
    if (!longitude) {
      tempErrors.longitude = "Longitude is required";
    } else if (!validateLongitude(longitude)) {
      tempErrors.longitude = "Invalid longitude value";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSave = () => {
    if (validate()) {
      // onCreateNewCompany()
      const data = {
        name: companyName,
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
      };
      
      initialValue
        ? editCompany({ id: initialValue?.id, ...data },isAddingCompetitor)
        : addCompetitor(data);
      console.log({ companyName, latitude, longitude });
    }
  };

  const handleBlur = (field) => {
    validate();
  };

  return (
    <Dialog
      sx={{
        minWidth: 300,
        maxHeight:350 ,
        margin: "auto",
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#F1F6FF",
          color: "#333333",
          fontWeight: "700",
          fontSize: "24px",
        }}
      >
        {isAddingCompetitor
          ? `${initialValue ? "Edit" : "Add"} Competitor`
          : `${initialValue ? "Edit" : "Add"} Company`}
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: "#F1F6FF",
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <InputCustom
            label="Company Name"
            variant="outlined"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            onBlur={() => handleBlur("companyName")}
            error={!!errors.companyName}
            helperText={errors.companyName}
          />
          <InputCustom
            label="Co-ordinates (latitude)"
            variant="outlined"
            value={latitude}
            onChange={(e) => setLatitude(e.target.value)}
            onBlur={() => handleBlur("latitude")}
            error={!!errors.latitude}
            helperText={errors.latitude}
          />
          <InputCustom
            label="Co-ordinates (longitude)"
            variant="outlined"
            value={longitude}
            onChange={(e) => setLongitude(e.target.value)}
            onBlur={() => handleBlur("longitude")}
            error={!!errors.longitude}
            helperText={errors.longitude}
          />
        </Box>
        {/* {!isAddingCompetitor && (
          <InputCustom
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            width="100%"
            label="Address"
            variant="outlined"
          />
        )} */}
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#F1F6FF",
        }}
      >
        {initialValue && isAddingCompetitor ? (
          <Button
            variant="outlined"
            color="error"
            sx={{
              mt: 3,
              marginRight: 5,
              width: 90,
              height: "5vh",
              border: "2px solid #ED05059E",
            }}
            onClick={() => deleteCompetitor(initialValue?.id)}
          >
            Delete
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="error"
            sx={{
              mt: 3,
              marginRight: 5,
              width: 90,
              height: "5vh",
              border: "2px solid #ED05059E",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={handleSave}
          variant="outlined"
          sx={{
            mt: 3,
            color: "#0275C6",
            border: "2px solid #0275C6",
            width: 90,
            height: "5vh",
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateLatitude = (lat) => {
  return !isNaN(lat) && lat >= -90 && lat <= 90;
};

const validateLongitude = (lng) => {
  return !isNaN(lng) && lng >= -180 && lng <= 180;
};
export default React.memo(CompanyAddModal);
