// Your context file

import React, {createContext, useContext, useEffect, useState} from 'react';
import {getCompletedBatches} from "../../api/ginnerTraceability/batch";
const BASEURL = process.env.REACT_APP_BASEURL;

const AllBatchesContext = createContext();

export const AllBatchesProvider = ({ children }) => {
    const [allBatches, setAllBatches] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const response = await getCompletedBatches();
            if (response){
                response.map((batch) => {
                    batch.action = ['Export QR']
                    batch.batchLink = `${BASEURL}/batch/${batch.id}`
                });
                setAllBatches(response);
            }
        }
        if (!allBatches.length){
            fetchData();
        }
    }, []);

    useEffect(() => {
    }, [allBatches]);

    return (
        <AllBatchesContext.Provider value={{ allBatches, setAllBatches }}>
            {children}
        </AllBatchesContext.Provider>
    );
};

export const useAllBatches = () => {
    const context = useContext(AllBatchesContext);
    if (!context) {
        throw new Error('useAllBatches must be used within an AllBatchesProvider');
    }
    return context;
};
