import {Grid, MenuItem, TextField, Typography, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React from "react";
import {tokens} from "../../assets/Theme";


export default function SelectFile({selectedFile,firstRowAsHeader, setFirstRowAsHeader, onClose, handleFileChange, importModule, setImportModule}) {


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const styleInput = {
        position: 'relative',
        top: '9px',
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: `${colors.customColor[100]}`},
        '& label.Mui-focused': {color: `${colors.customColor[100]} !important`},
        '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {borderColor: `${colors.customColor[100]} !important`},
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.customColor[100]} !important`,
            borderRadius: '15px !important'
        },
    };
    return (
        <>
            <Grid item>
                <Grid container direction="row" spacing={4}>
                    <Grid item>
                        <input
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => handleFileChange(e)}
                            style={{display: 'none'}}
                        />
                        <label htmlFor="contained-button-file">
                            <Typography variant="h3" style={{marginLeft: '10px', marginBottom: '20px'}}>
                                Add File
                            </Typography>
                            <Button
                                variant="outlined"
                                component="span"
                                endIcon={<AttachFileIcon />}
                                sx={{
                                    borderColor: `${colors.customColor[100]}`,
                                    width: '50em',
                                    height: '4em',
                                    justifyContent: 'flex-start',
                                    '& svg': {
                                        marginLeft: '20em',
                                    },
                                    borderRadius: '15px !important'
                                }}
                            >
                                {selectedFile ? selectedFile.name : 'Upload File'}
                            </Button>
                        </label>
                    </Grid>
                    <Grid item>
                        <Typography variant="h3" style={{marginLeft: '10px', marginBottom: '20px'}}>
                            Import Location
                        </Typography>
                        <TextField
                            select
                            value={importModule}
                            onChange={(e) => setImportModule(e.target.value)}
                            variant="outlined"
                            sx={{width: "44em", ...styleInput}}
                        >
                            <MenuItem value={'all-grower'}>All Grower</MenuItem>
                        </TextField>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item>
                <Grid item>
                    <FormGroup>
                        <FormControlLabel
                            label="First row as header"
                            control={
                                <Switch defaultChecked
                                        onChange={() => setFirstRowAsHeader(!firstRowAsHeader)}
                                        value={firstRowAsHeader}
                                        sx={{
                                            '& .MuiSwitch-switchBase': {color: `${colors.customColor[100]}`},
                                            '& .Mui-checked + .MuiSwitch-track': {backgroundColor: `${colors.customColor[100]}`},
                                            '& .MuiSwitch-thumb': {backgroundColor: `${colors.customColor[100]}`},
                                            '& .MuiSwitch-root': {height: '40px', width: '70px'}
                                        }}
                                />
                            }
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        </>
    )
}