import {createContext, useContext, useEffect, useState} from "react";
import {getVillages} from "../../api/allSurveys/Fields";


export const VillagesContext = createContext();

    export const VillagesProvider = ({children}) => {
        const [villages, setVillages] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);

        const fetchVillages = async () => {
            setLoading(true);
            try {
                const response = await getVillages();
                if (response){
                    setVillages(response.results.features);
                }
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        }

        useEffect(() => {
            fetchVillages();
        }, []);
        return (
            <VillagesContext.Provider value={{villages, setVillages, loading, error}}>
                {children}
            </VillagesContext.Provider>
        );
    }

    export const useVillages = () => {
        const context = useContext(VillagesContext);
        if (context === undefined) {
            throw new Error('useVillages must be used within a VillagesProvider');
        }
        return context;
    }