import {useState} from 'react';
import {setAuthToken} from "./setAuthToken";


export default function useAuth() {
    const getUser = () => {
        // TODO: Fix Token Verification through backend
        const token = localStorage.getItem('token');
        setAuthToken(token); //Adding to all axios requests
        const username = localStorage.getItem('username');
        const mobile_number = localStorage.getItem('mobile_number');
        const role = localStorage.getItem('role');

        var auth = false;
        if (token && token !== 'undefined') {
            auth = true;
            // Add additional Checks
        }
        return {auth: auth, username: username, mobile_number: mobile_number, token: token, role: role}
    };
    const [user, setUser] = useState(getUser());
    const saveUser = userInfo => {
        localStorage.setItem('token', JSON.stringify(userInfo.token));
        setAuthToken(userInfo.token); //Adding to all axios requests
        localStorage.setItem('username', JSON.stringify(userInfo.username));
        localStorage.setItem('mobile_number', JSON.stringify(userInfo.mobile_number));
        localStorage.setItem('role', JSON.stringify(userInfo.role));

        let auth = false;
        if (userInfo.token && userInfo.token !== 'undefined') {
            auth = true;
        }
        setUser({
            auth: auth,
            username: userInfo.username,
            mobile_number: userInfo.mobile_number,
            role: userInfo.role,
            token: userInfo.token,
        });
    };
    return {
        setUser: saveUser,
        user
    }
}
