import Topbar from "../../components/Topbar";
import React, {useEffect, useState} from "react";
import BoundaryDetailTable from "../../components/tables/BoundaryDetailTable";
import TeamTrakTableFooter from "../../components/teamTrak/TeamTrakTableFooter";
import {useDispatch, useSelector} from "react-redux";
import TeamTrakTableTopNav from "../../components/teamTrak/TeamTrakTableTopNav";
import {teamTrakClear} from "../../reduxSetup/TeamTrak";
import {getTeamTrakPageData} from "../../api/teamTrak/teamTrak";
import {useNavigate} from "react-router-dom";
import { TeamTrakHeaderNames } from "components/allSurvey/Helper";

export default function TeamTrak() {

    const [teamTrakData, setTeamTrakData] = useState([]);
    const currentPage = useSelector( state => state.teamTrakSlice.page);
    const dispatch = useDispatch();
    const [record, setRecord] = useState();
    const [next, setNext] = useState();
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState(
        {
            title: null,
            data: {}
        }
    );



    //function implementation goes down
    const handleSearch=(value) => {
        dispatch(teamTrakClear());
        setSearch(value);
    }

    const filteredEmployees = teamTrakData.filter((employee) => {
        return employee.username?.toLowerCase().includes(search.toLowerCase()) || employee.role?.toUpperCase().includes(search.toUpperCase()) || employee.id?.toString().includes(search)
    });
    const filterSurveyor = teamTrakData.filter((employee) => {
        return employee.role === 'S'
    });

    const fetchTeamTrakData = async (params) => {
        try {
            const teamTrakResponse = await getTeamTrakPageData(params);
            if (teamTrakResponse?.results && teamTrakResponse?.count){
                setTeamTrakData(teamTrakResponse?.results);
                setRecord(teamTrakResponse?.count);
                setNext(teamTrakResponse?.next);
            }else {
                setTeamTrakData([]);
                setRecord(0);
                setNext(null);
            }
        }catch (error) {
            console.error('Error in fetching Team Trak Data', error);
            alert('Error in fetching Team Trak Data')
        }
    }

    const handleClick = () => {
        navigate(`/team-trak`);
    }

    useEffect(() => {
        fetchTeamTrakData({p: currentPage})
    }, [currentPage]);


    return(
       <>
           <Topbar />
           <div style={{ width: "98%", position: "relative", left: "1%" }}>
                <BoundaryDetailTable
                    title={'Team Management'} tabIndex={0}
                    headerNames={TeamTrakHeaderNames}
                    rowsData={filteredEmployees}
                    onSearch={handleSearch}
                    tableNav={<TeamTrakTableTopNav />}
                    footer={
                        <TeamTrakTableFooter
                            currentPage={currentPage}
                            record={record}
                            next={next}
                            dispatch={dispatch}
                        />
                    }
                />

           </div>
       </>
    )
}
