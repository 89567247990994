import {createContext, useContext, useEffect, useState} from "react";
import {getSurveyors} from "../../api/allSurveys/User";

export const SurveyorsContext = createContext();

    export const SurveyorsProvider = ({children}) => {
        const [surveyors, setSurveyors] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);
        const fetchSurveyors = async () => {
            setLoading(true);
            try {
                const response = await getSurveyors({role: 'S'});
                if (response){
                    setSurveyors(response);
                }
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        };
        useEffect(() => {
            fetchSurveyors();
        }, []);
        return (
            <SurveyorsContext.Provider value={{surveyors, setSurveyors, loading, error, fetchSurveyors}}>
                {children}
            </SurveyorsContext.Provider>
        );
    }

    export const useSurveyors = () => {
        const context = useContext(SurveyorsContext);
        if (!context) {
            throw new Error('useSurveyors must be used within a SurveyorsProvider');
        }
        return context;
    };
