import axios from 'axios';

const makeRequest = async (url, method, data = null, params = null) => {
  try {
    const config = {
      method,
      url,
      ...(data && { data }), 
      ...(params && { params }), 
    };

    const response = await axios(config);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const getCropStage = (params) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_stage/`, 'get', null,params);
export const getCroptype = (params) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_type/`, 'get', null,params);
export const getCropCondition = (params) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_condition/`, 'get', null,params);
export const createCropCondition = (data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_condition/`, 'post',data,null);
export const createCropStage = (data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_stage/`, 'post',data,null);
export const createCropType = (data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_type/`, 'post',data,null);
export const updateCropCondition = (id,data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_condition/${id}/`, 'patch',data);
export const updateCropStage = (id,data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_stage/${id}/`, 'patch',data);
export const updateCropType = (id,data,) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_type/${id}/`, 'patch',data);
export const deleteCropCondition = (id,) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_condition/${id}`, 'delete');
export const deleteCropStage = (id,) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_stage/${id}`, 'delete');
export const deleteCropType = (id,) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/crop_type/${id}`, 'delete');