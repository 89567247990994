export const serializeForm = function (form) {
    var obj = {};
    var formData = new FormData(form);
    for (var key of formData.keys()) {
        obj[key] = formData.get(key);
    }
    return obj;
};
// Function to parse KML data and extract latLng values
export default function kmlToLatLng(kml) {
  var parser = new DOMParser();
  var xmlDoc = parser.parseFromString(kml, "text/xml");
  var coords = xmlDoc
    .getElementsByTagName("coordinates")[0]
    .childNodes[0].nodeValue.trim()
    .split(" ");
  var latLng = [];
  for (var i = 0; i < coords.length; i++) {
    var point = coords[i].split(",");
    var lat = parseFloat(point[1]);
    var lng = parseFloat(point[0]);
    latLng.push({ lat: lat, lng: lng });
  }
  return latLng;
}
//debounce function
export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};
