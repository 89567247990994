import React, {useEffect, useState} from "react";
import {getVehicles} from "../../api/ginnerTraceability/vehicle";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {TextField} from "@mui/material";
import MultiSelect from "./MultiSelect";
import {LocalShipping} from "@mui/icons-material";
import SearchableSelect from "./SearchableSelect";



export default function ({completedBatches, driverOptions}) {

    const [spinnerOptions, setSpinnerOptions] = useState([
        {
            name : 'John Doe',
            id : '1',
            label: 'John Doe',
            value: '1' + ' - John Doe'
        },
        {
            name: 'Jane Doe 2',
            id: '2',
            label: 'Jane Doe 2',
            value: '2' + ' - Jane Doe 2'
        }
        ]);
    const  [spinner, setSpinner] = useState('');
    const [driver, setDriver] = useState('');
    const [batch, setBatch] = useState('');
    const [vehicle, setVehicle] = useState('');
    const [vehicleOptions, setVehicleOptions] = useState([]);

    useEffect(() => {

        let isMounted = true;
        //fetch data on mount
        const fetchData = async () => {
            try {
                const response = await getVehicles();
                if (isMounted && response) {
                    response.map((vehicle) => {
                        vehicle.label = vehicle.id;
                        vehicle.value = vehicle.id;
                    })
                    setVehicleOptions(response);
                } else {
                    alert('Error fetching vehicles');
                }

            }catch (error) {
                console.error('Error fetching vehicles:', error);
                if (isMounted) {
                    alert('Error fetching vehicles');
                }
            }
        }
        fetchData();

        return () => {
            isMounted = false;
        };

    }, []);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSave = () => {
        console.log('save ship batch');
    }


    return (
        <div>
            <Button
                variant="outlined"
                color="info"
                onClick={handleOpen}
            >
                <LocalShipping sx={{ mr: 1 }} fontSize="small" />
                Ship Batch
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'scroll',
                        // backgroundColor: 'rgba(255, 255, 255)'
                    }}
                >


                    <Box display="flex" flexDirection="column" maxWidth="300px">
                        { completedBatches !== null && completedBatches.length  > 0  ? (
                            <>
                                <label htmlFor="broker">Select Batch</label>
                                <MultiSelect
                                    options={completedBatches}
                                    setState={setBatch}
                                    state={batch}
                                    showAssignWeight={false}
                                />
                            </>
                        ) : (
                            <p>No Completed Batch found, kindly create new and refresh</p>
                        )}
                    </Box>

                    <Box display="flex" flexDirection="column" maxWidth="300px" style={{ marginTop: "30px" }}>
                        <label htmlFor="driverName" style={{ marginBottom: '3px', marginTop: "5px" }}>Select Driver </label>
                        <SearchableSelect
                            options={driverOptions}
                            setState={setDriver}
                            state={driver}
                        />
                    </Box>

                    <Box display="flex" flexDirection="column" maxWidth="300px" style={{ marginTop: "30px" }}>
                        <label htmlFor="spinnerName" style={{ marginBottom: '3px', marginTop: "5px" }}>Select Spinner </label>
                        <SearchableSelect
                            options={spinnerOptions}
                            setState={setSpinner}
                            state={spinner}
                        />
                    </Box>

                    <Box display="flex" flexDirection="column" maxWidth="300px" style={{ marginTop: "30px" }}>
                        <label htmlFor="vehicle" style={{ marginBottom: '3px', marginTop: "5px" }}>Select Vehicle </label>
                        <SearchableSelect
                            options={vehicleOptions}
                            setState={setVehicle}
                            state={vehicle}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="outlined" color="success" onClick={handleSave}>
                            <LocalShipping sx={{ mr: 1 }} fontSize="small" />
                            Ship
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}