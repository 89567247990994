import React, {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import AddBrokerModal from "../AddBroker";
import HeapModal from "../AddHeap";
import CreateDriverModal from "../AddDriver";
import {Divider, Grid, Input, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import SearchableSelect from "../SearchableSelect";
import dayjs from "dayjs";
import AssignHeaps from "../AssignHeaps";
import {inputStyle, StyledDatePicker} from "../helpers/css/styles";
import AsyncSearchableSelect from "../AsyncSearchableSelect";
import SimpleBackdrop from "../../Backdrop";
import CreateVehicleModal from "../AddVehicle";


export default function DataEntryTab({brokerOptions, setBrokerOptions, heapOptions, setHeapOptions, driverOptions, setDriverOptions, growerOptions, setGrowerOptions}) {

    const [farmerPassbook, setFarmerPassbook] = useState({});
    const [farmerName, setFarmerName] = useState('');
    const [farmerCNIC, setFarmerCNIC] = useState('');
    const [farmerCircleCode, setFarmerCircleCode] = useState('');
    const [farmerVillageCode, setFarmerVillageCode] = useState('');
    const [cropWeight, setCropWeight] = useState(0);
    const [date, setDate] = useState(dayjs());
    const [truckRegistrationNumber, setTruckRegistrationNumber] = useState('');
    const [brokerCNIC, setBrokerCNIC] = useState('');
    const [driverName, setDriverName] = useState({});
    const [driverCNIC, setDriverCNIC] = useState('');
    const [heaps, setHeaps] = useState([]);
    const [broker, setBroker] = useState({});
    const [showBackdrop, setShowBackdrop] = useState(false);


    //updating broker selected CNIC
    useEffect(() => {
        setBrokerCNIC(broker.cnic);
    }, [broker]);

    useEffect(() => {
        setFarmerName(farmerPassbook.grower_name);
        setFarmerCircleCode(farmerPassbook.circle_code);
        setFarmerVillageCode(farmerPassbook.village_code);
        setFarmerCNIC(farmerPassbook.grower_cnic)
    }, [farmerPassbook]);

    useEffect(() => {
        setDriverCNIC(driverName.cnic);
    }, [driverName]);

    return (
        <div>

            {showBackdrop && (<SimpleBackdrop open={showBackdrop} setOpen={setShowBackdrop}/>)}

            <Stack direction={'row'} spacing={2} style={{marginBottom: "30px"}}>

                <div>
                    <AddBrokerModal brokerOptions={brokerOptions} setBrokerOptions={setBrokerOptions}/>
                </div>
                <div>
                    <HeapModal heapOptions={heapOptions} setHeapOptions={setHeapOptions}/>
                </div>
                <div>
                    <CreateDriverModal driverOptions={driverOptions} setDriverOptions={setDriverOptions}/>
                </div>
                <div>
                   <CreateVehicleModal/>
                </div>
            </Stack>

            <Grid container direction={'row'} style={{marginBottom: "30px"}}>
                {/*Left Side - Farmer Data */}
                <h2>Farmer Data</h2>
                <Grid item xs={5}>
                    <form style={{marginTop: "60px"}}>
                        <Box display="flex" flexDirection="column" maxWidth="300px">
                            <label htmlFor="farmer" style={{ marginBottom: '3px', marginTop: "5px" }}>Farmer Passbook</label>
                            <AsyncSearchableSelect
                                setState={setFarmerPassbook}
                                state={farmerPassbook}
                                options={growerOptions}
                                setOptions={setGrowerOptions}
                            />
                        </Box>

                        <Box display="flex" flexDirection="column" maxWidth="300px">
                            <label htmlFor="farmerName" style={{ marginBottom: '3px', marginTop: "5px" }}>Farmer Name</label>
                            <Input
                                id=""
                                name="farmerName"
                                type="text"
                                sx={inputStyle}
                                value={farmerName}
                            />
                        </Box>

                        <Box display="flex" flexDirection="column" maxWidth="300px">
                            <label htmlFor="farmerCNIC" style={{ marginBottom: '3px', marginTop: "5px" }}>Farmer CNIC</label>
                            <Input
                                id="farmerCNIC"
                                name="farmerCNIC"
                                type="number"
                                sx={inputStyle}
                                value={farmerCNIC}
                            />
                        </Box>
                    </form>
                </Grid>

                {/*Right Side - Farmer Data */}
                <Grid item xs={'5'}>
                    <form style={{marginTop: "60px"}} >
                        <Box display="flex" flexDirection="column" maxWidth="300px">
                            <label htmlFor="circleCode" style={{ marginBottom: '3px', marginTop: "5px" }}>Circle Code</label>
                            <Input
                                id="circleCode"
                                name="circleCode"
                                type="mobile_number"
                                sx={inputStyle}
                                value={farmerCircleCode}
                            />
                        </Box>

                        <Box display="flex" flexDirection="column" maxWidth="300px">
                            <label htmlFor="famerAddress" style={{ marginBottom: '3px', marginTop: "5px" }}>Farmer Village Code</label>
                            <Input
                                name="farmerAddress"
                                sx={inputStyle}
                                style={{ background: "transparent", width: "270px", color: "white",borderRadius: '5px'  }}
                                value={farmerVillageCode}
                            />
                        </Box>
                    </form>
                </Grid>
            </Grid>

            {(
                ()=> {
                    if ( Object.keys(farmerPassbook).length > 0){
                        return (
                            <>
                                <Divider />

                                <Grid container direction="row">
                                    {/* Left Side - Crop Data */}
                                    <h2>Crop Data</h2>
                                    <Grid item xs={5}>
                                        <div style={{ padding: '20px'}}>
                                            <form style={{ marginTop: "30px" }}>

                                                <Box display="flex" flexDirection="column" maxWidth="300px" >
                                                    <label htmlFor="weight" style={{ marginBottom: '3px', marginTop: "5px" }}>Crop Weight</label>
                                                    <Input
                                                        id=""
                                                        name="weight"
                                                        type="number"
                                                        required
                                                        sx={inputStyle}
                                                        value={cropWeight===0 ? '' : cropWeight}
                                                        onChange={(e) => setCropWeight(parseInt(e.target.value) || 0 )}
                                                    />
                                                </Box>

                                                <Box display="flex" flexDirection="column" maxWidth="270px" style={{ marginTop: "30px" }} >
                                                    <label htmlFor="weight" style={{ marginBottom: '3px', marginTop: "5px" }}>Date</label>
                                                    <StyledDatePicker
                                                        value={date}
                                                        renderInput={(params) => <TextField {...params} sx={inputStyle} />}
                                                        onChange={(newDate) => {setDate(newDate); console.log('date', date)}}
                                                    />
                                                </Box>

                                                <Box display="flex" flexDirection="column" maxWidth="300px" style={{ marginTop: "30px" }}>
                                                    <label htmlFor="broker" style={{ marginBottom: '3px', marginTop: "5px" }}>Broker</label>
                                                    <SearchableSelect
                                                        options={brokerOptions}
                                                        setState={setBroker}
                                                        state={broker}
                                                    />
                                                </Box>

                                                <Box display="flex" flexDirection="column" maxWidth="300px" style={{ marginTop: "30px" }}>
                                                    <label htmlFor="brokerCNIC" style={{ marginBottom: '3px', marginTop: "5px" }}>Broker CNIC</label>
                                                    <Input
                                                        id=""
                                                        name="brokerCNIC"
                                                        type="text"
                                                        sx={inputStyle}
                                                        value={brokerCNIC}
                                                    />
                                                </Box>

                                            </form>
                                        </div>
                                    </Grid>

                                    {/* Right Side - Crop Data */}
                                    <Grid item xs={5}>
                                        <div style={{ padding: '20px' }}>
                                            <form style={{ marginTop: "30px" }}>


                                                <Box display="flex" flexDirection="column" maxWidth="300px">
                                                    <label htmlFor="truckResgistrationNumber" style={{ marginBottom: '3px', marginTop: "5px" }}>Truck Registration Number</label>
                                                    <Input
                                                        id=""
                                                        name="truckResgistrationNumber"
                                                        type="text"
                                                        sx={inputStyle}
                                                        value={truckRegistrationNumber}
                                                        onChange={(e) => setTruckRegistrationNumber(e.target.value)}
                                                    />
                                                </Box>

                                                <Box display="flex" flexDirection="column" maxWidth="300px" style={{ marginTop: "30px" }}>
                                                    <label htmlFor="driverName" style={{ marginBottom: '3px', marginTop: "5px" }}>Driver Name</label>
                                                    <SearchableSelect
                                                        options={driverOptions}
                                                        setState={setDriverName}
                                                        state={driverName}
                                                    />
                                                </Box>

                                                <Box display="flex" flexDirection="column" maxWidth="300px" style={{ marginTop: "30px" }}>
                                                    <label htmlFor="driverCNIC" style={{ marginBottom: '3px', marginTop: "5px" }}>Driver CNIC</label>
                                                    <Input
                                                        id=""
                                                        name="driverCNIC"
                                                        type="text"
                                                        sx={inputStyle}
                                                        value={driverCNIC}
                                                        onChange={(e) => {
                                                            setDriverCNIC(e.target.value);
                                                        }}
                                                    />
                                                </Box>

                                            </form>

                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }
                }
            )()}



            {/*CTA for saving data*/}
            <Grid container direction={"row-reverse"} style={{paddingRight: "75px"}}>

                {
                    <AssignHeaps
                     heapOptions={heapOptions}
                     setHeapOptions={setHeapOptions}
                     cropWeight={cropWeight}
                     heaps={heaps}
                     grower={farmerPassbook}
                     broker={broker}
                     driver={driverName}
                     vehicle={truckRegistrationNumber}
                    />
                }

            </Grid>
        </div>
    )
}