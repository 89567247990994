import {Grid, MenuItem, TextField, Typography, useTheme} from "@mui/material";
import {tokens} from "../../assets/Theme";


export default function FieldMappings({fieldHeaders, fields, setFields}) {


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const styleInput = {
        position: 'relative',
        top: '9px',
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: `${colors.customColor[100]}`},
        '& label.Mui-focused': {color: `${colors.customColor[100]} !important`},
        '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {borderColor: `${colors.customColor[100]} !important`},
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.customColor[100]} !important`,
            borderRadius: '15px !important'
        },
    };


    const handleFieldMappingChange = (field, event) => {
        const newFields = fields.map((f) => {
            if (f.value === field.value) {
                return {...f, fieldMapping: event.target.value};
            }
            return f;
        });
        setFields(newFields);
    };

    return (
        <>
            {fields.reduce((rows, field, index) => {
                if (index % 3 === 0) rows.push([]);
                rows[rows.length - 1].push(field);
                return rows;
            }, []).map((row, rowIndex) => (
                <Grid item key={rowIndex}>
                    <Grid container direction="row" spacing={4}>
                        {row.map((field) => (
                            <Grid item xs={4} key={field.label}>
                                <Typography variant="h6" style={{marginLeft: '10px', marginBottom: '7px'}}>
                                    {field.label}
                                </Typography>
                                <TextField
                                    select
                                    value={field.fieldMapping}
                                    onChange={(e) => handleFieldMappingChange(field, e)}
                                    variant="outlined"
                                    sx={{width: "100%", ...styleInput}}
                                >
                                    {
                                        fieldHeaders?.map((header) => (
                                            <MenuItem key={header} value={header}>
                                                {header}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </>
    )
}