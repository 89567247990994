import * as React from 'react';
import Popup from 'components/Popup';
import { useParams} from 'react-router-dom';
import {
    Button, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography,
    styled 
} from '@mui/material';
import axios from 'axios';
import {tokens} from '../../assets/Theme';
import {useTheme} from '@mui/material';
import {useNavigate} from "react-router-dom";
import Loader from 'components/Loader';
import Topbar from 'components/Topbar';
import RemarkPopup from 'components/RemarkPopup';

function EmployeeDetail(props) {
    
    const {id} = useParams(); // retrieve the id parameter from the URL
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    // fetch the data for the employees with the specified id
    const [employee, setEmployee] = React.useState(null);


    // fetch the list of gate circles
    const [gateCircles, setGateCircles] = React.useState([]);

    // form state
    const [formValues, setFormValues] = React.useState({
        mobile_number: '', role: '', gate_circles: [],
    });

    // edit mode state
    const [editMode, setEditMode] = React.useState(false);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [passwordOpen, setPasswordOpen] = React.useState(false);
    const [initialEntry, setInitialEntry] = React.useState('');
    const [finalEntry, setFinalEntry] = React.useState('');
    const handleOpenConfirmDialog = () => {
        setConfirmationOpen(true);
    };
    const handleOpenPasswordDialog = () => {
        setPasswordOpen(true);
    };

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/users/${id}/`);
            const data = await response.data;
            setEmployee(data);
            setFormValues(data);
            console.log("formvalue",formValues)
        };

        fetchData();

        const fetchGateCircles = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/gates/`);
            const data = await response.data;
            setGateCircles(data);
        };
        fetchGateCircles();
    }, [id]);

    if (!employee) {
        return <div><Loader/></div>;
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormValues({
            ...formValues, [name]: value,
        });
    };

    const handleGateCircleChange = (event) => {
        const selectedCircleIds = event?.target?.value;
        const selectedCircles = gateCircles?.features?.filter((circle) => selectedCircleIds.includes(circle.id));
        setFormValues((prevValues) => ({
            ...prevValues, gate_circles: selectedCircles,
        }));
    };
    const handleDelete = async () => {

        try {
           let res = await axios.delete(`${process.env.REACT_APP_BASEURL}/api/users/${id}/`);
           navigate('/team-trak')
       }
       
       
         catch (error) {
            console.error(error);
            alert('Failed to delete employee');
        }
    };

    const handleResetPassword = async () => {
        if (initialEntry === finalEntry) {
          if (finalEntry.length >= 6) {
            try {
              const payload = {
                user_id: id,
                password: finalEntry,
              };
      
              await axios.post(`${process.env.REACT_APP_BASEURL}/user/auth/reset_password/`, payload, {
                headers: { 'Content-Type': 'application/json' },
              });
      
            } catch (error) {
              console.error(error);
            }
          } else {
            alert('Password length is less than 6 characters');
          }
        } else {
          alert('Passwords do not match');
        }
      };
      
    const handleSubmit = async (event) => {
        event.preventDefault();
        // delete formValues.id;
        // delete formValues.mobile_number;
        if (editMode) {
            try {
                let gc_to_users = formValues?.gate_circles?.map((gateCircle)=>gateCircle.id);
                console.log("gctou:",gc_to_users)
                console.log(id)
                let data = {username: formValues.username, fullname: formValues.fullname, role: formValues.role};

                await axios.patch(`${process.env.REACT_APP_BASEURL}/api/users/${id}/`, data);
                if ( gc_to_users.length > 0 ){
                await axios.post(`${process.env.REACT_APP_BASEURL}/api/users/assign_gate_circles/`, {
                    "user_id": id, "gate_circle_ids": gc_to_users
                }, 
                {headers: {'Content-Type': 'application/json'}}
                );
                }
                console.log(formValues)
            } catch (error) {
                console.error(error);
                alert('Failed to update employee information');
            }
        }


        setEditMode(!editMode)

    };

    return (<div>
        <Topbar/>
        <Typography variant="h4" component="h1" gutterBottom>
            {employee.username}
        </Typography>
        <form onSubmit={handleSubmit}>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                ID:
                            </TableCell>
                            <TableCell>
                                <TextField name="ID" value={employee.id} disabled={true}/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Mobile Number:
                            </TableCell>
                            <TableCell>
                                <TextField name="mobile_number" value={formValues.mobile_number}
                                           onChange={handleChange} disabled={true}/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Username
                            </TableCell>
                            <TableCell>
                                <TextField name="username" value={formValues.username}
                                           onChange={handleChange} disabled={!editMode}/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Role:
                            </TableCell>
                            <TableCell>
                                <Select name="role" value={formValues.role} onChange={handleChange}
                                           disabled={!editMode}>
                                    <MenuItem value="S">Surveyor</MenuItem>
                                    <MenuItem value="A">Auditor</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>
                        {/* <TableRow>
                            <TableCell component="th" scope="row">
                                Assign Gate Circle
                            </TableCell>
                            <TableCell>
                                {(formValues) && (<Select
                                    name="gate_circles"
                                    multiple
                                    value={formValues?.gate_circles?.map((circle) => circle.id)}
                                    onChange={handleGateCircleChange}
                                    disabled={!editMode}
                                    renderValue={(selected) => {
                                        const selectedCircles = gateCircles?.features?.filter((circle) => selected.includes(circle.id));
                                        return (
                                            <ol style={{ margin: 0 ,paddingLeft: 5 }}>
                                              {selectedCircles?.map((circle) => (
                                                <li key={circle.id}>{circle.properties.name}</li>
                                              ))}
                                            </ol>
                                          );
                                    }}
                                >
                                    {gateCircles?.features?.map((circle) => (
                                        <MenuItem key={circle.id} value={circle.id}>
                                           <ul> <li>{circle.properties.name}</li></ul>
                                        </MenuItem>))}
                                </Select>)}
                            </TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button type="submit" variant="contained" sx={{color:'white', border: '1px solid blue' ,margin: "10px 10px"}} >
        {editMode ? "Save" : "Edit"}
      </Button>
      <Button variant="contained"  sx={{color:'white', border: '1px solid red' ,margin: "10px 10px"}} onClick={handleOpenConfirmDialog}>
        Delete
      </Button>
      <Button variant="contained"  sx={{color:'white', border: '1px solid blue' ,margin: "10px 10px"}} onClick={handleOpenPasswordDialog} >
        Reset Password
      </Button>
        </form>
        <Popup
            title="Warning!"
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            onConfirm={handleDelete}
            className="title_container"
            btnColor='red'
            message={`Are you sure you want to delete ?`}
        />
        <RemarkPopup
            title="Reset Password"
            open={passwordOpen}
            onClose={() => setPasswordOpen(false)}
            onConfirm={handleResetPassword}
            className="title_container"
            btnColor='red'
            message={
                <div >
                    <TextField
                        onChange={(e) => { setInitialEntry(e.target.value); }}
                        placeholder='Enter new password'
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            style: { backgroundColor: '#fff', borderRadius: '5px' }, // Add any other custom styles here
                        }}                    />
                        <TextField
                        onChange={(e) => { setFinalEntry(e.target.value); }}
                        
                        placeholder='Enter same password again'
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            style: { backgroundColor: '#fff', borderRadius: '5px', marginTop:'2px' }, // Add any other custom styles here
                        }}                    />
                        <li style={{color:'#000', fontSize:'13px'}}>
                            <ol>Passbook length should be greater then 6</ol>
                            <ol>Passbook should match</ol>

                        </li>
                </div>
            }
        />
    </div>);
}

export default EmployeeDetail;
