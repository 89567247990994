import React, {  useEffect } from "react";
function IQPlatformRedirection() {
 
  useEffect(() => {
    handleLogin();
  }, []);
  const handleLogin =  () => {
    // event.preventDefault();
    

    const queryParams = new URLSearchParams(window.location.search);

    // Get the value of the 'jwt_token' query parameter
    const jwtToken = queryParams.get("jwt_token");
    const path = queryParams.get("path");
    const id = queryParams.get("id");

    try {
    
      if (!id) {
        window.location.href = `/agrichain/${path}`;
      } else {
        window.location.href = `/agrichain/${path}/${id}`;
      }
    } catch (error) {
      console.log(error);
 
    }
  };
}

export default IQPlatformRedirection
