import {styled} from "@mui/material/styles";
import {numberInputClasses} from "@mui/base/Unstable_NumberInput";
import { Unstable_NumberInput as BaseNumberInput} from "@mui/base/Unstable_NumberInput";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Paper} from "@mui/material";

//styling for active tab
export const activeTabStyle = {
    color: '#6870fa !important',
    fontSize: '16px',
    textTransform: 'none',
    marginRight: '10px',
    marginLeft: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    '&:hover': {
        color: '#fff',
    },
};


const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
};

const grey = {
    50: '#F3F6F9',
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
};
export const inputStyle = {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '5px',
    marginTop: '7px',
    width: '270px',
    '&:focus-within': {
        border: '1px solid #476FFF',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
};

export const CustomMenuProps = {
    PaperProps: {
        style: {
            maxHeight: 250,
        },
    },
};



export const StyledInputRoot = styled('div')(
    ({ theme }) => `
              font-family: IBM Plex Sans, sans-serif;
              font-weight: 400;
              border-radius: 8px;
              color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
              background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
              border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
              box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
              display: grid;
              grid-template-columns: 1fr 19px;
              grid-template-rows: 1fr 1fr;
              overflow: hidden;
            
            
              &.${numberInputClasses.focused} {
                border-color: ${blue[400]};
                box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
              }
            
              &:hover {
                border-color: ${blue[400]};
              }
            
              // firefox
              &:focus-visible {
                outline: 0;
              }
            `,
);

export const StyledInputElement = styled('input')(
    ({ theme }) => `
              font-size: 0.875rem;
              font-family: inherit;
              font-weight: 400;
              line-height: 1.5;
              grid-column: 1/2;
              grid-row: 1/3;
              color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
              background: inherit;
              border: none;
              border-radius: inherit;
              padding: 8px 12px;
              outline: 0;
            `,
);

export const StyledButton = styled('button')(
    ({ theme }) => `
              display: flex;
              flex-flow: row nowrap;
              justify-content: center;
              align-items: center;
              appearance: none;
              padding: 0;
              width: 19px;
              height: 19px;
              font-family: system-ui, sans-serif;
              font-size: 0.875rem;
              box-sizing: border-box;
              line-height: 1.2;
              background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
              border: 0;
              color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
              transition-property: all;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-duration: 120ms;
            
              &:hover {
                background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
                border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
                cursor: pointer;
              }
            
              &.${numberInputClasses.incrementButton} {
                grid-column: 2/3;
                grid-row: 1/2;
              }
            
              &.${numberInputClasses.decrementButton} {
                grid-column: 2/3;
                grid-row: 2/3;
              }
        `,);


//styling for DatePicker
export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: '1px solid #ccc',
        },
        '&:hover fieldset': {
            border: '1px solid #476FFF',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #476FFF',
        },
        '&.Mui-focused': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));


//styling for tabs
export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#141b2d',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const customSelectStyles = {
    control: (provided, state) => ({
        ...provided,
        background: 'transparent',
        border: state.isFocused ? '1px solid #476FFF' : '1px solid #ced4da',
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(71, 111, 255, 0.25)' : null,
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? '#476FFF' : state.isFocused ? '#e0e0e0' : 'white',
        color: state.isSelected ? 'blue' : 'black',
    }),
};

export const customSearchableSelectStyles = {
    control: (provided, state) => ({
        ...provided,
        background: 'transparent',
        border: state.isFocused ? '1px solid #476FFF' : '1px solid #ced4da',
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(71, 111, 255, 0.25)' : null,
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? '#476FFF' : state.isFocused ? '#e0e0e0' : 'white',
        color: state.isSelected ? 'white' : 'black',
    }),
    // Set the text color for the selected option
    singleValue: (provided, state) => ({
        ...provided,
    }),
    input: (provided, state) => ({
        ...provided,
        background: state.isFocused ? 'white' : 'transparent',
        color: 'white',
        textColor: 'white',
    }),
};


export const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#ccc',
        },
        '&:hover fieldset': {
            borderColor: '#476FFF',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#476FFF',
        },
    },
};

export const inputLabelStyle = {
    sx: {
        '&.Mui-focused': {
            color: '#476FFF',
        },
    },
};