import {Typography} from "@mui/material";
import {getFieldsById} from "api/allSurveys/Fields";
import Topbar from "components/Topbar";
import BottomFieldDetail from "components/allSurvey/BottomFieldDetail";
import MiddleFieldDetail from "components/allSurvey/MiddleFieldDetail";
import TopFieldDetail from "components/allSurvey/TopFieldDetail";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export default function FieldDetail() {
    const [fieldData, setFieldData] = useState([]);
    const [loading, setLoading] = useState(true);
    const {id} = useParams();
    const fetchFieldData = async () => {
        try {
            const res = await getFieldsById(id)
            setFieldData(res)
            setLoading(false);
        } catch (error) {
            console.error(error)
        }

    }
    useEffect(() => {
        fetchFieldData();
        return () => setFieldData([])
    }, [])
    return (<>
            <Topbar/>
            <div style={{display: "flex", flexDirection: "column", width: '90%', position: 'relative', left: "5%"}}>
                {(fieldData) && (<><TopFieldDetail data={fieldData}/>
                        <MiddleFieldDetail data={fieldData} loading={loading}/></>
                )}
                <BottomFieldDetail/>
            </div>
        </>);
}
