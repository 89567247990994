import React, {useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import {TextField, Typography, Grid, MenuItem} from "@mui/material";
import {Stack} from "@mui/system";
import {CrossIcon} from "assets/Icons";
import {useDispatch, useSelector} from "react-redux";
import {setFormData, clearFormData} from "reduxSetup/AllSurveyPages";
import Select from '@mui/material/Select';
import {useSurveyors} from "../AppStates/Surveyors";
import {useVillages} from "../AppStates/Villages";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FilterModal({open, onClose, title = "Filter"}) {
    const filteredData = useSelector((state) => state.filterData);
    const [filterData, setFilterData] = useState(filteredData || {
        created_at__gte: "",
        created_at__lte: "",
        status: "",
        user_id: "",
        village_name: "",
        acres__lte: "",
        acres__gte: "",
        created_time__lte: ""
    });

    function convertDateToDDMMYYYY(dateString) {
        // Check if the dateString is in the format yyyy-MM-dd convert
        if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
            const [year, month, day] = dateString.split('-');
            return `${day}-${month}-${year}`;
        }

        // If the dateString is not in the format yyyy-MM-dd return
        return dateString;
    }

    function convertDateToYYYYMMDD(dateString) {
        // Already in yyyy-MM-dd format return
        if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
            return dateString;
        }

        // Check if the dateString is in the format dd-MM-yyyy convert
        if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
            const [day, month, year] = dateString.split('-');
            return `${year}-${month}-${day}`;
        }
    }


    const SelectableStatus = [
        {value: 'PD', label: 'Pending'},
        {value: 'AP', label: 'Approved'},
        {value: 'RE', label: 'Rejected'},
    ];
    const dispatch = useDispatch();
    const {surveyors, setSurveyors} = useSurveyors();
    const {villages, setVillages} = useVillages();

    const handleChange = (field, value) => {
        setFilterData((prevData) => ({...prevData, [field]: value}));
    };

    /** Updates filters of parent on form save||close
     * params: obj (filterData)
     * */
    const handleSave = (data) => {
        if (data.created_at__gte !== "") {
            data.created_at__gte = convertDateToDDMMYYYY(data?.created_at__gte);
        }
        if (data?.created_at__lte !== "" || filteredData?.created_time__lte === "") {
            if (data?.created_at__lte === undefined){
                data.created_time__lte = filteredData?.created_time__lte;
            }else{
                data.created_time__lte = convertDateToDDMMYYYY(data?.created_at__lte)+"T23:59:59";
            }
        }
        delete data?.created_at__lte;
        dispatch(setFormData(data));
        onClose();
    };


    return (<div>
        <Dialog
            sx={{
                ".MuiDialog-paper": {
                    width: "500px !important",
                    height: "560px !important",
                    background: "#F1F6FF",
                    boxShadow: "0px 4px 6px 3px rgba(0, 0, 0, 0.30)",
                    borderRadius: 20,
                },
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography className="modal-title">{title}</Typography>
                <Button
                    size="small"
                    sx={{top: "5px"}}
                    onClick={() => onClose()}
                >
                    <CrossIcon style={{width: "25px", height: "25px"}}/>
                </Button>
            </Stack>
            <hr style={{opacity: "0.2", width: "90%"}}/>
            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                    sx={{marginBottom: "10px"}}
                >
                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            <Grid item>
                                <Typography>Start Date</Typography>
                                <TextField
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        top: "5px", width: "210px", "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#1BC795 !important",
                                        }, "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#fff",
                                        },
                                    }}
                                    value={filterData?.created_at__gte ? convertDateToYYYYMMDD(filterData?.created_at__gte) : ''}
                                    onChange={(e) => handleChange("created_at__gte", e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>End Date</Typography>
                                <TextField
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        top: "5px", width: "210px", "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#1BC795 !important",
                                        }, "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#fff",
                                        },
                                    }}
                                    value={filterData?.created_at__lte !== "" ? convertDateToYYYYMMDD(filterData?.created_at__lte) : convertDateToYYYYMMDD(filteredData?.created_time__lte?.split('T')[0]) || ''}
                                    onChange={(e) => handleChange("created_at__lte", e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                    sx={{marginBottom: "10px"}}
                >
                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            <Grid item>
                                <Typography>Status</Typography>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        top: "5px",
                                        width: "210px",
                                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#1BC795 !important",
                                        },
                                        "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#fff",
                                        },
                                    }}
                                    value={filterData?.status || filteredData?.status}
                                    onChange={(e) => handleChange("status", e.target.value)}
                                >
                                    {SelectableStatus.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item>
                                <Typography>Village Name</Typography>

                                <Select
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        top: "5px",
                                        width: "210px",
                                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#1BC795 !important",
                                        },
                                        "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#fff",
                                        },
                                    }}
                                    value={`${filterData?.village_name}` || `${filteredData?.village_name}`}
                                    onChange={(e) => handleChange("village_name", e.target.value)}
                                >
                                    {villages?.map((option) => (

                                        <MenuItem key={option?.id} value={option?.properties?.name}>
                                            {option?.properties?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {/*<TextField*/}
                                {/*    type="text"*/}
                                {/*    fullWidth*/}
                                {/*    variant="outlined"*/}
                                {/*    sx={{*/}
                                {/*        top: "5px", width: "210px", "& .Mui-focused .MuiOutlinedInput-notchedOutline": {*/}
                                {/*            borderColor: "#1BC795 !important",*/}
                                {/*        }, "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {*/}
                                {/*            borderColor: "#fff",*/}
                                {/*        },*/}
                                {/*    }}*/}
                                {/*    value={filterData?.village || filteredData?.village}*/}
                                {/*    onChange={(e) => handleChange("village", e.target.value)}*/}
                                {/*/>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            <Grid item>
                                <Typography>From Acres</Typography>
                                <TextField
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        top: "5px", width: "210px", "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#1BC795 !important",
                                        }, "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#fff",
                                        },
                                    }}
                                    value={filterData?.acres__gte || filteredData?.acres__gte}
                                    onChange={(e) => handleChange("acres__gte", e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>To Acres</Typography>
                                <TextField
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        top: "5px", width: "210px", "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#1BC795 !important",
                                        }, "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#fff",
                                        },
                                    }}
                                    value={filterData?.acres__lte || filteredData?.acres__lte}
                                    onChange={(e) => handleChange("acres__lte", e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                    sx={{marginBottom: "10px"}}
                >
                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            <Grid item sx={{marginTop: "10px"}}>
                                <Typography>Surveyor Name</Typography>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        top: "5px",
                                        width: "210px",
                                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#1BC795 !important",
                                        },
                                        "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#fff",
                                        },
                                    }}
                                    value={`${filterData?.user_id}` || `${filteredData?.user}`}
                                    onChange={(e) => handleChange("user_id", e.target.value)}
                                >
                                    {surveyors.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.username}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                <Button
                    variant="contained"
                    sx={{
                        width: "95%",
                        height: "100%",
                        background: "#1BC795",
                        borderRadius: "10px",
                        color: "#333",
                        textDecoration: "bold !important",
                        "&:hover": {
                            background: "#149b6a",
                        },
                    }}
                    onClick={() => {
                        handleSave(filterData);
                    }}
                >
                    Apply
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        width: "95%",
                        height: "100%",
                        background: "#1BC795",
                        borderRadius: "10px",
                        color: "#333",
                        textDecoration: "bold !important",
                        "&:hover": {
                            background: "#149b6a",
                        },
                    }}
                    onClick={() => {
                        dispatch(clearFormData());
                        setFilterData({
                            created_at__gte: "",
                            created_at__lte: "",
                            status: "",
                            user_id: "",
                            village_name: "",
                            acres__lte: "",
                            acres__gte: "",
                        });
                    }}
                >
                    Clear All Filter
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}
