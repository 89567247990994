import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { tokens } from '../assets/Theme';
import { useTheme} from '@mui/material';

function RemarkPopup(props) {
    const { open, onClose, onConfirm, title, message,  btnColor } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    const handleConfirm = () => {
      onConfirm();
      onClose();
    };
  
    return (
      <Dialog className='remark-box' open={open} onClose={onClose} PaperProps={{ sx: { backgroundColor: '#fff !important', borderRadius:'10px', width:'35%', height:'35%' } }}>
        <div style={{borderBottom:'1px solid #000'}}>
        <Typography className='remark-title'  sx={{ marginBottom:'2%'}}>{title}</Typography>
        </div>
        
        
        <DialogContent style={{fontWeight:'bolder', color: 'black'}}>{message}</DialogContent>
        <DialogActions>
          <Button variant='outlined' style={{borderRadius: '100px' , border: '1px solid #476FFF', color:'#000'}} onClick={onClose}>No</Button>
          <Button variant='contained' style={{color: '#fff', background:'#780808', borderRadius:"100px" }} onClick={handleConfirm}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  export default RemarkPopup;