import axios from "axios";
const BASEURL = process.env.REACT_APP_BASEURL;


// function to get stocks

export const getStocks = async () => {
    try {
        const response = await axios.get(`${BASEURL}/api/stock/`);
        console.log('Success getting stock response \n',response.data);
        return response?.data;
    } catch (error) {
        console.log('Server Error getting Stock \n', error);
        return false;
    }

}


//function to create Stocks
export const createStock = async (data) => {
    try {
        const response = await axios.post(`${BASEURL}/api/stock/`, data);
        return response?.data;
    } catch (error) {
        console.log('Server Error creating Stock \n', error);
        return false;
    }
}