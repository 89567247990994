import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {TextField} from "@mui/material";
import {inputLabelStyle, textFieldStyle} from "./helpers/css/styles";
import {createHeap} from "../../api/ginnerTraceability/heap";
import Loading from "../BeatLoader";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function HeapModal ({heapOptions, setHeapOptions}) {
    const [heapWeight, setHeapWeight] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const data = {
            driver : 7,
            vehicle: 'RBN-415'
        }
    }, []);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSave = () => {
        let data = {
            weight_limit: heapWeight,
        }
        setIsLoading(true);
        try {
            if ( heapWeight ) {
                createHeap(data).then((res) => {
                    if (res) {
                        res.value = res.id;
                        res.label = res.id;
                        setHeapOptions([...heapOptions, res]);
                        setIsLoading(false);
                        alert(`Heap created successfully : heapId = ${res.id}`)
                        handleClose();
                    } else {
                        setIsLoading(false);
                        alert('Heap creation failed, kindly refresh the page');
                    }
                })
            }else {
                data = {}
                createHeap(data).then((res) => {
                    if (res) {
                        console.log(res.id);
                        setIsLoading(false);
                        alert(`Heap created successfully : heapId = ${res.id}`)
                        handleClose();
                    } else {
                        setIsLoading(false);
                        alert('Heap creation failed, kindly refresh the page');
                    }
                });
            }
        }catch (error){
            console.error('Error creating heap');
            setIsLoading(false);
        }
    }


    return (
        <div>
            <Button
                variant="outlined"
                color="secondary"
                onClick={handleOpen}
            >
                <AddIcon sx={{ mr: 1 }} fontSize="small" />
                Create Heap
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'scroll',
                    }}
                >
                    <TextField
                        label="Total Weight"
                        type="number"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={heapWeight}
                        onChange={(e) => setHeapWeight(e.target.value)}
                        sx={textFieldStyle}
                        InputLabelProps={inputLabelStyle}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Close
                        </Button>

                        <Button variant="outlined" color="success" onClick={handleSave} disabled={isLoading}>
                            Create
                            {
                                isLoading && (
                                    <div style={{marginTop: '5px'}}>
                                        <Loading size={13} />
                                    </div>
                                )
                            }
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}