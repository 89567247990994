import React from "react";
import {Button, useTheme} from "@mui/material";
import {tokens} from "../../assets/Theme";
import {useNavigate} from "react-router-dom";
import AddModal from '../../components/AddModal';



export default function TeamTrakTableTopNav() {
    const [addEmployeeOpen, setAddEmployeeOpen] = React.useState(false);
    const [employees, setEmployees] = React.useState([]);

    const theme = useTheme();
    const navigate = useNavigate();
    const handleAddEmployee = (employee) => {
        if (employee && employee.id) {
            setEmployees((employees) => [...employees, employee]);
            setAddEmployeeOpen(false);
        }
    };
    const colors = tokens(theme.palette.mode);
    return(
        <>
            <Button
                variant="text"
                size="small"
                sx={{
                    marginTop: "21px",
                    marginRight: "10px",
                    marginLeft: "10px",
                    width: "20%",
                    backgroundColor: `${colors.customColor[100]} !important`,
                    height: "3em",
                    fontWeight: "bold",
                    fontSize: "0.7em",
                    color: 'white'
                }}
                onClick={() => {navigate('/team-trak/activity-tracking')}}
            >
                Activity Tracking
            </Button>
            <Button
                variant="text"
                size="small"
                sx={{
                    marginTop: "21px",
                    marginRight: "10px",
                    marginLeft: "10px",
                    width: "20%",
                    backgroundColor: `${colors.customColor[100]} !important`,
                    height: "3em",
                    fontWeight: "bold",
                    fontSize: "0.7em",
                    color: 'white'
                }}
                onClick={() => setAddEmployeeOpen(true)}
            >
                Add employee
            </Button>
            <AddModal
                open={addEmployeeOpen}
                onClose={() => setAddEmployeeOpen(false)}
                onAddEmployee={handleAddEmployee}
            />
        </>
    )
}
