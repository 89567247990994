import React, { useState } from "react";
import { tokens } from "assets/Theme";
import { useTheme, Button, Checkbox, FormControlLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addRowId, removeRowId } from "reduxSetup/AllSurveyPages";
import { useNavigate } from "react-router-dom";

function Status({ rowData }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let statusText;
  let color;

  switch (rowData?.status?.toLowerCase()) {
    case "ap":
      statusText = "Approved";
      color = "green";
      break;
    case "re":
      statusText = "Rejected";
      color = "red";
      break;
    case "pd":
      statusText = "Pending";
      color = "blue";
      break;
    default:
      statusText = rowData?.status;
      color = "inherit";
      break;
  }

  return <span style={{ color }}>{statusText}</span>;
}
function Role({ rowData }) {

  let roleText;

  switch (rowData?.role?.toLowerCase()) {
    case "s":
      roleText = "Surveyor";
      break;
    case "a":
      roleText = "Auditor";
      break;
    default:
      roleText = rowData?.role;
      break;
  }

  return <span >{roleText}</span>;
}
function Check({ rowData }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const selectedRowIds = useSelector((state) => state.rowsIdsSlice);

  const handleCheckboxChange = () => {
    if (selectedRowIds.includes(rowData.id)) {
      dispatch(removeRowId(rowData.id));
    } else {
      dispatch(addRowId(rowData.id));
    }
  };
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={selectedRowIds.includes(rowData.id)}
          onChange={handleCheckboxChange}
          sx={{
            color: `${colors.customColor[800]}  !important`,
            "&.Mui-checked": { color: `${colors.customColor[800]} !important` },
          }}
        />
      }
    />
  );
}
function Btn({ rowData }) {
  const navigate = useNavigate();
  return (
    <Button
      size="small"
      variant="text"
      sx={{ fontWeight: "bold", textDecoration: "underline", color: `#0375C5` }}
      onClick={() => navigate(`/all-surveys/bounderies/field/${rowData.id}`)}
    >
      View
    </Button>
  );
}
  
function Teambtn({ rowData }) {
  const navigate = useNavigate();
  return (
    <Button
      size="small"
      variant="text"
      sx={{ fontWeight: "bold", textDecoration: "underline", color: `#0375C5` }}
      onClick={() => navigate(`/team-trak/${rowData.id}`)}
    >
      View Detail
    </Button>
  );
}
  
export const TeamTrakHeaderNames = [
  {header: "User ID", value: "id"},
  {header: "Username", value: "username"},
  {header: "Mobile Number", value: "mobile_number"},
  { header: "Role", value: (rowData) => <Role rowData={rowData} /> },
  // {header: "Boundary Name", value: "boundary_name"},
  {header: "Survey Target", value: "survey_target_acres"},
  {header: "Total Survey", value: "survey_completed"},
  { header: `Action `, value: (rowData) => <Teambtn rowData={rowData} /> },
  // {header: "Assigned Task", value: "assigned_task"},
  // {header: "Status", value: "status"},
  // {header: "Total Attendance", value: "total_attendance"}
];

export const FieldsHeaders = [
  { header: "Field ID", value: "id" },
  { header: "Passbook No", value: "passbook" },
  { header: "Surveyor", value: "surveyor" },
  { header: "Grower", value: "grower" },
  { header: "Survey date", value: "created_at" },
  { header: "Crop type", value: "cropType" },
  { header: "Total Area", value: "total_area" },
  { header: "Status", value: (rowData) => <Status rowData={rowData} /> },
  { header: `Action `, value: (rowData) => <Btn rowData={rowData} /> },
];
export const UsersHeaders = [
  {
    header: "checkbox",
    value: (rowData) => <Check rowData={rowData} />,
  },
  {
    header: "User ID",
    value: "id",
  },
  {
    header: "Name",
    value: "fullname",
  },
  {
    header: "Mobile Number",
    value: "mobile_number",
  },
  {
    header: "Role",
    value: "role",
  },
];
