import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { getTask } from 'api/develoPro/Tasks';
import Topbar from 'components/Topbar';
import { DataGrid } from '@mui/x-data-grid';
import { allTaskColumns } from 'components/develoPro/helpers/DataColumns';
import SummaryBar from 'components/develoPro/SummaryBar';
import { Button, Grid, Typography, Stack, Select, MenuItem } from '@mui/material';
import { FilterIcon } from 'assets/Icons';
import SearchBar from 'components/develoPro/SearchBar';
import AccordionProjectSummary from 'components/develoPro/AccordionSummary';


export default function ProjectDetail() {

const [task, setTask] = useState()
const [next , setNext] = useState(null);
const [previous , setPrevious] = useState(null);
const [open, setOpen] = useState(false);
const [searchResults, setSearchResults] = useState(null);
const [page, setPage] = useState(1);
const navigate = useNavigate();
const {pageNumber} = useParams();
const {id} = useParams()
const totalPages = task?.total_pages
const queryParams = { project: id ,
    page: pageNumber 
};
const fetchProjectsDetails = async () => {
  try {
    if (searchResults === null){
    const res = await getTask(queryParams);
    console.log(res);
    setTask(res);
    setNext(res?.data?.links?.next)
    setPrevious(res?.data?.links?.previous)
    }else {
      setTask(searchResults)
      setNext(searchResults?.data?.links?.next)
      setPrevious(searchResults?.data?.links?.previous)
      
    }

  } catch (error) {
    console.error(error);
  }
};
 
  
  console.log("next", next, "previous", previous)
  const pageOptions = [];
  for (let pageCount = 1; pageCount <= totalPages; pageCount++) {
    pageOptions.push(
      <MenuItem key={pageCount} value={pageCount} >
       {pageCount}
      </MenuItem>
    );
  }
  const handleNextpage = () => {
    if (next !== null && searchResults === null) {
        const nextPage = parseInt(pageNumber, 10) + 1; 
        navigate(`/developro/projects/id/${id}/page/${nextPage}`);
    }else if (next !== null && searchResults !== null) {
      const nextPage = parseInt(pageNumber, 10) + 1; 
      setPage(nextPage)
      navigate(`/developro/projects/id/${id}/page/${nextPage}`);
      
    }
}
const handlePreviousPage = () => {
  if (previous !== null && searchResults === null) {
      const previousPage = parseInt(pageNumber, 10) - 1;

      navigate(`/developro/projects/id/${id}/page/${previousPage}`);
  } else if (previous !== null && searchResults !== null){
    const previousPage = parseInt(pageNumber, 10) - 1;
    setPage(previous)
    navigate(`/developro/projects/id/${id}/page/${previousPage}`);
  }
}
const handlePageChange = (event) => {
  if (searchResults === null){
  const newPage = event.target.value;
  
  navigate(`/developro/projects/id/${id}/page/${newPage}`);
  } else {
  const newPage = event.target.value;
  setPage(newPage)
  navigate(`/developro/projects/id/${id}/page/${newPage}`);
  }
};

  useEffect(() => {
    fetchProjectsDetails();
  }, [pageNumber,searchResults ]);


  const handleSearchResults = (results) => {
    setSearchResults(results);
  };
  

return (
    <div>
        <Topbar/>
        <div style={{width:'95%',position:'relative', left:'2%'}}>
            <SummaryBar/>
            {/* <AccordionProjectSummary id = {id}/> */}
            <div style={{display:'flex' ,justifyContent: 'space-between', width:"97%",marginTop: '20px'}}>
              <div >
                <Typography sx={{ marginTop: '20px', fontSize: '18px', fontWeight: '500' }}>Task List</Typography>
              </div>
              <div >
                <Stack direction="row" >
                  <SearchBar id={id} onSearchResults={handleSearchResults} searchAbout='Task' pageNumber={page} />
                  {/* <Button>
                    <FilterIcon style={{ height: '50px', width: '50px' }} />
                  </Button> */}
                  <Button variant="contained" className="createProjectbtn" onClick={() => navigate(`/developro/projects/id/${id}/task/create`)}>
                    Create Task
                  </Button>
                </Stack>
              </div>
            </div>
            <div style={{height:'55vh', marginTop:'2%',width: '100%',background: '#fff',borderRadius: 20}}>
                <DataGrid
                    rows={Array.isArray(task?.results) ? task?.results : []}
                    columns={allTaskColumns}
                    checkboxSelection
                    pageSize={5}
                    components={{
                        Footer: () => (
                            <Stack direction="row" spacing={2} sx={{ margin:'1%',display:'flex' ,justifyContent: 'flex-end' }}>
            
                                    <Button className='footerbtn' variant='outlined' onClick={handlePreviousPage} disabled={pageNumber < 2}>
                                      previous
                                    </Button>
                                    <Button className='footerbtn' variant='outlined' onClick={handleNextpage} disabled={pageNumber > totalPages - 1} >
                                      next
                                    </Button>
                                    {task && task?.total_pages && (
                                <Select value={pageNumber} sx={{ height: '40px' }} onChange={handlePageChange}>
                                  {pageOptions}
                                </Select>
                              )}
                            </Stack>
                        )
                    }}

                />
            </div>
        </div>
    
    
    </div>
)
}
