import React from 'react';
import Modal from '@mui/material/Modal';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';




const ImageModal = ({setImages, open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="image-list-modal"
      aria-describedby="modal-with-image-list"
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        
          padding: 20,
        }}
      >
        <ImageList cols={3} gap={7}>
          {setImages?.map((image, index) => (
            <ImageListItem key={index}>
              <img src={image} alt='Field image' />
            
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </Modal>
  );
};

export default ImageModal;
