import React, { useEffect } from 'react'
import Topbar from 'components/Topbar';
import VarietyModal from 'components/varietyManagement/VarietyModal';
import ManagementTable from 'components/tables/ManagementTable';
import { Stack,Button } from '@mui/material';
import { getVariety } from 'api/surveySetup/Variety';
import { incrementVarietyPage,decrementVarietyPage } from 'reduxSetup/SurveySetupPages';
import { useSelector, useDispatch } from 'react-redux';
export default function CropConditionManagement() {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [varietyList, setVarietyList] = React.useState([])
    const [next, setNext] = React.useState()
    const dispatch = useDispatch();
    const currentPage = useSelector(state => state.variety.page);
    const headerNames = [{header:'Variety ID',value:'id'},{header:'Variety name',value:'name'},{header:'Variety Type',value:'type'},{header:'Variety Stage',value:'variety_stage'}];
    const params = {p: currentPage}
    const fetchVariety = async () => {
      try {
      const res = await getVariety(params)
      setVarietyList(res);
      setNext(res?.next)
      console.log(res)
      } catch (error) {
        console.error(error)
      }
    }
    useEffect(()=>{
      fetchVariety();
    },[currentPage])

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSaveVariety = () => {
    handleCloseModal();
  };
  const handleNextPage = () => {
    dispatch(incrementVarietyPage());
  };

  const handlePreviousPage = () => {
    dispatch(decrementVarietyPage());
  };

  return (
    <div>
    <Topbar/>
  
    <div style={{width:'60%', marginTop:'10px'}}>
    <ManagementTable
        headerNames={headerNames}
        rowsData={varietyList}
        title='Variety Management'
        actionButtonLabel='Add More'
        onActionButtonClick={handleOpenModal}
        ModalComponent={VarietyModal} 
        footer={<Stack direction="row" spacing={2}
        sx={{marginTop: '5%', marginBottom:'3%', justifyContent: 'flex-end', position: 'relative', right: '7%'}}>
     <Button className='footerbtn-variety' variant='outlined' onClick={handlePreviousPage}
            >
         previous
     </Button>
     <Button className='footerbtn-variety' variant='outlined' disabled={next === null} onClick={handleNextPage} 
            >
         next
     </Button>
</Stack>}
      />

      {modalOpen && (
        <VarietyModal
          open={modalOpen}
          onClose={handleCloseModal}
          onSaveVariety={handleSaveVariety}
        />
      )}
    </div>
    </div>
  )
}