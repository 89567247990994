import React from 'react'
import { Dialog, DialogContent, Button, Typography } from '@mui/material';
import { tokens } from '../../assets/Theme';
import { useTheme } from '@mui/material';

export default function ConfirmationPopup({title,onOpen, onClose, onConfirm}) {
const theme = useTheme();
const colors = tokens(theme.palette.mode);

  return (
    <div> <Dialog
    sx={{
      '& .MuiDialog-container': {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .MuiPaper-root': {
        m: 0, // Remove or adjust margins as needed
        width: '500px',
        height: '200px',
      },
    }}
    open={onOpen} 
    onClose={onClose}
  >
    <DialogContent
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 2, // Adjust the gap between items as needed
      }}
    >
      <Typography variant="h4" fontWeight="bold" textAlign="center">
      {title}
      </Typography>
      <div>
        <Button variant="outlined" onClick={onClose} sx={{ mr: 2, background:'transparent',color: '#F46464', border: '2px solid #F46464 !important' }}>
          NO
        </Button>
        <Button variant="outlined" onClick={onConfirm} sx={{ mr: 2, background:'transparent', border:`2px solid ${colors.customColor[800]}`,color: `${colors.customColor[800]}` }}>
          Yes
        </Button>
      </div>
    </DialogContent>
  </Dialog></div>
  )
}
