import {Typography, Button, Box} from "@mui/material";
import React from "react";
import AddGrowerModal from "../allGrowers/AddGrowerModal";
import SAPDetails from "./SAPDetails";

export default function MiddleFieldDetail({ data, loading }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  }


  const formatDateTime = (timestamp) => {
    if (!timestamp) return "";
    const dateObject = new Date(timestamp);
    const formattedDateTime = dateObject.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    return formattedDateTime;
  };
  const  GrowerTyper = (data) => {
    switch (data) {
      case true:
        return "Loanee";
      case false:
        return "Not loanee";
      default:
        break;
    }
  };
  return (
      <>
          <div
              style={{
                  width: "100%",
                  height: "100%",
                  background: "white",
                  boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
                  borderRadius: 20,
                  marginTop: "2%",
              }}
          >
              <div style={{display: "flex", flexDirection: "row"}}>
                  <div
                      style={{
                          width: "50%",
                          borderBottom: "1px solid rgba(51, 51, 51, 0.30)",
                          borderRight: "1px solid rgba(51, 51, 51, 0.30)",
                          paddingLeft: "2%",
                      }}
                  >
                      <Typography variant="h5" fontWeight="bold" padding="2%">
                          Crop Detail
                      </Typography>
                      <div style={{direction: "flex", flexDirection: "column"}}>
                          <div
                              style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                              }}
                          >
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Crop Condition:
                                      </Typography>
                                      <Typography>
                                          {data?.properties?.crops_condition?.name}
                                      </Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Estimated Yield:
                                      </Typography>
                                      <Typography>{data?.properties?.estimated_yield}</Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Crop Type:
                                      </Typography>
                                      <Typography>{data?.properties?.crops_type?.name}</Typography>
                                  </div>
                              </div>
                          </div>
                          <div
                              style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                              }}
                          >
                              <div style={{width: "120px"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Sowing Date:
                                      </Typography>
                                      <Typography>
                                          {formatDateTime(data?.properties?.sowing_date)}
                                      </Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Sowing Distance:
                                      </Typography>
                                      <Typography>
                                          {data?.properties?.sowing_distance || "null"}
                                      </Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Crop Disease:
                                      </Typography>
                                      <Typography>
                                          {data?.properties?.diseases.map((disease, index) => (
                                              <div key={index}>
                                                  <Typography>{disease?.disease?.name} : {disease?.percentage}%</Typography>
                                                  {/*<Typography></Typography>*/}
                                              </div>
                                          ))}
                                      </Typography>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div
                      style={{
                          width: "50%",
                          borderBottom: "1px solid rgba(51, 51, 51, 0.30)",
                          paddingLeft: "2%",
                      }}
                  >
                      <Typography variant="h5" fontWeight="bold" padding="2%">
                          Grower Information
                      </Typography>
                      <div style={{direction: "flex", flexDirection: "column"}}>
                          <div
                              style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                              }}
                          >
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          ID:
                                      </Typography>
                                      <Typography>{data?.grower?.id}</Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Grower Name:
                                      </Typography>
                                      <Typography>{data?.grower?.grower_name}</Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Passbook Number:
                                      </Typography>
                                      <Typography>{data?.grower?.passbook_number}</Typography>
                                  </div>
                              </div>
                          </div>
                          <div
                              style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                              }}
                          >
                              <div style={{width: "120px"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Grower Type
                                      </Typography>
                                      <Typography>{GrowerTyper(data?.grower?.loanee)}</Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          CNIC
                                      </Typography>
                                      <Typography>{data?.grower?.grower_cnic}</Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          More Details
                                      </Typography>
                                      <Button variant='text' onClick={() => {
                                          data.grower.action = 'View Grower';
                                          data.grower.disableEditing = true;
                                          setIsModalOpen(true);
                                      }}>view</Button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div style={{display: "flex", flexDirection: "row"}}>
                  <div
                      style={{
                          width: "50%",
                          borderBottom: "1px solid rgba(51, 51, 51, 0.30)",
                          borderRight: "1px solid rgba(51, 51, 51, 0.30)",
                          paddingLeft: "2%",
                      }}
                  >
                      <Typography variant="h5" fontWeight="bold" padding="2%">
                          Variety Information
                      </Typography>
                      <div style={{direction: "flex", flexDirection: "column"}}>
                          <div
                              style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                              }}
                          >
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Variety Name
                                      </Typography>
                                      <Typography>
                                          {data?.properties?.varieties.map((variety, index) => (
                                              <div key={index}>

                                                  <Typography>{variety?.variety?.name}</Typography>
                                              </div>
                                          ))}
                                      </Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Variety Type
                                      </Typography>
                                      <Typography>{data?.properties?.varieties.map((variety, index) => (
                                          <div key={index}>

                                              <Typography>{variety?.variety?.type}</Typography>
                                          </div>
                                      ))}</Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Variety Stage:
                                      </Typography>
                                      <Typography>{data?.properties?.varieties.map((variety, index) => (
                                          <div key={index}>

                                              <Typography>{variety?.variety?.variety_stage}</Typography>
                                          </div>
                                      ))}</Typography>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div style={{direction: "flex", flexDirection: "column"}}>
                          <div
                              style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                              }}
                          >
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Variety Acres
                                      </Typography>
                                      <Typography>{data?.properties?.varieties.map((variety, index) => (
                                          <div key={index}>

                                              <Typography>{variety?.acres}</Typography>
                                          </div>
                                      ))}</Typography>
                                  </div>
                              </div>

                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Crop Stage
                                      </Typography>
                                      <Typography>{data?.properties?.varieties.map((variety, index) => (
                                          <div key={index}>

                                              <Typography>{variety?.crop_stage?.name}</Typography>
                                          </div>
                                      ))}</Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                  </div>
                              </div>

                          </div>


                      </div>
                  </div>
                  <div
                      style={{
                          width: "50%",
                          borderBottom: "1px solid rgba(51, 51, 51, 0.30)",

                          paddingLeft: "2%",
                      }}
                  >
                      <Typography variant="h5" fontWeight="bold" padding="2%">
                          Surveyor Information
                      </Typography>
                      <div style={{direction: "flex", flexDirection: "column"}}>
                          <div
                              style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                              }}
                          >
                              <div style={{width: "120px", paddingBottom: "3%"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          ID:
                                      </Typography>
                                      <Typography>{data?.properties?.user}</Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Surveyor Name
                                      </Typography>
                                      <Typography>{data?.properties?.user_location?.user?.username}</Typography>
                                  </div>
                              </div>
                              <div style={{width: "120px"}}>
                                  <div>
                                      <Typography
                                          variant="h6"
                                          sx={{
                                              color: "#1BC693",
                                              fontSize: 16,
                                              fontFamily: "Century Gothic",
                                              fontWeight: "700",
                                          }}
                                      >
                                          Field proximity
                                      </Typography>
                                      <Typography>{data?.properties?.field_proximity.toFixed(2)} - KM</Typography>
                                  </div>
                              </div>
                          </div>
                          <div
                              style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                              }}
                          >
                              <Typography variant="h5" fontWeight="bold" padding="2%">
                                  Additional Field
                              </Typography>
                              <div
                                  style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                  }}
                              >

                                  {
                                      data?.properties?.additional_fields === [{}] || data?.properties?.additional_fields === null ? <Typography>No additional fields</Typography> :
                                      data?.properties?.additional_fields?.map((field) => {
                                         return (<div style={{width: "120px"}}>
                                              <>
                                                  <Typography
                                                      variant="h6"
                                                      sx={{
                                                          color: "#1BC693",
                                                          fontSize: 16,
                                                          fontFamily: "Century Gothic",
                                                          fontWeight: "700",
                                                      }}
                                                  >
                                                      {field?.name}
                                                  </Typography>
                                                  <Typography>{field?.value}</Typography>

                                              </>
                                          </div>)
                                      })
                                  }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              {/*<div style={{display: "flex", flexDirection: "row"}}>*/}
              {
                  !loading ? <SAPDetails fieldId={data.id}/> : <Typography>Loading...</Typography>
              }
              {/*</div>*/}
              <div style={{display: "flex", flexDirection: "row"}}>
                  <div style={{direction: "flex", flexDirection: "column", width: "50%"}}>
                          <Box
                              sx={{
                                  borderRadius: 5,
                                  border: '1px solid #1BC693',
                                  padding: '10px',
                                  width: '90%',
                                  margin: '5px'
                              }}
                          >
                              <Typography
                                  variant="h6"
                                  sx={{
                                      color: "#1BC693",
                                      fontSize: 16,
                                      fontFamily: "Century Gothic",
                                      fontWeight: "700",
                                  }}
                              >
                                  Rejection Feedback:
                              </Typography>
                              <Typography>{data?.properties?.rejection_feedback || ''}</Typography>
                          </Box>
                  </div>
                  <div style={{direction: "flexbox", flexDirection: "column", width: "50%"}}>
                      {/*<div style={{}}>*/}
                          <Box
                              sx={{
                                  borderRadius: 5,
                                  border: '1px solid #1BC693',
                                  padding: '10px',
                                  width: '90%',
                                  margin: '5px'
                              }}
                          >
                              <Typography
                                  variant="h6"
                                  sx={{
                                      color: "#1BC693",
                                      fontSize: 16,
                                      fontFamily: "Century Gothic",
                                      fontWeight: "700",
                                  }}
                              >
                                  Remarks:
                              </Typography>
                              <Typography>{data?.properties?.notes || ''}</Typography>
                          </Box>
                      {/*</div>*/}
                  </div>
              </div>
          </div>
          {
              isModalOpen && (
                  <AddGrowerModal title={'Grower'} onClose={handleModalClose} open={isModalOpen} growerData={data?.grower} />
              )
          }

      </>
  )
      ;
}
