import React, { useState,useEffect } from 'react'
import { Button, Stack, TextField, Typography, Select, MenuItem, Autocomplete } from '@mui/material'
import { useParams } from 'react-router-dom'
import Topbar from 'components/Topbar'
import { getTaskById, getGrowers, updateTask, getUsers } from 'api/develoPro/Tasks'
import DeleteTaskDialog from 'components/develoPro/DeleteTaskDialog'
import CommentsWs from 'components/develoPro/CommentsWs'

export default function TaskDetail() {
    const [editMode, setEditMode] = useState(false)
    const [growersList, setGrowersList] = useState([]);
    const [growers, setGrowers] = useState([]);
    const [selectedGrowers, setSelectedGrowers] = useState([]);
    const[usersList, setUsersList] = useState([])
    const [assignees, setAssignees] = useState([]);
    const [supervisorId, setSupervisorId] = useState("");
    const [additionalFields, setAdditionalFields] = useState({});
    const [open, setOpen] = useState(false);
    const [taskData, setTaskData] = useState({name:'',
    task_type:'',
    username:'',
    status:'',
    start_date:'',
    end_date:'',
    description:'',})
  
    const {id} = useParams();
    const storedCompanyInfo = JSON.parse(localStorage.getItem('companyInfo'));
    console.log("companylocal",storedCompanyInfo?.id)
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (editMode) {
        
          try {
            const formData = {
              ...taskData,
              assignee_ids: assignees,
              supervisor: supervisorId,
              grower_ids: selectedGrowers.length > 0 ? selectedGrowers : growers.map(grower => grower.id) ,
              additional_fields: additionalFields,
            }
            await updateTask(id, formData)
          } catch (error) {
            console.error(error)
          }
        }
        setEditMode(!editMode);
      };
    
      const fetchTaskById = async() =>{
        try {
           const res = await getTaskById(id);
           setTaskData(res);
           setAssignees(res?.assignees.map(user => user.id))
           setAdditionalFields(res?.additional_fields || {});
           setSupervisorId(res?.supervisor?.id || "");
           setGrowers(res?.growers)
          console.log("ad",additionalFields)
           console.log('grower',growers)
           console.log(res);
        } catch (error) {
            console.error(error);
        }
      }
      const fetchUser = async() => {
        try {
          const res = await getUsers();
          setUsersList(res);
        } catch (error) {
          console.error(error);
        }
      }
      const handleClose = () => {
        setOpen(false);
      };
    useEffect(() =>{
        fetchTaskById();
        fetchUser();
    },[]);

    const fetchGrowers = async (searchValue) => {
        try {
          if (searchValue?.length >= 3) {
            const growerParams = {search: searchValue}
            const res = await getGrowers(growerParams) 
            setGrowersList(res)
            console.log(res)
          }
        } catch (error) {
          console.error(error);
        }
      };
      const handleGrowerChange = (event, newValue) => {
        console.log('Handle Grower Change', newValue);
        setGrowers(newValue); 
        setSelectedGrowers(newValue.map((grower) => grower.id));
      };
      const handleChange = (field, value) => {
        setTaskData((prevData) => ({ ...prevData, [field]: value }));
      };
     
      const handleAdditionalFieldChange = (name, value) => {
        setAdditionalFields(prevFields => ({
          ...prevFields,
          [name]: {
            ...prevFields[name],
            value: value
          }
        }));
      };
      const handleAssignees =(value) => {
        setAssignees(value);
      }
      const handleSupervisor =(value) => {
        setSupervisorId(value);
      }
      
  return (
    <div>
        <Topbar/>
        <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row', width:'95%', marginLeft:'2%', marginTop:'2%'}}>
            <Typography variant='h4'>Task Detail</Typography>
            <Stack direction='row' spacing={2}>
                <Button   
                variant='contained'  
                sx={{border:'1px solid #FF0000 !important', color:'#FF0000', background:'transparent',  width:'100px'}}
                onClick={()=> setOpen(true)} 
                >Delete</Button>
                <Button variant='outlined' sx={{background:'#1BC795', width:'100px', '&:hover': {
                          background: '#1BC795', 
                        }}} onClick={handleSubmit}>
                            {!editMode ? 'Edit Task' : 'Save Task'}
                </Button>
            </Stack>

        </div>
        <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row', width:'95%', marginLeft:'2%', marginTop:'1%'}}>
            <div style={{width:'50%', maxHeight:'78vh', background:'#fff',border:'1px solid #000', display:'flex', justifyContent:'space-between', flexDirection:'column',borderRadius:'10px', overflow:'auto'}}>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',margin:'2%', borderBottom:'1px solid #000',}}>
                        <Typography>Task Name:</Typography>
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                          }}
                          size='small'
                          value={taskData?.name}
                          disabled={!editMode}
                          onChange={(e) => handleChange('name', e.target.value)}
                        />
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',margin:'10px', borderBottom:'1px solid #000',}}>
                        <Typography>Task Type:</Typography>
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                          }}
                          size='small'
                          value={taskData?.task_type}
                          disabled={!editMode}
                          onChange={(e) => handleChange('task_type', e.target.value)}
                        />               
                        </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',margin:'10px', borderBottom:'1px solid #000',}}>
                        <Typography>Supervisor:</Typography>
                        <Select
                          sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            width:'12vw'
                          }}
                          value={supervisorId}
                          size='small'
                          disabled={!editMode}
                          onChange={(e) => handleSupervisor(e.target.value)}                        
                          >
                            {usersList?.map((user) =>(
                              <MenuItem key={user?.id} value={user?.id}>
                                {user?.fullname}
                              </MenuItem>
                            ))}
                          </Select>
                    </div>
                   {(taskData?.assignees) && <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',margin:'10px', borderBottom:'1px solid #000',}}>
                        <Typography>Assignees:</Typography>
                        <Select
                          sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            width:'12vw'
                          }}
                          size='small'
                          value={assignees}
                          disabled={!editMode}
                          multiple
                          onChange={(e) => handleAssignees(e.target.value)}                        
                          >
                            {usersList?.map((user) =>(
                              <MenuItem key={user?.id} value={user?.id}>
                                {user?.fullname}
                              </MenuItem>
                            ))}
                          </Select>
                    </div>}
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',margin:'10px', borderBottom:'1px solid #000',}}>
                    <Typography>Status:</Typography>
                        <Select
                           sx={{
                             '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                               borderColor: 'transparent !important',
                             },
                             '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                               borderColor: 'transparent !important',
                             },
                             '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                               borderColor: 'transparent !important',
                             },
                             width:'12vw'
                           }}
                           size='small'
                           value={taskData?.status}
                           disabled={!editMode}
                           onChange={(e) => handleChange('status', e.target.value)}
                         >
                            <MenuItem value="completed">Completed</MenuItem>
                            <MenuItem value="cancelled">Cancelled</MenuItem>
                            <MenuItem value="pending">Pending</MenuItem>
                            <MenuItem value="in_progress">In Progress</MenuItem>
                         </Select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '10px', borderBottom: '1px solid #000' }}>
                        <Typography>Growers (Optional):</Typography>
                        <Autocomplete
                          options={growersList}
                          getOptionLabel={(option) =>String(option?.grower_name + '( ' + option?.passbook_number+ ' )' )} 
                          sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            width:'13vw'
                          }}
                          multiple
                          size='small'
                          disabled={!editMode}
                          value={growers}              
                          onChange={handleGrowerChange}
                          onInputChange={(e, newValue) => fetchGrowers(newValue)}
                          renderInput={(params) => (
                            <TextField {...params} label='' variant='outlined' />
                          )}
                        />
                      </div>
                   
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',margin:'10px',paddingRight:'7%', borderBottom:'1px solid #000',}}>
                        <Typography>Task Start: </Typography>
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                          }}
                          size='small'
                          type='date'
                          value={taskData?.start_date}
                          disabled={!editMode}
                          onChange={(e) => handleChange(e, 'start_date')}
                        />
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',margin:'10px',paddingRight:'7%', borderBottom:'1px solid #000',}}>
                        <Typography>Task End: </Typography>
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                          }}
                          size='small'
                          type='date'
                          value={taskData?.end_date}
                          disabled={!editMode}
                          onChange={(e) => handleChange(e, 'end_date')}
                        />
                    </div>
                    {Object.keys(additionalFields).map((key) => (
                      <div key={key} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '10px', borderBottom: '1px solid #000' }}>
                         {editMode ? (
                            <TextField
                              sx={{
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'transparent !important',
                                },
                                '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'transparent !important',
                                },
                                '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'transparent !important',
                                },
                              }}
                              size='small'
                              value={additionalFields[key].name}
                              onChange={(e) => {
                                const newAdditionalFields = { ...additionalFields };
                                newAdditionalFields[key].name = e.target.value;
                                setAdditionalFields(newAdditionalFields);
                              }}
                            />
                          ) : (
                            <Typography>{additionalFields[key].name}:</Typography>
                          )}
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                          }}
                          size='small'
                          value={additionalFields[key].value}
                          disabled={!editMode}
                          onChange={(e) => handleAdditionalFieldChange(key, e.target.value)}
                        />
                      </div>
                    ))}
                       <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between',margin:'10px'}}>
                        <Typography>description: </Typography>
                        <TextField 
                        sx={{ '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {borderColor: '#000',
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: "white !important",}}}}
                            multiline 
                            value={taskData?.description} 
                            disabled={!editMode} 
                            rows={2}
                            onChange={(e) => handleChange(e, 'description')}/>
                    </div>
                
                

            </div>
            {/* <div 
            style={{
                background:'#272633', 
                height:'78.5vh', 
                width:'48%', 
                borderRadius:'10px',
                display:'flex', 
                // overflow: 'auto',
                flexDirection:'column',
                padding:'1%'
                
        
            }}>
                   <Typography 
                      variant='h4' 
                      style={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#272633',
                        zIndex: 1,
                        padding: '1%',
                      }}
                    >Comments</Typography>
                 <CommentsWs taskID={id} commenterID={storedCompanyInfo?.id}/> 
            </div> */}
        </div>
        <DeleteTaskDialog open={open } close={handleClose} id={id} taskName={taskData?.name}  />
    </div>
  )
}
