import axios from "axios";


const makeRequest = async (url, method, data = null, params = null) => {
    try {
        const config = {
            method,
            url,
            ...(data && { data }),
            ...(params && { params }),
        };

        const response = await axios(config);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getFields = (params) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/additional_fields/`, 'get', null, params);
export const createField = (data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/additional_fields/`, 'post', data);
export const updateField = (id, data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/additional_fields/${id}/`, 'patch', data);
export const deleteField = (id) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/additional_fields/${id}/`, 'delete');