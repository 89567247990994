import axios from "axios";
const BASEURL = process.env.REACT_APP_BASEURL;



//function to get drivers
export const getDrivers = async () => {

    try {
        const response = await axios.get(`${BASEURL}/api/vehicle-driver/`);
        return response?.data;
    } catch (error) {
        console.log('Server Error getting Drivers \n', error);
        return false;
    }
}


//function to create driver
export const createDriver = async (data) => {
    try {
        const response = await axios.post(`${BASEURL}/api/vehicle-driver/`, data);
        return response?.data;
    } catch (error) {
        console.log('Server Error creating Driver \n', error);
        return false;
    }
}