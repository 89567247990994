import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Topbar from "components/Topbar";
import { getVariety } from "api/surveySetup/Variety";
import { getCropStage } from "api/surveySetup/Crop";
import { getDisease } from "api/surveySetup/Disease";
import { getUsers } from "api/develoPro/Tasks";
import {getCircles, searchGateCircle} from "api/allSurveys/GateCircles";
import {reports } from "components/Reporting/helper";
import SearchIcon from "@mui/icons-material/Search";


export default function Reporting() {
  const [selectedFileType, setSelectedFileType] = useState("csv");
  const queryParamsInitialState = {
    start_date: "",
    end_date: "",
    passbook: [],
    varieties_id: [],
    variety_stage: [],
    crop_stage: [],
    disease: [],
    surveyor: [],
    grower_cnic: "",
    circles: [],
    survey_id: "",
    filetype: selectedFileType,
    area_from: "",
    area_to: "",
    template: 'master',
    stage: "Programmed",
    pdf : false,
  }
  const [varietyList, setVarietyList] = useState([]);
  const [varietyStageList, setVarietyStageList] = useState([]);
  const [cropStageList, setCropStageList] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);
  const [surveyorList, setSurveyorList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [gateList, setGateList] = useState([]);
  const [selectedReport, setSelectedReport] = useState({})
  const [queryParams, setQueryParams] = useState(queryParamsInitialState);
  const fetchVariety = async () => {
    try {
      const res = await getVariety();
      setVarietyList(res);
      const varietyStages = res?.map((variety) => {
          return variety?.variety_stage;
      });
      const varietyStagesList = varietyStages?.flat();
      const nullRemoved = varietyStagesList.filter((item) => item !== null);
      const VarietyStageList = [...new Set(nullRemoved)];
      setVarietyStageList(VarietyStageList);

    } catch (error) {
      console.error(error); 
    }
  };
  const fetchCropStage = async () => {
    try {
      const res = await getCropStage();
      setCropStageList(res);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDisease = async () => {
    try {
      const res = await getDisease();
      setDiseaseList(res);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchGate = async () => {
    try {
      const res = await getCircles();
      console.info('length', res);
      setGateList(res);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchSurveyor = async () => {
    try {
      const res = await getUsers();
      const surveyors = res?.filter((user) => user.role === "S"); // Filter out only the users with role 'S'
      setSurveyorList(surveyors);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setSelectedReport(reports[0]);
    fetchVariety();
    fetchCropStage();
    fetchDisease();
    fetchSurveyor();
    fetchGate();
  }, []);


  const handleReportChange = (event) => {
    const selectedReport = reports.find((report) => report.id === event.target.value);
    setQueryParams({ ...queryParams, template: selectedReport.template });
    setSelectedReport(selectedReport);
  };

  const handleSearch = async (value) => {
    if (value.length > 3){
      setSearchValue(value)
      const res = await searchGateCircle(value);
      //gateList?.results?.features
      if (res?.results?.features){
        setGateList(res)
      }
    }
  }

  const handleParamChange = (paramName) => (event) => {
    let value = event.target.value;
    setQueryParams({ ...queryParams, [paramName]: value });
  };
  const downloadFile = (filetype) => {
    const queryParamsForRequest = {
      ...queryParams,
      varieties_id: queryParams.varieties_id.filter(Boolean),
      variety_stage: queryParams.variety_stage.filter(Boolean),
      crop_stage: queryParams.crop_stage.filter(Boolean),
      disease: queryParams.disease.filter(Boolean),
      surveyor: queryParams.surveyor.filter(Boolean),
      circles: queryParams.circles.filter(Boolean),
      filetype: filetype
    };
    const nonEmptyParams = Object.fromEntries(
        Object.entries(queryParamsForRequest || {}).filter(([_, value]) => value !== "" && value.length !== 0)
    );

    if (!queryParamsForRequest.start_date || !queryParamsForRequest.end_date) {
      alert("Please add date ranges");
      return;
    }
    
    const queryParamsString = new URLSearchParams(nonEmptyParams);
    const downloadUrl = `${process.env.REACT_APP_MICROSERVICES_URL}/reporting/export_data?${queryParamsString}`;
    axios({
      method: "get",
      url: downloadUrl,
      responseType: "blob",
    })
      .then((response) => {
        let filename = `data.${selectedFileType}`;
        if (selectedFileType === "csv") {
          filename = "data.xlsx";
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };
  return (
    <div>
      <Topbar />
      <div
        style={{
          width: "98%",
          boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
          borderRadius: 20,
          height: "95vh",
          position: "relative",
          left: "1%",
        }}
      >
        <h1 style={{ marginLeft: "2%" }}>Reporting</h1>
        <Grid container spacing={1} sx={{marginBottom: '15px', marginTop: '10px',width:'95%'}}>
          <Typography sx={{marginLeft: "3%", width: "70%"}}>
            Select Report
          </Typography>
          <TextField
              select
              value={selectedReport?.id  === undefined ? "Default" : selectedReport.id}
              onChange={handleReportChange}
              variant="outlined"
              sx={{
                marginTop: "1%",
                marginLeft: "3%",
                width: "25%",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1BC693",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1BC693",
                },
              }}
          >
            {reports.map((option) => (
                <MenuItem key={option?.id} value={option?.id}>
                  {option.name}
                </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container spacing={2}>
          {selectedReport.start_date &&  <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Start Date
            </Typography>
            <TextField
                type="datetime-local"
                value={queryParams.start_date}
                onChange={handleParamChange("start_date")}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            />
          </Grid>}
          {selectedReport.end_date && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              End Date
            </Typography>
            <TextField
                type="datetime-local"
                value={queryParams.end_date}
                onChange={handleParamChange("end_date")}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            />
          </Grid>}
          {selectedReport.varieties && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Varieties
            </Typography>
            <TextField
                select
                value={queryParams.varieties_id}
                onChange={handleParamChange("varieties_id")}
                variant="outlined"
                SelectProps={{
                  multiple: true,
                }}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            >
              {varietyList.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option.name}
                  </MenuItem>
              ))}
            </TextField>
          </Grid>}
          {selectedReport.variety_stage && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Variety Stage
            </Typography>
            <TextField
                select
                value={queryParams.variety_stage}
                onChange={handleParamChange("variety_stage")}
                variant="outlined"
                SelectProps={{
                  multiple: true,
                }}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            >
              {varietyStageList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
              ))}
            </TextField>
          </Grid>}
          {selectedReport.crop_stage && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Crop Stage
            </Typography>
            <TextField
                select
                value={queryParams.crop_stage}
                onChange={handleParamChange("crop_stage")}
                variant="outlined"
                SelectProps={{
                  multiple: true,
                }}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            >
              {cropStageList.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option.name}
                  </MenuItem>
              ))}
            </TextField>
          </Grid>}
          {selectedReport.passbook_number && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Passbook Number
            </Typography>
            <TextField
                type="number"
                value={queryParams.passbook}
                onChange={handleParamChange("passbook")}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            />
          </Grid>}
          {selectedReport.status && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Status
            </Typography>
            <TextField
                select
                value={queryParams.status}
                onChange={handleParamChange("status")}
                variant="outlined"
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            >
              <MenuItem value="AP">Approved</MenuItem>
              <MenuItem value="RE">Rejected</MenuItem>
              <MenuItem value="PD">Pending</MenuItem>
            </TextField>
          </Grid>}
          {selectedReport.stage && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Stage Name
            </Typography>
            <TextField
                select
                value={queryParams.stage}
                defaultValue={"Programmed"}
                onChange={handleParamChange("stage")}
                variant="outlined"
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            >
              <MenuItem value="Programmed">Programmed</MenuItem>
              <MenuItem value="Inferior">Inferior</MenuItem>
              <MenuItem value="Pipe Line">Pipe Line</MenuItem>
              <MenuItem value="Improved (Mid Late)">Improved (Mid Late)</MenuItem>
              <MenuItem value="Improved (Early)">Improved (Early)</MenuItem>
            </TextField>
          </Grid>}
          {selectedReport.disease && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Disease
            </Typography>
            <TextField
                select
                value={queryParams.disease}
                onChange={handleParamChange("disease")}
                variant="outlined"
                SelectProps={{
                  multiple: true,
                }}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            >
              {diseaseList.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option.name}
                  </MenuItem>
              ))}
            </TextField>
          </Grid>}
          {selectedReport.surveyor && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Surveyor
            </Typography>
            <TextField
                select
                value={queryParams.surveyor}
                onChange={handleParamChange("surveyor")}
                variant="outlined"
                SelectProps={{
                  multiple: true,
                }}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            >
              {surveyorList.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option.username}
                  </MenuItem>
              ))}
            </TextField>
          </Grid>}
          {selectedReport.grower_cnic && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Grower Cnic
            </Typography>
            <TextField
                type="number"
                value={queryParams.grower_cnic}
                onChange={handleParamChange("grower_cnic")}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            />
          </Grid>}
          {
              selectedReport.gate_circles && (
                  <Grid item xs={4}>
                    <Typography sx={{ marginLeft: "5%", width: "70%" }}>Gate Circle</Typography>
                    <TextField
                        select
                        value={queryParams.circles}
                        onChange={handleParamChange("circles")}
                        variant="outlined"
                        SelectProps={{
                          multiple: true,
                        }}
                        sx={{
                          marginTop: "2%",
                          marginLeft: "5%",
                          width: "70%",
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                        }}
                    >
                      <TextField
                          InputProps={{
                            startAdornment: (
                                <SearchIcon sx={{ color: "gray", cursor: "pointer" }} />
                            ),
                          }}
                          sx={{
                            marginLeft: '7px'
                          }}
                          onChange={(event) => {
                            handleSearch(event.target.value);
                          }}
                      />
                      {
                        gateList?.results?.features?.filter((option) => {
                          const searchTerm = searchValue.toLowerCase() || ""; // Make search case-insensitive
                          return option.properties?.name.toLowerCase().includes(searchTerm);
                        }).map((option) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.properties?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
              )}

          {selectedReport.survey_id && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Survey ID
            </Typography>
            <TextField
                type="number"
                value={queryParams.survey_id}
                onChange={handleParamChange("survey_id")}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            />
          </Grid>}
          {selectedReport.area_from && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Area From
            </Typography>
            <TextField
                type="number"
                value={queryParams.area_from}
                onChange={handleParamChange("area_from")}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            />
          </Grid>}
          {selectedReport.area_to && <Grid item xs={4}>
            <Typography sx={{marginLeft: "5%", width: "70%"}}>
              Area To
            </Typography>
            <TextField
                type="number"
                value={queryParams.area_to}
                onChange={handleParamChange("area_to")}
                variant="outlined"
                InputLabelProps={{shrink: true}}
                sx={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  width: "70%",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1BC693",
                  },
                }}
            />
          </Grid>}
        </Grid>

        <Stack direction="row" sx={{ position: "relative", left: "30%",width:"45%" }}>
          <Typography
            sx={{ marginTop: "2%", marginLeft: "5%", color: "#1BC693" }}
            fontWeight="bold"
          >
            Export:
          </Typography>
          <Button
            variant="text"
            color="primary"
            size="small"
            sx={{
              marginTop: "2%",
              marginLeft: "2%",
              textDecoration: "underline",
              color: "#0375C5",
              borderLeft: "1px solid #0375C5",
            }}
            onMouseEnter={()=> setSelectedFileType("csv")}
            onClick={() => {
              setSelectedFileType("csv");
              downloadFile("csv");
            }}
          >
            CSV
          </Button>
          <Button
            variant="text"
            color="primary"
            size="small"
            sx={{
              marginTop: "2%",
              marginLeft: "2%",
              textDecoration: "underline",
              color: "#0375C5",
              borderLeft: "1px solid #0375C5",
            }}
            onMouseEnter={()=> setSelectedFileType("geojson")}
            onClick={() => {
          
              setSelectedFileType("geojson");
              downloadFile("geojson");
            }}
          >
            Geojson
          </Button>
          {selectedReport?.pdf && (
              <Button
                  variant="text"
                  color="primary"
                  size="small"
                  sx={{
                    marginTop: "2%",
                    marginLeft: "2%",
                    textDecoration: "underline",
                    color: "#0375C5",
                    borderLeft: "1px solid #0375C5",
                  }}
                  onMouseEnter={()=> setSelectedFileType("pdf")}
                  onClick={() => {
                    setSelectedFileType("pdf");
                    downloadFile("pdf");
                  }}
              >
                PDF
              </Button>
              )
          }

          <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="text"
              color="primary"
              size="small"
              sx={{
                marginTop: "2%",
                marginLeft: "2%",
                textDecoration: "underline",
                color: "#0375C5",
              }}
              onClick={() => {
                setQueryParams(queryParamsInitialState)
                setSelectedReport(reports[0])
              }}
              >
                Clear Filters
            </Button>
        </Stack>
      </div>
    </div>
  );
          }