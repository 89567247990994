import SurveyorTrak from "components/teamTrak/SurveyorTrak";
import Topbar from "components/Topbar";
import React, {useEffect, useRef, useState} from "react";
import { useParams } from "react-router-dom";

import L from "leaflet";
import ReactDOMServer, { renderToString } from "react-dom/server";
import { InIcon, Location, OutIcon } from "../../assets/Icons";
import { getUserDetail } from "../../api/teamTrak/teamTrak";
import Loader from "../../components/Loader";
import { GeoJSON, LayersControl, MapContainer, TileLayer } from "react-leaflet";
import { Typography, Grid, Stack, TextField } from "@mui/material";
import UserTrackingPopup from "../../components/teamTrak/UserTrackingPopup";
import ProgressBar from "../../components/teamTrak/ProgressBar";

const SurveyorDetailSec = () => {
  const { id } = useParams();
  const [surveyorData, setSurveyorData] = useState({});
  const currentDate = new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(`${currentDate}`);
  const pointDataRef = useRef(null)
  const lineDataRef = useRef(null)

  // Update Geojson when Data is updated
  useEffect(() => {
    if (pointDataRef.current &&  surveyorData.points) {
      pointDataRef.current.clearLayers().addData(surveyorData.points);
    }
    if (lineDataRef.current && surveyorData.linestring) {
      lineDataRef.current.clearLayers().addData(surveyorData.linestring);
    }
  }, [surveyorData]);


  const customIcon = new L.divIcon({
    className: "custom-icon",
    html: renderToString(<Location />),
    iconSize: [32, 32],
  });
  const inIcon = new L.divIcon({
    html: renderToString(<InIcon />),
    iconSize: [32, 32],
  });
  const outIcon = new L.divIcon({
    html: renderToString(<OutIcon />),
    iconSize: [32, 32],
  });
  const onEachFeature = (feature, layer) => {
    if (feature) {
      const popupComponent = ReactDOMServer.renderToString(
        <UserTrackingPopup feature={feature} />
      );
      layer.bindPopup(popupComponent);
    }
  };
  const pointToLayer = (feature, latlng) => {
    if (feature.properties.status === "si") {
      return L.marker(latlng, { icon: inIcon });
    } else if (feature.properties.status === "so") {
      return L.marker(latlng, { icon: outIcon });
    }
    return L.marker(latlng, { icon: customIcon });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  console.log(selectedDate);

  useEffect(() => {
    getUserDetail({ user_id: id, date: selectedDate }).then((data) => {
      setSurveyorData(data);
    });
  }, [selectedDate]);
  console.log("sd", surveyorData);
  if (!surveyorData?.points?.features) {
    return (
      <div>
        <Loader />
      </div>
    );
  } else {
    const containerStyle = {
      display: "flex",
      justifyContent: "space-between",
    };

    const mapStyle = {
      minWidth: "70%",
      maxWidth: "70%",
      height: "65vh",
      borderRadius: "12px",
    };

    const sidebarStyle = {
      maxWidth: "25%",
      minWidth: "25%",
      position: "relative",
      right: "2px",
      minHeight: "60vh",
      overFlow: "auto",
    };

    const contentStyle = {
      borderRadius: "12px",
      minHeight: "55vh",
    };
    const listItemStyle = {
      backgroundColor: "#ffffff",
      borderBottom: "1px solid #ddd",
      // borderRadius: '8px',
      padding: "1px",
      marginBottom: "10px",
    };

    // Function to format date as YYYY-MM-DD
    const formatDate = (date) => {
      return date.toISOString().split("T")[0];
    };


    return (
      <>
        <Grid
          container
          sx={{
            width: "92% ",
            maxHeight: "17vh",
            minHeight: "17vh",
            background: "#FFFDFD",
            boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
            borderRadius: 5,
            marginTop: "2%",
            marginLeft: "2%",
            marginBottom: "3%",
          }}
          direction="row"
        >
          <Grid item width="25%" textAlign="center" alignSelf="center">
            <Typography variant="h7" fontSize="14px" color="#1bc693">
              ID
            </Typography>
            <Typography variant="h5" fontSize="16px" color="#000">
              {surveyorData.user?.id}
            </Typography>
          </Grid>
          <Grid
            item
            width="25%"
            textAlign="center"
            borderLeft="1px #000 solid"
            alignSelf="center"
          >
            <Typography variant="h7" fontSize="14px" color="#1bc693">
              Name
            </Typography>
            <Typography variant="h5" fontSize="16px" color="#000">
              {surveyorData.user?.fullname}
            </Typography>
          </Grid>
          <Grid
            item
            width="25%"
            textAlign="center"
            borderLeft="1px #000 solid"
            alignSelf="center"
          >
            <Typography variant="h7" fontSize="14px" color="#1bc693">
              Mobile Number
            </Typography>
            <Typography variant="h5" fontSize="16px" color="#000">
              {surveyorData.user?.mobile_number}
            </Typography>
          </Grid>
          <Grid
            item
            width="25%"
            textAlign="center"
            borderLeft="1px #000 solid"
            alignSelf="center"
          >
            <Typography variant="h7" fontSize="14px" color="#1bc693">
              Role
            </Typography>
            <Typography variant="h5" fontSize="16px" color="#000">
              {surveyorData.user?.role === "S" && "Surveyor"}
            </Typography>
          </Grid>
        </Grid>

        <div
          style={{
            background: "white",
            boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
            borderRadius: 20,
            width: "93%",
            position: "relative",
            left: "2%",
            padding: "2%",
          }}
        >
          <h2>Team Trak</h2>

          <Stack
            direction="row"
            spacing={2}
            sx={{ marginTop: "2%", marginLeft: "2%" }}
          >
            <ProgressBar progress={surveyorData.user.survey_completed || 0} />
            <Typography variant="h6" fontSize="12px" color="#000">
              Survey Target Acres
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight="bold"
              sx={{ color: "#1bc693", marginTop: "5px" }}
            >
              {surveyorData?.user?.survey_target_acres}
            </Typography>
            <Typography variant="h6" fontSize="12px" color="#000">
              To Date
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight="bold"
              sx={{ color: "#1bc693", marginTop: "5px" }}
            >
              {surveyorData?.user?.survey_attributes?.to_date_surveyed}
            </Typography>
            <Typography variant="h6" fontSize="12px" color="#000">
              On Date
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight="bold"
              sx={{ color: "#1bc693", marginTop: "5px" }}
            >
              {surveyorData?.user?.survey_attributes?.on_date_surveyed}
            </Typography>
               {/* Date Picker */}
          <TextField
            type="date"
            title="Date"
            value={selectedDate}
            onChange={(event) => handleDateChange(event.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            sx={{ marginTop: "2%", marginLeft: "5%", width: "30%" }}
          />
          </Stack>
          <div style={containerStyle} >
            <MapContainer
              key={selectedDate}
              center={[33.6844, 73.0479]}
              zoom={5}
              maxZoom={40}
              style={mapStyle}
              attributionControl={false}
            >
              <LayersControl>
                <LayersControl.BaseLayer name={"Satellite Imagery"} checked>
                  <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
                </LayersControl.BaseLayer>
                <LayersControl.Overlay name={"Surveyors"} checked>
                  {(surveyorData.points) && <GeoJSON
                      ref={pointDataRef}
                      data={surveyorData.points}
                      onEachFeature={onEachFeature}
                      pointToLayer={pointToLayer}
                  />
                  }                </LayersControl.Overlay>
                <LayersControl.Overlay name={"Path"} checked>
                  {(surveyorData.linestring) &&  <GeoJSON
                      ref={lineDataRef}
                      data={surveyorData.linestring}   />}
                </LayersControl.Overlay>
              </LayersControl>
            </MapContainer>
            <div style={sidebarStyle}>
              <Typography
                variant="h5"
                paddingBottom="10px"
                alignSelf="end"
                sx={{ color: "#1bc693" }}
              >
                Recent Surveys:{" "}
                <span style={{ color: "#000" }}>
                  {surveyorData?.user?.survey_attributes?.on_date_surveyed}
                </span>
              </Typography>
              {surveyorData.user.gate_circles?.map((data, index) => (
                <div key={index} style={listItemStyle}>
                  {data?.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default function SurveyorDetail() {
  return (
    <>
      <Topbar />
      <SurveyorDetailSec />
    </>
  );
}
