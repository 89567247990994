import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import {useTheme} from "@mui/material";
import {tokens} from '../assets/Theme';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function Cards(props) {
  const { title, subtitle, description } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box sx={{ width: '211px'}}>
      <Card variant="outlined" sx={{background: `${colors.customColor[500]} !important`, borderRadius:'15px',boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)', height:'100px'}}>
        <CardContent sx={{ color:colors.customColor[100]}}>
          {/*<Typography sx={{ fontSize: 14 }}  gutterBottom>*/}
          {/*  {subtitle}*/}
          {/*</Typography>*/}
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <Typography color={colors.customColor[200]} sx={{ mb: 1.5, fontSize: 20, fontWeight:'bold' }} >
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
 Cards.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,

};

export default Cards;
