import React,{useEffect,useState} from 'react'
import Topbar from 'components/Topbar';
import CropStageModal from 'components/cropStageManagement/CropStageModal';
import ManagementTable from 'components/tables/ManagementTable';
import { Stack,Button } from '@mui/material';
import { getCropStage } from 'api/surveySetup/Crop';
import { incrementCropStagePage,decrementCropStagePage } from 'reduxSetup/SurveySetupPages';
import {useDispatch, useSelector } from 'react-redux';
export default function CropStageManagement() {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [cropStage,setCropStage] = useState([])
    const [next, setNext] = useState()
    const dispatch = useDispatch();
    const currentPage = useSelector(state => state.cropStage.page)
    const headerNames = [{header:'Crop Stage',value:'name'},{header:'Created by',value:'admin_details.name'},{header:'Creation Date',value:'created_at'}];
    const params = {p: currentPage}
      const fetchStage = async () => {
        try {
        const res = await getCropStage(params)
        setNext(res?.next)
        const formattedRes = res?.results?.map(item => ({
          ...item,
          created_at: new Date(item.created_at).toLocaleString(),
        }));
        setCropStage(formattedRes);
        console.log(res)
        } catch (error) {
          console.error(error)
        }
      }
      const handleSaveVariety = () => {
        handleCloseModal();
      };
      useEffect(()=>{
        fetchStage()
      },[currentPage])

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleNextPage = () => {
    dispatch(incrementCropStagePage());
  };

  const handlePreviousPage = () => {
    dispatch(decrementCropStagePage());
  };
  return (
    <div>
    <Topbar/>
  
    <div style={{width:'62%', marginTop:'10px'}}>
    <ManagementTable
        headerNames={headerNames}
        rowsData={cropStage}
        title='Crop Stage'
        actionButtonLabel='Add More'
        onActionButtonClick={handleOpenModal}
        ModalComponent={CropStageModal}
        footer={<Stack direction="row" spacing={2}
        sx={{marginTop: '5%', marginBottom:'3%', justifyContent: 'flex-end', position: 'relative', right: '7%'}}>
     <Button className='footerbtn-variety' variant='outlined' onClick={handlePreviousPage}
            >
         previous
     </Button>
     <Button className='footerbtn-variety' variant='outlined' disabled={next === null} onClick={handleNextPage}
            >
         next
     </Button>
</Stack>} 
      />

      {/* Render the CropStageModal component conditionally */}
      {modalOpen && (
        <CropStageModal
          open={modalOpen}
          onClose={handleCloseModal}
          onSaveVariety={handleSaveVariety}
        />
      )}
    </div>
    </div>
  )
}