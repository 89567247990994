import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { tokens } from '../assets/Theme';
import { useTheme, Box } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Typography } from "@mui/material";

function Popup(props) {
  const { open, onClose, onConfirm, title, message, className, btnColor } =
    props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#F1F6FF",
          borderRadius: "8px",
          maxWidth: "600px",
          maxHeight: "400px",
        },
      }}
    >
      <Typography variant="h5" className="title">
        {title}
      </Typography>

      <DialogContent
        style={{
          fontWeight: "700",
          fontSize: "30px",
          textAlign: "center",
          margin: 5,
        }}
      >
        {message}
        <Box
          sx={{
            display: "flex",

            flexDirection: "row",
            justifyContent: "center",
            alignSelf: "center",
            mt: 4,
          }}
        >
          <Button
            sx={{
              color: "#0275C6",
              border: "2px solid #0275C6",
              width: "150px",
              mr: 4,
            }}
            onClick={onClose}
          >
            No
          </Button>
          <Button
            sx={{ color: btnColor, border: "2px solid red", width: "150px" }}
            onClick={handleConfirm}
          >
            Yes
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default Popup;
