import { bottomNavigationActionClasses } from '@mui/material';
import React from 'react';

const ProgressBar = ({ progress }) => {
    const containerStyles = {
        height: '12px',
        width: '60%',
        borderRadius: '20px',
        backgroundColor: '#B2B2B2',
        overflow: 'hidden',
        // paddingTop: '5px',
        // paddingBottom: '5px',
    };

    const fillerStyles = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: '#1bc693',
        textAlign: 'right',
        transition: 'width 0.5s ease-in-out',
    };

    const labelStyles = {
        paddingRight: '5px',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '10px',
        position:'relative',
        bottom:'5px'
    };

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`Total Survey: ${progress}%`}</span>
            </div>
        </div>
    );
};

export default ProgressBar;
