import React, { useState, useEffect } from "react";
import Cards from "../components/Card";
import {
  Grid,
  Box,
  CardContent,
  Typography,Card, Stack
} from "@mui/material";
import axios from "axios";
import Topbar from "components/Topbar";
import {useTheme} from "@mui/material";
import {tokens} from '../assets/Theme';
import ColumnGraph from "graphs/ColumnGraph";
import MultiLineGraph from "graphs/MultiLineGraph";
import CropPieChart from "graphs/CropPieChart";
import QualityDonutGraph from "graphs/QualityDonutGraph";
import StackedColumnGraph from "graphs/StackedColumnGraph";
import {getLandingPageData} from "../api/dashboard";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const columns = [
  { field: "action", headerName: "Action", width: 150 },
  { field: "field_name", headerName: "Field Name", width: 150 },
  { field: "action_by", headerName: "Action By", width: 150 },
  { field: "created_at", headerName: "Created At", width: 250 },
];
function Dashboard() {
const theme = useTheme();
const colors = tokens(theme.palette.mode);
  const [logsData, setLogsData] = useState([]);
  console.log("logs:", logsData);
  const [cardData, setCardData] = useState(null);
  const [landingPageData, setLandingPageData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch data from backend API
    // axios
    //   .get(`${process.env.REACT_APP_BASEURL}/api/history/`)
    //   .then((response) => {
    //     setLogsData(response.data);
    //   });
    axios
      .get(`${process.env.REACT_APP_BASEURL}/api/users/details/`)
      .then((response) => {
        console.log("c", response?.data);
        setCardData(response?.data);
      }).catch((error) => {
        console.log(error);
        alert("Error something went wrong!, Please try again Or contact the admin.");
      });


      fetchLandingPageData();
  }, []);

  const fetchLandingPageData = async () => {
    try {
      const response = await getLandingPageData();
        response.percentage_by_quality.names = response?.percentage_by_quality?.names?.map(String);
        // response.percentage_by_type.crop_type remove null values
        response.percentage_by_type.crops_type = response?.percentage_by_type?.crops_type?.filter(Boolean);
        response.area_by_variety.varieties = response?.area_by_variety?.varieties?.filter(Boolean);
        response.area_by_variety.datta = response?.area_by_variety?.data?.filter((item) => item.name !== null);
        response.area_by_circle.circles = response?.area_by_circle?.circles?.filter(Boolean);
        response.area_by_variety.data = response?.area_by_variety?.data?.filter((item) => item.name !== null);

        setLandingPageData(response);
        setIsLoading(false);
    } catch (error) {
      console.error("Error fetching landing page data:", error);
    }
  }

  // TODO: FIX APPROVAL HISTORY TABLE
  return (
    <div>
      <Topbar />
      <div style={{ position: "relative", top: "50px" }}>
        <Grid container spacing={{ xs: 2 }} sx={{position:'relative', left:'3%', width:'95% !important'}}>
          <Grid item xs={2.4}>
            <Box
              sx={{
                position: "relative",
                bottom: "40% ",
                display: "flex",
                justifyContent: "center",

              }}
            >
                <Card variant="outlined" sx={{background: `${colors.customColor[500]} !important`, borderRadius:'15px',boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)', height:'100px',width: '211px'}}>
                    <CardContent sx={{ color:colors.customColor[100]}}>
                        <Typography variant="h6">Total Area</Typography>
                        <Stack direction='row' spacing={2}>
                            <div style={{borderRight:`2px solid ${colors.customColor[800]}`, paddingRight:'4px'}}>
                                <Typography color={colors.customColor[200]} sx={{ mb: 1.5, fontSize: 20, fontWeight:'bold'}}>{parseInt(cardData?.total_area_covered)}</Typography>
                                <Typography color={colors.customColor[200]} sx={{ mb: 1.5, fontSize: 12, fontWeight:'bold'}}> Acres </Typography>
                            </div>
                            <Stack direction='column'>
                                <Typography variant="p" color={colors.customColor[200]} fontWeight='bold'>Accepted: <Typography variant="p" sx={{color:`${colors.customColor[800]}`}}>{cardData?.accepted_area?.toFixed(2)}</Typography></Typography>
                                <Typography variant="p" color={colors.customColor[200]} fontWeight= 'bold'>Rejected: <Typography variant="p" sx={{color:`${colors.customColor[800]}`}}>{cardData?.rejected_area?.toFixed(2)}</Typography></Typography>
                                <Typography variant="p" color={colors.customColor[200]} fontWeight= 'bold'>Pending: <Typography variant="p" sx={{color:`${colors.customColor[800]}`}}>{cardData?.pending_area?.toFixed(2)}</Typography></Typography>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Box>
          </Grid>


          <Grid item xs={2.4}>
            <Box
              sx={{
                position: "relative",
                bottom: "40% ",
                display: "flex",
                justifyContent: "center",
              }}
            >
                  <Cards
                title="Total Gate Circles"
                description={cardData?.total_gate_circles}
                />

            </Box>
          </Grid>
          <Grid item xs={2.4}>
            <Box
              sx={{
                position: "relative",
                bottom: "40% ",
                display: "flex",
                justifyContent: "center",
              }}
            >

              <Card variant="outlined" sx={{background: `${colors.customColor[500]} !important`, borderRadius:'15px',boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)', height:'100px',width: '211px'}}>
                  <CardContent sx={{ color:colors.customColor[100]}}>
                    <Typography variant="h6">Total Surveys</Typography>
                    <Stack direction='row' spacing={2}>
                      <div style={{borderRight:`2px solid ${colors.customColor[800]}`, paddingRight:'8px'}}>
                    <Typography color={colors.customColor[200]} sx={{ mb: 1.5, fontSize: 20, fontWeight:'bold', paddingRight:'8px' }}>{cardData?.total_fields_drawn}</Typography>
                      </div>
                      <Stack direction='column' sx={{marginLeft:'5px'}}>
                      <Typography variant="p" color={colors.customColor[200]} fontWeight='bold'>Accepted: <Typography variant="p" sx={{color:`${colors.customColor[800]}`}}>{cardData?.approved_suvey}</Typography></Typography>
                      <Typography variant="p" color={colors.customColor[200]} fontWeight= 'bold'>Rejected: <Typography variant="p" sx={{color:`${colors.customColor[800]}`}}>{cardData?.rejected_suvey}</Typography></Typography>
                      <Typography variant="p" color={colors.customColor[200]} fontWeight= 'bold'>Pending: <Typography variant="p" sx={{color:`${colors.customColor[800]}`}}>{cardData?.pending_suvey}</Typography></Typography>
                      </Stack>
                    </Stack>
                  </CardContent>
              </Card>

            </Box>
          </Grid>
          <Grid item xs={2.4}>
            <Box
              sx={{
                position: "relative",
                bottom: "40% ",
                display: "flex",
                justifyContent: "center",

              }}
            >

              <Card variant="outlined" sx={{background: `${colors.customColor[500]} !important`, borderRadius:'15px',boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)', height:'100px',width: '211px'}}>
                  <CardContent sx={{ color:colors.customColor[100]}}>
                    <Typography variant="h6">Total Employees</Typography>
                    <Stack direction='row' spacing={2}>
                      <div style={{borderRight:`2px solid ${colors.customColor[800]}`, paddingRight:'8px'}}>
                    <Typography color={colors.customColor[200]} sx={{ mb: 1.5, fontSize: 20, fontWeight:'bold',paddingRight:'8px' }}>{cardData?.total_employees}</Typography>
                      </div>
                      <Stack direction='column' sx={{marginLeft:'5px'}}>
                      <Typography variant="p" color={colors.customColor[200]} fontWeight='bold'>Surveyor: <Typography variant="p" sx={{color:`${colors.customColor[800]}`}}>{cardData?.total_surveyors}</Typography></Typography>
                      <Typography variant="p" color={colors.customColor[200]} fontWeight= 'bold'>Auditor: <Typography variant="p" sx={{color:`${colors.customColor[800]}`}}>{cardData?.total_auditors}</Typography></Typography>
                      </Stack>
                    </Stack>
                  </CardContent>
              </Card>


            </Box>
          </Grid>
          <Grid item xs={2.4}>
            <Box
              sx={{
                position: "relative",
                bottom: "40% ",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card variant="outlined" sx={{background: `${colors.customColor[500]} !important`, borderRadius:'15px',boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)', height:'100px',width: '211px'}}>
                  <CardContent sx={{ color:colors.customColor[100]}}>
                    <Typography variant="h6">Total Tasks</Typography>
                    <Stack direction='row' spacing={2}>
                      <div style={{borderRight:`2px solid ${colors.customColor[800]}`, paddingRight:'8px'}}>
                    <Typography color={colors.customColor[200]} sx={{ mb: 1.5, fontSize: 20, fontWeight:'bold', paddingRight:'8px'}}>---</Typography>
                      </div>
                      <Stack direction='column'  sx={{marginLeft:'5px'}}>
                      <Typography variant="p" color={colors.customColor[200]} fontWeight='bold'>Completed: <Typography variant="p" sx={{color:`${colors.customColor[800]}`}}>-</Typography></Typography>
                      <Typography variant="p" color={colors.customColor[200]} fontWeight= 'bold'>Pending: <Typography variant="p" sx={{color:`${colors.customColor[800]}`}}>-</Typography></Typography>
                      <Typography variant="p" color={colors.customColor[200]} fontWeight= 'bold'>In Progress: <Typography variant="p" sx={{color:`${colors.customColor[800]}`}}>-</Typography></Typography>
                      </Stack>
                    </Stack>
                  </CardContent>
              </Card>

            </Box>
          </Grid>
          <Grid item xs={2.4}>
            <Box
              sx={{
                position: "relative",
                bottom: "40% ",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Cards
                title="Total Growers"
                description={cardData?.total_growers}
              />

            </Box>
          </Grid>
          <Grid item xs={2.4}>
            <Box
              sx={{
                position: "relative",
                bottom: "40% ",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Cards
                title="Main Crop Area"
                description="----"
              />

            </Box>
          </Grid>
          <Grid item xs={2.4}>
            <Box
              sx={{
                position: "relative",
                bottom: "40% ",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Cards
                title="Other Crop Area"
                description="----"
              />

            </Box>
          </Grid>
          <Grid item xs={2.4}>
            <Box
              sx={{
                position: "relative",
                bottom: "40% ",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Cards
                title="Expected Supply"
                description={cardData?.expected_supply?.toFixed(2)}
              />

            </Box>
          </Grid>
          <Grid item xs={2.4}>
            <Box
              sx={{
                position: "relative",
                bottom: "40% ",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Cards
                title="Total Active Users"
                description={cardData?.active_users}
              />

            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{position:'relative', left:'3%', width:'95% !important'}}>
            {
                isLoading &&
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            }

            {!isLoading && (
                <>
                    <Grid item xs={6}>
                        {
                            landingPageData?.crop_area_by_variety && landingPageData?.crop_area_by_circle ? (
                                <ColumnGraph cropAreaByVariety={landingPageData?.crop_area_by_variety} cropAreaByCircle={landingPageData?.crop_area_by_circle}/>
                            ) : (
                                <div style={{width: "320px", height: "320"}}>
                                    <p>Data for Cane Survey is not available.</p>
                                </div>
                            )
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {landingPageData?.survey_by_month ? (
                            <MultiLineGraph surveyByMonth={landingPageData.survey_by_month} surveyByDay={landingPageData.survey_by_day} />
                        ) : (
                            <div style={{width: "320px", height: "320"}}>
                                Data for Survey Area is not available.
                            </div>
                        )}
                    </Grid>
                    <Grid sx={{ marginTop: "2%", position: "relative", left: "3%", width: "95% !important" }} container spacing={1}>
                        <Grid item xs={3}>
                            {landingPageData?.percentage_by_type ? (
                                <CropPieChart cropData={landingPageData?.percentage_by_type} />
                            ) : (
                                <div style={{width: "320px", height: "320"}}>
                                    Data for Crop Comparison is not available.
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            {landingPageData?.percentage_by_quality ? (
                                <QualityDonutGraph qualityData={landingPageData.percentage_by_quality} />
                            ) : (
                                <div style={{width: "320px", height: "320"}}>
                                    Data for CropStage is not available.
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {landingPageData?.area_by_variety || landingPageData?.area_by_circle ? (
                                <StackedColumnGraph areaByVariety={landingPageData.area_by_variety} areaByCircle={landingPageData.area_by_circle} />
                            ) : (
                                <div style={{width: "320px", height: "320"}}>
                                    Data for Crop Stage is not available.
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>

        {/* <Grid container spacing={2}>
          <Grid className="map" item xs={12} md={8}>
            <MapView />
          </Grid> */}
          {/*<Grid className='logs' item xs={false} md={2.4.5}>*/}
          {/*        <Paper>*/}
          {/*          <TableContainer>*/}
          {/*            <Table>*/}
          {/*              <TableHead>*/}
          {/*                <TableRow>*/}
          {/*                  <TableCell>Approval History</TableCell>*/}
          {/*                </TableRow>*/}
          {/*              </TableHead>*/}
          {/*              <TableBody>*/}
          {/*                <div style={{ height: 400, width: '100%' }}>*/}
          {/*                  <DataGrid*/}
          {/*                      rows={logsData}*/}
          {/*                      columns={columns}*/}
          {/*                      pageSize={5}*/}
          {/*                  />*/}
          {/*                </div>*/}
          {/*                </TableBody>*/}
          {/*            </Table>*/}
          {/*          </TableContainer>*/}
          {/*        </Paper>*/}
          {/*</Grid>*/}
        {/* </Grid> */}
    </div>
    </div>
  );
}

export default Dashboard;
