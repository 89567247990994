import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../assets/Theme";
import axios from "axios";
import { Logo } from "assets/Icons";
import { useSelector, useDispatch } from "react-redux";
import { setNavigation, setSubNavigation } from "reduxSetup/NavigationState";
import { useLocation } from "react-router-dom";

const Item = ({ title, to, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const location = useLocation();
  const [selected, setSelected] = useState("");

  const handleClick = () => {
    dispatch(setNavigation(to));
  };

  const isActive = location.pathname === to;

  return (
    <MenuItem
      active={isActive}
      style={{
        color: colors.grey[100],
      }}
      onClick={handleClick}
      icon={icon}
    >
      <Typography fontWeight="bold">{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};
const SubItem = ({ title, to, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClick = () => {
    dispatch(setSubNavigation(to));
  };

  const isActive = location.pathname === to;

  return (
    <MenuItem
      active={isActive}
      style={{
        color: colors.grey[100],
        width: "110%",
        height: "4vh",
      }}
      onClick={handleClick}
      icon={icon}
    >
      <Typography
        fontWeight="bold"
        fontSize="12px"
        sx={{
          //   color: secondSelected === title ? "#fff" : "inherit",
          textDecoration: isActive ? "underline" : "inherit",
        }}
      >
        {title}
      </Typography>
      <Link to={to} />
    </MenuItem>
  );
};
const Sidebar = ({ role }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [secondSelected, setSecondSelected] = useState("");
  const [companyInfo, setCompanyInfo] = useState(null);
  const dispatch = useDispatch();
  const navTitle = useSelector((state) => state.navigation.title);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}/api/users/info/`)
      .then((response) => {
        setCompanyInfo(response?.data);
        console.log(companyInfo);
        localStorage.setItem("companyInfo", JSON.stringify(response?.data));
        if (
          response?.response?.status === 401 ||
          response?.response?.status === 403
        ) {
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Box
      className="maain-item"
      sx={{
        width: isCollapsed ? "80px" : "250px",
        "& .pro-sidebar-inner": {
          background: `#fff !important`,
          width: isCollapsed ? "80px" : "250px",
          borderRight: "1px solid #334E684D !important",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          right: "2px",
          marginTop: "20px !important",
          marginLeft: "0 !important",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.customColor[100]} !important`,
        },
        "& .pro-menu-item.active": {
          color: "#fff !important",
          background: `${colors.customColor[100]} `,
          borderTopRightRadius: "20px",
          borderBottomRightRadius: "20px",
        },
        // "& .pro-menu-item.active:hover": {
        //     color: "#fff !important",
        //     background: `${colors.customColor[100]} !important`,
        // },
        "& .pro-sidebar-layout": {
          height: "100vh !important",
        },
        "& .logo-container": {
          position: "relative",
          top: "17%",
          width: "100%",
          textAlign: "center",
          // paddingBottom: '20px',
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}

          {!isCollapsed && (
            <Box mb="55px">
              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "20px 0 10px 0" }}
                >
                  {companyInfo?.company_logo !== null ? (
                    <img src={companyInfo?.company_logo} alt="LOGO" />
                  ) : (
                    <Logo />
                  )}
                </Typography>
                <Typography variant="h5" color="#4F4F4F">
                  {companyInfo?.fullname}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingRight={isCollapsed ? "undefined" : "10%"}>
            <Item title="Dashboard" to="/" />
            {(role === '"MA"' || role === '"GN"') && (
              <>
                <MenuItem
                  active={navTitle === "Survey Setup"}
                  onMouseEnter={() => {
                    if (navTitle !== "Survey Setup") {
                      setOpen(false);
                    }
                  }}
                  onClick={() => {
                    dispatch(setNavigation("Survey Setup"));
                    setOpen(!open);
                  }}
                >
                  <Typography
                    className="multi-option"
                    color={
                      navTitle === "Survey Setup" ? "#FFF" : colors.grey[100]
                    }
                    fontWeight="bold"
                  >
                    Survey Setup
                  </Typography>
                </MenuItem>
                {/* Submenu options */}
                {open && navTitle === "Survey Setup" && (
                  <Box
                    sx={{
                      "& .pro-menu-item.active": {
                        color: `${colors.customColor[800]} !important `,
                        background: `transparent !important`,
                      },
                      "& .pro-inner-item": {
                        right: "2px",
                        marginTop: "5px !important",
                        marginLeft: "0 !important",
                      },
                      "& .pro-inner-item:hover": {
                        color: `${colors.customColor[800]} !important`,
                      },
                    }}
                  >
                    <SubItem
                      title="Variety Management"
                      to="/Variety-management"
                    />
                    <SubItem
                      title="Crop Health Management"
                      to="/disease-management"
                    />
                    <SubItem
                      title="Crop Stage Management"
                      to="/crop-stage-management"
                    />
                    <SubItem
                      title="Crop Type Management"
                      to="/crop-type-management"
                    />
                    <SubItem
                      title="Crop Condition Management"
                      to="/crop-condition-management"
                    />
                    <SubItem
                      title="Form Customizations"
                      to="/form-customization"
                    />
                  </Box>
                )}
                <Item title="Attendance " to="/attendace-management" />

                <MenuItem
                  active={navTitle === "Territory Management"}
                  onMouseEnter={() => {
                    if (navTitle !== "Territory Management") {
                      setOpen(false);
                    }
                  }}
                  onClick={() => {
                    dispatch(setNavigation("Territory Management"));
                    navigate("territory-management");
                    setOpen(!open);
                  }}
                >
                  <Typography
                    className="multi-option"
                    color={
                      navTitle === "Territory Management"
                        ? "#FFF"
                        : colors.grey[100]
                    }
                    fontWeight="bold"
                  >
                    Territory Management
                  </Typography>
                </MenuItem>
                {/* Submenu options */}
                {open && navTitle === "Territory Management" && (
                  <Box
                    sx={{
                      "& .pro-menu-item.active": {
                        color: `${colors.customColor[800]} !important `,
                        background: `transparent !important`,
                      },
                      "& .pro-inner-item": {
                        right: "2px",
                        marginTop: "5px !important",
                        marginLeft: "0 !important",
                      },
                      "& .pro-inner-item:hover": {
                        color: `${colors.customColor[800]} !important`,
                      },
                    }}
                  >
                    <SubItem
                      title="District Management"
                      to="/territory-management/district-management"
                    />
                    <SubItem
                      title="Gate Circle Management"
                      to="/territory-management/gate-circle-management"
                    />
                    <SubItem
                      title="Village/Sector Management"
                      to="/territory-management/village-management"
                    />
                    <SubItem
                      title="Deh/Mauza Management "
                      to="/territory-management/deh-management"
                    />
                    <SubItem
                      title="Company Management"
                      to="/territory-management/company-management"
                    />
                    {/* <SubItem
                                            title="Form Customizations"
                                            to="/"

                                        /> */}
                  </Box>
                )}
                {/*<Item title="Team Trak" to="/team-trak" />*/}
                <Item title="All Growers" to="/manage-growers" />
                <Item title="All Survey" to="/all-surveys/bounderies" />
                {/* <Item
                    {!isCollapsed && (
                        <Box mb="55px">
                            <Box textAlign="center">
                                <Typography
                                    variant="h3"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{m: "20px 0 10px 0"}}
                                >
                                    <img src={companyInfo?.profile_picture_url} alt="LOGO"/>
                                </Typography>
                                <Typography variant="h5" color='#4F4F4F'>
                                    {companyInfo?.fullname}

                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box paddingRight={isCollapsed ? "undefined" : "10%"}>
                        <Item
                            title="Dashboard"
                            to="/"

                        />
                        {role === '"MA"' &&
                        <>

                             <MenuItem
                                    active={navTitle === "Survey Setup"}
                                    onMouseEnter={()=>{
                                        if (navTitle !== "Survey Setup"){
                                            setOpen(false);
                                        }
                                    }}
                                    onClick={() => {
                                        dispatch(setNavigation("Survey Setup"));
                                        setOpen(!open)
                                   }}

                                >
                                    <Typography className="multi-option" color = {navTitle === "Survey Setup"? "#FFF" :colors.grey[100]} fontWeight="bold">Survey Setup</Typography>
                                </MenuItem>
                                {/* Submenu options */}

                <Item title="Team Trak" to="/team-trak" />
                {/* <Item
                                title="Variety Management"
                                to = "/Variety-management"

                                /> */}
                <Item title="Data Export" to="/Reporting" />
                <Item title="DeveloPro" to="/developro/projects/page/1" />
              </>
            )}
           
           {role === '"A"' && (
              <>
                <Item title="All Survey" to="/all-surveys/bounderies" />
                <Item title="Data Export" to="/Reporting" />
              </>
            )}
            {role === '"GN"' && (
              <>
                <Item title="Ginner Traceability" to="/ginner-traceability" />
              </>
            )}
          </Box>
        </Menu>
        <div className="logo-container">
          <Typography>POWERED BY</Typography>
          <Logo />
        </div>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
