import Control from 'react-leaflet-custom-control'
import {Button} from '@mui/material'
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {useMap} from "react-leaflet";
import styles from "../../assets/styles.module.css";
import {useEffect} from "react";


export function ReCenter({user_location, updateLocation}) {
    const map = useMap();
    const mapReCenter = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position){
                const latlng = [position.coords.latitude, position.coords.longitude];
                if (user_location !== latlng) {
                    updateLocation(latlng)
                }
                map.flyTo(latlng, 10, {animate: false});
            })
        }

    }
    useEffect(() => {
        mapReCenter();
    }, [])


    return (
      <Control position="bottomleft">
        <Button
          variant={"contained"}
          onClick={mapReCenter}
          className={styles["btn"]}
        >
          <MyLocationIcon />
        </Button>
      </Control>
    );
}
