export const reports = [
    {
        id: 1,
        name : 'Default',
        template: 'master',
        start_date : true,
        end_date :  true,
        varieties : true,
        variety_stage :  true,
        crop_stage :  true,
        passbook_number:  true,
        status :  true,
        disease :  true,
        surveyor :  true,
        grower_cnic :  true,
        gate_circles :  true,
        survey_id :  true,
        area_from :  true,
        area_to :  true,
        pdf : false,
    },
    {
        id: 2,
        name : 'Grower Per Variety',
        template: 'grower wise variety',
        start_date : true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : true,
    },
    {
        id: 3,
        name : 'Variety Stage Grower Wise',
        template: 'variety stage',
        start_date : true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : true,
    },
    {
        id: 4,
        name : 'Variety Stage Deh/Muaza Wise',
        template: 'variety stages mauza wise',
        start_date : true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : true,
    },
    {
        id: 5,
        name : 'Village Circle Wise',
        start_date : true,
        template: 'village circle wise',
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : true,
    },
    {
        id: 6,
        name : 'eSurvey Variety Wise',
        template: 'eSurvey variety wise',
        start_date : true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : true,
    },
    {
        id: 7,
        name : 'Variety Circle Wise',
        template: 'variety circle wise',
        start_date : true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : true,
    },
    //-------------- Commented out because of lack of support -----------------
    // {
    //     id: 8,
    //     name : 'Crop Stage Circle Wise',
    //     start_date : true,
    //     end_date :  true,
    //     varieties : false,
    //     variety_stage :  false,
    //     crop_stage :  false,
    //     passbook_number:  false,
    //     status :  false,
    //     disease :  false,
    //     surveyor :  false,
    //     grower_cnic :  false,
    //     gate_circles :  false,
    //     survey_id :  false,
    //     area_from :  false,
    //     area_to :  false,
    // },
    //-------------- Commented out because of lack of support -----------------
    {
        id: 9,
        name : 'Grower Wise',
        template: 'grower wise',
        start_date : true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : true,
    },
    {
        id: 10,
        name : 'Grower Per Circle',
        template: 'grower circle wise',
        start_date : true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : true,
    },
    {
        id: 11,
        name : 'Surveyor Crop Stage Wise',
        template: 'surveyor stage wise',
        start_date :  true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : true
    },
    {
        id: 12,
        name : 'Surveyor Audit Report',
        template: 'surveyor audit report',
        start_date :  true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : false,
    },
    {
        id: 13,
        name : 'Survey Summary',
        template: 'survey summary',
        start_date :  true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : false,
    },
    {
        id: 14,
        name : 'Plantation Village Wise',
        template: 'plantation village wise',
        start_date :  true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : false,
    },
    {
        id: 15,
        name : 'Grower Circle Village Wise',
        template: 'grower circle village wise',
        start_date :  true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : false,
    },
    {
        id: 16,
        name : 'Plantation Wise',
        template: 'plantation wise',
        start_date :  true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : false,
    },
    {
        id: 17,
        name : 'Circle Variety Wise',
        template: 'circle variety wise',
        start_date :  true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        pdf : false,
    },
    {
        id: 18,
        name : 'Variety Stage Sector Wise',
        template: 'variety stage sector wise',
        start_date :  true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        stage: true,
        pdf : false,
    },
    {
        id: 19,
        name : 'Variety Stage Deh Wise',
        template: 'variety stage deh wise',
        start_date :  true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        stage: true,
        pdf : false,
    },
    {
        id: 20,
        name : 'Variety Stage Grower Wise',
        template: 'variety stage grower wise',
        start_date :  true,
        end_date :  true,
        varieties : false,
        variety_stage :  false,
        crop_stage :  false,
        passbook_number:  false,
        status :  false,
        disease :  false,
        surveyor :  false,
        grower_cnic :  false,
        gate_circles :  false,
        survey_id :  false,
        area_from :  false,
        area_to :  false,
        stage: true,
        pdf : false,
    }
]