import React, {createContext, useContext, useEffect, useState} from 'react';
import {getBale, getBales} from "../../api/ginnerTraceability/bale";
const BalesContext = createContext();

export const BalesProvider = ({children}) => {
    const [bales, setBales] = useState([]);
    const [bale, setBale] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchBales = async () => {
        setLoading(true);
        try {
            const response = await getBales();
            if (response){
                const mappedResponse = response.map(bale => {
                    bale.action = ['Export QR'];
                    return bale;
                });
                setBales(mappedResponse);
            }
        } catch (error) {
            setError(error);
        }
        setLoading(false);
    };

    const fetchBale = async (id) => {
        setLoading(true);
        try {
            const response = await getBale(id)
           if (response) {
               setBale(response);
               setBales(bales.map(bale => bale.id === id ? response : bale));
           }
        } catch (error) {
            setError(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!bales.length){
            fetchBales();
        }
    }, []);

    return (
        <BalesContext.Provider value={{bales, setBales, loading, error}}>
            {children}
        </BalesContext.Provider>
    );
};

export const useBales = () => {
    const context = useContext(BalesContext);
    if (!context) {
        throw new Error('useBales must be used within a BalesProvider');
    }
    return  context;
}


