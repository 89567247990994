import React, {useEffect, useState} from 'react';
import {createStock} from "../../api/ginnerTraceability/stock";
import {bulkUpdateHeap} from "../../api/ginnerTraceability/heap";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MultiSelect from "./MultiSelect";
import {Container} from "@mui/system";
import generateHeapCards, {updateHeapOptions} from "./helpers/js/heapHelpers";
import Loading from "../BeatLoader";



export default function AssignHeaps({heapOptions, setHeapOptions, cropWeight, grower, broker, vehicle, driver}) {
    const [selectedHeaps, setSelectedHeaps] = useState([]);
    const [heapLength, setHeapLength] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setHeapLength(selectedHeaps.length);
    }, [selectedHeaps]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setSelectedHeaps([]);
        setOpen(false);
    };
    const handleSave = async () => {
        const weightDistributed = selectedHeaps.reduce((acc, heap) => acc + heap.newWeight, 0) ;
        const isHeapWeightValid = selectedHeaps.map( (heap) => {
                return heap.weight+heap.newWeight <= heap.weight_limit;
        }).every((heap) => heap === true);
        if (!isHeapWeightValid) {
            alert('Please make sure heap weight is assigned correctly');
            return;
        }
        setIsLoading(true);
        const isWeightEqual = parseFloat(weightDistributed) === parseFloat(cropWeight);
        const heapIds = selectedHeaps.map((heap) => {
            return heap.id;
        });
        const validatedHeapIds = heapIds.length > 0;
        const isBrokerSelected = broker?.id !== undefined;
        try {
            if (isWeightEqual && validatedHeapIds && isBrokerSelected) {
                const stockData = {
                    grower: grower.passbook_number,
                    broker: broker.value,
                    vehicle: vehicle,
                    driver: driver.value,
                    weight: cropWeight,
                }
                const response = await createStock(stockData)
                if (response) {
                    const stockId = response?.id;
                    const heapData = []
                    selectedHeaps.forEach((heap) => {
                        heapData.push(
                            {
                                id: heap.id,
                                stocks: [{
                                    id: stockId,
                                    weight: heap.newWeight,
                                }]
                            }
                        )
                    });
                    console.log('Heap Data: ', heapData);
                    const bulkUpdateResponse = await bulkUpdateHeap(heapData, heapIds);
                    if(bulkUpdateResponse){
                        const updatedHeaps = updateHeapOptions(heapOptions,selectedHeaps);
                        setHeapOptions([...updatedHeaps]);
                        setIsLoading(false);
                        handleClose();
                    }else {
                        isLoading(false);
                        alert('Something went wrong, please check if you have assigned weight correctly according to weights');
                    }

                } else {
                    alert('Something went wrong, creating stock! Please try again');
                    isLoading(false);
                }
            }else {
                setIsLoading(false);
                alert('Please make sure Heaps, grower, broker and weight are selected');
            }
        } catch (error) {
            console.error('Error in assigning stock: ', error);
            setIsLoading(false);
        }
    }


    return (
        <div>
            <Button
                variant="outlined"
                color="secondary"
                style={{padding: "13px", paddingRight: "20px" , marginLeft: "10px"}}
                onClick={handleOpen}
            >
                <AddIcon sx={{mr: 1}} fontSize="small"/>
                Assign Heaps
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 700,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'scroll',
                    }}
                >
                    <Box display="flex" flexDirection="column" maxWidth="300px">
                        <h4> Total Weight to be distributed : { parseFloat(cropWeight) }  Maunds</h4>
                    </Box>

                    <Box display="flex" flexDirection="column" maxWidth="300px">
                            <label htmlFor="heap" style={{marginBottom: '6px'}}>Select Heaps</label>
                            <MultiSelect
                                options={heapOptions}
                                setState={setSelectedHeaps}
                                state={selectedHeaps}
                                showAssignWeight={false}
                            />
                    </Box>

                    <Container style={{ border: '1px solid white', borderRadius: '8px', height: '200px',marginTop: '10px', overflow: 'auto', paddingBottom: '10px'  }}>
                        {
                            generateHeapCards(selectedHeaps)
                        }
                    </Container>

                    <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: 2}}>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Close
                        </Button>

                        <Button variant="outlined" color="success" onClick={handleSave}
                                disabled={selectedHeaps === null || selectedHeaps.length === 0 || isLoading}
                        >
                            Create Stock
                            {
                                isLoading &&(
                                    <div style={{marginTop: '5px'}}>
                                        <Loading size={13}  />
                                    </div>
                                )
                            }
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}