import {makeRequest} from "../makeRequest";

const BASEURL = process.env.REACT_APP_BASEURL;

export const getGrowers = (params) => makeRequest(`${BASEURL}/api/growers/`, "get", null, params);

//function to create grower
export const createGrower = (data) => makeRequest(`${BASEURL}/api/growers/`, "post", data);

//function to update grower
export const updateGrower = (id, data) => makeRequest(`${BASEURL}/api/growers/${id}/`, "patch", data);

//function to delete grower
export const deleteGrower = (id) => makeRequest(`${BASEURL}/api/growers/${id}/`, "delete");

//function to import growers data
export const bulkImportGrower = (data) => makeRequest(`${BASEURL}/api/growers/bulk_upload/`, "post", data);