import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Grid, Stack, Typography} from '@mui/material';
import {MapContainer, TileLayer, Marker, Popup, LayerGroup, LayersControl, FeatureGroup} from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import L from 'leaflet';
import ReactDOMServer, {renderToString} from 'react-dom/server';
import {Location} from 'assets/Icons';
import Loader from 'components/Loader';
import {getAllUserLocations} from "../../api/teamTrak/teamTrak";
import {GeoJSON} from "react-leaflet";
import ActivityTrackingPopup, {CustomPopup} from "./ActivityTrackingPopup";


export default function SurveyorTrak() {
    const [surveyorData, setSurveyorData] = useState({});
    const customIcon = new L.divIcon({
        className: 'custom-icon', html: renderToString(<Location/>), iconSize: [32, 32],
    });
    const onEachFeature = (feature, layer) => {
        console.log(feature)
        if (feature.properties && feature.properties.user) {
            const popupComponent = ReactDOMServer.renderToString(<ActivityTrackingPopup feature={feature}/>)
            console.log(popupComponent)
            layer.bindPopup(popupComponent)
        }
    }
    const pointToLayer = (feature, latlng) => {
        return L.marker(latlng, {icon: customIcon});
    };


    useEffect(() => {
        getAllUserLocations().then((data) => {
            setSurveyorData(data)
        });

    }, []);
    if (!surveyorData?.features) {
        return <div><Loader/></div>;
    } else {
        const containerStyle = {
            display: 'flex', justifyContent: 'space-between'
        };

        const mapStyle = {
            minWidth: '70%', maxWidth: '70%', height: '65vh', borderRadius: '12px'
        };

        const sidebarStyle = {
            maxWidth: '25%', minWidth: '25%', position: 'relative', right: '2px', minHeight: '60vh'
        };

        const contentStyle = {
            borderRadius: '12px', minHeight: '55vh'
        };
        const listItemStyle = {
            backgroundColor: '#ffffff', borderBottom: '1px solid #ddd', // borderRadius: '8px',
            padding: '1px', marginBottom: '10px',
        };
        return (<div style={{
                background: 'white',
                boxShadow: '4px 9px 16px rgba(0, 0, 0, 0.25)',
                borderRadius: 20,
                width: '93%',
                position: 'relative',
                left: '2%',
                padding: '2%'
            }}>
                <h2>Team Trak</h2>
                <div style={containerStyle}>
                    <MapContainer
                        center={[33.6844, 73.0479]}
                        zoom={5}
                        style={mapStyle}
                        attributionControl={false}>
                        <LayersControl>
                            <LayersControl.BaseLayer name={'Satellite Imagery'} checked>
                                <TileLayer
                                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.Overlay name={'Surveyors'} checked>
                                <GeoJSON data={surveyorData} onEachFeature={onEachFeature} pointToLayer={pointToLayer}/>
                            </LayersControl.Overlay>
                        </LayersControl>
                    </MapContainer>
                    <div style={sidebarStyle}>
                        <Typography variant='h5' paddingBottom="10px" alignSelf="end" sx={{color: "#1bc693"}}>
                            Total Active Users: <span
                            style={{color: "#000"}}>{surveyorData.total_active_users}</span></Typography>
                        {surveyorData.features?.map((data, index) => (<div key={index} style={listItemStyle}>
                                {data?.user?.username}
                            </div>))}
                    </div>
                </div>
            </div>);
    }
}
