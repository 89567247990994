import { useState } from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Topbar from "components/Topbar";
import AutocompleteCustom from "components/territoryManagement/components/AutoCompleteCustom";
import FieldSearchComponent from "components/territoryManagement/components/FieldSearchComponent";
import InputCustom from "components/territoryManagement/components/InputCustom";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import moment from "moment";
import {
  CircleMarker,
  LayerGroup,
  LayersControl,
  MapContainer,
  Polygon,
  Tooltip,
  WMSTileLayer,
  ZoomControl,
  TileLayer,
} from "react-leaflet";
import { ReCenter } from "../../components/map-utils/ReCenter";
import { Search } from "../../components/map-utils/Search";
import useIndexLogic from "./useIndexLogic";

function TerritoryManagement() {
  const {
    mapRef,
    userLocation,
    map_center,
    colors,
    pageNumber,
    boundaryIds,
    allBoundaries,
    allFields,
    filters,
    isFilterApplied,
    gateOptions,
    varietiesOptions,
    userOptions,
    isMapSearchLoading,
    searchResultOptions,
    onApplyFilters,
    resetFilters,
    setFilters,
    setUserLocation,
    onMapSearch,
    onSelectMapSearchValue,
    handleBoundaryPolygonClick,
    handlePolygonClick,
    onSearchByCoordinates,
  } = useIndexLogic();
  const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
  const [inputType, setInputType] = useState('text');
  const [fromInputType, setFromInputType] = useState('text');
  const companyFilter = companyInfo?.company
    ? `company_id=${companyInfo.company}`
    : null;
  const dateFilter =companyInfo?.season?.start_date?`created_at > ${companyInfo?.season?.start_date}T00:00:00Z AND created_at < ${companyInfo?.season?.end_date}T00:00:00Z`: `created_at > 2024-01-01T00:00:00Z`;
  const cqlFilters = [dateFilter, companyFilter].filter(Boolean).join(" AND ");

  return (
    <div style={{ width: "100%", padding: 5 }}>
      <Topbar page={pageNumber} />

      <Grid
        container
        sx={{
          width: "95%",
          position: "relative",
          left: "2%",
          background: "white",
          boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
          borderRadius: 5,
          padding: "3%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h3"
            style={{
              color: "#000",
              position: "relative",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Territory Management
          </Typography>
        </div>
        <Grid item>
          <Box
            // onSubmit={handleSubmit}

            sx={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-between",
              paddingLeft: 1,
              flexWrap: "wrap",
              gap: 3,
              mb: 2,
            }}
          >
            <InputCustom
              value={filters?.created_at__gte || null}
              id="startDate"
              onChange={(e) => {
                setFilters((pre) => ({
                  ...pre,
                  created_at__gte: e.target.value,
                }));
              }}
              name="startDate"
              label={"From Date"}
              width={"15%"}
              variant="outlined"
              type={fromInputType}
              onFocus={() => setFromInputType('date')}
              onBlur={() => setFromInputType('text')}
            />
            
            <InputCustom
              value={filters?.created_at__lte || null}
              id="endDate"
              onChange={(e) =>
                setFilters((pre) => ({
                  ...pre,
                  created_at__lte: e.target.value,
                }))
              }
              name="endDate"
              label={"To Date"}
              width={"15%"}
              variant="outlined"
              type={inputType}
              onFocus={() => setInputType('date')}
              onBlur={() => setInputType('text')}
            />
            <InputCustom
              margin="normal"
              required
              id="name"
              value={filters?.grower_passbook || ""}
              onChange={(e) =>
                setFilters((pre) => ({
                  ...pre,
                  grower_passbook: e.target.value,
                }))
              }
              label="Passbook  Number"
              variant="outlined"
              name="name"
              width="15%"
            />
            <AutocompleteCustom
              value={
                varietiesOptions.find(
                  (data) => data?.value === filters?.variety_id,
                )?.label || null
              }
              onChange={(e) =>
                setFilters((pre) => ({
                  ...pre,
                  variety_id: e?.value || "",
                }))
              }
              id={"Variety"}
              name="Variety"
              label={"Variety"}
              options={varietiesOptions}
              width="15%"
            />
            <AutocompleteCustom
              value={filters?.user_name || null}
              onChange={(e) =>
                setFilters((pre) => ({
                  ...pre,
                  user_name: e?.label || "",
                }))
              }
              name="Surveyor"
              label={"Surveyor"}
              options={userOptions}
              width="15%"
            />
            <InputCustom
              margin="normal"
              required
              id="name"
              value={filters?.grower_cnic || ""}
              onChange={(e) =>
                setFilters((pre) => ({
                  ...pre,
                  grower_cnic: e.target.value,
                }))
              }
              label="Grower Cnic"
              variant="outlined"
              name="name"
              width="15%"
            />
            <AutocompleteCustom
              id={"gate_circle_id"}
              value={
                gateOptions.find(
                  (data) => data?.value === filters?.gate_circle_id,
                )?.label || null
              }
              onChange={(e) =>
                setFilters((pre) => ({
                  ...pre,
                  gate_circle_id: e?.value || "",
                }))
              }
              name="gate_circle_id"
              label={"Circle"}
              options={gateOptions}
              width="15%"
            />

            <Button
              variant="contained"
              size="small"
              disabled={filters === null}
              onClick={() => onApplyFilters()}
              sx={{
                background: `${colors.customColor[100]}`,
                color: `#fff`,
                height: "40px",
                marginTop: "20px",
                borderRadius: "8px",
                "&:hover": {
                  background: `green`,
                },
              }}
            >
              Apply Filters
            </Button>
          </Box>
        </Grid>
        <FieldSearchComponent
          searchHandler={(value) => onMapSearch(value)}
          searchResultOptions={searchResultOptions}
          onSelectMapSearchValue={onSelectMapSearchValue}
          isMapSearchLoading={isMapSearchLoading}
          onSearchByCoordinates={onSearchByCoordinates}
        />

        <MapContainer
          center={map_center}
          zoom={15}
          maxZoom={18}
          scrollWheelZoom={true}
          zoomControl={false}
          attributionControl={false}
          style={{ height: "60vh", borderRadius: 5 }}
          ref={mapRef}
        >
          <Search />
          {/* <BaseLayerControl /> */}

          <LayersControl position="bottomright">
            <LayersControl.BaseLayer checked name="Satellite View">
              <TileLayer
                maxZoom={22}
                maxNativeZoom={19}
                url={`https://api.mapbox.com/styles/v1/kumail-09/clc0a4s9k000b14lmukbjuxvu/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
              />
            </LayersControl.BaseLayer>

            <LayersControl.Overlay name="Fields" checked>
              <LayerGroup>
                <WMSTileLayer
                  url="https://dev.farmdar.ai/geoserver/farmdar/wms"
                  layers="farmdar:fields"
                  format="image/png"
                  transparent={true}
                  attribution="Your attribution"
                  // cql_filter={`gate_circle=${data?.id}`}
                  cql_filter={cqlFilters}
                />
              </LayerGroup>
              ;
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Boundary" checked>
              <LayerGroup>
                {allBoundaries?.length >= 1 &&
                  allBoundaries?.map((data) => {
                    let coordinates = flipCoords(data.geometry.coordinates[0]);
                    let properties = data.properties;
                    return (
                      <Polygon
                        positions={coordinates}
                        // eventHandlers={{ click: handleBoundaryPolygonClick }}
                        id={data}
                        key={data.id}
                        pathOptions={{
                          color:
                            properties?.type === boundaryIds.districtId
                              ? "blue"
                              : properties?.type === boundaryIds.gateId
                              ? "purple"
                              : properties?.type === boundaryIds.villageId
                              ? "green"
                              : properties?.type === boundaryIds.dehId
                              ? "yellow"
                              : "blue",
                        }}
                      >
                        <Tooltip>
                          <Box sx={{}}>
                            <p>Name: {data?.properties.name}</p>
                            <p>
                              Total Acreage:{" "}
                              {data?.properties?.total_area === null
                                ? 0
                                : data?.properties?.total_area.toFixed(2)}
                            </p>
                            <p>
                              Esurvey Acreage:{" "}
                              {data?.properties?.surveyed_area === null
                                ? 0
                                : data?.properties?.surveyed_area.toFixed(2)}
                            </p>
                          </Box>
                        </Tooltip>
                      </Polygon>
                    );
                  })}
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>

          <ZoomControl prepend position="topright" />
          <CircleMarker center={userLocation} />

          <ReCenter
            user_location={userLocation}
            updateLocation={setUserLocation}
          />

          {allFields?.length >= 1 &&
            allFields?.map((data) => {
              let coordinates = flipCoords(data.geometry.coordinates[0]);
              let properties = data.properties;
              let grower = data?.grower;
              return (
                <Polygon
                  positions={coordinates}
                  eventHandlers={{ click: handlePolygonClick }}
                  id={data}
                  key={data.id}
                  pathOptions={{
                    color: "red",
                  }}
                >
                  <Tooltip>
                    <Box sx={{}}>
                      <p>Field ID: {data?.id || ""}</p>
                      <p>Farmer Name: {grower?.grower_name || ""}</p>
                      <p>Farmer Passbook: {grower?.passbook_number || ""}</p>
                      <p>
                        Total Area:{" "}
                        {properties?.total_area === null
                          ? 0
                          : properties?.total_area.toFixed(2)}
                      </p>
                    </Box>
                  </Tooltip>
                </Polygon>
              );
            })}
        </MapContainer>
      </Grid>
    </div>
  );
}

export const flipCoords = (coordsList) => {
  return [
    coordsList.map((c) => {
      return [c[1], c[0]];
    }),
  ];
};
export default TerritoryManagement;
