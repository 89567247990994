import {
  CircleMarker,
  MapContainer,
  WMSTileLayer,
  ZoomControl,
  LayersControl,
  LayerGroup,
  TileLayer,
  FeatureGroup,
} from "react-leaflet";
import { ReCenter } from "../../components/map-utils/ReCenter";
import * as React from "react";
import { flipCoords } from "../territoryManagement/ManageGateCircles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { EditControl } from "react-leaflet-draw";
import "assets/leaflet-draw.css";
import { serializeForm } from "../../utils/utils";
import { createNotification } from "../../components/Notifications";
import { useNavigate, useParams } from "react-router-dom";
import L from "leaflet";
import Popup from "components/Popup";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import EditVarietyField from "../../components/EditVarietyField";
import Topbar from "components/Topbar";
import {
  getCropCondition,
  getCropStage,
  getCroptype,
} from "api/surveySetup/Crop";
import { getVariety } from "api/surveySetup/Variety";
import {getDisease} from "../../api/surveySetup/Disease";
import EditDisease from "./EditDisease";
import {SurveyorsProvider} from "../../components/AppStates/Surveyors";

const pageHeight = "";

function EditField() {
  const { id } = useParams();
  const mapRef = useRef();
  const navigate = useNavigate();
  const featureGroupRef = useRef();
  const editRef = useRef();
  const [userLocation, setUserLocation] = useState([30.3753, 69.3451]);
  const [attributeData, setAttributeData] = useState(null);
  const map_center = [30.3753, 69.3451];
  const [state, setState] = useState({ leaflet_id: null, field_polygon: null });
  const [Open, setOpen] = useState(false);
  const [findField, setFindField] = useState(false);
  const [varietyList, setVarietyList] = useState([]);
  const [sowingDate, setSowingDate] = useState(dayjs());
  const [cropStage, setCropStage] = useState(null);
  const [cropType, setCropType] = useState(null);
  const [cropCondition, setCropCondition] = useState(null);
  const [selectedVarieties, setSelectedVarieties] = useState([]);
  const [varietyAcreage, setVarietyAcreage] = useState({});
  const [cropStageList, setCropStageList] = useState([]);
  const [cropConditionList, setCropConditionList] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);
  const [cropTypeList, setCropTypeList] = useState([]);
  const [gateId, setGateId] = useState("");
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const options = ["Ratoon", "After Wheat", "September", "Plant"]; // Options should be imported from backend or file

  const handleSowingDateChange = (date) => {
    setSowingDate(date);
  };
  const fetchLists = async () => {
    try {
      const cropStageResponse = await getCropStage();
      const cropConditionResponse = await getCropCondition();
      const cropTypeResponse = await getCroptype();
      const diseaseResponse = await getDisease();
      setDiseaseList(diseaseResponse);
      setCropStageList(cropStageResponse);
      setCropConditionList(cropConditionResponse);
      setCropTypeList(cropTypeResponse);
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch Fields
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}/api/fields/${id}/`)
      .then((res) => {
        if (res && res.data) {
          let field_polygon = flipCoords(res.data.geometry.coordinates[0]);
          if (res?.data && field_polygon) {
            featureGroupRef.current.clearLayers();
            let addedPoly = featureGroupRef.current.addLayer(
              L.polygon(field_polygon)
            );
            let leaflet_id = addedPoly._leaflet_id;
            setAttributeData({...res?.data.properties, grower_passbook: res?.data?.grower?.passbook_number});


            setState({ ...state, field_polygon, leaflet_id });
            if (res?.data?.properties?.variety)
              setSelectedVarieties(res?.data?.properties?.varieties?.variety);
            if (res?.data?.properties?.sowing_date)
              setSowingDate(dayjs(res?.data?.properties?.sowing_date));
            setCropStage(
              res?.data?.properties?.varieties.flatMap(
                (v) => v?.crop_stage?.name || []
              )
            );
            setCropType(res?.data?.properties?.crops_type?.name);
            setCropCondition(res?.data?.properties?.crops_condition?.name);
            setGateId(res?.data?.properties?.gate_circle);
            const bounds = addedPoly.getBounds();
            mapRef.current.fitBounds(bounds);
          }
        }
      });
  }, [findField]);
 
  // Fetch Varieties
  useEffect(() => {
    getVariety().then((res) => {
      if (res) {
        setVarietyList(res);
      }
    });
    fetchLists();
  }, [findField]);

  /***************************************Leaflet Draw Functionality*********************************************/
  const onMountedRect = (e) => {
    editRef.current = e;
    // Style Draw Button
    if (document.getElementsByClassName("leaflet-draw-draw-polygon")[0]) {
      let drawBtn = document.getElementsByClassName(
        "leaflet-draw-draw-polygon"
      )[0];
      drawBtn.innerHTML = "Draw";
    }
    // Style Edit Button and Hide on Mount
    if (document.getElementsByClassName("leaflet-draw-edit-edit")[0]) {
      let editBtn = document.getElementsByClassName(
        "leaflet-draw-edit-edit"
      )[0];
      editBtn.innerHTML = "Edit";
      editBtn.style.display = "none";
    }
    // If Polygon is Drawn, Hide Draw Button, Show Edit Button
    if (
      document.getElementsByClassName("leaflet-draw-draw-polygon")[0] &&
      state.field_polygon
    ) {
      let drawBtn = document.getElementsByClassName(
        "leaflet-draw-draw-polygon"
      )[0];
      let editBtn = document.getElementsByClassName(
        "leaflet-draw-edit-edit"
      )[0];
      drawBtn.style.pointerEvents = "none";
      drawBtn.style.display = "none";
      editBtn.style.display = "block";
    }
  };
  const deletePolygon = () => {
    try {
      // Remove All Drawn Layers
      editRef.current._toolbars.edit._modes.remove.handler.removeAllLayers();
    } catch (e) {
      console.log(e);
    }
    if (document.getElementsByClassName("leaflet-draw-draw-polygon")[0]) {
      let drawBtn = document.getElementsByClassName(
        "leaflet-draw-draw-polygon"
      )[0];
      drawBtn.style.pointerEvents = "all";
      drawBtn.style.display = "block";
    }
  };
  const onDrawStart = () => {
    editRef.current._toolbars.draw._modes.polygon.handler.enable();
  };
  const onEditStop = (e) => {
    try {
      if (state.leaflet_id) {
        const coords = e?.sourceTarget._layers[state.leaflet_id]._latlngs[0];
        setState((prevState) => ({
          ...prevState,
          field_polygon: coords,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    mapRef.current.eachLayer(function (lyr) {
      if (lyr._leaflet_id === state.leaflet_id) {
        const coords = lyr._layers[Object.keys(lyr._layers)[0]]._latlngs;
        const serialized = serializeForm(event.currentTarget);
        let coordinate_pair = coords[0].map((coord) => {
          return [coord.lng, coord.lat];
        });
        const uniqueCoordinates = [
          ...new Set(coordinate_pair.map(JSON.stringify)),
        ].map(JSON.parse);
        uniqueCoordinates.push(uniqueCoordinates[0]);
        serialized.polygon = {
          type: "Feature",
          geometry: { type: "Polygon", coordinates: [uniqueCoordinates] },
        };
        serialized.varieties = selectedVarieties.map((variety) => ({
          ...variety,
          crop_stage: cropStageList.find((stage) => stage.name === cropStage)?.id,
        }));
        serialized.diseases = selectedDiseases?.length > 0 ? selectedDiseases.map((disease) => ({id: disease.id, percentage: disease.percentage})) : [];
        serialized.varieties = selectedVarieties;
        serialized.sowing_date = sowingDate.toISOString();
        serialized.crop_stage = cropStageList.find(
          (stage) => stage.name === cropStage
        )?.id;
        serialized.crops_type = cropTypeList.find(
          (type) => type.name === cropType
        )?.id;
        serialized.crops_condition = cropConditionList.find(
          (condition) => condition?.name === cropCondition
        )?.id;
        serialized.status = "PD";
        serialized.additional_fields = attributeData?.additional_fields;
        serialized.area_entered = parseFloat(attributeData?.area_entered);
        serialized.estimated_yield = parseFloat(attributeData?.estimated_yield);
        serialized.field_name = attributeData?.field_name;
        serialized.field_address = attributeData?.field_address;
        serialized.grower = attributeData?.grower_passbook || '';
       delete serialized['Estimated Yield'];
       delete serialized['Area Entered'];
        try {
          axios
            .patch(
              `${process.env.REACT_APP_BASEURL}/api/fields/${id}/`,
              serialized
            )
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                let gc_id = res?.data?.gate_cricle;
                if (gc_id) {
                  createNotification("success");
                  navigate(`/gate-circles/${gc_id}/fields`);
                } else {
                  createNotification("error");
                }
              }
            });
        } catch (error) {
          if (error?.response?.status === 400) {
            alert("Invalid Username or Password");
          } else if (error?.response?.data) {
            alert(JSON.stringify(error?.response?.data));
          } else alert(error);
        }
      }
    });
  };

  // delete gate circles
  const handleDelete = async () => {
    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_BASEURL}/api/fields/${id}`
      );
      if (response.status === 204) {
        navigate(`/all-surveys/bounderies/${gateId}/detail`);
      }
    } catch (error) {
      console.error(error);
      alert("Failed to delete employee");
    }
  };
  const onShapeDrawn = (e) => {
    /* Enable Editing On Click of Polygon */
    e.layer.on("click", () => {
      editRef.current._toolbars.edit._modes.edit.handler.enable();
    });
    /* Save Polygon */
    try {
      let coords = e?.layer?._latlngs[0];
      let leafletId = e?.layer._leaflet_id;
      setState((prevState) => ({
        ...prevState,
        field_polygon: coords,
        leaflet_id: leafletId,
      }));
    } catch (e) {
      console.error(e);
    }
  };

  /*************************************** END /Leaflet Draw Functionality / END ****************************************/
 
  //cql filters
  const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
   const companyFilter = companyInfo?.company
    ? `company_id=${companyInfo.company}`
    : null;
  const gateFilter =`gate_circle=${gateId}`
  const dateFilter =companyInfo?.season?.start_date?`created_at > ${companyInfo?.season?.start_date}T00:00:00Z AND created_at < ${companyInfo?.season?.end_date}T00:00:00Z`: `created_at > 2024-01-01T00:00:00Z`;
  const cqlFilters = [dateFilter, companyFilter,gateFilter].filter(Boolean).join(" AND ");

  return (
    <>
      <SurveyorsProvider>
      <Topbar fieldId={id} gateCId={attributeData?.gate_cricle} />
      <div
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          background: "white",
          boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
          borderRadius: 20,
          padding: "2%",
          position: "relative",
          left: "10%",
        }}
      >
        <div>
          <MapContainer
            center={map_center}
            zoom={7}
            maxZoom={18}
            scrollWheelZoom={true}
            zoomControl={false}
            attributionControl={false}
            style={{ height: "50vh", borderRadius: 20 }}
            ref={mapRef}
          >
            <ZoomControl prepend position="topright" />
            <CircleMarker center={userLocation} />
            <ReCenter
              user_location={userLocation}
              updateLocation={setUserLocation}
            />
            {/*<Delete deletePolygon={deletePolygon}/>*/}

            <div style={{ position: "relative" }}>
              {/* Add your button here */}
              <Button
                variant="contained"
                style={{
                  position: "absolute",
                  top: "135px",
                  right: "10px",
                  zIndex: 1000,
                }}
                onClick={() => setFindField(!findField)}
              >
                gate
              </Button>

              <LayersControl position="bottomright">
                <LayersControl.BaseLayer checked name="Satellite View">
                  <TileLayer
                    maxZoom={18}
                    maxNativeZoom={19}
                    url={`https://mt1.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}`}
                  />
                </LayersControl.BaseLayer>

                {gateId && (
                  <LayersControl.Overlay name="Fields" checked>
                    <LayerGroup>
                      <WMSTileLayer
                        url="https://dev.farmdar.ai/geoserver/farmdar/wms"
                        layers="farmdar:fields"
                        format="image/png"
                        transparent={true}
                        attribution="Your attribution"
                        cql_filter={cqlFilters}
                      />
                    </LayerGroup>
                  </LayersControl.Overlay>
                )}
              </LayersControl>
              <FeatureGroup ref={featureGroupRef}>
                <EditControl
                  onMounted={onMountedRect}
                  position="bottomleft"
                  onCreated={onShapeDrawn}
                  onDrawStart={onDrawStart}
                  onEditStop={onEditStop}
                  // edit={{ remove:false, edit:false }}
                  draw={{
                    rectangle: false,
                    circle: false,
                    polyline: false,
                    circlemarker: false,
                    marker: false,
                    polygon: {
                      allowIntersection: false,
                      shapeOptions: { color: "#15ff00" },
                    },
                  }}
                />
              </FeatureGroup>
            </div>
          </MapContainer>
        </div>
        <div>
          {attributeData && (
            <Box>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "row",
                  overflow: "hidden",
                  justifyContent: "space-between",
                  overflowY: "auto",
                  height: pageHeight,
                  // paddingLeft: 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#1BC693",
                        fontSize: 16,
                        fontFamily: "Century Gothic",
                        fontWeight: "700",
                      }}
                    >
                      Field Name
                    </Typography>
                    <TextField
                      required
                      size="small"
                      id="field_name"
                      name="field_name"
                      sx={{ marginTop: "5px" }}
                      value={attributeData?.field_name}
                      onChange={(e) => {
                        setAttributeData({
                          ...attributeData,
                          field_name: e.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#1BC693",
                        fontSize: 16,
                        fontFamily: "Century Gothic",
                        fontWeight: "700",
                      }}
                    >
                      Field Address
                    </Typography>
                    <TextField
                      required
                      size="small"
                      id="field_address"
                      name="field_address"
                      sx={{ marginTop: "5px" }}
                      value={attributeData?.field_address}
                      onChange={(e) => {
                        setAttributeData({
                          ...attributeData,
                          field_address: e.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                   {/*Discontinued as per request */}
                  {/* {cropStage && (*/}
                  {/*  <Grid item>*/}
                  {/*    <Typography*/}
                  {/*      sx={{*/}
                  {/*        color: "#1BC693",*/}
                  {/*        fontSize: 16,*/}
                  {/*        fontFamily: "Century Gothic",*/}
                  {/*        fontWeight: "700",*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      Crop Stage*/}
                  {/*    </Typography>*/}
                  {/*    <FormControl*/}
                  {/*      sx={{ marginTop: "5px" }}*/}
                  {/*      size="small"*/}
                  {/*      required*/}
                  {/*    >*/}

                  {/*      <Select*/}

                  {/*        id="crop-stage"*/}
                  {/*        value={cropStage || ""}*/}
                  {/*        onChange={(e) => {*/}
                  {/*          setCropStage(e.target.value);*/}
                  {/*        }}*/}

                  {/*      >*/}
                  {/*        {cropStageList.map((cropStage) => (*/}
                  {/*          <MenuItem key={cropStage.id} value={cropStage.name}>*/}
                  {/*            {cropStage.name}*/}
                  {/*          </MenuItem>*/}
                  {/*        ))}*/}
                  {/*      </Select>*/}
                  {/*    </FormControl>*/}
                  {/*  </Grid>*/}
                  {/*)}*/}

                  {/* TODO: fix variety edit */}
                  {/* </Box>
              <Box
                component="form"
                
                noValidate
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "row",
                  overflow: "hidden",
                  justifyContent: "space-between",
                  overflowY: "auto",
                  height: pageHeight,
                  // paddingLeft: 1,
                }}
              > */}
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#1BC693",
                        fontSize: 16,
                        fontFamily: "Century Gothic",
                        fontWeight: "700",
                      }}
                    >
                      Crop Type
                    </Typography>
                    <FormControl
                      sx={{ marginTop: "5px", width: "100px" }}
                      size="small"
                      required
                    >
                      <Select
                        id="crop-type"
                        value={cropType}
                        onChange={(e) => {
                          setCropType(e.target.value);
                        }}
                      >
                        {cropTypeList.map((cropType) => (
                          <MenuItem key={cropType?.id} value={cropType?.name}>
                            {cropType?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#1BC693",
                        fontSize: 16,
                        fontFamily: "Century Gothic",
                        fontWeight: "700",
                      }}
                    >
                      Crop Condition
                    </Typography>
                    <FormControl
                      sx={{ marginTop: "5px" }}
                      size="small"
                      required
                    >
                      <Select
                        id="crop-condition"
                        value={cropCondition}
                        onChange={(e) => {
                          // setAttributeData({
                          //   ...attributeData,
                          //   crops_condition: e.target.value,
                          // });
                          setCropCondition(e.target.value);
                        }}
                      >
                        {cropConditionList.map((cropCondition) => (
                          <MenuItem
                            key={cropCondition?.id}
                            value={cropCondition?.name}
                          >
                            {cropCondition?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#1BC693",
                        fontSize: 16,
                        fontFamily: "Century Gothic",
                        fontWeight: "700",
                      }}
                    >
                      Sowing Date
                    </Typography>
                    <DateTimePicker
                      required
                      size="small"
                      id="sowing_date"
                      input
                      sx={{ marginTop: "5px" }}
                      name="sowing_date"
                      value={sowingDate}
                      onChange={handleSowingDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#1BC693",
                        fontSize: 16,
                        fontFamily: "Century Gothic",
                        fontWeight: "700",
                      }}
                    >
                      Remarks
                    </Typography>
                    <TextField
                      sx={{ marginTop: "5px" }}
                      required
                      size="small"
                      id="remarks"
                      name="notes"
                      value={attributeData?.notes}
                      onChange={(e) => {
                        setAttributeData({
                          ...attributeData,
                          notes: e.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Typography
                        sx={{
                          color: "#1BC693",
                          fontSize: 16,
                          fontFamily: "Century Gothic",
                          fontWeight: "700",
                        }}
                    >
                      Area Entered
                    </Typography>
                    <TextField
                        sx={{ marginTop: "5px" }}
                        required
                        size="small"
                        id="area_entered"
                        name="Area Entered"
                        value={attributeData?.area_entered}
                        onChange={(e) => {
                          setAttributeData({
                            ...attributeData,
                            area_entered: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                        sx={{
                          color: "#1BC693",
                          fontSize: 16,
                          fontFamily: "Century Gothic",
                          fontWeight: "700",
                        }}
                    >
                      Estimated Yield
                    </Typography>
                    <TextField
                        sx={{ marginTop: "5px" }}
                        required
                        size="small"
                        id="estimated_yield"
                        name="Estimated Yield"
                        value={attributeData?.estimated_yield}
                        onChange={(e) => {
                          setAttributeData({
                            ...attributeData,
                            estimated_yield: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                        sx={{
                          color: "#1BC693",
                          fontSize: 16,
                          fontFamily: "Century Gothic",
                          fontWeight: "700",
                        }}
                    >
                      Sowing Distance
                    </Typography>
                    <TextField
                        sx={{ marginTop: "5px" }}
                        required
                        size="small"
                        id="estimated_yield"
                        name="Estimated Yield"
                        value={attributeData?.sowing_distance}
                        onChange={(e) => {
                          setAttributeData({
                            ...attributeData,
                            sowing_distance: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                        sx={{
                          color: "#1BC693",
                          fontSize: 16,
                          fontFamily: "Century Gothic",
                          fontWeight: "700",
                        }}
                    >
                      Grower Passbook
                    </Typography>
                    <TextField
                        sx={{ marginTop: "5px" }}
                        required
                        size="small"
                        id="estimated_yield"
                        name="Estimated Yield"
                        value={attributeData?.grower_passbook}
                        onChange={(e) => {
                          setAttributeData({
                            ...attributeData,
                            grower_passbook: e.target.value,
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                  </Grid>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
                    <Grid item>
                      <Typography
                          sx={{
                            color: "#1BC693",
                            fontSize: 16,
                            fontFamily: "Century Gothic",
                            fontWeight: "700",
                            margin: 3,
                            marginBottom: 0,
                          }}
                      >
                        Varieties
                      </Typography>
                      <EditVarietyField
                          varietyList={varietyList}
                          varietyAcreage={selectedVarieties}
                          setVarietyAcreage={setSelectedVarieties}
                          cropStageList={cropStageList}
                          attributeData={attributeData}
                          handleChangeSelectedVariety={setSelectedVarieties}

                      />
                    </Grid>
                    <Grid item>
                      <Typography
                          sx={{
                            color: "#1BC693",
                            fontSize: 16,
                            fontFamily: "Century Gothic",
                            fontWeight: "700",
                            margin: 3,
                            marginBottom: 0,
                          }}
                      >
                        Disease
                      </Typography>
                      <EditDisease
                          diseaseList={diseaseList}
                          fieldDiseases={attributeData?.diseases}
                          selectedDiseases={selectedDiseases}
                          setSelectedDiseases={setSelectedDiseases}
                      />
                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
                    <Grid item sx={{marginLeft: 5}}>
                      {
                          Array.isArray(attributeData?.additional_fields) && attributeData?.additional_fields.length > 0 && (
                              attributeData?.additional_fields.map((field, index) => (
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
                                        <Grid item>
                                        <Typography
                                            sx={{
                                                color: "#1BC693",
                                                fontSize: 16,
                                                fontFamily: "Century Gothic",
                                                fontWeight: "700",
                                                // margin: 3,
                                                marginBottom: 0,
                                            }}
                                        >
                                            {field?.name}
                                        </Typography>
                                        <TextField
                                            sx={{ marginTop: "5px" }}
                                            required
                                            size="small"
                                            id={field?.name}
                                            name={field?.name}
                                            value={field?.value}
                                            onChange={(e) => {
                                                let updatedFields = attributeData?.additional_fields;
                                                updatedFields[index].value = e.target.value;
                                                setAttributeData({
                                                    ...attributeData,
                                                    additional_fields: updatedFields
                                                });

                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        </Grid>
                                    </Grid>
                                ))
                          )
                      }
                    </Grid>
                  </Grid>
                  <Grid item>
                    {state.field_polygon ? (
                      <>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            mt: 3,
                            mb: 2,
                            color: "#1BC693",
                            background: "transparent !important",
                            border: "1px solid #1BC693",
                            marginRight: "10px",
                          }}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <Typography variant={"h3"}>
                        Draw Polygon on Map To Continue
                      </Typography>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpen(true);
                      }}
                      sx={{
                        mt: 3,
                        mb: 2,
                        color: "#F46464",
                        background: "transparent !important",
                        border: "1px solid #F46464",
                      }}
                    >
                      Delete
                    </Button>
                    <Popup
                      title="Warning!"
                      open={Open}
                      onClose={() => setOpen(false)}
                      onConfirm={handleDelete}
                      className="title_container"
                      btnColor="red"
                      message={`Are you sure you want to delete Field with ID: ${id} ?`}
                    />
                  </Grid>

                </Grid>
              </Box>
            </Box>
          )}
        </div>
      </div>
    </SurveyorsProvider>
    </>
  );
}

export default EditField;
