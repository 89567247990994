const ActivityTrackingPopup = ({feature}) => {
    console.log(feature)
    const username  = feature.user?.username;
    const user_id = feature.user?.id;
    const user_status = feature.user?.user_status === 'so' ? 'Signed Out' : 'Signed In';
    const headingStyle = { color: "#1bc693" };
    const linkStyle = {color: "#1bc693", display: 'flex', justifyContent: 'center', marginTop: '1px' };

    return (
        <div>
            <div><strong style={headingStyle}>User ID:</strong> {user_id}</div>
            <div><strong style={headingStyle}>Username:</strong> {username}</div>
            <div><strong style={headingStyle}>Recent Survey:</strong> {feature.user?.survey_attributes?.last_survey_id}</div>
            <div><strong style={headingStyle}>On Date Survey:</strong> {feature.user?.survey_attributes?.on_date_surveyed}</div>
            <div><strong style={headingStyle}>To Date Survey:</strong> {feature.user?.survey_attributes?.to_date_surveyed}</div>
            <div><strong style={headingStyle}>Total Completed Surveys:</strong> {feature.user?.survey_attributes?.to_date_approved}</div>
            <div><strong style={headingStyle}>Status:</strong> {user_status}</div>
            <div style={linkStyle}><a href={`/team-trak/activity-tracking/${user_id}`}>View</a></div>
        </div>
    );
};

export default ActivityTrackingPopup;
