import React, { useContext, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, useTheme, Typography } from '@mui/material';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { ColorModeContext, tokens } from '../assets/Theme';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Topbar = ({ gateCId, fieldId, page, role, teamId }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState("Dashboard");
    const [anchorEl, setAnchorEl] = useState(null);
    const storedCompanyInfo = JSON.parse(localStorage.getItem('companyInfo'));
        const handleLogout = () => {
        localStorage.clear();
        window.location.href = "/";
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const Item = ({ title, to, icon }) => {
        const theme = useTheme();
        const colors = tokens(theme.palette.mode);

        const handleClick = () => {
            setSelected(title);
        };

        return (
            <Box
                component={Link}
                to={to}
                sx={{
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    color: selected === title ? colors.customColor[100] : colors.customColor[200],
                    '&:hover': {
                        color: colors.customColor[100],
                    },
                    '&:active': {
                        color: colors.customColor[100],
                    }
                }}
                onClick={handleClick}
            >
                {icon && React.cloneElement(icon, { sx: { marginRight: 1 } })}
                <Typography>{title}</Typography>
            </Box>
        );
    };

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            <Box
                display="flex"
                backgroundColor='transparent'
                borderRadius="3px"
                justifyContent="space-between"
                width='70%'
            >
                <Item
                    title="Dashboard"
                    to="/"
                    icon={<DashboardIcon />}
                />
                <Item
                    title="Cropscan"
                    to={process.env.REACT_APP_IQ_URL}
                    icon={<DataThresholdingIcon />}
                />
                <Item
                    title="DeveloPro"
                    to="/developro/projects/page/1"
                    icon={<DeveloperBoardIcon />}
                />
                <Item
                    title="Esurvey"
                    to="/"
                    icon={<DataThresholdingIcon />}

                />
                <Item

                    title="Team Trak"
                    to="/team-trak"
                    icon={<DataThresholdingIcon/>}
                />

            </Box>

            {/* ICONS */}
            <Box display="flex" >
                {/* <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton> */}

                <IconButton onClick={handleMenuOpen}>
                    <PersonOutlinedIcon />
                    <Typography variant='h5' color={colors.customColor[100]} >{storedCompanyInfo?.username}</Typography>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                </Menu>

            </Box>
        </Box>
    );
};

export default Topbar;
