import React, {useState, useEffect} from 'react';
import Topbar from 'components/Topbar';
import {Button, Typography, Stack} from '@mui/material';
import TopBarView from 'components/develoPro/TopBarView';
import SearchBar from 'components/develoPro/SearchBar';
import AccordionTable from 'components/develoPro/AccordionTable';
import {FilterIcon} from 'assets/Icons';
import {useNavigate, useParams} from 'react-router-dom';
import {Select, MenuItem} from '@mui/material';
import Modal from 'components/develoPro/Modal';
import {getProject} from 'api/develoPro/Projects';
import FilterModal from 'components/develoPro/FilterModal';

export default function Projects() {
    const [projects, setProjects] = useState([]);
    const [next, setNext] = useState(null);
    const [previous, setPrevious] = useState(null);
    const [open, setOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [filters, setFilters] = useState({
        name: '', project_type: '', grower: '', start_date: '', end_date: '',
    })

    const navigate = useNavigate();
    const {pageNumber} = useParams();
    const totalPages = projects?.total_pages

    /**Takes form data set in Filters Modal
     * Updates Filter State here */
    const handleFiltersChange = (formData) => {
        setFilters(formData);
    }

    const fetchProjects = async (queryParams) => {
        try {
            const res = await getProject(queryParams);
            console.log(res);
            setProjects(res);
            setNext(res?.data?.links?.next)
            setPrevious(res?.data?.links?.previous)
        } catch (error) {
            console.error(error);
        }
    };
    const updateProjects = (newProjects) => {
        setProjects(newProjects);
    };

    console.log("next", next, "previous", previous)
    const pageOptions = [];
    for (let pageCount = 1; pageCount <= totalPages; pageCount++) {
        pageOptions.push(<MenuItem key={pageCount} value={pageCount}>
            {pageCount}
        </MenuItem>);
    }
    const handleNextpage = () => {
        if (next !== null) {
            const nextPage = parseInt(pageNumber, 10) + 1;
            navigate(`/developro/projects/page/${nextPage}`);
        }
    }
    const handlePreviousPage = () => {
        if (previous !== null) {
            const previousPage = parseInt(pageNumber, 10) - 1;
            navigate(`/developro/projects/page/${previousPage}`);
        }
    }
    const handlePageChange = (event) => {
        const newPage = event.target.value;
        navigate(`/developro/projects/page/${newPage}`);
    };

    useEffect(() => {
        const queryParams = {page: pageNumber};
        Object.entries(filters).forEach(([key, value]) => {
            if (typeof (filters[key]) === "string" && filters[key] !== '') {
                queryParams.key = value;
            }
        })
        fetchProjects(queryParams);
    }, [pageNumber, filters]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleFilterClose = () => {
        setFilterOpen(false);
    };


    return (<div>
        <Topbar/>
        <div style={{marginTop: '30px', marginLeft: '50px'}}>
            <TopBarView/>
            <div style={{display: 'flex', justifyContent: 'space-between', width: "93%", marginTop: '20px'}}>
                <div>
                    <Typography sx={{marginTop: '20px', fontSize: '18px', fontWeight: '500'}}>Projects List</Typography>
                </div>
                <div>
                    <Stack direction="row">
                        <SearchBar/>
                        <Button onClick={() => setFilterOpen(true)}>
                            <FilterIcon style={{height: '50px', width: '50px'}}/>
                        </Button>
                        <Button variant="contained" className="createProjectbtn" onClick={handleOpen}>
                            Create Project
                        </Button>
                    </Stack>
                </div>
            </div>
            <AccordionTable projectsData={projects}/>
            <Stack direction="row" spacing={2}
                   sx={{margin: '1%', justifyContent: 'flex-end', position: 'relative', right: '7%'}}>
                <Button className='footerbtn' variant='outlined' onClick={handlePreviousPage}
                        disabled={pageNumber < 2}>
                    previous
                </Button>
                <Button className='footerbtn' variant='outlined' onClick={handleNextpage}
                        disabled={pageNumber > totalPages - 1}>
                    next
                </Button>
                {projects && projects?.total_pages && (
                    <Select value={pageNumber} sx={{height: '40px'}} onChange={handlePageChange}>
                        {pageOptions}
                    </Select>)}
            </Stack>
        </div>
        {open && <Modal
            open={open}
            close={handleClose}
            title='Create Project'
            fetchProjects={fetchProjects}
        />}
        {filterOpen && <FilterModal
            title={'Apply Filter'}
            handleFiltersChange={handleFiltersChange}
            open={filterOpen}
            close={handleFilterClose}
            updateProjects={updateProjects}
        />}
    </div>);
}
