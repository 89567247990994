import {Button, useTheme} from "@mui/material";
import {Add} from "@mui/icons-material";
import React from "react";
import {tokens} from "../../assets/Theme";


export default function AllGrowersTopNav({handleModalOpen}) {


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <>
            <Button
                variant="text"
                size="small"
                sx={{
                    marginTop: "21px",
                    marginRight: "10px",
                    marginLeft: "10px",
                    width: "20%",
                    backgroundColor: `${colors.customColor[100]} !important`,
                    height: "3em",
                    fontWeight: "bold",
                    fontSize: "0.7em",
                    color: 'white'
                }}
                onClick={() => handleModalOpen?.handleImportGrowerModalOpen()}
            >
                Upload CSV
            </Button>
            <Button
                variant="text"
                size="small"
                sx={{
                    marginTop: "21px",
                    marginRight: "10px",
                    marginLeft: "10px",
                    width: "20%",
                    backgroundColor: `${colors.customColor[100]} !important`,
                    height: "3em",
                    fontWeight: "bold",
                    fontSize: "0.7em",
                    color: 'white'
                }}
                onClick={() => handleModalOpen?.handleAddGrowerModalOpen(true, null)}
            >
                <Add /> Add new
            </Button>
        </>
    )
}