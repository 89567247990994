import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { Button, Typography, Stack } from "@mui/material";
import ChangeLogModal from "components/ActivityLogs";
import axios from "axios";
const itemsPerPage = 6;
export default function BottomFieldDetail() {
  const [logData, setLogData] = useState([]);
  const [datetimeQuery, setDatetimeQuery] = useState("");
  const [selectedChangeLog, setSelectedChangeLog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = logData?.slice(startIndex, endIndex);
  const closeModal = () => {
    setSelectedChangeLog(null);
    setIsModalOpen(false);
  };
  const openModal = (changeLog) => {
    setSelectedChangeLog(changeLog);
    setIsModalOpen(true);
  };

  // Function to handle page navigation
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  function ActivityLog(item) {
    const dateTimeString = new Date(item?.datetime).toLocaleString(undefined, {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    if (item.extra_data?.data?.change_log?.status) {
      const id = item?.extra_data?.data?.id;
      const status = item?.extra_data?.data?.change_log?.status;
      const type = item?.extra_data?.data?.type;

      if (status === "AP" && type === "field") {
        return (
          <div key={item.id}>
            <Typography variant="h5">
              {item.fullname} changed the status of field with id {id} to
              approved
            </Typography>
            <Typography
              variant="h8"
              sx={{ color: "#CBCBCB", fontSize: "14px", fontWeight: "400" }}
            >
              {dateTimeString}
            </Typography>
          </div>
        );
      } else if (status === "RE") {
        return (
          <div key={item?.id}>
            <Typography variant="h5">
              {item?.fullname} changed the status field with id {id} to rejected
              and left feedback:{" "}
              {item?.extra_data?.data?.change_log?.rejection_feedback}
            </Typography>
            <Typography
              variant="h8"
              sx={{ color: "#CBCBCB", fontSize: "14px", fontWeight: "400" }}
            >
              {dateTimeString}
            </Typography>
          </div>
        );
      }
    } else if (item.request_method === "DELETE") {
      const id = item?.extra_data?.data?.id;
      const type = item?.extra_data?.data?.type;

      if (type === "field") {
        return (
          <div key={item.id}>
            <Typography variant="h5">
              {item.fullname} has deleted the field with id {id}
            </Typography>
            <Typography
              variant="h8"
              sx={{ color: "#CBCBCB", fontSize: "14px", fontWeight: "400" }}
            >
              {dateTimeString}
            </Typography>
          </div>
        );
      } else if (type === "gate") {
        return (
          <div key={item.id}>
            <Typography variant="h5">
              {item.fullname} has deleted the gate circles with id {id}
            </Typography>
            <Typography
              variant="h8"
              sx={{ color: "#CBCBCB", fontSize: "14px", fontWeight: "400" }}
            >
              {dateTimeString}
            </Typography>
          </div>
        );
      }
    }
    return (
      <div key={item.id}>
        <Stack direction="row" spacing={2}>
          <Typography variant="h5">
            {item.fullname} made updated field with id{" "}
            {item?.extra_data?.data?.id} details
          </Typography>
          <Button
            variant="text"
            size="small"
            sx={{ color: "#fff" }}
            onClick={() => openModal(item?.extra_data?.data?.change_log)}
          >
            view details
          </Button>
        </Stack>
        <Typography
          variant="h8"
          sx={{ color: "#CBCBCB", fontSize: "14px", fontWeight: "400" }}
        >
          {dateTimeString}
        </Typography>
      </div>
    );
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_MICROSERVICES_URL}/activity/logs?type=field`;

        const response = await axios.get(url);

        if (response.status === 200) {
          const data = response?.data;

          setLogData(data);
        }
      } catch (error) {
        // Handle the error
        console.error(error);
      }
    }

    fetchData();
  }, [datetimeQuery]);
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "white",
          boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
          borderRadius: 20,
          marginTop: "2%",
          marginBottom: "5%",
        }}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ position: "relative", padding: "1%" }}
        >
          Activity Logs
        </Typography>
        <ul style={{ width: "700px" }}>
          {Array.isArray(currentItems) && currentItems?.length > 0 ? (
            currentItems?.map((item) => (
              <li key={item.id}>{ActivityLog(item)}</li>
            ))
          ) : (
            <p>No active logs for this user</p>
          )}
        </ul>
        <div>
          {/* Pagination controls */}
          <Button
            variant="text"
            sx={{ color: "green" }}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span>Page {currentPage}</span>
          <Button
            variant="text"
            sx={{ color: "green" }}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={endIndex >= logData?.length ?? 0}
          >
            Next
          </Button>
        </div>
      </div>
      <ChangeLogModal
        isOpen={isModalOpen}
        onClose={closeModal}
        changeLog={selectedChangeLog}
      />
    </div>
  );
}
