import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { SearchBarIcon } from 'assets/Icons';
import { Stack } from '@mui/system';
import { getTask } from 'api/develoPro/Tasks';
import { getProject } from 'api/develoPro/Projects';

export default function SearchBar({onSearchResults, id, searchAbout, pageNumber }) {
  const [searchValue, setSearchValue] = useState('')
  const handleSearch = async () => {
    try {
      if (searchAbout === 'Task') {
      const res = await getTask({  project: id ,search: searchValue, page: pageNumber });
      onSearchResults(res);
      }else {
        const res = await getProject({ search: searchValue, page: pageNumber });
        onSearchResults(res);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
   
    }
  };
  useEffect(() => {
    
      handleSearch();
    
  }, [pageNumber]);

  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <Box sx={{ background: '#fff', borderRadius: '9px', width: '340px' }}>
        <Stack direction='row' spacing={2}>
          <SearchBarIcon
            style={{ height: '28px', width: '28px', position: 'relative', top: '10px', left: '7px' }}
            onClick={handleSearch}
          />
          <TextField
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={handleKeyPress}
            sx={{ width: '280px', position: 'relative', bottom: '10px' }}
            label="Search here..."
            variant="standard"
          />
        </Stack>
      </Box>
    </Box>
  );
}