import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { BaseLayerControl } from "../../components/map-utils/BaseLayerControl";
import {
  CircleMarker,
  MapContainer,
  Polygon,
  ZoomControl,
  Tooltip,
} from "react-leaflet";
import { ReCenter } from "../../components/map-utils/ReCenter";
import { useEffect, useRef } from "react";
import axios from "axios";
import { tokens } from "../../assets/Theme";
import { bbox, feature } from "@turf/turf";
import L from "leaflet";
import openPopupAt from "../../components/map-utils/PopupData";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Stack,
  useTheme,
  Box,
} from "@mui/material";
import { Search } from "../../components/map-utils/Search";
import { Tabs, Tab } from "@mui/material";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { useNavigate } from "react-router-dom";
import Topbar from "components/Topbar";
import { useDispatch, useSelector } from "react-redux";
import {
  districtDecrement,
  districtIncrement,
} from "reduxSetup/TerritoryManagementPage";
import BoundaryDetailPopup from "components/territoryManagement/components/BoundaryDetailPopup";
import TerritoryTable from "../../components/territoryManagement//TerritoryTable";
import SearchComponent from "components/territoryManagement/components/SearchComponent";

function ManageDistrict() {
  const mapRef = useRef();
  const navigate = useNavigate();
  const { pageNumber } = useParams();
  const [districtGridData, setDistrictGridData] = React.useState([]);
  const [districtData, setDistrictData] = React.useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [next, setNext] = React.useState();
  const [record, setRecord] = React.useState();
  const [userLocation, setUserLocation] = React.useState([30.3753, 69.3451]);
  const map_center = [31.588, 74.3107];
  const currentpage = useSelector((state) => state.district.page);
  const boundaryTypeId = useSelector(
    (state) =>
      state?.boundaries?.array.find((data) => data.name === "District")?.id,
  );
  const [isMapSearchLoading, setIsMapSearchLoading] = React.useState(false);
  const [searchResultOptions, setSearchResultOptions] = React.useState([]);
  const headerNames = [
    { header: "District Name", value: "name" },
    { header: "Total  Acreage", value: "total_area" },
    { header: "eSurvey Acreage", value: "surveyed_area" },
    {
      header: "Actions",
      value: (rowData) => (
        <Button
          size="small"
          variant="text"
          sx={{
            fontWeight: "bold",
            textDecoration: "underline",
            color: `${colors.customColor[800]}`,
          }}
          onClick={() => {
            navigate(
              `/territory-management/district-management/edit/${rowData.id}`,
            );
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const dispatch = useDispatch();

  function handleRowClick(params) {
    let rowId = params.id;
    let data = districtData;
    let geojsonPoly = null;
    for (let i = 0; i <= data.features.length; i++) {
      if (data.features[i]?.id === rowId) {
        geojsonPoly = data.features[i].geometry;
        let coordinates = {
          coordinates: [
            geojsonPoly.coordinates[0].map((c) => {
              return [c[1], c[0]];
            }),
          ],
        };
        geojsonPoly = { ...geojsonPoly, ...coordinates };
      }
    }
    try {
      if (geojsonPoly) {
        const bboxArray = bbox(geojsonPoly);
        let bounds = L.latLngBounds(
          L.latLng([bboxArray[0], bboxArray[1]]),
          L.latLng([bboxArray[2], bboxArray[3]]),
        );
        mapRef.current.fitBounds(bounds, true);
      }
    } catch (e) {
      console.log(e);
    }
  }
  //search on table
  const handleSearchChange = async (searchValue) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/gates/?search=${searchValue}&p=${currentpage}&type=${boundaryTypeId}`,
      );

      if (res && res.data) {
        let gridData = res.data?.results?.features.map((ft) => {
          return {
            id: ft.id,
            name: ft.properties?.name,
            surveyed_area:
              ft.properties?.surveyed_area === null
                ? 0
                : ft.properties?.surveyed_area.toFixed(2),
            total_area:
              ft.properties?.total_area === null
                ? 0
                : ft.properties?.total_area.toFixed(2),
          };
        });
        if (gridData.length >= 1) {
          setDistrictGridData(gridData);

          setDistrictData(res?.data?.results);
          setNext(res?.data?.next);
          setRecord(res?.data?.count);
        } else {
          alert("District not available");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onMapSearch = async (searchValue) => {
    try {
      setIsMapSearchLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/gates/?search=${searchValue}&p=1&type=${boundaryTypeId}`,
      );

      if (res && res.data) {
        setSearchResultOptions(res?.data?.results.features);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsMapSearchLoading(false);
    }
  };
  const onSelectMapSearchValue = (value) => {
    if (value === null) {
      return;
    }
    const isExist =
      districtData.features.filter((data) => data?.id === value.id).length > 0;
    !isExist &&
      setDistrictData({ features: [value, ...districtData?.features] });
    gotoCurrentBoundary(value);
  };
  const gotoCurrentBoundary = (value) => {
    let geojsonPoly = value?.geometry;
    let coordinates = {
      coordinates: [
        geojsonPoly.coordinates[0].map((c) => {
          return [c[1], c[0]];
        }),
      ],
    };
    geojsonPoly = { ...geojsonPoly, ...coordinates };

    if (geojsonPoly) {
      const bboxArray = bbox(geojsonPoly);
      let bounds = L.latLngBounds(
        L.latLng([bboxArray[0], bboxArray[1]]),
        L.latLng([bboxArray[2], bboxArray[3]]),
      );
      mapRef.current.fitBounds(bounds, true);
      let polygonData = {
        data: value,
        coords: bounds,
      };

      BoundaryDetailPopup(
        mapRef.current,
        getCenter(polygonData.coords),
        polygonData.data.properties,
        polygonData.data.id,
        navigate,
      );
    }
  };
  const handlePolygonClick = (event) => {
    event.target.setStyle({ color: "green" });
    let polygonData = {
      data: event?.target?.options?.id,
      coords: event?.target?._bounds,
    };

    BoundaryDetailPopup(
      mapRef.current,
      getCenter(polygonData.coords),
      polygonData.data.properties,
      polygonData.data.id,
      navigate,
    );
  };
  const onSearchByCoordinates = async ({ latitude, longitude }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/gates/nearest-boundaries/?latitude=${latitude}&longitude=${longitude}&return_count=1&type=${boundaryTypeId}`,
      );
      console.log(data);
      const isExist =
        districtData.features.filter(
          (bound) => bound?.id === data?.features[0]?.id,
        ).length > 0;
      !isExist &&
        setDistrictData({
          features: [data?.features[0], ...districtData?.features],
        });
      gotoCurrentBoundary(data?.features[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const getCenter = (coords) => {
    const bounds = L.latLngBounds(coords);
    return bounds.getCenter();
  };

  const refreshData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/gates/?p=${currentpage}&type=${boundaryTypeId}`,
      );

      if (res && res.data) {
        let gridData = res.data?.results?.features.map((ft) => {
          return {
            id: ft.id,
            name: ft.properties?.name,
            surveyed_area:
              ft.properties?.surveyed_area === null
                ? 0
                : ft.properties?.surveyed_area.toFixed(2),
            total_area:
              ft.properties?.total_area === null
                ? 0
                : ft.properties?.total_area.toFixed(2),
          };
        });
        setDistrictGridData(gridData);

        setDistrictData(res?.data?.results);
        setNext(res?.data?.next);
        setRecord(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextpage = () => {
    if (next !== null) {
      dispatch(districtIncrement());
    }
  };

  const handlePreviousPage = () => {
    dispatch(districtDecrement());
  };
  useEffect(() => {
    refreshData();
  }, [currentpage]);

  return (
    <div style={{ width: "100%", padding: 5 }}>
      <Topbar page={pageNumber} />

      <Grid
        container
        sx={{
          width: "95%",
          position: "relative",
          left: "2%",
          background: "white",
          boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
          borderRadius: 5,
          padding: "3%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h3"
            style={{
              color: "#000",
              position: "relative",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            District Management
          </Typography>
        </div>
        <SearchComponent
          searchHandler={(value) => onMapSearch(value)}
          searchResultOptions={searchResultOptions}
          onSelectMapSearchValue={onSelectMapSearchValue}
          isMapSearchLoading={isMapSearchLoading}
          onSearchByCoordinates={onSearchByCoordinates}
        />
        <MapContainer
          center={map_center}
          zoom={15}
          maxZoom={18}
          scrollWheelZoom={true}
          zoomControl={false}
          attributionControl={false}
          style={{ height: "60vh", borderRadius: 5 }}
          ref={mapRef}
        >
          <Search />

          <BaseLayerControl />
          <ZoomControl prepend position="bottomleft" />
          <CircleMarker center={userLocation} />
          <Button
            variant="contained"
            sx={{
              margin: "10px",
              marginLeft: "auto", // Aligns the button to the right
              background: "#1BC693",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              borderRadius: 5,
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
              width: "100px",
              padding: "10px 26px", // Corrected padding format
              display: "flex",
              zIndex: 1000,
            }}
            onClick={() => {
              navigate("/territory-management/district-management/create");
            }}
          >
            + Add
          </Button>

          <ReCenter
            user_location={userLocation}
            updateLocation={setUserLocation}
          />
          {districtData?.features?.length >= 1 &&
            districtData?.features?.map((data) => {
              let coordinates = flipCoords(data.geometry.coordinates[0]);
              return (
                <Polygon
                  positions={coordinates}
                  eventHandlers={{ click: handlePolygonClick }}
                  id={data}
                  key={data.id}
                >
                  <Tooltip>
                    <Box sx={{}}>
                      <p>Name: {data?.properties.name}</p>
                      <p>
                        Total Acreage:{" "}
                        {data?.properties?.total_area === null
                          ? 0
                          : data?.properties?.total_area.toFixed(2)}
                      </p>
                      <p>
                        Esurvey Acreage:{" "}
                        {data?.properties?.surveyed_area === null
                          ? 0
                          : data?.properties?.surveyed_area.toFixed(2)}
                      </p>
                    </Box>
                  </Tooltip>
                </Polygon>
              );
            })}
        </MapContainer>
      </Grid>

      <TerritoryTable
        headerNames={headerNames}
        rowsData={districtGridData}
        title="District Management"
        actionButtonLabel="Add More"
        onRowClick={handleRowClick}
        onSearchChange={(value) => handleSearchChange(value)}
        footer={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                position: "relative",
                marginTop: "2%",
                marginBottom: "2%",
                left: "10px",
                color: `${colors.customColor[800]}`,
              }}
            >{`Page: ${currentpage} - ${Math.ceil(record / 10)}`}</Typography>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                marginTop: "2%",
                marginBottom: "2%",
                justifyContent: "flex-end",
                position: "relative",
                right: "7%",
              }}
            >
              <Button
                className="footerbtn-variety"
                variant="outlined"
                onClick={handlePreviousPage}
              >
                previous
              </Button>
              <Button
                className="footerbtn-variety"
                variant="outlined"
                disabled={next === null}
                onClick={handleNextpage}
              >
                next
              </Button>
            </Stack>
          </div>
        }
      />
    </div>
  );
}

export const flipCoords = (coordsList) => {
  return [
    coordsList.map((c) => {
      return [c[1], c[0]];
    }),
  ];
};
export default ManageDistrict;
