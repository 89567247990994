import { Typography } from '@mui/material'
import React from 'react'

export default function SummaryCard({title, value}) {
  return (
    <div 
    style={{
        display:'flex', 
        justifyContent:'space-between', 
        flexDirection:'row', 
        width:'250px', 
        height:'40px', 
        background: '#fff', 
        border: '1px solid #000', 
        borderRadius: 5,
        alignItems: 'center',
        }}>
        <Typography sx={{margin:'8px'}} >
            {title}
        </Typography>
        <Typography sx={{margin:'8px'}} variant='h3'>
            {value}
        </Typography>
    </div>
  )
}
