import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SAPModal from "./SAPModal";
import {getSAPdatabyId} from "../../api/allSurveys/sap";


export default function SAPDetails({fieldId}) {


    const [page, setPage] = React.useState(0);
    const [SAPData, setSAPData] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [modalExtraHeaders, setModalExtraHeaders] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(3);
    const [modalOpen, setModalOpen] = React.useState(false);
    const columns = [
        { id: 'vendor_code', label: 'Vendor Code', minWidth: 140 },
        { id: 'vendor_name', label: 'Vendor Name', minWidth: 140 },
        {
            id: 'serial_number',
            label: 'Serial#',
            align: 'right',
            format: value => parseInt(value),
        },
        {
            id: 'location',
            label: 'Location',
            minWidth: 140,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'zone',
            label: 'Zone',
            minWidth: 140,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'material_number',
            label: 'Material',
            minWidth: 140,
            align: 'right',
            format: (value) => parseInt(value),
        },
        {
            id: 'lot_number',
            label: 'Lot Number',
            minWidth: 140,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'year',
            label: 'Year',
            minWidth: 140,
            align: 'right',
        },
    ];

    React.useEffect(() => {
        fetchData();
    }, [])

    async function fetchData() {
        const response = await getSAPdatabyId({'field_id': fieldId});
        response?.results?.map( (entry) => {
            entry.created_at = entry?.created_at.split('T')[0];
        })
        setRows(response.results);
        setSAPData(response);
        //map all sap_attributes keys in an array
        const keys = response.results.map((result) => {
            return Object.keys(result.sap_attributes);
        });
        //flatten the array of arrays
        const flattenedKeys = keys.flat();
        //remove duplicates
        const uniqueKeys = [...new Set(flattenedKeys)];
        //convert uniqueKeys to an array of objects
        const extraHeaders = uniqueKeys.map((key) => {
            return {header: key, value: `sap_attributes.${key}`}
        });
        setModalExtraHeaders(extraHeaders);
    }


    return (
        <>
            <div style={{display: "flex", flexDirection: "row"}}>
            <Typography variant="h5" fontWeight="bold" padding="2%">
               SAP Data
                <Button
                    sx={{
                        marginLeft: "5px"
                    }}
                    onClick={() => setModalOpen(true)}
                >
                    <NorthEastIcon sx={{paddingBottom: "5px", marginBottom: "3px", color: "#0375C5",}}/>
                </Button>
            </Typography>
            </div>

            <div style={{display: "flex", flexDirection: "row"}}>
                <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: "3px" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth , fontWeight: "bold", fontFamily: "Century Gothic", color: "#1BC693", fontSize: "16px"}}
                                            sx={{ borderBottom: 'none' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.vendor_code}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align} sx={{ borderBottom: 'none' }}>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                {modalOpen && (
                    <div>
                        <SAPModal open={modalOpen} onClose={() => setModalOpen(false)} extraHeaders={modalExtraHeaders} sapData={SAPData} />
                    </div>
                )}
            </div>
        </>
    )
}