import axios from 'axios';

const makeRequest = async (url, method, data = null, params = null) => {
  try {
    const config = {
      method,
      url,
      ...(data && { data }), 
      ...(params && { params }), 
    };

    const response = await axios(config);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const getAttendanceData = (params) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/attendance/`, 'get',null,params);