import React, {useState} from 'react';
import {Modal, Paper, Typography, Button} from '@mui/material';
import {BlackFileIcon, FileIcon} from 'assets/Icons';
import {color} from '@mui/system';
import {downloadData} from "../../api/develoPro/Projects";

const ExportModal = ({open, handleClose}) => {
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const downloadCsv = () => {
        console.log('Downloading Data')
        downloadData()
    }




    return (<Modal open={open} onClose={handleClose}>
            <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                <Paper style={{padding: '8px', maxWidth: '400px', borderRadius: '10px'}}>
                    <Paper
                        elevation={3}
                        onDragOver={handleDragOver}
                        style={{
                            width: '380px',
                            height: '180px',
                            borderRadius: '10px',
                            background: '#fff',
                            border: '2px dashed #1BC795 ',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            padding: '20px',
                        }}
                    >
                        <div style={{
                            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '140px',
                                height: '100px',
                                margin: '10px',
                                background: '#1BC795',
                                borderRadius: 10,
                            }}>
                                <BlackFileIcon style={{marginTop: '10px  '}}/>
                                <Button sx={{marginTop: '8px'}} onClick={downloadCsv}>Download CSV File</Button>
                            </div>
                        </div>
                    </Paper>
                </Paper>
            </div>
        </Modal>);
};

export default ExportModal;
