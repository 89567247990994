import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  cropTypeSlice,
  cropConditionSlice,
  cropStageSlice,
  diseaseSlice,
  varietySlice,
  formCustomizationSlice
} from './SurveySetupPages';
import { navigationSlice, secondNavigationSlice } from './NavigationState';
import { fieldsSlice, formDataSlice, gateCircleSlice, rowsIdsSlice } from './AllSurveyPages';
import {
  GatePageSlice,
  DistrictPageSlice,
  VillagePageSlice,
  DehPageSlice,
  CompanyPageSlice,
  BoundaryTypeSlice,
} from "./TerritoryManagementPage";
import {growersSlice} from "./AllGrowers";
import {teamTrakSlice} from "./TeamTrak";

const rootReducer = combineReducers({
  cropType: cropTypeSlice.reducer,
  cropCondition: cropConditionSlice.reducer,
  formCustomization: formCustomizationSlice.reducer,
  cropStage: cropStageSlice.reducer,
  disease: diseaseSlice.reducer,
  variety: varietySlice.reducer,
  navigation: navigationSlice.reducer,
  subNavigation: secondNavigationSlice.reducer,
  rowsIdsSlice: rowsIdsSlice.reducer,
  gateCircleSlice: gateCircleSlice.reducer,
  fieldsSlice: fieldsSlice.reducer,
  filterData: formDataSlice.reducer,
  gates: GatePageSlice.reducer,
  district: DistrictPageSlice.reducer,
  village: VillagePageSlice.reducer,
  deh: DehPageSlice.reducer,
  company: CompanyPageSlice.reducer,
  boundaries: BoundaryTypeSlice.reducer,
  growersSlice: growersSlice.reducer,
  teamTrakSlice: teamTrakSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["company", "village", "district", "gates"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware:  (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }),
});

export const persistor = persistStore(store);
