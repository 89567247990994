import Dialog from "@mui/material/Dialog";
import {Stack} from "@mui/system";
import {Grid, MenuItem, Select, TextField, Typography, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import {CrossIcon} from "../../assets/Icons";
import React, {useEffect, useState} from "react";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import {tokens} from "../../assets/Theme";
import FormControl from "@mui/material/FormControl";
import {createGrower, deleteGrower, updateGrower} from "../../api/grower/grower";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddGrowerModal({title= 'Grower', open,  onClose, growerData}){

    const [grower, setGrower] = useState(
        {
          id: null,
          total_land_area: 0,
          passbook_number: '',
          grower_name: '',
          mother_name: '',
          mobile_number: '',
          address: '',
          father_name: '',
          muaza_name: '',
          grower_cnic: '',
          village_code: '',
          circle_code: '',
          loanee: '',
          updated_at: '',
          caste: '',
          mode_tpt: '',
          mill_distance: 0,
          leased_land_area: 0,
          owned_land_area: 0,
          created_by: ''
        }
    );
    const [viewOnly, setViewOnly] = useState(growerData?.action === 'View Grower');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isUpdate, setIsUpdate] = useState(false);
    const styleInput = {
        position: 'relative',
        top: '9px',
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: `${colors.customColor[100]}`},
        '& label.Mui-focused': {color: `${colors.customColor[100]} !important`},
        '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {borderColor: `${colors.customColor[100]} !important`},
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.customColor[100]} !important`,
            borderRadius: '15px !important'
        },
    }

    useEffect(() => {
       if (growerData){
           setGrower(growerData);
       }
    }, []);

    const handleGrowerFieldUpdate = (field, value) => {
        if (growerData){
            setIsUpdate(true);
        }
        setGrower({...grower, [field]: value});
    }

    const handleSave =async () => {
        const storedCompanyInfo = JSON.parse(localStorage.getItem('companyInfo'));
        if (grower.passbook_number.length <= 0 ) {
            alert('Passbook number is required');
            return;
        }
        if (grower.loanee.length >= 0 || grower.loanee.length <= 0) {
            grower.loanee === 'true' ? grower.loanee = true : grower.loanee = false;
        }
        if (isUpdate){
            try {
                const res = await updateGrower(grower.id, grower);
                if (res?.id){
                    window.location.reload();
                }else if (res instanceof Error){
                    alert('Error updating grower ' + res.message)
                }
            }catch (error) {
                alert('Error updating grower' + error.message)
            }
        }else {
            grower.created_by = storedCompanyInfo?.id;
            try {
                const res =await createGrower(grower);
                if (res?.id){
                    window.location.reload();
                }else if (res instanceof Error){
                    alert('Error creating grower ' + res.message)
                }
            }catch (error) {
                alert('Error creating grower' + error.message)
            }
        }
    }

    const handleEnableEdit = () => {
        setViewOnly(false);
    }

    const handleDelete = async (id) => {
        try {
            const res = await deleteGrower(id);
            window.location.reload();
        }catch (error) {
            alert('Error deleting grower' + error.message)
        }
    }

    return(
        <Dialog
            sx={{
                ".MuiDialog-paper": {
                    maxWidth: "100% !important",
                    height: "760px !important",
                    background: "#F1F6FF",
                    boxShadow: "0px 4px 6px 3px rgba(0, 0, 0, 0.30)",
                    borderRadius: 20,
                },
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography className="modal-title">{title}</Typography>
                <Button
                    size="small"
                    sx={{top: "5px"}}
                    onClick={onClose}
                >
                    <CrossIcon style={{width: "25px", height: "25px"}}/>
                </Button>
            </Stack>
            <hr style={{opacity: "0.2", width: "90%"}}/>
            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                    sx={{marginBottom: "10px"}}
                >
                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            <Grid item>
                                <Typography>Passbook Number</Typography>
                                <TextField
                                    value={grower.passbook_number}
                                    onChange={(e) => handleGrowerFieldUpdate('passbook_number', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>CNIC</Typography>
                                <TextField
                                    value={grower.grower_cnic}
                                    onChange={(e) => handleGrowerFieldUpdate('grower_cnic', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>Status</Typography>
                                <FormControl sx={{ minWidth: 120, ...styleInput }} >
                                <Select
                                    value={grower.loanee}
                                    onChange={(e) => handleGrowerFieldUpdate('loanee', e.target.value)}
                                    variant="outlined"
                                    disabled={viewOnly}
                                >
                                  <MenuItem value={'true'}>Loanee</MenuItem>
                                  <MenuItem value={'false'}>Non Loanee</MenuItem>
                                </Select>
                                </FormControl>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            <Grid item>
                                <Typography>Grower Name</Typography>
                                <TextField
                                    value={grower.grower_name}
                                    onChange={(e) => handleGrowerFieldUpdate('grower_name', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>Father Name</Typography>
                                <TextField
                                    value={grower.father_name}
                                    onChange={(e) => handleGrowerFieldUpdate('father_name', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>Caste</Typography>
                                <TextField
                                    value={grower.caste}
                                    onChange={(e) => handleGrowerFieldUpdate('caste', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            <Grid item>
                                <Typography>Mode of TPT</Typography>
                                <TextField
                                    value={grower.mode_tpt}
                                    onChange={(e) => handleGrowerFieldUpdate('mode_tpt', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>Distance From Mill</Typography>
                                <TextField
                                    value={grower.mill_distance}
                                    onChange={(e) => handleGrowerFieldUpdate('mill_distance', parseFloat(e.target.value))}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>Circle Name</Typography>
                                <TextField
                                    value={grower.circle_code}
                                    onChange={(e) => handleGrowerFieldUpdate('circle_code', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            <Grid item>
                                <Typography>Village Name</Typography>
                                <TextField
                                    value={grower.village_code}
                                    onChange={(e) => handleGrowerFieldUpdate('village_code', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>Muaza Name</Typography>
                                <TextField
                                    value={grower.muaza_name}
                                    onChange={(e) => handleGrowerFieldUpdate('muaza_name', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>

                            <Grid item>
                                <Typography>Mother Name</Typography>
                                <TextField
                                    value={grower?.mother_name}
                                    onChange={(e) => handleGrowerFieldUpdate('mother_name', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            <Grid item>
                                <Typography>Mobile Number</Typography>
                                <TextField
                                    value={grower.mobile_number}
                                    onChange={(e) => handleGrowerFieldUpdate('mobile_number', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>Address</Typography>
                                <TextField
                                    value={grower.address}
                                    onChange={(e) => handleGrowerFieldUpdate('address', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>

                            <Grid item>
                                <Typography>Total Land Area</Typography>
                                <TextField
                                    value={grower?.total_land_area}
                                    onChange={(e) => handleGrowerFieldUpdate('total_land_area', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            <Grid item>
                                <Typography>Leased Land Area</Typography>
                                <TextField
                                    value={grower.leased_land_area}
                                    onChange={(e) => handleGrowerFieldUpdate('leased_land_area', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>Owned Land Area</Typography>
                                <TextField
                                    value={grower.owned_land_area}
                                    onChange={(e) => handleGrowerFieldUpdate('owned_land_area', e.target.value)}
                                    variant="outlined"
                                    sx={{width: "90%", ...styleInput}}
                                    disabled={viewOnly}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            <Grid item sx={{marginTop: '5%'}}>
                                <Typography></Typography>

                                <Button
                                    variant="outlined"
                                    className={'footerbtn-variety'}
                                    onClick={() => {
                                        viewOnly ?  handleEnableEdit() : handleSave()
                                    }}
                                    disabled={growerData?.disableEditing}
                                >
                                    {viewOnly && 'Edit' || isUpdate && 'Update' || 'Save'}
                                </Button>
                            </Grid>
                            <Grid item sx={{marginTop: '5%'}}>
                                <Typography></Typography>

                                <Button
                                    variant="outlined"
                                    className={'footerbtn-variety'}
                                    onClick={() => onClose()}
                                >
                                    Cancel
                                </Button>
                            </Grid>

                            {
                                growerData?.id && (
                                    <Grid item sx={{marginTop: '5%'}}>
                                        <Typography></Typography>

                                        <Button
                                            variant="outlined"
                                            color="error"
                                            sx={{fontWeight: 'bold', borderWidth: '2px'}}
                                            onClick={() => handleDelete(growerData.id)}
                                            disabled={growerData?.disableEditing}
                                        >
                                            Delete
                                        </Button>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}