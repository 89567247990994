import {Stack} from "@mui/system";
import {Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {CrossIcon} from "../../assets/Icons";
import React, {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import SelectFile from "./SelectFile";
import FieldMappings from "./FieldMappings";
import {bulkImportGrower} from "../../api/grower/grower";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImportGrowerModal({open, onClose, title}) {

    const [selectedFile, setSelectedFile] = useState(null);
    const [firstRowAsHeader, setFirstRowAsHeader] = useState(true);
    const [showFieldMappings, setShowFieldMappings] = useState(false);
    const [fieldHeaders, setFieldHeaders] = useState([]);
    const [importModule, setImportModule] = useState('');


    const [fields, setFields] = useState([
        {value: "passbook_number", label: "Passbook No", fieldMapping: ""},
        {value: "grower_name", label: "Grower Name", fieldMapping: ""},
        {value: "father_name", label: "Father Name", fieldMapping: ""},
        {value: "muaza_name", label: "Muaza Name", fieldMapping: ""},
        {value: "grower_cnic", label: "CNIC", fieldMapping: ""},
        {value: "grower_type", label: "Status", fieldMapping: ""},
        {value: "circle_code", label: "Circle Name", fieldMapping: ""},
        {value: "village_code", label: "Village Name", fieldMapping: ""},
        {value: "loanee", label: "Loanee", fieldMapping: ""},
        {value: "caste", label: "Caste", fieldMapping: ""},
        {value: "mode_tpt", label: "Mode of Transport", fieldMapping: ""},
        {value: "mill_distance", label: "Mill Distance", fieldMapping: ""},
        {value: "total_land_area", label: "Total Land Area", fieldMapping: ""},
        {value: "mother_name", label: "Mother Name", fieldMapping: ""},
        {value: "mobile_number", label: "Mobile Number", fieldMapping: ""},
        {value: "address", label: "Address", fieldMapping: ""},
        {value: "leased_land_area", label: "Leased Land Area", fieldMapping: ""},
        {value: "owned_land_area", label: "Owned Land Area", fieldMapping: ""},
    ]);

    useEffect(() => {
        if (selectedFile) {
            readFirstLineFromCsv(selectedFile).then((headers) => {
                setFieldHeaders(headers);
            }).catch((error) => {
                console.error(error);
                alert('An error occurred while reading the file');
            })
        }

    }, [selectedFile]);

    const readFirstLineFromCsv = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const text = event.target.result;
                const lines = text.split('\n');
                const headers = lines[0].split(',').map(header => header.replace(/['"]+/g, ''));
                resolve(headers);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsText(file);
        });
    };


    const handleFileChange = (event) => {
        if (event.target.files[0].name.endsWith('.csv')) {
            setSelectedFile(event.target.files[0]);
        }else {
            alert('Please select a CSV file');
        }
    };

    const readAllLinesFromCsv = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const text = event.target.result;
                const lines = text.split('\n');

                if (lines.length === 0) {
                    reject('No data found in the file');
                    return;
                }

                const headers = lines[0].split(',').map(header => header.trim().replace(/"/g, ''));

                const data = lines.slice(1).map(line => {
                    const values = line.split(',').map(value => value.trim().replace(/"/g, ''));
                    return headers.reduce((obj, header, index) => {
                        obj[header] = values[index];
                        return obj;
                    }, {});
                }).filter(row => Object.values(row).some(value => value));

                if (data.length === 0) {
                    reject('No data found in the file');
                }
                if (data.length >= 1001){
                    alert('You can only import 1000 records at a time ,kindly break down the file into smaller chunks and import one by one.');
                    reject('You can only import 1000 records at a time ,kindly break down the file into smaller chunks and import one by one.');
                }
                resolve(data);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsText(file);
        });
    };
    const createPayload = (data, fields) => {
        let payload =  data.map(item => {
            return fields.reduce((obj, field) => {
                if (field.fieldMapping) {
                    let value = item[field.fieldMapping];
                    if (value === 'N/A' || value === 'n/a' || value === 'NA' || value === 'NAN' || value === 'nan' ) {
                        value = null;
                    } else if (['mill_distance', 'total_land_area', 'leased_land_area', 'owned_land_area'].includes(field.value) && value) {
                        value = parseFloat(value);
                    }
                    obj[field.value] = value;
                } else {
                    obj[field.value] = ['mill_distance', 'total_land_area', 'leased_land_area', 'owned_land_area'].includes(field.value) ? 0 : null;
                }
                return obj;
            }, {});
        });

        let seenPassbookNumbers = new Set();
        payload = payload?.filter(item => {
            let passbookNumber = item['passbook_number'];
            if (seenPassbookNumbers.has(passbookNumber)) {
                // If we've seen this passbook number before, filter it out
                return false;
            } else {
                // If it's a new passbook number, remember it and keep this item
                seenPassbookNumbers.add(passbookNumber);
                return true;
            }
        });
        //remove last row from payload because all values are undefined
        payload.pop();
        return payload;
    };

    const handleNext = () => {
        if (!selectedFile) {
            alert('Please select a file');
            return;
        }
        if (importModule === '') {
            alert('Please select import location');
            return;
        }
        if (!firstRowAsHeader) {
            alert('Please select first row as header');
            return;
        }
        setShowFieldMappings(true);
    }

    const handleSave = () => {
        readAllLinesFromCsv(selectedFile).then(async (data) => {
            const payload = createPayload(data, fields);
            try {
                const response = await bulkImportGrower(payload);
                if (response.length > 0) {
                    alert('Data imported successfully');
                    onClose();
                } else {
                    alert('An error occurred while importing the data');
                }
            }catch (error) {
                alert('An error occurred while importing the data' + error);
            }

        }).catch((error) => {
            console.error(error);
            alert('An error occurred while reading the file');
        });
    }


    return(
        <Dialog
            sx={{
                ".MuiDialog-paper": {
                    maxWidth: "100% !important",
                    height: "760px !important",
                    background: "#F1F6FF",
                    boxShadow: "0px 4px 6px 3px rgba(0, 0, 0, 0.30)",
                    borderRadius: 20,
                },
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography className="modal-title">{title}</Typography>
                <Button
                    size="small"
                    sx={{top: "5px"}}
                    onClick={onClose}
                >
                    <CrossIcon style={{width: "25px", height: "25px"}}/>
                </Button>
            </Stack>
            <hr style={{opacity: "0.2", width: "90%"}}/>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                    sx={{marginBottom: "10px"}}
                >
                    {
                        showFieldMappings && ( <FieldMappings fieldHeaders={fieldHeaders} fields={fields} setFields={setFields} />)
                    }
                    {
                        !showFieldMappings && (
                            <SelectFile
                                handleFileChange={handleFileChange}
                                selectedFile={selectedFile}
                                importModule={importModule}
                                setImportModule={setImportModule}
                                firstRowAsHeader={firstRowAsHeader}
                                setFirstRowAsHeader={setFirstRowAsHeader}
                                onClose={onClose}
                            />
                        )
                    }

                    <Grid item>
                        <Grid container direction="row" spacing={4} sx={{marginTop: '3%', justifyContent: 'end'}}>
                            <Grid item>
                                <Typography></Typography>

                                <Button
                                    variant="outlined"
                                    className={'footerbtn-variety'}
                                    onClick={() => {
                                        if (!showFieldMappings) {
                                           handleNext();
                                        }else if (showFieldMappings){
                                            handleSave();
                                        }
                                    }}
                                >
                                    {showFieldMappings ?  'Save' : 'Next'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Typography></Typography>

                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{fontWeight: 'bold', borderWidth: '2px', marginRight: '5em'}}
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog>
    )
}