import AuditorTrak from 'components/teamTrak/AuditorTrak';
import SurveyorTrak from 'components/teamTrak/SurveyorTrak'
import Topbar from 'components/Topbar';
import React from 'react'
import { useParams } from 'react-router-dom';

export default function AllEmployees() {
  return (
    <>
        <Topbar/>
        <SurveyorTrak />
    </>
  )
}
