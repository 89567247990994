import axios from "axios";

export const makeRequest = async (url, method, data = null, params = null) => {
    try {
        const config = {
            method,
            url,
            ...(data && { data }),
            ...(params && { params }),
        };

        const response = await axios(config);
        return response?.data;
    } catch (error) {
        throw error;
    }
};
