import React from "react";
import {BeatLoader} from "react-spinners";



export default function Loading({size}) {
    return (
        <div className="loading">
            <BeatLoader
                size={size}
                color={"#123abc"}
                loading={true}
            />
        </div>
    )
}
