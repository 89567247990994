import React, { useState } from "react";
import { tokens } from "assets/Theme";

import { Button, Typography, TextField, useTheme, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { debounce } from "utils/utils";

// import CropTypeModal from './CroptypeModal';

export default function TerritoryTable({
  headerNames,
  rowsData,
  title,
  onSearchChange,
  footer,
  onRowClick = () => {},
  handleAddAction = () => {},
  actionButton,
}) {
  const [searchQuery, setSearchQuery] = useState("");

  // Debounced search handler
  const handleSearchChange = debounce((value) => {
    if (value === "") {
      onSearchChange(value);
    }
    if (value.length > 3) {
      onSearchChange(value);
    }
  }, 500);

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
    handleSearchChange(value);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const headersCsv = headerNames
    .filter((data) => data.header !== "Actions")
    .map((header) => ({
      label: header.header,
      key: header.value,
    }));
  const csvReport = {
    data: rowsData,
    headers: headersCsv,
    filename: `${title}.csv`,
  };
  const exportPDF = () => {
    const doc = new jsPDF();
    const tableColumn = headerNames.map((header) => header.header);
    const tableRows = [];

    rowsData.forEach((row) => {
      const rowData = headerNames.map((header) => row[header.value]);
      tableRows.push(rowData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.text(`${title}`, 14, 15);
    doc.save(`${title}.pdf`);
  };

  return (
    <div
      style={{
        width: "95%",
        position: "relative",
        left: "2%",
        marginTop: 15,
        borderRadius: "12px",
        boxShadow: "4px 9px 16px 0px #00000040",
        background: `${colors.customColor[500]}`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "98%",
        }}
      >
        <TextField
          label="Search here"
          value={searchQuery}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearchChange(searchQuery);
            }
          }}
          variant="outlined"
          size="small"
          sx={{
            position: "relative",
            marginTop: "20px",
            marginBottom: "20px",
            left: "10px",
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: `${colors.customColor[800]} !important`,
              borderRadius: "15px !important",
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: `${colors.customColor[100]} !important`,
            },
            "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "transparent !important",
              },
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: actionButton ? "30%" : "200px",
          }}
        >
          <Typography
            variant="h6"
            color={colors.customColor[100]}
            sx={{ marginTop: "27px", width: "2px" }}
          >
            Export
          </Typography>
          <div style={{ marginTop: "27px", marginLeft: "8px" }}>
            <button
              style={{
                width: "5px !important",
                background: "transparent",
                boxShadow: "none",
                textDecoration: "underline",
                borderColor: "transparent",
                borderRight: `1px solid ${colors.customColor[800]}`,
                color: `${colors.customColor[800]}`,
              }}
            >
              <CSVLink
                {...csvReport}
                style={{
                  textDecoration: "none",
                  color: `${colors.customColor[800]}`,
                }}
              >
                CSV
              </CSVLink>
            </button>
            <button
              style={{
                width: "5px !important",
                background: "transparent",
                boxShadow: "none",
                textDecoration: "underline",
                borderColor: "transparent",
                color: `${colors.customColor[800]}`,
              }}
              onClick={exportPDF}
            >
              PDF
            </button>
          </div>
          {actionButton && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon fontSize="small" />}
              onClick={handleAddAction}
              sx={{
                background: `${colors.customColor[100]}`,
                color: `#fff`,
                height: "40px",
                marginTop: "20px",
                borderRadius: "8px",
                "&:hover": {
                  background: `green`,
                },
              }}
            >
              Add Competitor
            </Button>
          )}
        </div>
      </div>
      <table>
        <thead>
          <tr
            style={{
              color: `${colors.customColor[100]}`,
              height: "30px",
              backgroundColor: "#ECF1FA",
            }}
          >
            {headerNames.map((header, index) => (
              <th
                key={index}
                style={{
                  width: `${100 / headerNames.length}%`,
                  padding: "8px",
                }}
              >
                {header?.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowsData.map((row, rowIndex) => (
            <tr onClick={() => onRowClick(row)} key={rowIndex}>
              {headerNames.map((header, headerIndex) => (
                <td
                  key={headerIndex}
                  style={{
                    width: `${100 / headerNames?.length}vw`,
                    padding: "8px",
                    borderBottom: "0.5px #5A6975 dotted",
                    height: "40px",
                    textAlign: "center",
                  }}
                >
                  {typeof header?.value === "function"
                    ? header?.value(row)
                    : row[header?.value]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {footer}
    </div>
  );
}
