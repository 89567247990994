import { Button, Stack, useTheme, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { bbox } from "@turf/turf";
import axios from "axios";
import Topbar from "components/Topbar";
import L from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  CircleMarker,
  MapContainer,
  ZoomControl,
  Marker,
  Tooltip,
} from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  companyDecrement,
  companyIncrement,
} from "reduxSetup/TerritoryManagementPage";
import { tokens } from "../../assets/Theme";
import { BaseLayerControl } from "../../components/map-utils/BaseLayerControl";
import openPopupAt from "../../components/map-utils/PopupData";
import { ReCenter } from "../../components/map-utils/ReCenter";
import { Search } from "../../components/map-utils/Search";

import SearchComponent from "components/territoryManagement/components/SearchComponent";
import CompanyAddModal from "components/territoryManagement/manageCompany/companyAddModal";
import TerritoryTable from "../../components/territoryManagement//TerritoryTable";
import CompetitorMarker from "Images/competitor.png";
import SelfCompanyMarker from "Images/selfCompany.png";
import { createNotification } from "components/Notifications";

const CompetitorMarkerIcon = new L.Icon({
  iconUrl: CompetitorMarker,
  iconSize: [30, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const SelfCompanyMarkerIcon = new L.Icon({
  iconUrl: SelfCompanyMarker,
  iconSize: [30, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function ManageCompany() {
  const mapRef = useRef();
  const { pageNumber } = useParams();
  const [companyGridData, setCompanyGridData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [next, setNext] = useState();
  const [record, setRecord] = useState();

  const [userLocation, setUserLocation] = useState([30.3753, 69.3451]);
  const map_center = [31.588, 74.3107];
  const currentpage = useSelector((state) => state.company.page);
  const dispatch = useDispatch();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isAddingCompetitor, setIsAddingCompetitor] = useState(false);
  const [initialValue, setInitialValue] = useState(null);
  const [isMapSearchLoading, setIsMapSearchLoading] = useState(false);
  const [searchResultOptions, setSearchResultOptions] = useState([]);
  const [selfCompany, setSelfCompany] = useState(null)
  const [selfCompanyGrid, setSelfCompanyGrid] = useState()

  function handleRowClick(params) {
    let rowId = params.id;
    let data = companyData;
    let geojsonPoint = null;

    for (let i = 0; i < data.length; i++) {
      if (data[i].id === rowId) {
        geojsonPoint = data[i].geometry;
        break;
      }
    }
    try {
      if (geojsonPoint) {
        const coordinates = [
          geojsonPoint.coordinates[1],
          geojsonPoint.coordinates[0],
        ]; // [latitude, longitude]

        // Center the map on the marker
        mapRef.current.setView(coordinates, 13);
      }
    } catch (e) {
      console.log(e);
    }
  }
  const handleSearchChange = async (searchValue) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/competitors/?search=${searchValue}&p=${currentpage}`,
      );

      let gridData = res.data?.results?.map((ft) => {
        return {
          id: ft.id,
          name: ft?.name,
          latitude: ft?.geometry?.coordinates[1] || 0,
          longitude: ft?.geometry?.coordinates[0] || 0,
        };
      });
      if (gridData.length >= 1) {
        setCompanyGridData(gridData);
        setCompanyData(res?.data?.results);
        setNext(res?.data?.next);
        setRecord(res?.data?.count);
      } else {
        alert("Company not available");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const onMapSearch = useCallback(async (searchValue) => {
    try {
      setIsMapSearchLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/competitors/?search=${searchValue}&p=1`,
      );

      if (res && res.data) {
        setSearchResultOptions(res?.data?.results);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsMapSearchLoading(false);
    }
  }, []);

  const onSelectMapSearchValue = (value) => {
    if (value === null) {
      return;
    }
    const isExist =
      companyData.filter((data) => data?.id === value.id).length > 0;
    !isExist && setCompanyData([value, ...companyData]);
    gotoCurrentPoint(value);
  };

  const onSearchByCoordinates = useCallback(async ({ latitude, longitude }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/competitors/coordinates/?latitude=${latitude}&longitude=${longitude}`
      );

      const isExist = companyData.some((bound) => bound?.id === data?.id);
      if (!isExist) {
        setCompanyData((prevCompanyData) => [data, ...prevCompanyData]);
      }
      gotoCurrentPoint(data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const gotoCurrentPoint = (value) => {
    let geojsonPoint = value?.geometry;

    if (geojsonPoint) {
      const coordinates = [
        geojsonPoint.coordinates[1],
        geojsonPoint.coordinates[0],
      ]; // [latitude, longitude]

      // Center the map on the marker
      mapRef.current.setView(coordinates, 13);
    }
  };

  const onPressEdit = (rowData) => {
    if (rowData?.self) {
      setInitialValue(rowData);
      setIsAddModalOpen(true);
      setIsAddingCompetitor(false);
    } else {
      setInitialValue(rowData);
      setIsAddModalOpen(true);
      setIsAddingCompetitor(true);
    }
  };
  const refreshData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/competitors/?p=${currentpage}`,
      );

      let gridData = res.data?.results?.map((ft) => {
        return {
          id: ft.id,
          name: ft?.name,
          latitude: ft?.geometry?.coordinates[1] || 0,
          longitude: ft?.geometry?.coordinates[0] || 0,
        };
      });
      setCompanyGridData(gridData);
      setCompanyData(res?.data?.results);
      setNext(res?.data?.next);
      setRecord(res?.data?.count);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextpage = () => {
    if (next !== null) {
      dispatch(companyIncrement());
    }
  };

  const handlePreviousPage = () => {
    dispatch(companyDecrement());
  };
  useEffect(() => {
    refreshData();
  }, [currentpage]);
  const handleCompetitorAddAction = () => {
    setIsAddModalOpen(true);
    setIsAddingCompetitor(true);
  };
  const handleOnCloseAddModal = () => {
    setIsAddModalOpen(false);
    setIsAddingCompetitor(false);
    setInitialValue(null);
  };

  const getSelfCompanyDetails = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/company/retrieve/`,
      );
      console.log("self company", res.data);
      setSelfCompany(res.data)
      setSelfCompanyGrid({
        id: res?.data?.id,
        name: res?.data?.name,
        latitude: res?.data?.geometry?.coordinates[1] || 0,
        longitude: res?.data?.geometry?.coordinates[0] || 0,
        self: true
      })
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => { getSelfCompanyDetails() }, [])
  const addCompetitor = useCallback(async (data) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/competitors/`,
        {
          name: data?.name || "",
          location: {
            coordinates: [data?.longitude || 0, data?.latitude || 0],
            type: "Point",
          },
        },
      );
      createNotification("success", "Competitors added successfully");
      refreshData();
      handleOnCloseAddModal();
    } catch (error) {
      createNotification("error");
    }
  }, []);

  // function to edit company separate url for competitors and self company
const editCompany =  useCallback(async (data, isAddingCompetitor) => {
  try {
    const res = isAddingCompetitor? await axios.patch(
      `${process.env.REACT_APP_BASEURL}/api/competitors/${data?.id}/`,
      {
        name: data?.name || "",
        location: {
          coordinates: [data?.longitude || 0, data?.latitude || 0],
          type: "Point",
        },
      },
    ):await axios.patch(
      `${process.env.REACT_APP_BASEURL}/api/company/update/`,
      {
        name: data?.name || "",
        location: {
          coordinates: [data?.longitude || 0, data?.latitude || 0],
          type: "Point",
        },
      },
    );
    createNotification("success");
        refreshData();
    handleOnCloseAddModal();
  } catch (error) {
    createNotification("error");
  }
}, [])
  
  // delete competitor
const deleteCompetitor = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BASEURL}/api/competitors/${id}/`,
    );
    createNotification("success", "Competitors deleted successfully");
    refreshData();
    handleOnCloseAddModal();
  } catch (error) {
    createNotification("error");
  }
};
return (
  <div style={{ width: "100%", padding: 5 }}>
    <Topbar page={pageNumber} />
    {isAddModalOpen && (
      <CompanyAddModal
        onClose={handleOnCloseAddModal}
        open={isAddModalOpen}
        isAddingCompetitor={isAddingCompetitor}
        initialValue={initialValue}
        editCompany={editCompany}
        addCompetitor={addCompetitor}
        deleteCompetitor={deleteCompetitor}
      />
    )}
    <Grid
      container
      sx={{
        width: "95%",
        position: "relative",
        left: "2%",
        background: "white",
        boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
        borderRadius: 5,
        padding: "3%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="h3"
          style={{
            color: "#000",
            position: "relative",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Company Management
        </Typography>
      </div>
      <SearchComponent
        searchHandler={(value) => onMapSearch(value)}
        searchResultOptions={searchResultOptions}
        onSelectMapSearchValue={onSelectMapSearchValue}
        isMapSearchLoading={isMapSearchLoading}
        onSearchByCoordinates={onSearchByCoordinates}
      />
      <MapContainer
        center={map_center}
        zoom={15}
        maxZoom={18}
        scrollWheelZoom={true}
        zoomControl={false}
        attributionControl={false}
        style={{ height: "60vh", borderRadius: 5 }}
        ref={mapRef}
      >
        <Search />

        <BaseLayerControl />
        <ZoomControl prepend position="bottomleft" />
        <CircleMarker center={userLocation} />

        <ReCenter
          user_location={userLocation}
          updateLocation={setUserLocation}
        />
        {companyData?.length >= 1 &&
          companyData.map((data) => {
            const coordinates = data.geometry.coordinates;
            return (
              <Marker
                position={[coordinates[1], coordinates[0]]} // Leaflet expects [lat, lng] format
                // eventHandlers={{ click: handlePolygonClick }}
                icon={CompetitorMarkerIcon}
                key={data.id}
              >
                <Tooltip>
                  <Box sx={{}}>
                    <p>Name: {data?.name || ""}</p>
                  </Box>
                </Tooltip>
              </Marker>
            );
          })}
        
              <Marker
                position={selfCompany?.geometry ? [selfCompany?.geometry?.coordinates[1], selfCompany?.geometry?.coordinates[0]]:[0,0]} // Leaflet expects [lat, lng] format
                // eventHandlers={{ click: handlePolygonClick }}
                icon={SelfCompanyMarkerIcon}
              >
                <Tooltip>
                  <Box sx={{}}>
                    <p>Name: {selfCompany?.name || ""}</p>
                  </Box>
                </Tooltip>
              </Marker>
      </MapContainer>
    </Grid>

    <TerritoryTable
      headerNames={getHeaders(onPressEdit,colors)}
      rowsData={selfCompanyGrid ?[selfCompanyGrid,...companyGridData]:companyData}
      title="Company Management"
      actionButtonLabel="Add More"
      onRowClick={handleRowClick}
      onSearchChange={handleSearchChange}
      handleAddAction={handleCompetitorAddAction}
      actionButton={true}
      footer={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              position: "relative",
              marginTop: "2%",
              marginBottom: "2%",
              left: "10px",
              color: `${colors.customColor[800]}`,
            }}
          >{`Page: ${currentpage} - ${Math.ceil(record / 10)}`}</Typography>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginTop: "2%",
              marginBottom: "2%",
              justifyContent: "flex-end",
              position: "relative",
              right: "7%",
            }}
          >
            <Button
              className="footerbtn-variety"
              variant="outlined"
              onClick={handlePreviousPage}
            >
              previous
            </Button>
            <Button
              className="footerbtn-variety"
              variant="outlined"
              disabled={next === null}
              onClick={handleNextpage}
            >
              next
            </Button>
          </Stack>
        </div>
      }
    />
  </div>
);
}
//render table header
const getHeaders = (onPressEdit,colors) => {
  return  [
  { header: "Company Name", value: "name" },
  { header: "Latitude", value: "latitude" },
  { header: "Longitude", value: "longitude" },
  {
    header: "Actions",
    value: (rowData) => (
      <Button
        size="small"
        variant="text"
        sx={{
          fontWeight: "bold",
          textDecoration: "underline",
          color: `${colors.customColor[800]}`,
        }}
        onClick={() => {
          onPressEdit(rowData);
        }}
      >
        Edit
      </Button>
    ),
  },
];

}
export const flipCoords = (coordsList) => {
  return [
    coordsList.map((c) => {
      return [c[1], c[0]];
    }),
  ];
};
export default ManageCompany;
