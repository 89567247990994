import React, { useState } from 'react';
import Select from 'react-select';
import {customSearchableSelectStyles} from "./helpers/css/styles";


const MySelectComponent = ({options, setState, state,compWidth}) => {

    const handleChange = (selectedOption) => {
        setState(selectedOption);
    };

    return (
        <div style={{width: '270px'}}>
            <Select
                styles={customSearchableSelectStyles}
                value={state}
                onChange={handleChange}
                options={options}
                isSearchable={true}
                placeholder="Select an option..."
            />
        </div>
    );
};

export default MySelectComponent;
