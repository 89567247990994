import React,{useEffect,useState} from 'react'
import Topbar from 'components/Topbar';
import CropConditionModal from 'components/cropConditionManagement/CropConditionModal';
import ManagementTable from 'components/tables/ManagementTable';
import { Stack, Button } from '@mui/material';
import { getCropCondition } from 'api/surveySetup/Crop';
import { incrementCropConditionPage,decrementCropConditionPage } from 'reduxSetup/SurveySetupPages';
import { useSelector, useDispatch } from 'react-redux';
export default function CropConditionManagement() {
    const [modalOpen, setModalOpen] = useState(false);
    const [cropCondition, setCropCondition] = useState([])
    const [next, setNext] = useState()
    const dispatch = useDispatch();
    const currentPage = useSelector(state => state.cropCondition.page);
    const headerNames = [{header:'Crop Condition',value:'name'},{header:'Created by',value:'admin_details.name'},{header:'Creation Date',value:'created_at'}];
    const params = {p: currentPage}
    const fetchCondition = async () => {
      try {
      const res = await getCropCondition(params)
      setNext(res?.next)
      const formattedRes = res?.results.map(item => ({
        ...item,
        created_at: new Date(item.created_at).toLocaleString(),
      }));
      setCropCondition(formattedRes);
      console.log(res)
      } catch (error) {
        console.error(error)
      }
    }
    const handleSaveVariety = () => {
      handleCloseModal();
    };
    useEffect(()=>{
      fetchCondition();
    },[currentPage])

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleNextPage = () => {
    dispatch(incrementCropConditionPage());
  };

  const handlePreviousPage = () => {
    dispatch(decrementCropConditionPage());
  };

 
  return (
    <div>
    <Topbar/>
  
    <div style={{width:'60%', marginTop:'10px'}}>
    <ManagementTable
        headerNames={headerNames}
        rowsData={cropCondition}
        title='Crop Condition'
        actionButtonLabel='Add More'
        onActionButtonClick={handleOpenModal}
        ModalComponent={CropConditionModal}
        footer={<Stack direction="row" spacing={2}
        sx={{marginTop: '5%', marginBottom:'3%', justifyContent: 'flex-end', position: 'relative', right: '7%'}}>
     <Button className='footerbtn-variety' variant='outlined' onClick={handlePreviousPage}
            >
         previous
     </Button>
     <Button className='footerbtn-variety' variant='outlined' disabled={next === null} onClick={handleNextPage}
            >
         next
     </Button>
</Stack>} 
      />

      {/* Render the CropConditionModal component conditionally */}
      {modalOpen && (
        <CropConditionModal
          open={modalOpen}
          onClose={handleCloseModal}
          onSaveVariety={handleSaveVariety}
        />
      )}
    </div>
    </div>
  )
}