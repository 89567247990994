import React, {useEffect, useState} from 'react';
import Chart from "react-apexcharts";
import { tokens } from "../assets/Theme";
import { useTheme, ToggleButtonGroup, ToggleButton, Typography } from "@mui/material";

const StackedColumnGraph = ({areaByVariety, areaByCircle}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [alignment, setAlignment] = React.useState('web');
    const [graphName, setGraphName] = useState('Variety');



    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    // Dummy data
    const [categories, setCategories] = useState([...areaByVariety?.varieties || []]);
    const [seriesData, setSeriesData ]= useState([
        ...areaByVariety?.data || []
    ]);

    useEffect(() => {
        if (alignment === 'web') {
            setGraphName('Variety')
            setCategories([...areaByVariety?.varieties || []]);
            setSeriesData([...areaByVariety?.data || []]);
        } else {
            setGraphName('Gate Circle')
            setCategories([...areaByCircle?.circle || []]);
            setSeriesData([...areaByCircle?.data || []]);
        }
    }, [alignment]);

    // Chart options
    const options = {
        chart: {
            type: 'bar',
            height: 300,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '10%',
                borderRadius: 4,
                endingShape: 'rounded',



            },

        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: categories,
            title: {
                text: graphName,
                style: {
                    color: colors.customColor[800],
                },
            },
            labels: {
                style: {
                    colors: colors.customColor[600],
                }
            }
        },
        yaxis: {
            title: {
                text: 'Acres',
                style: {
                    color: colors.customColor[800],
                },
            },
            labels: {
                "formatter": function (val) {
                    return val.toFixed(2)
                },
                style: {
                    colors: ['#000'],
                }
            }
        },
        colors: [colors.greenAccent[400], colors.greenAccent[500], colors.greenAccent[600], colors.greenAccent[700]], // Different colors for each series
        toolbar: null
    };

    return (
        <div id="chart"
             style={{
                 background: `${colors.customColor[500]}`,
                 width: '97%',
                 marginLeft: '2%',
                 borderRadius: '12px',
                 boxShadow: '2px 6px 12px rgba(0, 0, 0, 0.1)',
             }} >
            <div style={{width:'98%',display:'flex', flexDirection:'row', justifyContent:"space-between"}}>
                <Typography variant='h4' fontWeight='bold' sx={{marginLeft:'1%'}}>Crop Stage</Typography>
                <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                    sx={{ position: 'relative', }}
                >
                    <ToggleButton
                        size='small'
                        sx={{
                            borderRadius: '20px',
                            height: '28px',
                            width: '80px',
                            backgroundColor: `${colors.customColor[400]}`,
                            '&.Mui-selected, &.Mui-selected:hover': {
                                color: '#fff',
                                backgroundColor: `${colors.customColor[100]}`,
                                borderRadius: '20px !important',
                                zIndex: '1000 !important',
                                left: '9px !important'
                            }
                        }}
                        value="web"
                    >Variety
                    </ToggleButton>
                    <ToggleButton
                        size='small'
                        sx={{
                            borderRadius: '20px',
                            height: '28px',
                            width: '80px',
                            backgroundColor: `${colors.customColor[400]}`,
                            '&.Mui-selected, &.Mui-selected:hover': {
                                color: '#fff',
                                backgroundColor: `${colors.customColor[100]}`,
                                borderRadius: '20px !important',
                                zIndex: '1000 !important',
                                right: '9px !important'
                            }
                        }}
                        value="android"
                    >Circle</ToggleButton>
                    {/*commented due to lack of functionality*/}
                    {/*<div style={{ padding: '4px', marginLeft: '25px' }} ><FilterSettingIcon /></div >*/}
                </ToggleButtonGroup>
            </div>
            <Chart options={options} series={seriesData} type="bar" height={250} />
        </div>
    );
};

export default StackedColumnGraph;
