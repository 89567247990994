import React, { useState, useEffect, useCallback } from 'react'
import { TextField, Typography, Stack,Select, MenuItem,Autocomplete } from '@mui/material'
import Topbar from 'components/Topbar'
import { Button } from '@mui/material'
import { AddIcon } from 'assets/Icons'
import { useParams, useNavigate } from 'react-router-dom'
import AdditionalFieldModal from 'components/develoPro/AdditionalFieldModal'
import { createTask, getGrowers,getUsers } from 'api/develoPro/Tasks'


 function CreateTask() {
  const [open, setOpen] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const {id} = useParams();
  const [growersList, setGrowersList] = useState([]) 
  const[usersList, setUsersList] = useState([])
  const [additionalFieldsObj, setAdditionalFieldsObj] = useState([])
  const [formData, setFormData] = useState({
    project: id, 
    name: "",
    task_type: "",
    description: "",
    status: "pending",
    additional_fields: {},
    start_date: "",
    end_date: "",
    supervisor: null,
    assignee_ids: [],
    grower_ids: [],
    grower_field_ids: []
  });
  const navigate = useNavigate()


  const handleClose = () =>{
    setOpen(false);
  }
  const handleGrowers = async (searchValue) => {
    try {
      if (searchValue?.length >= 3) {
        const growerParams = {search: searchValue}
        const res = await getGrowers(growerParams) 
        setGrowersList(res)
        console.log(res)
      }
    } catch (error) {
      
    }
  }
  const fetchUser = async() => {
    try {
      const res = await getUsers();
      setUsersList(res);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    const storedFields = JSON.parse(localStorage.getItem('fieldValues')) || [];
    setAdditionalFields(storedFields);
    fetchUser();
  }, []);
  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };
  const handleChangeAdditionalField = (name, value) => {
    const updatedFields = [...additionalFieldsObj];
    const index = updatedFields.findIndex(field => field.name === name);
  
    if (index !== -1) {
      updatedFields[index].value = value;
    } else {
      updatedFields.push({ name, value });
    }
  
    setAdditionalFieldsObj(updatedFields);
  };
  const handleGrowerChange = (event, newValue) => {
    console.log('Handle Grower Change', formData?.grower_ids)

    if (Array.isArray(newValue)) {
      const growerIds = newValue?.map(grower => grower?.id); 
      if (growerIds ){
        handleChange('grower_ids',growerIds);
      }
    }
  };

  // useEffect(() => {
  //   console.log('Grower Ids Changed', formData.grower_ids)
  // }, [formData.grower_ids])


  const handleCreate =async () => {
    try {
      const formDataWithAdditionalFields = {
        ...formData,
        additional_fields: additionalFieldsObj,
      };
      await createTask(formDataWithAdditionalFields)
      navigate(`/developro/projects/id/${id}/page/1`)
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div>
      <Topbar/>
      <div style={{width: '95%', height: '95%', background: '#fff', borderRadius: 20, marginLeft:'2%'}}>
        <Typography variant='h4' sx={{margin:'20px'}}>Create Task</Typography>
        <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row', margin:'10px'}}>
          <div style={{display:'flex', justifyContent:'space-between', flexDirection:'column', margin:'10px', width:'45%'}}>
          <Stack direction='column' spacing={1}>
            <Typography>Task Name</Typography>          
            <TextField fullWidth
             onChange={(e) => handleChange('name', e.target.value)}
            />
          </Stack>
          <Stack direction='column' spacing={1}>
            <Typography>Select Supervisor</Typography>
            <Select
                          sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent !important',
                            },
                            paddingRight:'10%'
                          }}
                          value={formData?.supervisor}
                          size='small'
                          onChange={(e) => handleChange('supervisor',e.target.value)}                        
                          >
                            {usersList?.map((user) =>(
                              <MenuItem key={user?.id} value={user?.id}>
                                {user?.fullname}
                              </MenuItem>
                            ))}
                          </Select>
          </Stack>
          <Stack direction='column' spacing={1}>
            <Typography>Select Task Status</Typography>          
            <Select
                fullWidth
                defaultValue="pending"
                onChange={(e) => handleChange('status', e.target.value)}
              >
                <MenuItem value="completed">Completed</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="in_progress">In Progress</MenuItem>
            </Select>
          </Stack>
          <Stack direction='column' spacing={1}>
            <Typography>Select Grower (Optional)</Typography>          
            <Autocomplete
              multiple
              id="growers"
              options={growersList}
              getOptionLabel={(option) =>String(option?.grower_name + '( ' + option?.passbook_number+ ' )' )}
              onChange={handleGrowerChange}
              onInputChange={(event, newInputValue) => handleGrowers(newInputValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Search and select growers"
                  
                />
              )}
             
            />
          </Stack>
          <Stack direction='row' >
            <Button onClick={() => setOpen(true)}><AddIcon/> </Button> 
            <Button onClick={() => setOpen(true)} sx={{color:'#000'}}>Add more Field</Button>         
         
          </Stack>
          </div>
          <div style={{display:'flex', justifyContent:'space-between', flexDirection:'column', margin:'10px', width:'45%'}}>
          <Stack direction='column' spacing={1} >
            <Typography>Task Type</Typography>          
            <TextField fullWidth
            value={formData?.task_type}
            onChange={(e)=> handleChange('task_type', e.target.value)}
            />
          </Stack>
          <Stack direction='column' spacing={1} sx={{marginTop:'20px'}}>
            <Typography>Select Assignee</Typography>          
            <Select
              sx={{
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent !important',
                },
                '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent !important',
                },
                '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent !important',
                },
              }}
              size='small'
              value={formData?.assignee_ids}
              multiple
              onChange={(e)=> handleChange('assignee_ids', e.target.value)}                        
              >
                {usersList?.map((user) =>(
                  <MenuItem key={user?.id} value={user?.id}>
                    {user?.fullname}
                  </MenuItem>
                ))}
            </Select>
          </Stack>
          <Stack direction='row' spacing={3} sx={{marginTop:'20px'}}>
            <Stack direction='column' spacing={1} sx={{width:'50%'}}>
            <Typography>Start Date</Typography>          
            <TextField fullWidth type='date'
              onChange={(e) => handleChange('start_date', e.target.value)}
            />
            </Stack>
            <Stack direction='column' spacing={1} sx={{width:'50%'}}>
            <Typography>End Date</Typography>          
            <TextField fullWidth type='date'
              onChange={(e) =>handleChange('end_date', e.target.value) }
            />
            </Stack>
          </Stack>
          <Stack direction='column' spacing={1} sx={{marginTop:'20px'}}>
            <Typography>Description</Typography>          
            <TextField fullWidth
            onChange={(e) => handleChange('description', e.target.value)}/>
          </Stack>
          <div  style={{marginTop:'20px', display:'flex', justifyContent:'end'}}>
            <Button sx={{width: '30%', height: '55px', background: '#1BC795', borderRadius: 12, border: '1px #1BC795 solid'}} 
            onClick={handleCreate}>create</Button>
          </div>
          
        </div>
   
        </div>
        {additionalFields.map((field, index) => (
          <div  key={index} spacing={2} style={{ marginLeft: '2%', display:'flex', flexDirection:'row', justifyContent:'space-between' }}>
            <Stack  direction='column' spacing={1} sx={{width:'90%'}}>
              <Typography>{field?.name}</Typography>
              <TextField fullWidth 
               onChange={(e) => handleChangeAdditionalField(field.name, e.target.value)} />
            </Stack>
          </div>
        ))} 

      </div>
   {open && <AdditionalFieldModal open={open} close={handleClose} title='Create Field'/>}
    </div>
  )
}

export default React.memo(CreateTask)