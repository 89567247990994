import {
  CircleMarker,
  MapContainer,
  Polygon,
  ZoomControl,
  LayersControl,
  LayerGroup,
  TileLayer,
  FeatureGroup,
} from "react-leaflet";
import { ReCenter } from "../../map-utils/ReCenter";
import * as React from "react";
import { flipCoords } from "../../../pages/territoryManagement/ManageGateCircles";
import { Box, Button, Grid, TextField, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { EditControl } from "react-leaflet-draw";
import "../../../assets/leaflet-draw.css";
import { Delete } from "../../map-utils/Delete";
import { createNotification } from "../../Notifications";
import { useNavigate, useParams } from "react-router-dom";
import { Search } from "../../map-utils/Search";
import { Stack } from "@mui/system";
import Topbar from "components/Topbar";
import { tokens } from "assets/Theme";
import InputCustom from "../components/InputCustom";
import { useSelector } from "react-redux";

const pageHeight = "86vh";

function CreateDistrict() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const mapRef = useRef();
  const editRef = useRef();
  const navigate = useNavigate();
  const { pageNumber } = useParams();
  const [geojsonData, setGeojsonData] = useState(null);
  const [districtName, setDistrictName] = useState("");
  const boundaryTypeId = useSelector(
    (state) =>
      state?.boundaries?.array.find((data) => data.name === "District")?.id,
  );
  const [userLocation, setUserLocation] = React.useState([30.3753, 69.3451]);
  const map_center = [30.3753, 69.3451];

  const [state, setState] = useState({ leaflet_id: null, field_polygon: null });
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = JSON.parse(event.target.result);
      setGeojsonData(data);
      const field_polygon = data.features[0].geometry.coordinates[0].map(
        (point) => ({
          lng: point[0],
          lat: point[1],
        }),
      );

      setState({ ...state, field_polygon });
    };
    reader.readAsText(file);
  };

  /***************************************Leaflet Draw Functionality*********************************************/
  const onMountedRect = (e) => {
    editRef.current = e;
    // Style Draw Button
    if (document.getElementsByClassName("leaflet-draw-draw-polygon")[0]) {
      let drawBtn = document.getElementsByClassName(
        "leaflet-draw-draw-polygon",
      )[0];
      drawBtn.innerHTML = "+ Draw";
    }
    // Style Edit Button and Hide on Mount
    if (document.getElementsByClassName("leaflet-draw-edit-edit")[0]) {
      let editBtn = document.getElementsByClassName(
        "leaflet-draw-edit-edit",
      )[0];
      editBtn.innerHTML = "Edit";
      editBtn.style.display = "none";
    }
    // If Polygon is Drawn, Hide Draw Button, Show Edit Button
    if (
      document.getElementsByClassName("leaflet-draw-draw-polygon")[0] &&
      state.field_polygon
    ) {
      let drawBtn = document.getElementsByClassName(
        "leaflet-draw-draw-polygon",
      )[0];
      let editBtn = document.getElementsByClassName(
        "leaflet-draw-edit-edit",
      )[0];
      drawBtn.style.pointerEvents = "none";
      drawBtn.style.display = "none";
      editBtn.style.display = "block";
    }
  };
  const deletePolygon = () => {
    try {
      // Remove All Drawn Layers
      editRef.current._toolbars.edit?._modes?.remove.handler.removeAllLayers();
    } catch (e) {
      console.log(e);
    }
    if (document.getElementsByClassName("leaflet-draw-draw-polygon")[0]) {
      let drawBtn = document.getElementsByClassName(
        "leaflet-draw-draw-polygon",
      )[0];
      drawBtn.style.pointerEvents = "all";
      drawBtn.style.display = "block";
    }
    if (document.getElementsByClassName("leaflet-draw-edit-edit")[0]) {
      let editBtn = document.getElementsByClassName(
        "leaflet-draw-edit-edit",
      )[0];
      editBtn.innerHTML = "Edit";
      editBtn.style.display = "none";
    }
  };
  const onDrawStart = () => {
    editRef.current._toolbars.draw._modes?.polygon?.handler.enable();
  };
  const onEditStop = (e) => {
    try {
      if (state.leaflet_id) {
        const coords = e?.sourceTarget._layers[state.leaflet_id]._latlngs[0];
        setState((prevState) => ({
          ...prevState,
          field_polygon: coords,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleSubmit = async (event) => {
    let coordiantes = state.field_polygon;
    coordiantes.push(coordiantes[0]);
    let coordinate_pair = coordiantes.map((coord) => {
      return [coord.lng, coord.lat];
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/gates/`,
        {
          name: districtName,
          polygon: {
            type: "Feature",
            geometry: { type: "Polygon", coordinates: [coordinate_pair] },
          },
          type: boundaryTypeId,
          parent: null,
        },
      );

      if (res.status === 201) {
        let gc_id = res?.data?.gate_cricle;
        if (gc_id) {
          createNotification("success");
          navigate(`/territory-management/district-management`);
        } else {
          createNotification("error");
        }
      }

      //area calculation
      // calculateArea(coordinate_pair);
    } catch (error) {
      if (error?.response?.status === 400) {
        alert("Invalid Username or Password");
      } else if (error?.response?.data) {
        alert(JSON.stringify(error?.response?.data));
      } else alert(error);
    }
  };

  const onShapeDrawn = (e) => {
    /* Enable Editing On Click of Polygon */
    e.layer.on("click", () => {
      editRef.current._toolbars.edit._modes?.edit?.handler.enable();
    });
    /* Save Polygon */
    try {
      let coords = e?.layer?._latlngs[0];
      let leafletId = e?.layer._leaflet_id;
      setState((prevState) => ({
        ...prevState,
        field_polygon: coords,
        leaflet_id: leafletId,
      }));
    } catch (e) {
      console.log(e);
    }
  };

  /***************************************Leaflet Draw Functionality*********************************************/
  return (
    <>
      <Topbar page={pageNumber} />

      <Grid
        container
        direction="column"
        sx={{
          background: "white",
          boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
          borderRadius: 5,
          padding: "3%",
          width: "95%",
          position: "relative",
          left: "2%",
        }}
      >
        <Grid container>
          <Grid item>
            <Typography
              variant="h3"
              style={{
                color: "#000",
                fontWeight: "bold",
                position: "relative",
                left: "10px",
              }}
            >
              Add District
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Box
            // onSubmit={handleSubmit}

            sx={{
              mt: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingLeft: 1,
            }}
          >
            <InputCustom
              margin="normal"
              required
              id="name"
              value={districtName}
              label="District Name"
              onChange={(e) => setDistrictName(e.target.value)}
              variant="outlined"
              name="name"
            />

            <Stack direction="row">
              <input
                type="file"
                accept=".geojson"
                onChange={handleFileChange}
                id="upload-file"
                style={{ display: "none" }}
              />
              <label htmlFor="upload-file">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    background: "#1BC693",
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    borderRadius: 3,
                    overflow: "hidden",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    display: "inline-flex",
                    marginTop: "20px",
                    minHeight: "52px",
                  }}
                >
                  Upload Boundary
                </Button>
              </label>
            </Stack>
          </Box>
        </Grid>
        <Grid item>
          <MapContainer
            center={map_center}
            //   zoom={7}
            maxZoom={18}
            scrollWheelZoom={true}
            zoomControl={false}
            attributionControl={false}
            style={{ height: "400px", borderRadius: "20px" }}
            ref={mapRef}
          >
            <Search />
            <ZoomControl prepend position="bottomleft" />
            <CircleMarker center={userLocation} />
            <ReCenter
              user_location={userLocation}
              updateLocation={setUserLocation}
            />
            <Delete deletePolygon={deletePolygon} />
            {geojsonData &&
              geojsonData?.features?.length >= 1 &&
              geojsonData?.features?.map((data) => {
                let coordinates = flipCoords(data.geometry.coordinates[0]);
                return (
                  <Polygon
                    positions={coordinates}
                    id={data}
                    key={data.id}
                  ></Polygon>
                );
              })}
            <LayersControl position="bottomright">
              <LayersControl.BaseLayer checked name="Satellite View">
                <TileLayer
                  maxZoom={22}
                  maxNativeZoom={19}
                  url={`https://api.mapbox.com/styles/v1/kumail-09/clc0a4s9k000b14lmukbjuxvu/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                />
              </LayersControl.BaseLayer>
            </LayersControl>
            <FeatureGroup>
              <EditControl
                onMounted={onMountedRect}
                position="topright"
                onCreated={onShapeDrawn}
                onDrawStart={onDrawStart}
                onEditStop={onEditStop}
                // edit={{ remove:false, edit:false }}
                draw={{
                  rectangle: false,
                  circle: false,
                  polyline: false,
                  circlemarker: false,
                  marker: false,
                  polygon: {
                    allowIntersection: false,
                    shapeOptions: { color: "#15ff00" },
                  },
                }}
              />
            </FeatureGroup>
          </MapContainer>
        </Grid>
        <Box
          sx={{
            display: "flex",

            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{
              mt: 3,
              marginRight: 5,
              width: 90,
              height: "5vh",
              border: "2px solid #ED05059E",
            }}
            onClick={() =>
              navigate("/territory-management/district-management")
            }
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleSubmit}
            variant="outlined"
            sx={{
              mt: 3,
              color: "#0275C6",
              border: "2px solid #0275C6",
              width: 90,
              height: "5vh",
            }}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default CreateDistrict;
