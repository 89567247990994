

export const deleteObjectById = (dataArray, idToDelete) => {
    const newArray = dataArray.filter(item => item.id !== idToDelete);
    return newArray;
};

// update object in array by id
export const updateObjectById = (dataArray, idToUpdate, updatedObject) => {
    const newArray = dataArray.map(item => {
        if (item.id === idToUpdate) {
            return updatedObject;
        }
        return item;
    });
    return newArray;
};

//function to update object in array by id and data to be added
export const updateObjectByIdWithNewData = (dataArray, idToUpdate, newData) => {
    const newArray = dataArray.map(item => {
        if (item.id === idToUpdate) {
            return { ...item, ...newData };
        }
        return item;
    });
    return newArray;
};


