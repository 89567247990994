import React, {useEffect, useState} from 'react';
import Chart from "react-apexcharts";
import { tokens } from "../assets/Theme";
import {useTheme, ToggleButtonGroup, ToggleButton, Typography, MenuItem, Menu} from "@mui/material";
import { FilterSettingIcon } from 'assets/Icons';

const ColumnGraph = ({cropAreaByVariety, cropAreaByCircle}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [alignment, setAlignment] = React.useState('web');
    const [byVarietyVarieties, setByVarietyVarieties] = React.useState(Object.keys(cropAreaByVariety) || []);
    const [byCircleVarieties, setByCircleVarieties] = React.useState(Object.keys(cropAreaByCircle) || []);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedVariety, setSelectedVariety] = useState(byVarietyVarieties[0] || '');
    const [categories, setCategories] = useState(selectedVariety !== '' ? [...cropAreaByVariety[selectedVariety].varieties || []] : []);
    const [seriesData, setSeriesData] = useState(selectedVariety !== '' ? [...cropAreaByVariety[selectedVariety].total_area || []] : []);
    const [graphName, setGraphName] = useState('Variety');

    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };

    useEffect(() => {
        if (selectedVariety) {
            if (alignment === 'web') {
                setSeriesData([...cropAreaByVariety[selectedVariety].total_area || []]);
                setCategories([...cropAreaByVariety[selectedVariety].varieties || []]);
                setGraphName('Variety');
            } else if(alignment === 'android') {
                setSeriesData([...cropAreaByCircle[selectedVariety].total_area || []]);
                setCategories([...cropAreaByCircle[selectedVariety].circles || []]);
                setGraphName('Gate Circle');
            }
        }
    }, [selectedVariety, alignment]);
  
    // Chart options
    const options = {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '10%',
          borderRadius: 4,
          endingShape:'rounded',
      
        },
        
      },
      dataLabels: {
        enabled: false,
        // style: {
        //   colors: colors.customColor[100], 
        //     }
          
      },
      xaxis: {
        categories: categories,
        title: {
            text: graphName,
            style: {
              color: colors.customColor[800],
            },
          },
        labels: {
            
          style: {
            colors: colors.customColor[600], 
          }
        }
      },
      yaxis: {
        title: {
            text: 'Acres',
            style: {
              color: colors.customColor[800],
            },
          },
        labels: {
          style: {
            colors: ['#000'], 
          },
          "formatter": function (val) {
            return val.toFixed(2)
          }
        }
      },
      colors: colors.customColor[100],
      toolbar: null 
    };
  
    // Chart series data
    const series = [{
      name:'Area',
      data: seriesData ,
    }];
  
    return (
      <div id="chart" 
      style={{
        background:`${colors.customColor[500]}`, 
        width:'98%', 
        marginLeft:'2%', 
        borderRadius:'12px',
        boxShadow: '2px 6px 12px rgba(0, 0, 0, 0.1)', 
        }} >
        <div style={{width:'98%',display:'flex', flexDirection:'row', justifyContent:"space-between"}}>
          <Typography variant='h4' fontWeight='bold' sx={{marginLeft:'1%'}}>Cane Survey</Typography>
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          sx={{ position: 'relative', }}
        >
          <ToggleButton
            size='small'
            sx={{
              borderRadius: '20px',
              height: '28px',
              width: '80px',
              backgroundColor: `${colors.customColor[400]}`,
              '&.Mui-selected, &.Mui-selected:hover': {
                color: '#fff',
                backgroundColor: `${colors.customColor[100]}`,
                borderRadius: '20px !important',
                zIndex: '1000 !important',
                left: '9px !important'
              }
            }}
            value="web"
          >Variety
          </ToggleButton>
          <ToggleButton
            size='small'
            sx={{
              borderRadius: '20px',
              height: '28px',
              width: '80px',
              backgroundColor: `${colors.customColor[400]}`,
              '&.Mui-selected, &.Mui-selected:hover': {
                color: '#fff',
                backgroundColor: `${colors.customColor[100]}`,
                borderRadius: '20px !important',
                zIndex: '1000 !important',
                right: '9px !important'
              }
            }}
            value="android"
          >Circle</ToggleButton>
          <div
              style={{ padding: '4px', marginLeft: '25px' }}
              onClick={(event) => setAnchorEl(event.currentTarget)}>
              <FilterSettingIcon />
          </div >
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {alignment === 'web' ? (
                    byVarietyVarieties.map((variety) => (
                        <MenuItem key={variety} onClick={() => setSelectedVariety(variety)}>
                            {variety}
                        </MenuItem>
                    ))
                ) : (
                    byCircleVarieties.map((variety) => (
                        <MenuItem key={variety} onClick={() => setSelectedVariety(variety)}>
                            {variety}
                        </MenuItem>
                    ))
                )}
            </Menu>
        </ToggleButtonGroup>
        </div>
        <Chart options={options} series={series} type="bar" height={300}  />
      </div>
    );
  };

export default ColumnGraph;
