import axios from "axios";
const BASEURL = process.env.REACT_APP_BASEURL;
// const MOCKURL = process.env.REACT_APP_MOCKURL;


// function to get heaps
export const getHeaps = async () => {
    try {
        const response = await axios.get(`${BASEURL}/api/heap/`);
        console.log('Heaps response', response);
        return response?.data;
    } catch (error) {
        console.error('Server error getting Heaps \n',error);
        return false;
    }
}


//function to create heap
export const createHeap = async (data) => {
    try {
        if (data){
            const response = await axios.post(`${BASEURL}/api/heap/`, data);
            return response?.data;
        }
        const response = await axios.post(`${BASEURL}/api/heap/`);
        return response?.data;

    } catch (error) {
        console.log('Server Error creating Heap \n', error);
        return false;
    }
}

//function to update heap
export const updateHeap = async (data, heapId) => {
    try {
        if (data){
            const response = await axios.patch(`${BASEURL}/api/heap/${heapId}/`, data);
            return response?.data;
        }
        throw new Error('No data provided to update Heap');

    } catch (error) {
        console.error('Server Error updating Heap \n', error);
        return false;
    }
}

//function for bulk-heap update
export const bulkUpdateHeap = async (data) => {
    try {
        if (data){
            const response = await axios.patch(`${BASEURL}/api/heap/bulk/`, data);
            if (response?.status === 200){
                return response?.data;
            }else {
                console.error('Server Error updating Heap \n', response);
                return false;
            }
        }
            throw new Error('No data provided to update Heap');
    } catch (error) {
        console.error('Server Error updating Heap \n', error);
        return false;
    }
}
