import React, {useEffect, useState, useTransition} from "react";
import Button from "@mui/material/Button";
import {QrCode} from "@mui/icons-material";
import {Grid, MenuItem, Select} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import SimpleBackdrop from "../../Backdrop";
import {generateBaleQR} from "../../../api/ginnerTraceability/bale";
import {updateObjectByIdWithNewData} from "../helpers/js/ginnerHelper";
import {CustomMenuProps} from "../helpers/css/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {useBales} from "../../AppStates/Bales";
import Loader from "../../Loader";


const BaleModal= ({ bale, isOpen, handleClose }) => {
    return(
        <div>
            <Modal
                open={isOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 900,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'scroll',
                    }}
                >
                    <Paper elevation={3} style={{ padding: "16px", background:"transparent" }} justifyContent="center"

                    >
                        <Box display="flex" justifyContent="center">

                            <img
                                src= {bale?.qr?.imageURL}
                                alt="Image"
                                style={{ maxWidth: "30%", height: "30%" }}
                            />
                        </Box>
                    </Paper>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}
export default function BaleTab(){
    const {bales, setBales, loading: baleLoading, error: baleError} = useBales();
    const [balePageSize, setBalePageSize] = useState(10);
    const [balePageNumber, setBalePageNumber] = useState(1);
    const [baleRecord, setBaleRecord] = useState(bales.length);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [balesToView, setBalesToView] = useState(bales.length ? bales.slice(0, balePageSize) : []);
    const [BaleModalIsOpen, setBaleModalIsOpen] = useState(false);
    const [baleToModal, setBaleToModal] = useState({});
    const [isPending, startTransition] = useTransition();
    useEffect(() => {
        startTransition(() => {
            setBalesToView(bales.slice(0, balePageSize));
            setBaleRecord(bales.length);
        });
    }, [bales, balePageSize, startTransition]);

    const handleBaleNextpage = () => {
        const totalPages = Math.ceil(baleRecord / balePageSize);
        if (balePageNumber < totalPages) {
            setBalePageNumber((prevPage) => prevPage + 1);
            setBalesToView(bales.slice(balePageNumber * balePageSize, (balePageNumber + 1) * balePageSize));
        }
    };

    const handleBalePreviousPage = () => {
        if (balePageNumber > 1) {
            setBalePageNumber((prevPage) => prevPage - 1);
            setBalesToView(bales.slice((balePageNumber - 2) * balePageSize, (balePageNumber - 1) * balePageSize));
        }
    };

    const handleBalePageChange = (event) => {
        setBalePageNumber(event.target.value);
        setBalesToView(bales.slice((event.target.value - 1) * balePageSize, event.target.value * balePageSize));
    };

    const balePageOptions = Array.from(
        { length: Math.ceil(baleRecord / balePageSize) },
        (_, index) => index + 1
    ).map((page) => (
        <MenuItem key={page} value={page}>
            {page}
        </MenuItem>
    ));
    const handleBaleRowClick = (params, event) => {
        if (event?.target?.attributes[2]?.nodeValue !== 'action' && event?.target?.attributes[2]?.nodeValue !== 'button'){
            const bale = bales.find(bale => bale.id === params?.row?.id);
            if (bale?.qr){
                setBaleToModal(bale);
                setBaleModalIsOpen(true);
                return;
            }
            setShowBackdrop(true);
            generateBaleQR(bale?.id).then(response => {
                if (response?.imageURL){
                    const updatedBales = updateObjectByIdWithNewData(bales, bale?.id, {qr: response});
                    setBales(updatedBales);
                    setBaleToModal({...bale, qr: response});
                    setShowBackdrop(false);
                    setBaleModalIsOpen(true);
                }else {
                    setShowBackdrop(false);
                    alert('Something went wrong, please try again later')
                }
            }).catch(error => {
                setShowBackdrop(false);
                alert('Something went wrong, please try again later')
            })
        }


    };

    const handleQR = async (action, params) => {
        const bale = bales.find(bale => bale.id === params?.row?.id);
        if (bale?.qr){
            window.open(bale?.qr?.downloadLink, '_blank');
            return;
        }
        setShowBackdrop(true);
        const response = await generateBaleQR(bale?.id);
        if (response?.imageURL){
            window.open(response?.downloadLink, '_blank');
           const updatedBales = updateObjectByIdWithNewData(bales, bale.id, {qr: response});
           setBales(updatedBales);
           setShowBackdrop(false);
        }else {
            setShowBackdrop(false);
            alert('Something went wrong, please try again later')
        }
    };

    const baleColumns = [
        {
            field: 'id', headerName: 'Bale Number', width: 420,
        },
        {
            field: 'created_at', headerName: 'Created At', width: 420,
        },
        {
            field: 'action', headerName: 'Action', width: 420,
            renderCell: (params) => {
                const actions = params?.row?.action || [];
                return (
                    <div>
                        {actions.map((action, index) => (
                            <Button
                                key={index}
                                variant="outlined"
                                color={action === 'delete' ? 'error' : 'success'}
                                style={{ marginRight: "10px" }}
                                onClick={() => handleQR(action, params)}
                            >
                                <QrCode sx={{ mr: 1 }} fontSize={'small'} />
                                {action}
                            </Button>
                        ))}
                    </div>
                );
            }
        }
    ];

    const handleBaleModalClose = () => {
        setBaleModalIsOpen(false);
    };

    return (
        <div style={{height: 600, width: '100%'}}>
            {
                baleLoading && (
                   <Loader />
                )
            }
            {
                baleError && (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <p>Something went wrong, please try again later</p>
                    </div>
                )
            }
            {
                !baleLoading && (
                    <Grid container>
                        <Grid item xs={12} style={{flex: '1', position: 'relative'}}>
                            <DataGrid
                                sx={{minHeight: '55vh'}}
                                rows={balesToView}
                                columns={baleColumns}
                                pageSize={balePageSize}
                                onPageSizeChange={(newPageSize) => setBalePageSize(newPageSize)}
                                onRowClick={handleBaleRowClick}
                                components={{
                                    Footer: () => (
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            padding: '8px'
                                        }}>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={handleBalePreviousPage}
                                                style={{ marginRight: "8px" }}
                                                disabled={balePageNumber === 1}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="info"
                                                onClick={handleBaleNextpage}
                                                style={{marginRight: "8px"}}
                                                disabled={balePageNumber === Math.ceil(bales.length / balePageSize)}
                                            >
                                                Next
                                            </Button>
                                            <p style={{marginRight: "8px"}}>Total Bales:{baleRecord}</p>
                                            <p style={{margin: '10px'}}>Items: {balesToView.length}</p>
                                            <p>Page:
                                                <Select value={balePageNumber} onChange={handleBalePageChange}
                                                        MenuProps={CustomMenuProps} style={{marginRight: "8px",}}>
                                                    {balePageOptions}
                                                </Select>
                                            </p>
                                        </div>
                                    ),
                                }}
                            />
                            {
                                BaleModalIsOpen && (
                                    <BaleModal  bale={baleToModal} isOpen={BaleModalIsOpen} handleClose={handleBaleModalClose} />
                                )
                            }
                            {
                                showBackdrop && (
                                    <SimpleBackdrop open={showBackdrop} setOpen={setShowBackdrop}/>
                                )
                            }
                        </Grid>
                    </Grid>
                )
            }
        </div>
    )
}