import { useTheme } from "@mui/material";
import { bbox } from "@turf/turf";
import { tokens } from "assets/Theme";
import axios from "axios";
import BoundaryDetailPopup from "components/territoryManagement/components/BoundaryDetailPopup";
import FieldDetailPopup from "components/territoryManagement/components/FieldDetailPopup";
import L from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import moment from "moment";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { setBoundaryTypes } from "reduxSetup/TerritoryManagementPage";
function useIndexLogic() {
  const mapRef = useRef();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { pageNumber } = useParams();
  const dispatch = useDispatch();
  const [userLocation, setUserLocation] = useState([30.3753, 69.3451]);
  const map_center = [31.588, 74.3107];
  const [boundaryIds, setBoundaryIds] = useState(null);
  const [allBoundaries, setAllBoundaries] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [filters, setFilters] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [gateOptions, setGateOptions] = useState([]);
  const [varietiesOptions, setVarietiesOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [isMapSearchLoading, setIsMapSearchLoading] = React.useState(false);
  const [searchResultOptions, setSearchResultOptions] = React.useState([]);
  const cancelTokenRef = useRef(null);

  //On Apply Filters
  const onApplyFilters = async () => {
    try {
      const date = {
        created_at__lte: filters?.created_at__lte
          ? moment(filters?.created_at__lte).format("DD-MM-YYYY")
          : null,
        created_at__gte: filters?.created_at__gte
          ? moment(filters?.created_at__gte).format("DD-MM-YYYY")
          : null,
      };
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/fields/?p=1&page_size=50`,
        {
          params: {
            ...filters,
            ...date,
          },
        },
      );

      if (res && res.data) {
        setAllFields(res?.data?.results?.features);
        let geojsonPoly =
          res?.data?.results?.features.length > 0
            ? res?.data?.results?.features[0]?.geometry
            : null;

        if (geojsonPoly) {
          let coordinates = {
            coordinates: [
              geojsonPoly.coordinates[0].map((c) => {
                return [c[1], c[0]];
              }),
            ],
          };
          geojsonPoly = { ...geojsonPoly, ...coordinates };

          const bboxArray = bbox(geojsonPoly);

          let bounds = L.latLngBounds(
            L.latLng([bboxArray[0], bboxArray[1]]),
            L.latLng([bboxArray[2], bboxArray[3]]),
          );
          mapRef.current.fitBounds(bounds, true);
        }
        setIsFilterApplied(true);
      }
    } catch (error) {}
  };
  const getBoundaryTypes = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/boundary-type/`,
      );

      setBoundaryIds({
        districtId: data?.find((data) => data.name === "District")?.id,
        villageId: data?.find((data) => data.name === "Village")?.id,
        dehId: data?.find((data) => data.name === "Mauza")?.id,
        gateId: data?.find((data) => data.name === "Gate Circle")?.id,
      });
      getGateCircles(data?.find((data) => data.name === "Gate Circle")?.id);
      dispatch(setBoundaryTypes(data));
      getAllBoundaries();
    } catch (error) {
      console.log("error", error);
    }
  };
  // const getAllFields = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       `${
  //         process.env.REACT_APP_BASEURL
  //       }/api/fields/?p=1&page_size=20&created_at__gte=${moment()
  //         .subtract(2, "day")
  //         .format("DD-MM-YYYY")}&created_at__lte=${moment().format(
  //         "DD-MM-YYYY",
  //       )}`,
  //     );

  //     setAllFields((pre) => [...pre, ...data.results.features]);
  //     let p = 2;
  //     let nextPage = data?.next;
  //     while (nextPage !== null) {
  //       const res = await axios.get(
  //         `${
  //           process.env.REACT_APP_BASEURL
  //         }/api/fields/?p=${p}&page_size=20&created_at__gte=${moment()
  //           .subtract(2, "day")
  //           .format("DD-MM-YYYY")}&created_at__lte=${moment().format(
  //           "DD-MM-YYYY",
  //         )}`,
  //       );
  //       setAllFields((pre) => [...pre, ...res.data.results.features]);
  //       p++;
  //       nextPage = res.data.next;
  //     }
  //   } catch (error) {}
  // };
  const getAllBoundaries = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/gates/`,
      );

      if (res && res.data) {
        setAllBoundaries(res?.data?.results?.features);
        let nextPage = res.data.next;
        let p = 2;
        while (nextPage !== null) {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BASEURL}/api/gates/?p=${p}`,
          );
          setAllBoundaries((pre) => [...pre, ...data?.results?.features]);
          nextPage = data.next;
          p++;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleBoundaryPolygonClick = (event) => {
    event.target.setStyle({ color: "green" });

    let polygonData = {
      data: event?.target?.options?.id,
      coords: event?.target?._bounds,
    };

    BoundaryDetailPopup(
      mapRef.current,
      getCenter(polygonData.coords),
      polygonData.data.properties,
      polygonData.data.id,
      navigate,
      polygonData.data.grower,
    );
  };
  useEffect(() => {
    getBoundaryTypes();
    // getAllFields();
  }, []);
  //onReset Filters
  const resetFilters = () => {
    setAllFields([]);
    setFilters(null);
    setIsFilterApplied(false);
  };

  const getGateCircles = async (gateCircleId) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/gates/?type=${gateCircleId}`,
      );

      setGateOptions((pre) => [
        ...pre,
        ...data.results.features.map((feature) => ({
          value: feature.id,
          label: feature.properties?.name,
        })),
      ]);
      let p = 2;
      let nextPage = data?.next;
      while (nextPage !== null) {
        const res = await axios.get(
          `${process.env.REACT_APP_BASEURL}/api/gates/?type=${gateCircleId}&p=${p}`,
        );
        setGateOptions((pre) => [
          ...pre,
          ...res.data.results.features.map((feature) => ({
            value: feature.id,
            label: feature.properties?.name,
          })),
        ]);
        p++;
        nextPage = res.data.next;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getVarieties = async () => {
    try {
      let nextPage = null;
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/variety/?p=1`,
      );
      setVarietiesOptions((pre) => [
        ...pre,
        ...data.map((variety) => ({
          value: variety.id,
          label: variety?.name,
        })),
      ]);
      // let p = 2;
      // nextPage = data?.next;
      // while (nextPage !== null) {
      //   const res = await axios.get(
      //     `${process.env.REACT_APP_BASEURL}/api/variety/?p=${p}`,
      //   );
      //   setVarietiesOptions((pre) => [
      //     ...pre,
      //     ...res.data.map((variety) => ({
      //       value: variety.id,
      //       label: variety?.name,
      //     })),
      //   ]);

      //   p++;
      //   nextPage = res.data.next;
      // }
    } catch (error) {
      console.log(error);
    }
  };
  const getUsers = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/users/?p=1`,
      );
      setUserOptions((pre) => [
        ...pre,
        ...data?.results.map((user) => ({
          value: user.id,
          label: user?.username,
        })),
      ]);
      let p = 2;
      let nextPage = data?.next;
      while (nextPage !== null) {
        const res = await axios.get(
          `${process.env.REACT_APP_BASEURL}/api/users/?p=${p}`,
        );
        setUserOptions((pre) => [
          ...pre,
          ...res.data?.results.map((user) => ({
            value: user.id,
            label: user?.username,
          })),
        ]);

        p++;
        nextPage = res.data.next;
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUsers();
    getVarieties();
  }, []);

  //when user invoke search on map
  const onMapSearch = async (search = "") => {
    try {
      // Cancel the previous request if it exists
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel("Operation canceled due to new request.");
      }

      // Create a new CancelToken
      cancelTokenRef.current = axios.CancelToken.source();

      setIsMapSearchLoading(true);

      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/fields/?p=1&page_size=10&search=${search}`,
        {
          cancelToken: cancelTokenRef.current.token,
        },
      );

      if (res && res.data) {
        setSearchResultOptions(res?.data?.results?.features);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("An error occurred:", error);
      }
    } finally {
      setIsMapSearchLoading(false);
    }
  };
  const onSelectMapSearchValue = (value) => {
    if (value === null) {
      return;
    }
    setAllFields([value]);
    let geojsonPoly = value?.geometry;
    let coordinates = {
      coordinates: [
        geojsonPoly.coordinates[0].map((c) => {
          return [c[1], c[0]];
        }),
      ],
    };
    geojsonPoly = { ...geojsonPoly, ...coordinates };

    if (geojsonPoly) {
      const bboxArray = bbox(geojsonPoly);
      let bounds = L.latLngBounds(
        L.latLng([bboxArray[0], bboxArray[1]]),
        L.latLng([bboxArray[2], bboxArray[3]]),
      );
      mapRef.current.fitBounds(bounds, true);
      let polygonData = {
        data: value,
        coords: bounds,
      };

      FieldDetailPopup(
        mapRef.current,
        getCenter(polygonData.coords),
        polygonData.data.properties,
        polygonData.data.id,
        navigate,
        polygonData.data.grower,
      );
    }
  };

  const handlePolygonClick = (event) => {
    event.target.setStyle({ color: "green" });

    let polygonData = {
      data: event?.target?.options?.id,
      coords: event?.target?._bounds,
    };

    FieldDetailPopup(
      mapRef.current,
      getCenter(polygonData.coords),
      polygonData.data.properties,
      polygonData.data.id,
      navigate,
      polygonData.data.grower,
    );
  };
  const getCenter = (coords) => {
    const bounds = L.latLngBounds(coords);
    return bounds.getCenter();
  };
  //search fields by coordinates
  const onSearchByCoordinates = async ({ latitude, longitude }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/fields/nearest_fields/?latitude=${latitude}&longitude=${longitude}&return_count=1`,
      );

      setAllFields(data?.features);
      let geojsonPoly = data?.features[0]?.geometry;
      let coordinates = {
        coordinates: [
          geojsonPoly.coordinates[0].map((c) => {
            return [c[1], c[0]];
          }),
        ],
      };
      geojsonPoly = { ...geojsonPoly, ...coordinates };

      if (geojsonPoly) {
        const bboxArray = bbox(geojsonPoly);
        let bounds = L.latLngBounds(
          L.latLng([bboxArray[0], bboxArray[1]]),
          L.latLng([bboxArray[2], bboxArray[3]]),
        );
        mapRef.current.fitBounds(bounds, true);
        let polygonData = {
          data: data?.features[0],
          coords: bounds,
        };

        FieldDetailPopup(
          mapRef.current,
          getCenter(polygonData.coords),
          polygonData.data.properties,
          polygonData.data.id,
          navigate,
          polygonData.data.grower,
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  return {
    mapRef,
    userLocation,
    map_center,
    colors,
    pageNumber,
    boundaryIds,
    allBoundaries,
    allFields,
    filters,
    isFilterApplied,
    gateOptions,
    varietiesOptions,
    userOptions,
    isMapSearchLoading,
    searchResultOptions,
    setFilters,
    setUserLocation,
    onApplyFilters,
    resetFilters,
    onMapSearch,
    onSelectMapSearchValue,
    handleBoundaryPolygonClick,
    handlePolygonClick,
    onSearchByCoordinates,
  };
}

export default useIndexLogic;
