import {useMap} from "react-leaflet";
import {useEffect,
    // useState
} from "react";
import {GoogleProvider, GeoSearchControl} from 'leaflet-geosearch';
import Control from 'react-leaflet-custom-control'
import {Button} from "@mui/material";
import styles from "../../assets/styles.module.css";
import SearchIcon from '@mui/icons-material/Search';

// const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const GOOGLE_TOKEN = process.env.REACT_APP_GOOGLE_GEOCODING_TOKEN;



export function Search() {
    let barShown = false;
    const map = useMap();
    const showBar = () => {
        if (!barShown){
            document.getElementsByClassName('leaflet-geosearch-bar')[1].style.display = 'block';
            barShown = true;
        }else {
            document.getElementsByClassName('leaflet-geosearch-bar')[1].style.display = 'none';
            barShown = false;
        }
    }
    useEffect(() => {
        const search = new GeoSearchControl({
            provider: new GoogleProvider({apiKey: GOOGLE_TOKEN}),
            // position: 'topright',,
            style: 'bar',
            notFoundMessage: 'Error! Address Not Found. Change Spellings to try again.'
        });
        map.addControl(search);
        try {
            document.getElementsByClassName('leaflet-geosearch-bar')[1].style.display = 'none';
        } catch (e) {
        }

        return () => {
            map.removeControl(search);
        }

    }, [])

    return (
      <Control position={"bottomleft"}>
        <Button
          variant={"contained"}
          className={styles["btn"]}
          onClick={showBar}
        >
          <SearchIcon />
        </Button>
      </Control>
    );
}
