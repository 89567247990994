import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import React from "react";


export default function Btn({ rowData, route, func, title }) {
    const navigate = useNavigate();
    return (
        <Button
            size="small"
            variant="text"
            sx={{ fontWeight: "bold", textDecoration: "underline", color: `#0375C5` }}
            onClick={() => route ? navigate(`${route + rowData.id}`) : func(false, rowData, rowData.action) }
        >
            {title}
        </Button>
    );
}