import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Stack, FormControl, Checkbox, FormGroup, FormControlLabel, Typography } from '@mui/material';
import { tokens } from '../../assets/Theme';
import { useTheme } from '@mui/material';
import ConfirmationPopup from './ConfirmationPopup';
import { CreateVariety, deleteVariety, updateVariety } from 'api/surveySetup/Variety';

const VarietyModal = ({ open, onClose, onSaveVariety, editData=null }) => {
  const storedCompanyInfo = JSON.parse(localStorage.getItem('companyInfo'));
  const [variety, setVariety] = useState({ 
    name: '',
    type: '',
    variety_stage: '',
    admin:storedCompanyInfo?.id
 });
 console.log("ed", editData);
  const [confirmOpen, setConfirmOpen] = useState(false); 
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

 useEffect(() =>{
  if (editData !== null){
    setVariety({
      name: editData?.name || '',
      type: editData?.type ||'',
      variety_stage: editData?.variety_stage ||'',
      admin: editData?.admin || storedCompanyInfo?.id
  })
  }
 },[editData])
 const handleCheckboxChange = (type) => {
  setVariety((prev) => ({
    ...prev,
    type: type,
  }));
};
const saveVariety = async() => {
  try {
    if (editData === null){
    await CreateVariety(variety)
    setConfirmOpen(false);
    window.location.reload();
    } else {
      await updateVariety(editData?.id, variety)
      setConfirmOpen(false);
      window.location.reload();
    } 
  } catch (error) {
    console.error(error)
  }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setConfirmOpen(true);
   
    }
  };
  
  const handleDelete =async()=>{
    await deleteVariety(editData?.id);
    setConfirmOpen(false);
    window.location.reload();
    onSaveVariety();
  }

  const handleConfirmSave = () => {
      if (variety.name === '') {
          alert('Please enter variety name');
          return;
      }
    saveVariety()
    setConfirmOpen(false); 
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ background: `${colors.customColor[500]}`, fontSize: '20px', padding: '20px' }}>Add New Variety</DialogTitle>
        <DialogContent
          sx={{
            background: `${colors.customColor[500]}`,
          }}
        >
          <Stack spacing={3}>
            <TextField
              label="Variety"
              fullWidth
              value={variety.name}
              onChange={(e) => setVariety({ ...variety, name: e.target.value })}
              sx={{
                position: 'relative',
                top: '9px',
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.customColor[100]}` },
                '& label.Mui-focused': { color: `${colors.customColor[100]} !important` },
                '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.customColor[100]} !important` },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${colors.customColor[100]} !important`,
                  borderRadius: '15px !important'
                },
              }}
            />
            <div
              style={{
                position: 'relative',
                top: '9px',
                border: `1px solid ${colors.customColor[100]}`,
                borderRadius: '15px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography sx={{ marginLeft: '2%' }}>Variety Type</Typography>
              <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel control={<Checkbox checked={variety.type === "Quality"} onChange={() => handleCheckboxChange("Quality")} sx={{ color: `${colors.customColor[800]} !important`, '&.Mui-checked': { color: `${colors.customColor[800]} !important` } }} />} label="Quality" />
                <FormControlLabel control={<Checkbox checked={variety.type === "Non Quality"} onChange={() => handleCheckboxChange("Non Quality")} sx={{ color: `${colors.customColor[800]} !important`, '&.Mui-checked': { color: `${colors.customColor[800]} !important` } }} />} label="Non-Quality" />
              </FormGroup>
            </div>
            <TextField
              label="Stage"
              fullWidth
              value={variety?.variety_stage}
              onKeyDown={handleKeyPress}
              onChange={(e) => setVariety({ ...variety, variety_stage: e.target.value })}
              sx={{
                position: 'relative',
                top: '9px',
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.customColor[100]}` },
                '& label.Mui-focused': { color: colors.grey[100] },
                '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: colors.grey[100] },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${colors.customColor[100]} !important`,
                  borderRadius: '15px !important'
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ background: `${colors.customColor[500]}`, width: '96%' }}>
          <Button variant="outlined" onClick={onClose} sx={{ color: '#F46464', border: '2px solid #F46464 !important' }}>
            Cancel
          </Button>
          <Button variant="outlined" sx={{ color: `${colors.customColor[800]}`, border: `2px solid ${colors.customColor[800]}` }}  
          onClick={() => {
                            if (editData) {
                              handleDelete(); 
                            } else {
                              setConfirmOpen(true); 
                            }
                          }}>
            {!editData? 'Save':'Delete'}
          
          </Button>
          {editData &&
            <Button variant="outlined" onClick={()=>setConfirmOpen(true)} sx={{ color:`${colors.customColor[100]}`,  border: `2px solid ${colors.customColor[100]}` }}>
            Save
          </Button>}
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog */}
     <ConfirmationPopup 
        title='Are you sure you want to save the changes?' 
        onOpen={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleConfirmSave}
      />
    </div>
  );
};

export default VarietyModal;
