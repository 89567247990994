import React from 'react';
import Chart from 'react-apexcharts';
import { tokens } from "../assets/Theme";
import { useTheme, Typography  } from "@mui/material";
const CropPieChart = ({cropData}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // Data for the donut chart
    const series = [...cropData?.total_area || []]; // Dummy data representing maize, rice, cane, and wheat
    const barColors = [`${colors.customColor[800]}`,'#8BEED1', `${colors.customColor[100]}`, `${colors.customColor[300]}`]
    // Options for the donut chart
    const options = {
        labels: [...cropData?.crops_type || []],
        chart: {
            type: 'donut',
        },
        legend: {
            position: 'bottom',
            offsetY: 0,
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '55%',
                }
            }
        },
        colors: barColors,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <div
            style={{
                background:`${colors.customColor[500]}`,

                marginLeft:'2%',
                borderRadius:'12px',
                boxShadow: '2px 6px 12px rgba(0, 0, 0, 0.1)', }}>
            <div style={{width:'80%', padding:'25px'}}>
                <Typography variant='h4' fontWeight='bold'>Crops Comparison</Typography>
            </div>
            <Chart options={options} series={series} type="donut" width="320" />
        </div>
    );
};

export default CropPieChart;
