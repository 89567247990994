import { createSlice } from '@reduxjs/toolkit';

// Navigation slice
export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: { title: '/' },
  reducers: {
    setNavigation: (state, action) => {
      state.title = action.payload;
    },
  },
});

// Sub-navigation slice
export const secondNavigationSlice = createSlice({
  name: 'subNavigation',
  initialState: { subTitle: '' },
  reducers: {
    setSubNavigation: (state, action) => {
      state.subTitle = action.payload;
    },
  },
});

export const { setNavigation } = navigationSlice.actions;
export const { setSubNavigation } = secondNavigationSlice.actions;

