import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { AddIcon, CrossIcon } from 'assets/Icons';
import { getProject } from 'api/develoPro/Projects';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AdditionalFieldModal({ open, close, title }) {
  const [fieldValues, setFieldValues] = useState([{ name: '' }]);

  const handleAddField = () => {
    setFieldValues([...fieldValues, { name: '' }]);
  };

  const handleFieldValueChange = (index, value) => {
    const newFieldValues = [...fieldValues];
    newFieldValues[index].name = value;
    setFieldValues(newFieldValues);
  };

  const handleCreate = () => {
    const filteredFields = fieldValues.filter((field) => field.name.trim() !== '');
    localStorage.setItem('fieldValues', JSON.stringify(filteredFields));
    close();
  };

  return (
    <div>
      <Dialog
        sx={{
          '.MuiDialog-paper': {
            width: '300px !important',
            minHeight: '200px !important',
            maxHeight: '400px !important',
            background: '#fff',
            borderRadius: 20,
          },
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        aria-describedby="alert-dialog-slide-description"
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography className="modal-title">{title}</Typography>
          <Button size="small" sx={{ top: '5px' }} onClick={close}>
            <CrossIcon style={{ width: '25px', height: '25px' }} />
          </Button>
        </Stack>
        <hr style={{ opacity: '0.2', width: '90%' }} />
        <DialogContent>
          <Typography>Add Field Name</Typography>
          {fieldValues.map((field, index) => (
            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <TextField
                fullWidth
                value={field.name}
                onChange={(e) => handleFieldValueChange(index, e.target.value)}
              />
              {index === fieldValues.length - 1 && (
                <Button size="small" onClick={handleAddField}>
                  <AddIcon />
                </Button>
              )}
            </div>
          ))}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{ width: '100%', height: '100%', background: '#1BC795', borderRadius: 5, border: '1px #1BC795 solid' }}
            onClick={handleCreate}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
