import React, {useState} from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MultiSelect from "./MultiSelect";
import {createBatch} from "../../api/ginnerTraceability/batch";
import Loading from "../BeatLoader";



export default function AddBatchModal({options, batches, setBatches}) {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [heaps, setHeaps] = useState([]);
    const handleSave = () => {
        const selectedHeaps = heaps.map(heap => heap.id);
        if (selectedHeaps.length === 0){
            alert('Please select at least one heap');
            return;
        }
        setIsLoading(true);
        const data = {
            heaps : selectedHeaps,
            status : 'not_started'
        }
        console.log('Heap Ids : ', selectedHeaps);
        createBatch(data).then(response => {
            if (response){
                console.log('Batch Created Success: \n', response);
                response.action = ['delete','start'];
                response.statusNew='Not Started'
                setBatches(prevBatches => [response ,...prevBatches]);
                setIsLoading(false);
                handleClose();
            }else {
                setIsLoading(false)
                alert('Error in creating batch');
            }
        })
    }

    return (
        <div>
            <Button
                variant="outlined"
                color="success"
                onClick={handleOpen}
            >
                <AddIcon sx={{ mr: 1 }} fontSize="small" />
                Create Batch
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'scroll',
                    }}
                >
                    <label htmlFor="outlined-basic">Select Heaps</label>
                    <MultiSelect options={options} setState={setHeaps} state={heaps} showAssignWeight={false}/>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="outlined" color="success" onClick={handleSave} disabled={isLoading}>
                            Create
                            {
                                isLoading && (
                                    <div style={{marginTop: '5px'}}>
                                    <Loading size={13} />
                                    </div>
                                )
                            }
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )

}