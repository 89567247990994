import axios from "axios";
const BASEURL = process.env.REACT_APP_BASEURL;

//function to get growers
export const getGrowers = async () => {
    try {
        const response = await axios.get(`${BASEURL}/api/growers/?p=1`)
        return response?.data?.results
    } catch (error) {
        console.log(error)
        return false
    }
}

export const searchGrowers = async (search) => {
    try {
        const response = await axios.get(`${BASEURL}/api/growers/?search=${search}`)
        return response?.data
    } catch (error) {
        console.log(error)
        return false
    }
}