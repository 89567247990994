import HeapCard from "../../heapCard";

//function to update heapOptions weights after newWeight is successfully updated in the database
export const updateHeapOptions = (heapOptions, heapsUpdated) => {
    return heapOptions.map((heapOption) => {
        if (heapOption?.id === heapsUpdated?.id) {
            return heapsUpdated;
        }
        return heapOption;
    });
};

export default function generateHeapCards(heaps){
    if (heaps?.length === 0) return (<div></div>)
    return heaps?.map((heap, index) => {
        return <HeapCard key={index} heap={heap}/>
    });
}