import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Stack, FormControl, Checkbox, FormGroup, FormControlLabel, Typography } from '@mui/material';
import { tokens } from '../../assets/Theme';
import { useTheme } from '@mui/material';
import ConfirmationPopup from './ConfirmationPopup';
import { createCropType, deleteCropType, updateCropType } from 'api/surveySetup/Crop';

const CropTypeModal = ({ open, onClose, onSaveVariety, editData=null }) => {
  const storedCompanyInfo = JSON.parse(localStorage.getItem('companyInfo'));
  const [type, setType] = useState({ 
    name: '',
    admin:storedCompanyInfo?.id
 });
  const [confirmOpen, setConfirmOpen] = useState(false); 
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const params ={id: editData?.id}
 useEffect(() =>{
  if (editData !== null){
    setType({
      name: editData?.name || '',
      admin: editData?.admin ||storedCompanyInfo?.id,
      
  })
  }
 },[editData])
 console.log(editData)
 const saveType = async() => {
  try {
    if (editData === null){
    await createCropType(type)
    setConfirmOpen(false); 
    onSaveVariety();
    window.location.reload();
    }else{
      updateCropType(editData?.id,type)
      setConfirmOpen(false); 
      onSaveVariety();
      window.location.reload();
      
    }
  } catch (error) {
    console.error(error)
  }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setConfirmOpen(true);
   
    }
  };
  const handleDelete =async()=>{
    await deleteCropType(editData?.id);
    setConfirmOpen(false);
    onSaveVariety();
    window.location.reload();
  }
  const handleConfirmSave = () => {
    saveType();
    setConfirmOpen(false); 
    editData = null
  };


  return (
    <div>
      <Dialog open={open} onClose={onClose} sx={{'& .MuiDialog-paper':{height:'200px'}}}>
        <DialogTitle sx={{ background: `${colors.customColor[500]}`, fontSize: '20px', padding: '20px' }}>{!editData? 'Add Crop Type':'Edit Crop Type'}</DialogTitle>
        <DialogContent
          sx={{
            background: `${colors.customColor[500]}`,
          }}
        >
            <TextField
              label="Crop Type"
              fullWidth
              value={type?.name}
              onChange={(e) => setType({ ...type, name: e.target.value })}
              onKeyDown={handleKeyPress}
              sx={{
                position: 'relative',
                top: '9px',
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.customColor[100]}` },
                '& label.Mui-focused': { color: `${colors.customColor[100]} !important` },
                '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.customColor[100]} !important` },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${colors.customColor[100]} !important`,
                  borderRadius: '15px !important'
                },
              }}
            />
           
        </DialogContent>
        <DialogActions sx={{ background: `${colors.customColor[500]}`, width: '96%' }}>
          <Button variant="outlined" onClick={onClose} sx={{ color: '#F46464', border: '2px solid #F46464 !important' }}>
            Cancel
          </Button>
          <Button variant="outlined" sx={{ color: `${colors.customColor[800]}`, border: `2px solid ${colors.customColor[800]}` }} 
          onClick={() => {
                            if (editData) {
                              handleDelete(); 
                            } else {
                              setConfirmOpen(true); 
                            }
                          }}>
            {!editData? 'Save':'Delete'}
          </Button>
          {editData &&
            <Button variant="outlined" onClick={()=>setConfirmOpen(true)} sx={{ color:`${colors.customColor[100]}`,  border: `2px solid ${colors.customColor[100]}` }}>
            Save
          </Button>}
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog */}
     <ConfirmationPopup 
        title='Are you sure you want to save the changes?' 
        onOpen={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleConfirmSave}
      />
    </div>
  );
};

export default CropTypeModal;
