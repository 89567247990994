import axios from 'axios';

const makeRequest = async (url, method, data = null, params = null) => {
  try {
    const config = {
      method,
      url,
      ...(data && { data }), 
      ...(params && { params }), 
    };

    const response = await axios(config);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const getVariety = (params) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/variety/`, 'get',null,params);
export const CreateVariety = (data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/variety/`, 'post',data,null);
export const updateVariety = (id,data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/variety/${id}/`, 'patch',data,null);
export const deleteVariety = (id) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/variety/${id}`, 'delete');