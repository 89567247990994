import React, { useEffect, useState } from 'react'
import SummaryCard from './SummaryCard'
import { getSummary } from 'api/develoPro/Tasks';

export default function SummaryBar() {
    const [summary, setSummary] = useState()
    
    const handleSummary = async () => {
        try {
            const  res = await getSummary()
            setSummary(res)
            console.log(res)
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        handleSummary()
      }, []);
  return (
    <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}>
        <SummaryCard title='Total Task' value={summary?.tasks}/>
        <SummaryCard title='In Progress' value={summary?.in_progress_tasks}/>
        <SummaryCard title='Completed' value={summary?.completed_tasks}/>
        <SummaryCard title='Pending' value={summary?.pending_tasks}/>

    </div>
  )
}
