import {
  CircleMarker,
  MapContainer,
  ZoomControl,
  LayersControl,
  TileLayer,
  FeatureGroup,
} from "react-leaflet";
import { ReCenter } from "../../map-utils/ReCenter";
import * as React from "react";
import { flipCoords } from "../../../pages/territoryManagement/ManageGateCircles";
import { Box, Button, Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { EditControl } from "react-leaflet-draw";
import "../../../assets/leaflet-draw.css";
import { serializeForm } from "../../../utils/utils";
import { createNotification } from "../../Notifications";
import { useNavigate, useParams } from "react-router-dom";
import L from "leaflet";
import Popup from "components/Popup";
import InputCustom from "../components/InputCustom";
import AutocompleteCustom from "../components/AutoCompleteCustom";
import { useSelector } from "react-redux";

function EditGateCircle() {
  const { id } = useParams();
  const mapRef = useRef();
  const navigate = useNavigate();
  const featureGroupRef = useRef();
  const editRef = useRef();
  const [userLocation, setUserLocation] = useState([30.3753, 69.3451]);
  const [attributeData, setAttributeData] = useState(null);
  const [districtOptions, setDistrictOptions] = useState([]);
  const map_center = [30.3753, 69.3451];
  const [state, setState] = useState({ leaflet_id: null, field_polygon: null });
  const [Open, setOpen] = useState(false);
    const [district, setDistrict] = useState(null);
    const boundaryTypeId = useSelector(
      (state) =>
        state?.boundaries?.array.find((data) => data.name === "Gate Circle")
          ?.id,
    );
    const districtId = useSelector(
      (state) =>
        state?.boundaries?.array.find((data) => data.name === "District")?.id,
    );
    const getGate = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASEURL}/api/gates/${id}/`,
        );

        if (res && res.data) {
          let field_polygon = flipCoords(res.data.geometry.coordinates[0]);
          if (res?.data && field_polygon) {
            featureGroupRef.current.clearLayers();
            let addedPoly = featureGroupRef.current.addLayer(
              L.polygon(field_polygon),
            );
            let leaflet_id = addedPoly._leaflet_id;

            setAttributeData(res?.data.properties);
            setState({ ...state, field_polygon, leaflet_id });
            const bounds = addedPoly.getBounds();
            mapRef.current.fitBounds(bounds);
          }
        }
      } catch (error) {}
    };
    const getDistricts = async () => {
      try {
        let districtsArray = [];
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASEURL}/api/gates/?type=${districtId}`,
        );
        districtsArray.push(
          ...data.results.features.map((feature) => ({
            id: feature.id,
            name: feature.properties?.name,
          })),
        );
        let p = 2;
        let nextPage = data?.next;
        while (nextPage !== null) {
          const res = await axios.get(
            `${process.env.REACT_APP_BASEURL}/api/gates/?type=${districtId}&p=${p}`,
          );
          districtsArray.push(
            ...res.data.results.features.map((feature) => ({
              id: feature.id,
              name: feature.properties?.name,
            })),
          );

          p++;
          nextPage = res.data.next;
        }
        setDistrictOptions(districtsArray);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      getDistricts();
      getGate();
    }, []);

    /***************************************Leaflet Draw Functionality*********************************************/
    const onMountedRect = (e) => {
      editRef.current = e;
      // Style Draw Button
      if (document.getElementsByClassName("leaflet-draw-draw-polygon")[0]) {
        let drawBtn = document.getElementsByClassName(
          "leaflet-draw-draw-polygon",
        )[0];
        drawBtn.innerHTML = "Draw";
      }
      // Style Edit Button and Hide on Mount
      if (document.getElementsByClassName("leaflet-draw-edit-edit")[0]) {
        let editBtn = document.getElementsByClassName(
          "leaflet-draw-edit-edit",
        )[0];
        editBtn.innerHTML = "Edit";
        editBtn.style.display = "none";
      }
      // If Polygon is Drawn, Hide Draw Button, Show Edit Button
      if (
        document.getElementsByClassName("leaflet-draw-draw-polygon")[0] &&
        state.field_polygon
      ) {
        let drawBtn = document.getElementsByClassName(
          "leaflet-draw-draw-polygon",
        )[0];
        let editBtn = document.getElementsByClassName(
          "leaflet-draw-edit-edit",
        )[0];
        drawBtn.style.pointerEvents = "none";
        drawBtn.style.display = "none";
        editBtn.style.display = "block";
      }
    };

    const onDrawStart = () => {
      editRef.current._toolbars.draw._modes.polygon.handler.enable();
    };
    const onEditStop = (e) => {
      try {
        if (state.leaflet_id) {
          const coords = e?.sourceTarget._layers[state.leaflet_id]._latlngs[0];
          setState((prevState) => ({
            ...prevState,
            field_polygon: coords,
          }));
        }
      } catch (e) {
        console.log(e);
      }
    };
    const handleSubmit = async () => {
      mapRef.current.eachLayer(async function (lyr) {
        if (lyr._leaflet_id === state.leaflet_id) {
          const coords = lyr._layers[Object.keys(lyr._layers)[0]]._latlngs;

          let coordinate_pair = coords[0].map((coord) => {
            return [coord.lng, coord.lat];
          });
          const uniqueCoordinates = [
            ...new Set(coordinate_pair.map(JSON.stringify)),
          ].map(JSON.parse);
          uniqueCoordinates.push(uniqueCoordinates[0]);

          try {
            await axios.patch(
              `${process.env.REACT_APP_BASEURL}/api/gates/${id}/`,
              {
                name: attributeData.name,
                polygon: {
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    coordinates: [uniqueCoordinates],
                  },
                },
                parent: attributeData?.parent?.id,
              },
            );
            createNotification("success");
            navigate(`/territory-management/gate-circle-management`);
          } catch (error) {
            if (error?.response?.status === 400) {
              alert("Invalid Username or Password");
            } else if (error?.response?.data) {
              alert(JSON.stringify(error?.response?.data));
            } else alert(error);
          }
        }
      });
    };

    // delete gate circles
    const handleDelete = async () => {
      try {
        let response = await axios.delete(
          `${process.env.REACT_APP_BASEURL}/api/gates/${id}`,
        );

        if (response.status === 204) {
          navigate("/territory-management/gate-circle-management");
        }
      } catch (error) {
        console.error(error);
        alert("Failed to delete employee");
      }
    };
    const onShapeDrawn = (e) => {
      /* Enable Editing On Click of Polygon */
      e.layer.on("click", () => {
        editRef.current._toolbars.edit._modes.edit.handler.enable();
      });
      /* Save Polygon */
      try {
        let coords = e?.layer?._latlngs[0];
        let leafletId = e?.layer._leaflet_id;
        setState((prevState) => ({
          ...prevState,
          field_polygon: coords,
          leaflet_id: leafletId,
        }));
      } catch (e) {
        console.log(e);
      }
    };
    /***************************************Leaflet Draw Functionality*********************************************/
    return (
      <div>
        <Grid
          container
          direction="column"
          sx={{
            background: "white",
            boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
            borderRadius: 5,
            padding: "3%",
            width: "95%",
            position: "relative",
            left: "2%",
          }}
        >
          <Grid container>
            <Grid item xs={9}>
              <Typography
                variant="h3"
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  position: "relative",
                  left: "10px",
                }}
              >
                Edit Gate Circle
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Box
              noValidate
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 1,
              }}
            >
              <InputCustom
                width="22%"
                margin="normal"
                required
                id="name"
                label="Gate Circle Name"
                variant="outlined"
                name="name"
                focused
                value={attributeData?.name}
                onChange={(e) => {
                  setAttributeData({ ...attributeData, name: e.target.value });
                }}
              />
              <InputCustom
                margin="normal"
                required
                id="code"
                focused
                value={attributeData?.code || ""}
                label="Circle Code"
                onChange={(e) => {
                  setAttributeData({ ...attributeData, code: e.target.value });
                }}
                variant="outlined"
                name="code"
                width="22%"
              />
              <AutocompleteCustom
                value={attributeData?.parent_details || ""}
                getOptionKey={(option) => option?.id || ""}
                getOptionLabel={(option) => option?.name || ""}
                id={"district"}
                onChange={(e) => {
                  setAttributeData({
                    ...attributeData,
                    parent: e,
                    parent_details: e,
                  });
                }}
                name="district"
                label={"District"}
                options={districtOptions}
                width="22%"
              />
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
                sx={{
                  mt: 3,
                  color: "white",
                  background: "#F46464",
                  border: "2px solid red",
                  borderRadius: "5px",

                  width: "20%",
                  height: "6vh",
                  marginLeft: "5px",
                  mb: 2,
                }}
              >
                Delete Gate
              </Button>
              <Popup
                title="Warning!"
                open={Open}
                onClose={() => setOpen(false)}
                onConfirm={handleDelete}
                className="title_container"
                btnColor="red"
                message={`Are you sure you want to delete Gate circle with ID: ${id} ?`}
              />
            </Box>
          </Grid>

          <Grid item xs={8}>
            <MapContainer
              center={map_center}
              zoom={7}
              maxZoom={18}
              scrollWheelZoom={true}
              zoomControl={false}
              attributionControl={false}
              style={{ height: "400px", borderRadius: "10px" }}
              ref={mapRef}
            >
              <ZoomControl prepend position="bottomleft" />
              <CircleMarker center={userLocation} />
              <ReCenter
                user_location={userLocation}
                updateLocation={setUserLocation}
              />

              <div style={{ position: "relative" }}>
                <Button
                  variant="contained"
                  style={{
                    position: "absolute",
                    top: "60px",
                    left: "10px",
                    zIndex: 1000,
                    backgroundColor: "#1BC693",
                  }}
                  onClick={() => getGate()}
                >
                  Gate
                </Button>

                <LayersControl position="bottomright">
                  <LayersControl.BaseLayer checked name="Satellite View">
                    <TileLayer
                      maxZoom={22}
                      maxNativeZoom={19}
                      url={`https://mt1.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}`}
                    />
                  </LayersControl.BaseLayer>
                </LayersControl>
                <FeatureGroup ref={featureGroupRef}>
                  <EditControl
                    onMounted={onMountedRect}
                    position="topright"
                    onCreated={onShapeDrawn}
                    onDrawStart={onDrawStart}
                    onEditStop={onEditStop}
                    draw={{
                      rectangle: false,
                      circle: false,
                      polyline: false,
                      circlemarker: false,
                      marker: false,
                      polygon: {
                        allowIntersection: false,
                        shapeOptions: { color: "#15ff00" },
                      },
                    }}
                  />
                </FeatureGroup>
              </div>
            </MapContainer>
          </Grid>
          <Box
            sx={{
              display: "flex",

              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color="error"
              sx={{
                mt: 3,
                marginRight: 5,
                width: 90,
                height: "5vh",
                border: "2px solid #ED05059E",
              }}
              onClick={() =>
                navigate("/territory-management/gate-circle-management")
              }
            >
              Cancel
            </Button>
            {state.field_polygon ? (
              <Button
                // type="submit"
                onClick={handleSubmit}
                variant="outlined"
                sx={{
                  mt: 3,
                  color: "#0275C6",
                  border: "2px solid #0275C6",
                  width: 90,
                  height: "5vh",
                }}
              >
                Save
              </Button>
            ) : (
              <Typography variant={"h3"}>
                Draw Polygon on Map To Continue
              </Typography>
            )}
          </Box>
        </Grid>
      </div>
    );
}

export default EditGateCircle;
