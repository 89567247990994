import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Stack, FormControl, Checkbox, FormGroup, FormControlLabel, Typography } from '@mui/material';
import { tokens } from '../../assets/Theme';
import { useTheme } from '@mui/material';
import ConfirmationPopup from './ConfirmationPopup';
import { createDisease, deleteDisease, updateDisease } from 'api/surveySetup/Disease';

const DiseaseModal = ({ open, onClose, onSaveVariety, editData=null }) => {
  const storedCompanyInfo = JSON.parse(localStorage.getItem('companyInfo'));
  const [disease, setDisease] = useState({ 
    name: '',
    admin: storedCompanyInfo?.id,
 });
 console.log("ed", editData);
  const [confirmOpen, setConfirmOpen] = useState(false); 
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

 useEffect(() =>{
  if (editData !== null){
    setDisease({
      name: editData?.name || '',
      admin: editData?.admin || storedCompanyInfo?.id
  })
  }
 },[editData])

const saveDisease = async() => {
try {
  if (editData === null){
  await createDisease(disease)
  setConfirmOpen(false);
  window.location.reload();
  } else {
    await updateDisease(editData?.id, disease)
    setConfirmOpen(false);
    window.location.reload();
  } 
} catch (error) {
  console.error(error)
}
};
const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    setConfirmOpen(true);
 
  }
};
const handleDelete =async()=>{
  await deleteDisease(editData?.id);
  setConfirmOpen(false);
  onSaveVariety();
  window.location.reload();
}
  const handleConfirmSave = () => {
    saveDisease()
    setConfirmOpen(false); 
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} sx={{'& .MuiDialog-paper':{height:'200px'}}}>
        <DialogTitle sx={{ background: `${colors.customColor[500]}`, fontSize: '20px', padding: '20px' }}>{!editData? 'Add Disease':'Edit Disease'}</DialogTitle>
        <DialogContent
          sx={{
            background: `${colors.customColor[500]}`,
          }}
        >
            <TextField
              label="Disease"
              fullWidth
              value={disease.name}
              onChange={(e) => setDisease({ ...disease, name: e.target.value })}
              onKeyDown={handleKeyPress}
              sx={{
                position: 'relative',
                top: '9px',
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.customColor[100]}` },
                '& label.Mui-focused': { color: `${colors.customColor[100]} !important` },
                '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.customColor[100]} !important` },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${colors.customColor[100]} !important`,
                  borderRadius: '15px !important'
                },
              }}
            />
           
        </DialogContent>
        <DialogActions sx={{ background: `${colors.customColor[500]}`, width: '96%' }}>
          <Button variant="outlined" onClick={onClose} sx={{ color: '#F46464', border: '2px solid #F46464 !important' }}>
            Cancel
          </Button>
          <Button variant="outlined" sx={{ color: `${colors.customColor[800]}`, border: `2px solid ${colors.customColor[800]}` }}  
          onClick={() => {
                            if (editData) {
                              handleDelete(); 
                            } else {
                              setConfirmOpen(true); 
                            }
                          }}>
            {!editData? 'Save':'Delete'}
          </Button>
          {editData &&
            <Button variant="outlined" onClick={()=>setConfirmOpen(true)} sx={{ color:`${colors.customColor[100]}`,  border: `2px solid ${colors.customColor[100]}` }}>
            Save
          </Button>}
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog */}
     <ConfirmationPopup 
        title='Are you sure you want to save the changes?' 
        onOpen={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleConfirmSave}
      />
    </div>
  );
};

export default DiseaseModal;
