import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {  DeleteIcon, EditIcon, ViewEye} from 'assets/Icons'
import { Button, Menu, MenuItem } from '@mui/material';
import { deleteTask } from 'api/develoPro/Tasks';
import DeleteTaskDialog from '../DeleteTaskDialog';

const CustomGrowersCell = ({ value }) => {
  const firstGrower = value[0]?.name;
  const additionalGrowers = value.slice(1);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {firstGrower && (
        <div>
          {firstGrower}
          {additionalGrowers.length > 0 && (
            <Button sx={{color:'#fff'}} aria-controls="growers-menu" aria-haspopup="true" onMouseOver={handleClick}>
              +{additionalGrowers.length} more
            </Button>
          )}
        </div>
      )}
      <Menu
        id="growers-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {additionalGrowers.map((grower, index) => (
          <MenuItem key={index}>{grower?.name}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export const accordianTaskColumns = [
    {
        field: 'name', 
        headerName: 'Task Name', 
        width: 220,
      },
      {
        field:'task_type', 
        headerName: 'Task Type',
        width: 220,
      },
      {
        field: 'assignees', 
        headerName: 'Assignee',
        renderCell: (params) =>  <CustomGrowersCell value={params?.value}/>,
        width: 220,
      },
      {
        field: 'growers', 
        headerName: 'Grower(s)',
        renderCell: (params) => <CustomGrowersCell value={params?.value}/>,
        width: 220,
      },
      {
        field: 'end_date', 
        headerName: 'Due Date',
        width: 220,
      },

]


const CustomActions = ({ id , name }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();  
  const handleDeleteTask = async () => {
    try {
      await deleteTask(id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        size="small"
        sx={{ borderRadius: '0px', borderRight: '1px solid #fff' }}
        onClick={() => navigate(`/developro/projects/task/id/${id}`)}
      >
        <ViewEye style={{ height: '15px', width: '15px', color:'#000' }} />
      </Button>
      <Button
        size="small"
        sx={{ borderRadius: '0px' }}
        onClick={() => setOpen(true)} 
      >
        <DeleteIcon style={{ height: '15px', width: '15px' }} />
      </Button>
      <DeleteTaskDialog open={open } close={handleClose} id={id} taskName={name}  />
    </React.Fragment>
  );
};

export const allTaskColumns = [
  {
    field: 'name',
    headerName: 'Task',
    width: 220,
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    width: 220,
  },
  {
    field: 'assignees',
    headerName: 'Assignees',
    renderCell: (params) =>  <CustomGrowersCell value={params?.value}/>,
    width: 220,
  },
  {
    field: 'action',
    headerName: '',
    renderCell: (params) => <CustomActions id={params.id} name ={params.name} />,
    width: 120,
  },
];
