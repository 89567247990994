import { getCircles } from 'api/allSurveys/GateCircles';
import Topbar from 'components/Topbar';
import AllSurveyTables from 'components/tables/AllSurveyTables'
import { Button,Stack, useTheme,Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { tokens } from 'assets/Theme';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { clearPage, gateDecrement, gateIncrement, gatePageclear } from 'reduxSetup/AllSurveyPages';


export default function () {
    const [gateCircle, setGateCircle] = useState([])
    const [loading, setLoading] = useState(false)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [record, setRecord] = useState();
    const [next, setNext] = useState();
    const currentPage = useSelector(state => state.gateCircleSlice.page);
    const [search, setSearch] = useState('');

    const headerNames = [
        {header:'Boundary name',value:'name'},
        {header:'Total Fields',value:'total_fields'},
        {header:'Field Accepted',value:'fields_approved'},
        {header:'Total Area',value:'total_area'},
        {
            header: 'Actions',
            value: (rowData) => (
                <Button
                    size='small'
                    variant='text'
                    sx={{ fontWeight: 'bold', textDecoration: 'underline', color: `${colors.customColor[800]}` }}
                    onClick={() =>{
                        navigate(`/all-surveys/bounderies/${rowData.id}/detail`)
                        dispatch(clearPage())
                    }}
                >
                    View
                </Button>
            )
        },];

    const params ={p:currentPage, search:search}
    const fetchGateCircles = async () => {
        try {
            setLoading(true);
            const res = await getCircles(params);
            if (res){
                setGateCircle(res?.results?.features?.map((ft) => ({
                    id: ft.id,
                    name: ft.properties?.name,
                    fields_approved: ft.properties?.fields_approved,
                    total_fields: ft.properties?.total_fields,
                    total_area: ft.properties?.total_area === null ?  0 : ft.properties?.total_area?.toFixed(2),
                })));
                setNext(res?.next);
                setRecord(res?.count);
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };
    const handleSearch=(value) => {
        setSearch(value);
        dispatch(gatePageclear())
    }
    useEffect(() => {
        fetchGateCircles()
    },[currentPage, search])
  return (

    <div>
         <Topbar/>
         <div style={{width:'90%',position:'relative' ,left:'5%'}}>
            <AllSurveyTables
            title="Boundaries Name"
            headerNames={headerNames}
            rowsData={gateCircle}
            headerButtonLabel="Add"
            onSearch={handleSearch}
            footer={
                <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                <Typography sx={{position:'relative', top:'35px',left:'10px', color:`${colors.customColor[800]}`}}>{`Page: ${currentPage} - ${Math.ceil(record / 10)}`}</Typography>
            <Stack direction="row" spacing={2}
                            sx={{marginTop: '2%', marginBottom:'1%', justifyContent: 'flex-end', position: 'relative', right: '7%'}}>
                         <Button className='footerbtn-variety' variant='outlined' onClick={()=>{dispatch(gateDecrement())}}
                                >
                             previous
                         </Button>
                         <Button className='footerbtn-variety' variant='outlined' disabled={next===null || loading} onClick={()=>{dispatch(gateIncrement())}}
                                >
                             next
                         </Button>
                    </Stack>
                    </div>} />
         </div>
    </div>
  )
}
