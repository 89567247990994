import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  Grid,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { tokens } from "../assets/Theme";
import { useTheme } from "@mui/material";
import { createNotification } from "../components/Notifications";
import Topbar from "components/Topbar";

const baseurl = process.env.REACT_APP_BASEURL;
console.log("base url: " + baseurl);
function Login({ setUser }) {
  const [formData, setFormData] = useState({
    mobile_number: "",
    password: "",
    showPassword: false,
  });

  const handleInputChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    // Check if input is a valid number with up to 11 digits
    if (inputName === "mobile_number") {
      const isValidNumber = /^\d{0,11}$/.test(inputValue);
      if (!isValidNumber) {
        return;
      }
    }

    // Update form data with new value
    setFormData({ ...formData, [inputName]: inputValue });
  };

  const handleClickShowPassword = () => {
    setFormData({
      ...formData,
      showPassword: !formData.showPassword,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      let res = await axios.post(`${baseurl}/user/auth/login/`, {
        mobile_number: formData.mobile_number,
        password: formData.password,
      });
      if (res.status === 200) {
        console.log("LOGIN res:", res);
        const token = res?.data?.token;
        const role = res?.data?.role;
        if ( role === 'S' ) {
          alert('Surveyor are not autherized to login');
          localStorage.clear();
          window.location.href = "/";
          return;
        }
        setUser({
          token: token,
          mobile_number: res.data.mobile_number,
          username: res.data.username,
          role: res.data.role,
        });
        window.location.href = "/";
      }else if (res.response?.data?.error) {
        alert(res.response?.data?.error);
      }else {
        console.log('response is : ',res)
        alert('Something Went Wrong! Please try again.');
      }

    } catch (error) {
      console.log('error : ' ,error);
      if (error?.response?.data.statusText === 'Unauthorized') {
        setError('Invalid Credentials')
        alert('Invalid credentials');
      }
    }
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("Surveyor or Editor is not authorized to login");

  return (
    <>
      <Grid
        container
        style={{ position: "relative", top: "200px" }}
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h5" component="h1">
            Login
          </Typography>
        </Grid>
        <Grid item>
          <form onSubmit={handleLogin}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel htmlFor="mobile_number">Mobile Number</InputLabel>
                  <Input
                    id="mobile_number"
                    name="mobile_number"
                    type="mobile_number"
                    required
                    value={formData.mobile_number}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    name="password"
                    type={formData.showPassword ? "text" : "password"}
                    required
                    value={formData.password}
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {formData.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Login
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Forgot your password?{" "}
                  <Link
                    style={{ color: colors.grey[100] }}
                    onClick={() => setShowAlert(true)}
                  >
                    Click me
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>
        {showAlert && (
          <Alert severity="info" onClose={() => setShowAlert(false)}>
            Please contact Farmdar customer support
          </Alert>
        )}
      </Grid>
    </>
  );
}

export default Login;
