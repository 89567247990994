import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import AddIcon from "@mui/icons-material/Add";
import { TextField} from "@mui/material";
import {useState} from "react";
import {createDriver} from "../../api/ginnerTraceability/driver";
import {inputLabelStyle, textFieldStyle} from "./helpers/css/styles";
import Loading from "../BeatLoader";
import {createVehicle} from "../../api/ginnerTraceability/vehicle";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function CreateVehicleModal() {
    const [openVehicleModal, setOpenVehicleModal] = useState(false);
    const handleOpen = () => setOpenVehicleModal(true);
    const handleClose = () => setOpenVehicleModal(false);
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = () => {
        setIsLoading(true);
        try {
            if (vehicleNumber !== '') {
                    const data = {
                        id: vehicleNumber
                    };
                    createVehicle(data).then((res) => {
                        if (res){
                            setIsLoading(false);
                            handleClose();
                        }else {
                            setIsLoading(false);
                            alert('Error Creating Driver');
                        }

                    }).catch((err) => {
                        console.error('Server Error Creating Driver \n',err);
                        setIsLoading(false);
                        alert('Error Creating Driver');
                    });
            }else {
                setIsLoading(false);
                alert('Please Fill Vehicle Number Field');
            }
        }catch (error){
            console.error('Error Creating Driver \n',error);
            setIsLoading(false);
        }

    }



    return (
        <div>
            <Button
                variant="outlined"
                color="error"
                onClick={handleOpen}
            >
                <AddIcon sx={{ mr: 1 }} fontSize="small" />
                Create Vehicle
            </Button>
            <Modal
                open={openVehicleModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'scroll',
                    }}
                >
                    <TextField
                        label="Vehicle Number"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={vehicleNumber}
                        onChange={(e) => setVehicleNumber(e.target.value)}
                        sx={textFieldStyle}
                        InputLabelProps={inputLabelStyle}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="outlined" color="success" onClick={handleSave} disabled={isLoading}>
                            Create
                            {
                                isLoading && (
                                    <div style={{marginTop: '5px'}}>
                                        <Loading size={13} />
                                    </div>
                                )
                            }
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}