import React, { useEffect, useState } from "react";
import Control from "react-leaflet-custom-control";
import {
  TextField,
  Box,
  Autocomplete,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { debounce } from "utils/utils";
import { tokens } from "assets/Theme";

const FieldSearchComponent = ({
  searchHandler = () => {},
  customOptions = [
    "Field ID",
    "Farmer Name",
    "Coordinates",
    "Passbook No.",
    "Boundary Name",
  ],
  searchResultOptions = [],
  onSelectMapSearchValue,
  isMapSearchLoading = false,
  onSearchByCoordinates = () => {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchType, setSearchType] = useState(customOptions[0]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedResult, setSelectedResult] = useState(null);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const handleSearchChange = debounce((value) => {
    if (value.length > 3) {
      setIsOpen(true);
      searchHandler(value, searchType);
    }
  }, 500);
  // Event handler for input change
  const handleChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
    handleSearchChange(value);
  };
  const handleSearchByCoordinates = () => {
    // Handle search logic for coordinates
    const coordinates = { latitude, longitude };
    onSearchByCoordinates(coordinates);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "white",
          border: "1px solid #1BC693",
          width: "35vw",
          height: "50px",
          padding: 0,
          borderRadius: "5px",
          zIndex: 1000,
          backgroundColor: "white",
          marginBottom: -200,
        }}
      >
        <Autocomplete
          value={searchType}
          onChange={(event, newValue) => setSearchType(newValue)}
          options={customOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search By"
              size="small"
              variant="outlined"
              sx={{
                width: "12vw",
                height: "40px",
                mt: "10px",
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "white !important",
                  },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white !important",
                  borderRadius: "0px !important",
                },
              }}
            />
          )}
        />

        {searchType === "Coordinates" ? (
          <>
            <TextField
              label="Latitude"
              size="small"
              variant="outlined"
              placeholder="Latitude"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              sx={{
                mt: "10px",
                height: "40px",
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },

                "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "white !important",
                  },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white !important",
                  borderRadius: "px !important",
                },
              }}
            />
            <TextField
              label="Longitude"
              size="small"
              placeholder="Longitude"
              variant="outlined"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              sx={{
                mt: "10px",
                height: "40px",
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },

                "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "white !important",
                  },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white !important",
                  borderRadius: "px !important",
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearchByCoordinates}
              sx={{
                mt: "5px",

                background: `${colors.customColor[100]}`,
                color: `#fff`,
                height: "40px",

                borderRadius: "8px",
                "&:hover": {
                  background: `green`,
                },
              }}
            >
              Search
            </Button>
          </>
        ) : isMapSearchLoading ? (
          <Typography sx={{ mt: "10px", height: "40px" }}>
            Loading...
          </Typography>
        ) : (
          <Autocomplete
            open={isOpen}
            sx={{ width: "100%" }}
            //   getOptionKey={(option) => option?.id || ""}
            //   getOptionLabel={(option) =>
            //     `${option?.grower?.passbook_number} (${option?.id})` || ""
            //   }
            value={selectedResult?.value || null}
            onSelect={(event, value) => {
              setIsOpen(false);
            }}
            onChange={(event, newValue) => {
              setSelectedResult(newValue);

              newValue &&
                onSelectMapSearchValue(
                  searchResultOptions.find(
                    (data) => data?.id === newValue.value,
                  ),
                );
              setIsOpen(false);
            }}
            options={searchResultOptions.map((option) => ({
              value: option.id,
              label:
                searchType === "Farmer Name"
                  ? `${option?.grower?.grower_name} (${option?.id})`.toString()
                  : `${option?.grower?.passbook_number} (${option?.id})`.toString(),
            }))}
            noOptionsText={
              searchQuery.length > 3
                ? "No Options"
                : isMapSearchLoading
                ? "Loading...."
                : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Search by ${searchType}`}
                placeholder={`Search by ${searchType}`}
                size="small"
                width="23vw"
                value={searchQuery}
                onChange={(e) => handleChange(e)}
                variant="outlined"
                sx={{
                  mt: "10px",
                  height: "40px",
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },

                  "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white !important",
                    },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white !important",
                    borderRadius: "px !important",
                  },
                }}
              />
            )}
          />
        )}
      </Box>
    </>
  );
};

export default FieldSearchComponent;
