import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Input, InputAdornment} from "@mui/material";
import {useState} from "react";
import {inputStyle} from "./helpers/css/styles";

const HeapCard =  ({heap}) => {

    const [weight, setWeight] = useState(0);

    const handleInputChange = (event) => {
        const newWeight = parseFloat(event.target.value, 10) || 0;
            setWeight(newWeight);
            heap.newWeight = newWeight;
    };

    return (
        <>
            <Card style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px'}}>
                {/* Left side content */}
                <CardContent>
                    <Typography variant="h5" component="div">
                        Heap-ID: {heap.id}
                    </Typography>
                    <Typography color="white">
                        Total Capacity: {heap.weight_limit}
                    </Typography>
                    <Typography color="white">
                        Remaining Capacity: {heap.weight_limit - weight- heap.weight}
                    </Typography>
                </CardContent>


                <Input
                    id={heap.id}
                    endAdornment={<InputAdornment position="end">/{heap.weight_limit - weight- heap.weight}</InputAdornment>}
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                        'aria-label': 'weight',
                    }}
                    sx={{...inputStyle,
                    marginRight: '10px',
                    }}
                    onChange={handleInputChange}
                    value={weight}
                />

            </Card>
        </>

    );
}

export default HeapCard;