import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import {TextField, Typography, Grid} from '@mui/material';
import {Stack} from '@mui/system';
import {CrossIcon} from 'assets/Icons';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FilterModal({open, close, title, handleFiltersChange}) {
    const [formData, setFormData] = useState({
        name: '', project_type: '', grower: '', start_date: '', end_date: '',
    });


    const handleChange = (field, value) => {
        setFormData((prevData) => ({...prevData, [field]: value}));
    };


    /** Updates filters of parent on form save||close
     * params: obj (formData)
     * */
    const handleSave = (data) => {
        handleFiltersChange(data);
    };

    return (<div>
            <Dialog
                sx={{
                    '.MuiDialog-paper': {
                        width: '500px !important', height: '560px !important', background: '#fff', borderRadius: 20,
                    },
                }}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={close}
                aria-describedby="alert-dialog-slide-description"
            >
                <Stack direction="row" justifyContent="space-between">
                    <Typography className="modal-title">{title}</Typography>
                    <Button size="small" sx={{top: '5px'}} onClick={close}>
                        <CrossIcon style={{width: '25px', height: '25px'}}/>
                    </Button>
                </Stack>
                <hr style={{opacity: '0.2', width: '90%'}}/>
                <DialogContent>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography>Project Title</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                sx={{
                                    top: '5px', '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#1BC795 !important',
                                    }, '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#fff',
                                    },
                                }}
                                value={formData?.name}
                                onChange={(e) => handleChange('name', e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Project Type</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                sx={{
                                    top: '5px', '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#1BC795 !important',
                                    }, '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#fff',
                                    },
                                }}
                                value={formData?.project_type}
                                onChange={(e) => handleChange('project_type', e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Grower Passbook</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                sx={{
                                    top: '5px', '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#1BC795 !important',
                                    }, '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#fff',
                                    },
                                }}
                                value={formData?.grower}
                                onChange={(e) => handleChange('grower', e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" spacing={0}>
                                <Typography>Deadline</Typography>
                            </Grid>
                            <Grid container direction="row" spacing={4}>
                                <Grid item>
                                    <Typography>Start Date</Typography>
                                    <TextField
                                        type="date"
                                        fullWidth
                                        variant="outlined"
                                        sx={{
                                            top: '5px',
                                            width: '210px',
                                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#1BC795 !important',
                                            },
                                            '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#fff',
                                            },
                                        }}
                                        value={formData?.start_date}
                                        onChange={(e) => handleChange('start_date', e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>End Date</Typography>
                                    <TextField
                                        type="date"
                                        fullWidth
                                        variant="outlined"
                                        sx={{
                                            top: '5px',
                                            width: '210px',
                                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#1BC795 !important',
                                            },
                                            '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#fff',
                                            },
                                        }}
                                        value={formData?.end_date}
                                        onChange={(e) => handleChange('end_date', e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
                    <Button
                        variant="contained"
                        sx={{
                            width: '95%',
                            height: '100%',
                            background: '#1BC795',
                            borderRadius: '10px',
                            color: '#333',
                            textDecoration: 'bold !important',
                            '&:hover': {
                                background: '#149b6a',
                            },
                        }}
                        onClick={() => {
                            handleSave(formData)
                        }}
                    >
                        Apply
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            width: '95%',
                            height: '100%',
                            background: '#1BC795',
                            borderRadius: '10px',
                            color: '#333',
                            textDecoration: 'bold !important',
                            '&:hover': {
                                background: '#149b6a',
                            },
                        }}
                        onClick={() => handleSave({
                            name: '', project_type: '', grower: '', start_date: '', end_date: ''
                        })}
                    >
                        Clear All Filter
                    </Button>
                </DialogActions>
            </Dialog>
        </div>);
}
