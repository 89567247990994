import axios from 'axios';

const makeRequest = async (url, method, data = null, params = null) => {
    try {
        const config = {
            method, url, ...(data && {data}), ...(params && {params}),
        };

        const response = await axios(config);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const postProject = (data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/project/`, 'post', data);
export const getProject = (params) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/project/`, 'get', null, params);
export const getProjectById = (id) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/project/${id}`, 'get');
export const patchProject = (id, data) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/project/${id}/`, 'patch', data);
export const deleteProject = (id) => makeRequest(`${process.env.REACT_APP_BASEURL}/api/project/${id}`, 'delete');

export const bulkUploadTasks = async (file) => {
    if (file) {
        const formData = new FormData();
        formData.append("file", file);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/project/bulk_create_tasks/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 201) {
                const result = await response.json();
                console.log('Upload successful:', result);
            } else {
                console.error('Upload failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during the upload:', error);
        }
    } else {
        console.log('No file selected for upload.');
    }
}

export const downloadData = async (id) => { // Ensure 'id' is passed as a parameter
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/project/export_data/`, {
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Project-Export.csv');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};
