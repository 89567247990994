import React, { useState } from 'react';
import Select from 'react-select';
import {Grid, Input} from "@mui/material";
import Box from "@mui/material/Box";
import {inputStyle, customSelectStyles} from "./helpers/css/styles";

const MultiSelect = ({options,setState, state, showAssignWeight}) => {

    const handleChange = (selectedOptions) => {
        setState(selectedOptions);
    };

    return (
        <div style={{width: "270px"}}>
            <Select
                isMulti
                options={options || []}
                value={state}
                onChange={handleChange}
                placeholder="Select ..."
                styles={customSelectStyles}
            />
            {
                showAssignWeight === true &&
                state.length > 0 && (
                    <h4 style={{marginTop: "24px"}}>
                        Assign Weightage
                    </h4>
                )
            }
            {showAssignWeight === true && state.length > 0 && (
                <div>
                    <Grid container direction={"column"}>
                        {
                            state.map((option) => (
                                <Grid item xs={6} key={option.value}>

                                    <Box display="flex" flexDirection="column" maxWidth="300px" style={{marginTop: "10px"}}>
                                        <label htmlFor="weight" style={{marginBottom: '5px'}}>Weight in {option.label}</label>
                                        <Input
                                            id=""
                                            name="weight"
                                            type="number"
                                            required
                                            value={option.newWeight}
                                            sx={inputStyle}
                                            onChange={(e) => {
                                                const newWeight = e.target.value;
                                                const newOptions = state.map((item) => {
                                                    if (item.value === option.value) {
                                                        return {...item, newWeight};
                                                    }
                                                    return item;
                                                });
                                                setState(newOptions);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default MultiSelect;
