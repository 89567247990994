import { createSlice } from "@reduxjs/toolkit";

export const GatePageSlice = createSlice({
  name: "Gates",
  initialState: { gatePage: 1 },
  reducers: {
    incrementPage: (state) => {
      state.gatePage += 1;
    },
    decrementPage: (state) => {
      state.gatePage = Math.max(1, state.gatePage - 1);
    },
    clearPage: (state) => {
      state.gatePage = 1;
    },
  },
});

export const DistrictPageSlice = createSlice({
  name: "District",
  initialState: { page: 1 },
  reducers: {
    incrementPage: (state) => {
      state.page += 1;
    },
    decrementPage: (state) => {
      state.page = Math.max(1, state.page - 1);
    },
    clearPage: (state) => {
      state.page = 1;
    },
  },
});
export const VillagePageSlice = createSlice({
  name: "Village",
  initialState: { page: 1 },
  reducers: {
    incrementPage: (state) => {
      state.page += 1;
    },
    decrementPage: (state) => {
      state.page = Math.max(1, state.page - 1);
    },
    clearPage: (state) => {
      state.page = 1;
    },
  },
});
export const DehPageSlice = createSlice({
  name: "Deh",
  initialState: { page: 1 },
  reducers: {
    incrementPage: (state) => {
      state.page += 1;
    },
    decrementPage: (state) => {
      state.page = Math.max(1, state.page - 1);
    },
    clearPage: (state) => {
      state.page = 1;
    },
  },
});
export const CompanyPageSlice = createSlice({
  name: "Company",
  initialState: { page: 1 },
  reducers: {
    incrementPage: (state) => {
      state.page += 1;
    },
    decrementPage: (state) => {
      state.page = Math.max(1, state.page - 1);
    },
    clearPage: (state) => {
      state.page = 1;
    },
  },
});
export const BoundaryTypeSlice = createSlice({
  name: "BoundaryType",
  initialState: { array: [] },
  reducers: {
    setBoundaryTypes: (state, action) => {
      console.log("actionname", action.payload);
      state.array = action.payload;
    },
  },
});
export const {
  incrementPage: gateIncrement,
  decrementPage: gateDecrement,
  clearPage: gatePageclear,
} = GatePageSlice.actions;
export const {
  incrementPage: districtIncrement,
  decrementPage: districtDecrement,
  clearPage: districtPageclear,
} = DistrictPageSlice.actions;
export const {
  incrementPage: villageIncrement,
  decrementPage: villageDecrement,
  clearPage: villagePageClear,
} = VillagePageSlice.actions;
export const {
  incrementPage: dehIncrement,
  decrementPage: dehDecrement,
  clearPage: dehPageClear,
} = DehPageSlice.actions;
export const {
  incrementPage: companyIncrement,
  decrementPage: companyDecrement,
  clearPage: companyPageClear,
} = CompanyPageSlice.actions;
export const { setBoundaryTypes: setBoundaryTypes } = BoundaryTypeSlice.actions;
