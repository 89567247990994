import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import AddBatchModal from "../AddBatch";
import Button from "@mui/material/Button";
import {Grid, MenuItem, Select,} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import SpinnerShipment from "../SpinnerShipment";
import {FileDownload, QrCode} from "@mui/icons-material";
import {createBatchQrCode} from "../../../api/ginnerTraceability/batch";
import SimpleBackdrop from "../../Backdrop";
import {CustomMenuProps} from "../helpers/css/styles";

export default function AllBatchesTab({brokerOptions, heapOptions, batchOptions, setBatchOptions, driverOptions, vehicleOptions, allBatches, setAllBatches}){

    const [broker, setBroker] = useState();
    const [allPageSize, setAllPageSize] = useState(10);
    const [allPageNumber, setAllPageNumber] = useState(1);
    const [allRecord, setAllRecord] = useState(allBatches.length);
    const [batchesToView, setBatchesToView] = useState(allBatches.length ? allBatches.slice(0, allPageSize) : []);
    let [allViewBatch, setAllViewBatch] = useState({});
    const [allBatchModalIsOpen, setAllBatchModalIsOpen] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);


    const allPageOptions = Array.from(
        { length: Math.ceil(allRecord / allPageSize) },
        (_, index) => index + 1
    ).map((page) => (
        <MenuItem key={page} value={page}>
            {page}
        </MenuItem>
    ));

    const handleAllNextpage = () => {
        const totalPages = Math.ceil(allRecord / allPageSize);
        if (allPageNumber < totalPages) {
            setAllPageNumber((prevPage) => prevPage + 1);
            setBatchesToView(allBatches.slice(allPageNumber * allPageSize, (allPageNumber + 1) * allPageSize));
        }
    }

    const handleAllPreviousPage = () => {
        if (allPageNumber > 1) {
            setAllPageNumber((prevPage) => prevPage - 1);
            setBatchesToView(allBatches.slice((allPageNumber - 2) * allPageSize, (allPageNumber - 1) * allPageSize));
        }
    }

    const handleAllBatchModalOpen = () => {
        setAllBatchModalIsOpen(true);
    }

    const handleAllBatchModalClose = () => {
        setAllBatchModalIsOpen(false);
    }

    const handleAllPageChange = (e) => {
        setAllPageNumber(e.target.value);
        setBatchesToView(allBatches.slice((e.target.value - 1) * allPageSize, e.target.value * allPageSize));
    }

    const handleAllRowClick = async (params, event) => {
        if (event?.target?.attributes[2]?.nodeValue !== 'action' && event?.target?.attributes[2]?.nodeValue !== 'button') {
            const updatedViewBatch = params.row;
            if (updatedViewBatch?.qr?.imageURL){
                setAllViewBatch(updatedViewBatch);
                handleAllBatchModalOpen();
                return;
            }
            setShowBackdrop(true);
            const response = await createBatchQrCode(updatedViewBatch?.id);
            if (response){
                updatedViewBatch.qr = response;
                setAllViewBatch(updatedViewBatch);
                setShowBackdrop(false);
                handleAllBatchModalOpen();
            }else {
                setShowBackdrop(false);
                alert('Something went wrong, please try again later')
            }
        }
    }

    const handleQR = async (action, params) => {
        if (action === 'Export QR') {
            const updatedViewBatch = params?.row;
            if (updatedViewBatch?.qr?.imageURL){
                window.open(updatedViewBatch?.qr?.imageURL, '_blank');
            }else {
                setShowBackdrop(true);
                const response = await createBatchQrCode(params?.row?.id);
                if (response){
                    updatedViewBatch.qr = response;
                    const updatedAllBatches = allBatches.map((batch) => {
                        if (batch.id === updatedViewBatch.id){
                            return updatedViewBatch;
                        }else {
                            return batch;
                        }
                    });
                    setAllBatches(updatedAllBatches);
                    setShowBackdrop(false);
                    window.open(response?.imageURL, '_blank');
                }else {
                    setShowBackdrop(false);
                    alert('Something went wrong, please try again later')
                }
            }
        }
    }

    function AllBatchModal(props) {
        const batchToView  = props.batch;

        const tableData = batchToView;


        const [selectedRow, setSelectedRow] = useState(null);
        function createData(id, status, qr_code) {
            return { id, status, qr_code };
        }

        // const rows = [
        //     createData('Frozen yoghurt', 159, 6.0),
        //     createData('Ice cream sandwich', 237, 9.0),
        //     createData('Eclair', 262, 16.0),
        //     createData('Cupcake', 305, 3.7),
        //     createData('Gingerbread', 356, 16.0),
        // ];

        const ViewBailQRCode = (row) => {
            setSelectedRow(row);
            handleOpenQR();
        };

        const [openQR, setOpenQR] = React.useState(false);
        const handleOpenQR = () => {
            setOpenQR(true);
        };
        const handleCloseQR = () => {
            setOpenQR(false);
        };

        const closeImage = () => {
            setSelectedRow(null);
        };
        // const ViewBailQRCode = (params) => {
        //     console.log('params', params);
        // }


        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
        };
        function ChildModal() {


            return (
                <React.Fragment>
                    <Button onClick={handleOpenQR}>Open Child Modal</Button>
                    <Modal
                        open={openQR}
                        onClose={handleCloseQR}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                    >
                        <Box sx={{ ...style, width: 200 }}>
                            <h2 id="child-modal-title">Text in a child modal</h2>
                            <p id="child-modal-description">
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                            </p>
                            <Button onClick={handleCloseQR}>Close Child Modal</Button>
                        </Box>
                    </Modal>
                </React.Fragment>
            );
        }

        return(
            <div  style={{
                background: selectedRow !== null ? 'red' : 'transparent',
            }}
            >
                <Modal
                    open={allBatchModalIsOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 900,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            overflow: 'scroll',
                        }}
                    >
                        <Paper elevation={3} style={{ padding: "16px", background:"transparent" }} justifyContent="center"

                        >
                            <Box display="flex" justifyContent="center">

                                <img
                                    src= {batchToView.qr?.imageURL}
                                    alt="Image"
                                    style={{ maxWidth: "30%", height: "30%" }}
                                />
                            </Box>
                        </Paper>

                        {/*<TableContainer component={Paper} style={{ backgroundColor: 'transparent' }}>*/}
                        {/*    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" >*/}
                        {/*        <TableHead>*/}
                        {/*            <TableRow>*/}
                        {/*                <TableCell>ID</TableCell>*/}
                        {/*                <TableCell align="right">Bail Link</TableCell>*/}
                        {/*                <TableCell align="right">QR Code</TableCell>*/}
                        {/*            </TableRow>*/}
                        {/*        </TableHead>*/}
                        {/*        <TableBody>*/}
                        {/*            {rows.map((row) => (*/}
                        {/*                <TableRow*/}
                        {/*                    key={row.id}*/}
                        {/*                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}*/}
                        {/*                    // onClick={() => ViewBailQRCode(row)}*/}
                        {/*                >*/}
                        {/*                    <TableCell component="th" scope="row">*/}
                        {/*                        {row.id}*/}
                        {/*                    </TableCell>*/}
                        {/*                    <TableCell align="right">{row.status}</TableCell>*/}
                        {/*                    <TableCell align="right">{row.qr_code}</TableCell>*/}
                        {/*                </TableRow>*/}
                        {/*            ))}*/}
                        {/*        </TableBody>*/}
                        {/*    </Table>*/}
                        {/*</TableContainer>*/}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                            <Button variant="outlined" color="error" onClick={handleAllBatchModalClose}>
                                Close
                            </Button>
                        </Box>

                        {/*<ChildModal />*/}
                    </Box>

                </Modal>
            </div>
        )
    }

    // Batch Table Data
    const allColumns = [
        {
            field: 'id', headerName: 'Batch Number', width: 420,
        }, {
            field: 'batchLink', headerName: 'Batch Link', width: 420,
        },
        {
            field: 'created_at', headerName: 'Created At', width: 420,
        },
        {
            field: 'action', headerName: 'Action', width: 460,
            renderCell: (params) => {
                const actions = params.value;

                return (
                    <div>
                        {actions.map((action, index) => (
                            <Button
                                key={index}
                                variant="outlined"
                                color= {action === 'delete' ? 'error' : 'secondary'}
                                style={{ marginRight: "10px" }}
                                onClick={() => handleQR(action,params)}
                            >
                                <QrCode sx={{mr: 1}} fontSize={'small'}/>
                                {action}
                            </Button>
                        ))}
                    </div>
                )
            }
        }
    ];



    return(
        <div>
            <Stack direction={'row'} spacing={2} style={{marginBottom: "30px"}}>
                <div>
                    {AddBatchModal({options: heapOptions, batches: batchOptions, setBatches: setBatchOptions})}
                </div>
                <div>
                    <Button variant="outlined" color="secondary">
                        <FileDownload sx={{mr: 1}} fontSize={'small'}/>
                        Export All Batches
                    </Button>
                </div>
                <div>
                    <SpinnerShipment completedBatches={batchOptions} driverOptions={driverOptions}/>
                </div>
            </Stack>
            <div style={{ height: 600, width: '100%' }}>
                <Grid container>
                    <Grid item xs={12} style={{flex: '1', position: 'relative'}}>
                        <DataGrid
                            sx={{minHeight: '55vh'}}
                            rows={batchesToView}
                            columns={allColumns}
                            pageSize={allPageSize}
                            onPageSizeChange={(newPageSize) => setAllPageSize(newPageSize)}
                            // rowsPerPageOptions={[10]}
                            onRowClick={handleAllRowClick}
                            components={{
                                Footer: () => (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        padding: '8px'
                                    }}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleAllPreviousPage}
                                            style={{marginRight: "8px"}}
                                            disabled={allPageNumber === 1}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            onClick={handleAllNextpage}
                                            style={{marginRight: "8px"}}
                                            disabled={allPageNumber === Math.ceil(allBatches.length / allPageSize)}
                                        >
                                            Next
                                        </Button>
                                        <p style={{marginRight: "8px"}}>Total Batches:{allRecord}</p>
                                        <p style={{margin: '10px'}}>Items: {batchesToView.length}</p>
                                        <p>Page:
                                            <Select value={allPageNumber} onChange={handleAllPageChange}
                                                    MenuProps={CustomMenuProps} style={{marginRight: "8px",}}>
                                                {allPageOptions}
                                            </Select>
                                        </p>
                                    </div>
                                ),
                            }}
                        />
                        {
                            showBackdrop && (
                                <SimpleBackdrop open={showBackdrop} setOpen={setShowBackdrop}/>
                            )
                        }
                        {allBatchModalIsOpen && (
                            <>
                                <AllBatchModal batch={allViewBatch}/>
                            </>
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
