import Topbar from "../../components/Topbar";
import ManagementTable from "../../components/tables/ManagementTable";
import {Button, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {decrementFormCustomizationPage, incrementFormCustomizationPage, formBaseCase} from "../../reduxSetup/SurveySetupPages";
import {useDispatch, useSelector} from "react-redux";
import FormCustomizationModal from "../../components/formCustomization/FormCustomizationModal";
import {getFields} from "../../api/surveySetup/FormCustomization";


export default function FormCustomization() {

    const [modalOpen, setModalOpen] = useState(false);
    const [fields, setFields] = useState([])
    const dispatch = useDispatch();
    const currentPage = useSelector(state => state.formCustomization.page);
    const params = {p: currentPage}
    const [next, setNext] = useState()
    const [previous, setPrevious] = useState()
    const headerNames = [
        {header:'Title',value:'name'},
        {header:'Type',value:'type'},
        // {header:'isRequired',value:'is_required'},
        {header:'Created by',value:'admin_details.name'},
        {header:'Creation Date',value:'created_at'},
    ];

    async function fetchFields() {
        try {
            const res = await getFields(params)
            setNext(res?.next)
            setPrevious(res?.previous)
            const formattedRes = res?.results.map(item => ({
                ...item,
                created_at: new Date(item.created_at).toLocaleString(),
            }));
            setFields(formattedRes);
            
        } catch (error) {
            console.error(error)
        }

    }

    useEffect(() => {
        if (!fields.length) {
            fetchFields();
        }
    }, [currentPage]);

    const handleOpenModal = () => {
        setModalOpen(true);
    };
    const handleNextPage = () => {
        dispatch(incrementFormCustomizationPage());
        window.location.reload();
    };

    const handlePreviousPage = () => {
        dispatch(decrementFormCustomizationPage());
        window.location.reload();
    };


    return (
        <div>
            <Topbar/>
            <div style={{width:'60%', marginTop:'10px'}}>
                <ManagementTable
                    headerNames={headerNames}
                    rowsData={fields}
                    title='Field Information'
                    actionButtonLabel='Add New'
                    onActionButtonClick={handleOpenModal}
                    ModalComponent={FormCustomizationModal}
                    footer={
                        <Stack direction="row" spacing={2}
                                       sx={{marginTop: '5%', marginBottom:'3%', justifyContent: 'flex-end', position: 'relative', right: '7%'}}>
                            <Button className='footerbtn-variety' variant='outlined' disabled={previous == null} onClick={handlePreviousPage}
                            >
                                previous
                            </Button>
                            <Button className='footerbtn-variety' variant='outlined' disabled={next === null} onClick={handleNextPage}
                            >
                                next
                            </Button>
                        </Stack>
                    }
                />

            </div>
        </div>
    )
}