import React, { useState } from 'react';
import {  accordianTaskColumns } from 'components/develoPro/helpers/DataColumns';
import { Button, Typography, Stack, Accordion, AccordionDetails } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {  OptionIcon, ViewEye, DeleteIcon, EditIcon } from 'assets/Icons';
import { DataGrid } from '@mui/x-data-grid';
import DeleteDialog from './DeleteDialog';
import Modal from './Modal';
import { useNavigate } from 'react-router-dom';



export default function AccordionTable({projectsData}) {
    const [expandedRow, setExpandedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [editmode, setEditMode] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const navigate = useNavigate()
    const handleOpen = (project) => {
      setOpen(true);
      setSelectedProject(project);
    };
  
    const handleClose = () => {
      setOpen(false);
      setSelectedProject(null)
    };
    const handleOpenEdit = (project) => {
      setEditMode(true);
      setSelectedProject(project);
    };
  
    const handleCloseEdit = () => {
      setEditMode(false);
      setSelectedProject(null)
    };
    
  const handleAccordionChange = (rowId) => (event, isExpanded) => {
    setExpandedRow(isExpanded ? rowId : null);
   
  };
  const handleRowClick = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  return (
    <div style={{ height: '380px', width: '95%', border: '1px solid #262532', overflow: 'auto', borderRadius:'20px', marginTop:'1%' }}>
    <table style={{ width: '95%', borderCollapse: 'collapse', color: '#262532', marginLeft:'2%', marginRight:'2%' }}>
      {/* Table Header */}
      <thead style={{ borderBottom: '1px rgba(240.12, 240.12, 240.12, 0.12) solid', height: '40px' }}>
        <tr>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '98%' }}>

            <div style={{ opacity: 0.70,  margin: '20px 20px 20px 0px', flex: '1' }}>
              <Typography>Project Title</Typography>
            </div>
            <div style={{ opacity: 0.70,  margin: '20px', flex: '1',right:'1%', position:'relative' }}>
              <Typography>Created By</Typography>
            </div>
            <div style={{ opacity: 0.70,  margin: '20px', flex: '1', }}>
              <Typography>Due Date</Typography>
            </div>
            <div style={{ opacity: 0.70, margin: '20px', flex: '1', right:'10%', position:'relative'   }}>
              <Typography>Progress</Typography>
            </div>
          </div>
        </tr>
      </thead>
      {/* Table Body */}
      <tbody>
        {Array.isArray(projectsData?.results) &&
          projectsData?.results?.map((project) => (
            <React.Fragment key={project?.id}>
              {/* Project Row */}
              <Accordion expanded={expandedRow === project?.id} onChange={handleAccordionChange(project?.id) } sx={{background: 'transparent', border:'none'}}>
          
                  <tr onClick={() => handleRowClick(project?.id)}>
                    
                    <td style={{ width: '360px', borderBottom:'1px rgba(240.12, 240.12, 240.12, 0.12) solid', margin:'20px'  }}>{project?.name}</td>
                    <td style={{ width: '350px', borderBottom:'1px rgba(240.12, 240.12, 240.12, 0.12) solid', margin:'20px'  }}>{project?.created_by}</td>
                    <td style={{ width: '160px', borderBottom:'1px rgba(240.12, 240.12, 240.12, 0.12) solid', margin:'20px'  }}>{project?.end_date}</td>
                    <td style={{ width: '300px', borderBottom:'1px rgba(240.12, 240.12, 240.12, 0.12) solid', margin:'20px'   }}>
                      <span style={{ display: 'flex', alignItems: 'center', width: '110' }}>
                        <LinearProgress
                          variant= "determinate"
                          value={parseInt(project?.percentage_completed, 10)}
                          color='success'
                          sx={{ flexGrow: 1, marginRight: '8px'}}
                        />
                        <span style={{opacity: 0.50, color: 'white', fontSize: 14, fontFamily: 'DM Sans', fontWeight: '400', wordWrap: 'break-word'}}>{`${project?.percentage_completed.toFixed(2)}%`}</span>
                      </span>
                    </td>
                    <td style={{ width: '30px', borderBottom:'1px rgba(240.12, 240.12, 240.12, 0.12) solid', height:'55px' }}>
                      <Button sx={{width:'10px'}} onClick={() => navigate(`/developro/projects/id/${project?.id}/page/1`)}><ViewEye style={{height:'20px', width:'20px'}}/></Button>
                    </td>
                    <td style={{ width: '10px', borderBottom: '1px rgba(240.12, 240.12, 240.12, 0.12) solid', height: '55px' }}>
                      
                      <Stack direction='row' spacing={1} >
                        <Button size='small' sx={{borderRight:'1px solid #262532', borderRadius:'0px', top:'4px',}} onClick={() => handleOpenEdit(project)}>
                          <EditIcon style={{ height: '12px', width: '12px', color:'#000' }}/>
                        </Button>
                        <Button size='small' sx={{top:'4px', borderRadius:'0px',}} onClick={() => handleOpen(project)}>
                          <DeleteIcon style={{ height: '12px', width: '12px'  }}/>
                        </Button>
                      </Stack>
                      
                    </td>
                  </tr>
               
                <AccordionDetails>
                <div style={{ height:'280px', width:'100%' }}> 
                  <DataGrid  
                      rows={Array.isArray(project?.recent_tasks) ? project?.recent_tasks : []}
                      columns={accordianTaskColumns}
                      pageSize={5}
                      />
                </div>
                </AccordionDetails>
              </Accordion>
              <DeleteDialog
                open={open}
                close={handleClose}
                projectName={selectedProject}
                totalTask={projectsData}/>
            </React.Fragment>
          ))}
      </tbody>
    </table>
    <Modal 
    open={editmode}
    close={handleCloseEdit}
    project={selectedProject}
    title='Edit Project'/>
  </div>
  )
}
