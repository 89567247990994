import axios from "axios";
const BASEURL = process.env.REACT_APP_BASEURL


export const createBale = async (data) => {
    try {
        const response = await axios.post(`${BASEURL}/api/bale/`, data);
        return response?.data;
    } catch (error) {
        console.error('Server Error creating Bale \n', error);
        return false;
    }
}

export const  generateBaleQR= async (baleId) => {
    try {
        const response = await axios.get(`${BASEURL}/api/bale/generate_qr/?bale_id=${baleId}`, { responseType: 'arraybuffer' } );
        if (response.status === 200){
            const blob = new Blob([response?.data], { type: 'image/png' });
            const imageURL = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = imageURL;
            downloadLink.download = 'bale'+ baleId + '.png' || 'image.png';
            return { imageURL : imageURL, downloadLink : downloadLink};
        }
    } catch (error) {
        console.error('Server Error generating QR Bale \n', error);
        return false;
    }
}

export const getBales = async () => {
    try {
        const response = await axios.get(`${BASEURL}/api/bale/`);
        return response?.data;
    } catch (error) {
        console.error('Server Error getting Bale \n', error);
        return false;
    }
}

export const getBale = async (baleId) => {
    try {
        const response = await axios.get(`${BASEURL}/api/bale/${baleId}`);
        return response?.data;
    } catch (error) {
        console.error('Server Error getting Bale \n', error);
        return false;
    }
}