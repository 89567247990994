import axios from "axios";
const BASEURL = process.env.REACT_APP_BASEURL;




//function to get brokers
export const getBrokers = async () => {
    try {
        const response = await axios.get(`${BASEURL}/api/broker/`);
        console.log('Broker Response', response?.data);
        return response?.data;
    } catch (error) {
        console.log('Server error getting Brokers \n',error);
        return false;
    }
}

//function to create broker
export const createBroker = async (data) => {
    try {
        const response = await axios.post(`${BASEURL}/api/broker/`, data);
        return response?.data;
    } catch (error) {
        console.log('Server error creating Broker \n',error);
        return false;
    }
}