import axios from "axios";
const BASEURL = process.env.REACT_APP_BASEURL;


//function to get all vehicles
export const getVehicles = async () => {

    try {
        const response = await axios.get(`${BASEURL}/api/vehicle/`);
        return response?.data;
    } catch (err) {
        console.error('Server error getting Vehicles \n',err);
        return false;
    }
}

//function to create a vehicle
export const createVehicle = async (vehicle) => {

    try {
        const response = await axios.post(`${BASEURL}/api/vehicle/`, vehicle);
        return response?.data;
    } catch (err) {
        console.error('Server error creating Vehicle \n',err);
        return false;
    }
}