import React, {useEffect, useState} from "react";
import { Button, Typography, Modal, TextField } from "@mui/material";
import {getSurroundingFields, updateFieldsById} from "api/allSurveys/Fields";
import { useNavigate } from "react-router-dom";
import {
    FeatureGroup,
    MapContainer,
    Polygon,
    ZoomControl,
    LayersControl,
    TileLayer,
    GeoJSON
} from "react-leaflet";
import ImageModal from "../ImageModal";
export default function TopFieldDetail({ data }) {
    const [openModal, setOpenModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    const navigate = useNavigate();
    const [surroundingFields, setSurroundingFields] = useState(null);
    const [images, setImages] = useState([]);
    const [open, setOpen] = useState(false);


    const coords = data?.geometry?.coordinates[0].map((coord) => [
        coord[1],
        coord[0],
    ]);

    // Fetch Surrounding Fields against ID
    useEffect(() => {
        if (data.id) {
            getSurroundingFields(data.id).then((res_data) => {
                if (res_data) {
                    setSurroundingFields(res_data)
                }
            })
        }
    }, [data.id])


    const changeStatus = async (newStatus, remarks) => {
        try {
            const payload = {
                status: newStatus,
            };
            if (remarks !== null) {
                payload["rejection_feedback"] = remarks;
            }
            await updateFieldsById(data?.id, payload);
        } catch (error) {
            // Handle the error
            console.error(error);
        }
    };
    const handleReject = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const formatDateTime = (timestamp) => {
        if (!timestamp) return "";
        const dateObject = new Date(timestamp);
        const formattedDateTime = dateObject.toLocaleString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
        });

        return formattedDateTime;
    };
    const latitude = data?.properties?.center_coordinates?.coordinates[0];
    const longitude = data?.properties?.center_coordinates?.coordinates[1];
    const Status = (status) => {
        switch (status) {
            case "AP":
                return "Approved";

            case "RE":
                return "Rejected";
            case "PD":
                return "Pending";
            default:
                break;
        }
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "100%",
                    background: "white",
                    boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
                    borderRadius: 20,
                    padding: "2%",
                }}
            >
                <div style={{ width: "50%" }}>
                    <div style={{ padding: "1%" }}>
                        <Typography variant="h5" fontWeight="bold">
                            Field Detail
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontWeight: "700",
                                    }}
                                    style={{ fontFamily: "Century Gothic" }}
                                >
                                    Survey ID
                                </Typography>
                                <Typography>{data?.id}</Typography>
                            </div>
                        </div>
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Field Name
                                </Typography>
                                <Typography>{data?.properties?.field_name}</Typography>
                            </div>
                        </div>
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Status
                                </Typography>
                                <Typography>{Status(data?.properties?.status)}</Typography>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                        }}
                    >
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Created At:
                                </Typography>
                                <Typography>
                                    {formatDateTime(data?.properties?.created_at)}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Updated At:
                                </Typography>
                                <Typography>
                                    {formatDateTime(data?.properties?.updated_at)}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Upload date
                                </Typography>
                                <Typography>
                                    {formatDateTime(data?.properties?.created_at)}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                        }}
                    >
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Field Address
                                </Typography>
                                <Typography>{data?.properties?.field_address}</Typography>
                            </div>
                        </div>
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Circle Name
                                </Typography>
                                <Typography>{data?.gate_circle?.properties?.name}</Typography>
                            </div>
                        </div>
                        <div>
                            <div style={{ width: "120px" }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Village Name
                                </Typography>
                                <Typography>{data?.grower?.village_code}</Typography>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                        }}
                    >
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    System Area
                                </Typography>
                                <Typography>
                                    {data?.properties?.total_area.toFixed(2)}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Area Entered
                                </Typography>
                                <Typography>
                                    {data?.properties?.area_entered.toFixed(2)}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Variance
                                </Typography>
                                <Typography>
                                    {(
                                        data?.properties?.area_entered -
                                        data?.properties?.total_area
                                    ).toFixed(2)}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                        }}
                    >
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Field Image
                                </Typography>

                                <Button variant='text' onClick={() => {
                                    if (data?.properties?.images !== null) {
                                        setImages(data?.properties?.images?.map((image) => image?.url))
                                        setOpen(true)
                                    }else {
                                        alert('No images found')
                                    }
                                }}>view</Button>
                            </div>
                        </div>
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Murabba No.
                                </Typography>
                                <Typography>
                                    {(data?.properties?.total_area.toFixed(2) / 25).toFixed(2)}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ width: "120px" }}>
                            <div>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#1BC693",
                                        fontSize: 16,
                                        fontFamily: "Century Gothic",
                                        fontWeight: "700",
                                    }}
                                >
                                    Field Centroid:
                                </Typography>
                                <Typography>longitude: {longitude}</Typography>
                                <Typography>latitude: {latitude}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "45%", position: "relative" }}>
                    <div
                        style={{
                            padding: "1%",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Typography variant="h5" fontWeight="bold">
                            <Button
                                variant="contained"
                                sx={{
                                    background: "#1BC693",
                                    borderRadius: "12px",
                                    marginRight: "5px",
                                }}
                                onClick={() => {
                                    navigate(`/all-surveys/bounderies/field/edit/${data?.id}`);
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    background: "#1BC693",
                                    borderRadius: "12px",
                                    marginRight: "5px",
                                }}
                                onClick={() => {
                                    changeStatus("AP");
                                    window.location.reload();
                                }}
                            >
                                Accept
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ background: "#F46464", borderRadius: "12px" }}
                                onClick={() => {
                                    handleReject();
                                }}
                            >
                                Reject
                            </Button>
                        </Typography>
                    </div>
                    {coords && (
                        <MapContainer
                            center={coords[0]}
                            zoom={15}
                            key={data.id}
                            maxZoom={18}
                            scrollWheelZoom={true}
                            style={{
                                height: "50vh",
                                width: "100%",
                                position: "relative",
                                top: "10px",
                                borderRadius: "15px",
                            }}
                            attributionControl={false}
                        >
                            <LayersControl position="topright">
                                <LayersControl.BaseLayer name="Satellite" checked={true}>
                                    <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
                                </LayersControl.BaseLayer>
                                <LayersControl.Overlay name="Field" checked={true}>
                                    <FeatureGroup>
                                        <Polygon positions={coords} />
                                    </FeatureGroup>
                                </LayersControl.Overlay>
                                <LayersControl.Overlay name="Overlapping Fields" checked={true}>
                                    <FeatureGroup>
                                        {surroundingFields &&
                                            <GeoJSON data={surroundingFields}
                                                     key={data.id}
                                                     style={(feature) => ({
                                                         color: 'red', fillColor: 'red', fillOpacity: 0.25, weight: 1, // Adjust border width
                                                     })}
                                                     onEachFeature={(feature, layer) => {
                                                         if (feature.properties && feature.id) {
                                                             // Dynamically generate the HTML content for the popup
                                                                  const popupContent = `<div style="font-family: Arial, sans-serif; max-width: 400px; margin: 10px auto; overflow: hidden;">
                                                                 <div style="padding: 10px;">
                                                                     <h2 style="margin: 0; font-size: 18px; color: #006400;">Survey Details</h2>
                                                                 </div>
                                                                 <div style="padding: 10px; color: #006400;">
                                                                     <p><strong>Survey ID:</strong> ${feature.id}</p>
                                                                     <p><strong>Passbook Number:</strong> ${feature.grower?.passbook_number || 'N/A'}</p>
                                                                     <p><strong>Grower Name:</strong> ${feature.grower?.grower_name || 'N/A'}</p>
                                                                     <p><strong>Father Name:</strong> ${feature.grower?.father_name || 'N/A'}</p>
                                                                     <p><strong>Surveyor ID:</strong> ${feature.properties.user || 'N/A'}</p>
                                                                     <p style="margin-bottom: 0;">
                                                                         <a href="/all-surveys/bounderies/field/${feature.id}/" style="display: inline-block; background-color: #228B22; color: #fff; padding: 8px 16px; text-decoration: none; border-radius: 4px;">View</a>
                                                                     </p>
                                                                 </div>
                                                             </div>`;
                                                             layer.bindPopup(popupContent);
                                                         }
                                                     }}
                                            />

                                        }

                                    </FeatureGroup>
                                </LayersControl.Overlay>

                            </LayersControl>
                            <ZoomControl position="bottomright" />
                        </MapContainer>
                    )}
                </div>
            </div>
            {/* Modal for rejection reason */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        background: "white",
                        boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
                        borderRadius: 20,
                        padding: "2%",
                    }}
                >
                    <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                        Enter Rejection Reason:
                    </Typography>
                    <TextField
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                    />
                    <div style={{ marginTop: "1rem", textAlign: "right" }}>
                        <Button onClick={handleCloseModal} sx={{ marginRight: "1rem" }}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                changeStatus("RE", rejectionReason);
                                window.location.reload();
                            }}
                        >
                            Reject
                        </Button>
                    </div>
                </div>
            </Modal>

            {
                open && (
                    <ImageModal setImages={images} open={open} onClose={() => setOpen(false)} />
                )
            }
        </div>
    );
}
