import React, { useEffect, useState } from "react";
import Topbar from "components/Topbar";
import CropTypeModal from "components/cropTypeManagement/CroptypeModal";
import ManagementTable from "components/tables/ManagementTable";
import { Stack, Button } from "@mui/material";
import {
  incrementCropTypePage,
  decrementCropTypePage,
} from "reduxSetup/SurveySetupPages";
import { useSelector, useDispatch } from "react-redux";
import { getCroptype } from "api/surveySetup/Crop";
export default function CropTypeManagement() {
  const [modalOpen, setModalOpen] = useState(false);
  const [cropType, setCropType] = useState([]);
  const [next, setNext] = useState();
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.cropType.page);
  const headerNames = [
    { header: "Crop Type", value: "name" },
    { header: "Created by", value: "admin_details.name" },
    { header: "Creation Date", value: "created_at" },
  ];
  const params = { p: currentPage };
  const fetchStage = async () => {
    try {
      const res = await getCroptype(params);
      setNext(res?.next);
      const formattedRes = res?.results?.map((item) => ({
        ...item,
        created_at: new Date(item.created_at).toLocaleString(),
      }));
      setCropType(formattedRes);
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSaveVariety = () => {
    handleCloseModal();
  };
  const handleNextPage = () => {
    dispatch(incrementCropTypePage());
  };

  const handlePreviousPage = () => {
    dispatch(decrementCropTypePage());
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    fetchStage();
  }, [currentPage]);
  return (
    <div>
      <Topbar />

      <div style={{ width: "60%", marginTop: "10px" }}>
        <ManagementTable
          headerNames={headerNames}
          rowsData={cropType}
          title="Crop Type "
          actionButtonLabel="Add More"
          onActionButtonClick={handleOpenModal}
          ModalComponent={CropTypeModal}
          footer={
            <Stack
              direction="row"
              spacing={2}
              sx={{
                marginTop: "5%",
                marginBottom: "3%",
                justifyContent: "flex-end",
                position: "relative",
                right: "7%",
              }}
            >
              <Button
                className="footerbtn-variety"
                variant="outlined"
                onClick={handlePreviousPage}
              >
                previous
              </Button>
              <Button
                className="footerbtn-variety"
                variant="outlined"
                disabled={next === null}
                onClick={handleNextPage}
              >
                next
              </Button>
            </Stack>
          }
        />

        {/* Render the CropTypeModal component conditionally */}
        {modalOpen && (
          <CropTypeModal
            open={modalOpen}
            onClose={handleCloseModal}
            onSaveVariety={handleSaveVariety}
          />
        )}
      </div>
    </div>
  );
}
