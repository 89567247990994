import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField, useTheme } from '@mui/material';
import { tokens } from '../assets/Theme';
import axios from 'axios';
import { createNotification } from './Notifications';

export default function AddModal(props) {
  const [state, setState] = useState({
    fullname: '',
    username: '',
    mobile_number: '',
    role: '',
    password: '',
    cnic: '',
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'cnic') {
      const formattedCNIC = formatCNIC(value);
      setState((prevState) => ({
        ...prevState,
        cnic: formattedCNIC,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const formatCNIC = (cnic) => {
    const cleanedCNIC = cnic.replace(/\D/g, '');

    if (cleanedCNIC.length === 13) {
      return cleanedCNIC.replace(/^(\d{5})(\d{7})(\d{1})$/, '$1-$2-$3');
    }

    return cleanedCNIC;
  };

  const handleGeneratePassword = () => {
    // Generate password logic
  };

  const handleAddEmployee = () => {
    try {
      axios.post(`${process.env.REACT_APP_BASEURL}/api/users/`, state)
        .then((response) => {
          console.log('res:', response);
          props.onAddEmployee(response.data);
          setState({
            fullname: '',
            username: '',
            mobile_number: '',
            role: '',
            password: '',
            cnic: '',
          });
          props.onClose(); // Call onClose() function after successful POST request
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle sx={{ color: colors.grey[100] }}>Add Employee</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: colors.grey[100] }}>
          To add a new employee, please enter their details below:
        </DialogContentText>
        <TextField
          margin="dense"
          label="Full Name"
          fullWidth
          name="fullname"
          value={state.fullname}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Username"
          fullWidth
          name="username"
          value={state.username}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="CNIC"
          fullWidth
          type="text"
          name="cnic"
          value={state.cnic}
          onChange={handleChange}
          inputProps={{
            maxLength: 15, // Set the maximum length of the input field
          }}
        />
        <TextField
          margin="dense"
          label="Phone Number"
          type="number"
          fullWidth
          name="mobile_number"
          value={state.mobile_number}
          onChange={handleChange}
        />
        <TextField
          select
          margin="dense"
          label="Role"
          fullWidth
          name="role"
          value={state.role}
          onChange={handleChange}
        >
          <MenuItem value="A">Auditor</MenuItem>
          <MenuItem value="S">Surveyor</MenuItem>
        </TextField>
        <TextField
          margin="dense"
          label="Password"
          fullWidth
          name="password"
          value={state.password}
          onChange={handleChange}
        />
        <Button color="success" variant="outlined" onClick={handleGeneratePassword}>
          Generate Password
        </Button>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: colors.grey[100] }} onClick={props.onClose}>
          Cancel
        </Button>
        <Button sx={{ color: colors.blueAccent[400] }} onClick={handleAddEmployee}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
