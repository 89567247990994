import {NotificationManager} from 'react-notifications';
export const createNotification = (type, msg) => {
    
    if (!msg){
        msg = 'Unhandled Error';
    } else if (typeof(msg) === 'object'){
        msg = JSON.stringify(msg)
    }
    
    switch (type) {
        case 'info':
            NotificationManager.info('Info message');
            break;
        case 'fetched':
           
            NotificationManager.success('Data Fetched', 'Success', 1000);
            break;
        case 'created':
            NotificationManager.success('Created Data', 'Success', 1000);
            break;
        case 'warning':
            NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
            break;
        case 'error':
            NotificationManager.error(msg, 'Error', 5000, () => {alert('callback');});
            break;
        case 'unauth':
            NotificationManager.error('Unauthenticated!', 'Login Again to Continue', 5000, () => {
                alert('callback');
            });
            break;
        case 'login_failed':
            NotificationManager.error('Login Failed!', 'Invalid Mobile Number or Password', 5000, () => {
                alert('callback');
            });
            localStorage.clear();
            window.location.href = "/";
            break;

        default:
            break;
    }
};
