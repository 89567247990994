import { TextField, Box, Typography, Button } from "@mui/material";

// export default BoundaryDetail;
import React from "react";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { useNavigate } from "react-router-dom";

const BoundaryDetailPopup = (map, latlng, data, id, navigate) => {
  const popupContent = ReactDOMServer.renderToStaticMarkup(
    <PopupCard data={data} navigate={navigate} />,
  );
  const popupOptions = {
    // minWidth: 550,
    closeButton: true,
    closeOnClick: false,
  };

  const popup = L.popup(popupOptions)
    .setLatLng(latlng)
    .setContent(popupContent)
    .openOn(map);
  const buttonEl = document.getElementById("popup-nav-button");
  if (buttonEl) {
    buttonEl.addEventListener("click", () => {
      navigate(`/all-surveys/bounderies/${id}/detail`);
    });
  }

  return popup;
};

const PopupCard = ({ data, navigate }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Changed to column for vertical display
        backgroundColor: "white",
        border: "1px solid #1BC693",
        width: "15vw",
        marginTop: "70px !important",
        margin: "auto",
        padding: 0, // Added padding for better presentation
        borderRadius: "5px",
        padding: 2,
        zIndex: 1,
        backgroundColor: "white",
      }}
    >
      <Typography>
        <span style={{ color: "#1BC693", marginRight: "5px" }}>
          Boundary Name:{" "}
        </span>
        {data?.name || "-"}
      </Typography>

      <Typography>
        <span style={{ color: "#1BC693", marginRight: "5px" }}>
          Total Area:{" "}
        </span>
        {data?.total_area === null ? 0 : data?.total_area.toFixed(2)}
      </Typography>
      <Typography>
        <span style={{ color: "#1BC693", marginRight: "5px" }}>
          Surveyed Area:{" "}
        </span>
        {data?.surveyed_area === null ? 0 : data?.surveyed_area.toFixed(2)}
      </Typography>
      <Typography>
        <span style={{ color: "#1BC693", marginRight: "5px" }}>
          Total Fields:{" "}
        </span>
        {data.total_fields || 0}
      </Typography>
      <Typography>
        <span style={{ color: "#1BC693", marginRight: "5px" }}>
          Fields Approved:
        </span>
        {data.fields_approved || 0}
      </Typography>
      <Typography>
        <span style={{ color: "#1BC693", marginRight: "5px" }}>
          Total Growers:{" "}
        </span>
        {data.total_growers || 0}
      </Typography>
      {/* <Button>
          <span style={{ color: "#0275C6", textAlign: "center" }}>
            View Details
          </span>
        </Button> */}
      <div className="button-container">
        <button className="navigate-button" id="popup-nav-button">
          Show Details
        </button>
      </div>
    </Box>
  );
};

export default BoundaryDetailPopup;
