import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { searchGrowers } from '../../api/ginnerTraceability/grower';
import { customSearchableSelectStyles } from './helpers/css/styles';

const animatedComponents = makeAnimated();

export default function AsyncSearchableSelect({ state, setState, options, setOptions }) {
    const [inputValue, setInputValue] = useState('');
    const loadOptions = async (inputValue) => {
        try {
            const resp = await searchGrowers(inputValue);
            if (resp) {
                return resp?.results.map((grower) => ({
                    value: grower.passbook_number,
                    label: grower.passbook_number,
                    ...grower,
                }));
            }
        }catch (error) {
            alert('Something went wrong! Please try again.');
        }

    };

    const handleInputChange = (inputValue, { action }) => {
        setInputValue(inputValue);

        if (action === 'input-change') {
            if (inputValue.length < 3) return [];
            loadOptions(inputValue).then((newOptions) => {
                const mergedOptions = [...options, ...newOptions];
                setOptions(mergedOptions);
            });
        }
    };

    const setSelected = (selected) => {
        setState(selected);
    };

    return (
        <div style={{ width: '270px' }}>
            <Select
                isSearchable={true}
                styles={customSearchableSelectStyles}
                components={animatedComponents}
                options={options || []}
                state={state}
                onInputChange={handleInputChange}
                onChange={setSelected}
                placeholder={'Please Select an Option'}
                inputValue={inputValue}
            />
        </div>
    );
}
