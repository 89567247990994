import React, { useState } from 'react';
import ViewSwitch from './SwitchView';
import { Grid, Button, Typography } from '@mui/material';
import { ExportIcon, ImportIcon } from 'assets/Icons';
import ImportModal from './ImportModal';
import ExportModal from './ExportModal';

export default function TopBarView() {
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const handleImportModalOpen = () => {
    setImportModalOpen(true);
  };

  const handleImportModalClose = () => {
    setImportModalOpen(false);
  };
  const handleExportModalOpen = () => {
    setExportModalOpen(true);
  };

  const handleExportModalClose = () => {
    setExportModalOpen(false);
  };

  return (
    <>
      <div className='projectBar'>
        <Grid container direction='row'>
          <Grid item xs={6} sx={{ margin: '15px 10px' }}>
            {/* <ViewSwitch /> */}
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="text"
              sx={{ color: '#000', margin: '2px', position: 'relative', left: '91%' }}
              onClick={handleImportModalOpen}
            >
              <ImportIcon />
              <Typography sx={{ margin: '4px', fontSize: '13px' }}>Import </Typography>
            </Button>
            <Button variant="text" sx={{ color: '#000', margin: '2px', position: 'relative', left: '91%' }}
            onClick={handleExportModalOpen}>
              <ExportIcon />
              <Typography sx={{ margin: '4px', fontSize: '13px' }}>Export </Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
      <ImportModal open={importModalOpen} handleClose={handleImportModalClose} />
      <ExportModal open={exportModalOpen} handleClose={handleExportModalClose}/>
    </>
  );
}
