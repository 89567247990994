import React from "react";
import TextField from "@mui/material/TextField";
import { tokens } from "assets/Theme";
import { useTheme } from "@mui/material";
const InputCustom = ({
  value,
  id,
  onChange,
  name = "",
  label = "",
  width = "50%",
  ...otherProps
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <TextField
      margin="normal"
      {...otherProps}
      //   InputLabelProps={{ shrink: true }}
      sx={{
        width: width,
        position: "relative",
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: `${colors.customColor[100]}`,
        },
        "& label.Mui-focused": {
          color: `${colors.customColor[100]} !important`,
        },
        "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          {
            borderColor: `${colors.customColor[100]} !important`,
          },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: `${colors.customColor[100]} !important`,
          borderRadius: "px !important",
        },
      }}
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};

export default InputCustom;
