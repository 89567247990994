import Control from 'react-leaflet-custom-control'
import {Button} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import styles from "../../assets/styles.module.css"

export function Delete({deletePolygon}) {
    return (
      <Control position="bottomright">
        <Button
          className={styles["btn"]}
          color="error"
          variant={"contained"}
          onClick={deletePolygon}
        >
          <DeleteIcon />
        </Button>
      </Control>
    );

}
