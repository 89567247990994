import {
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    FormGroup, Grid, IconButton, InputAdornment,
    MenuItem, OutlinedInput,
    TextField,
    useTheme
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {tokens} from "../../assets/Theme";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Add} from "@mui/icons-material";
import {Stack} from "@mui/system";
import {createField, deleteField, updateField} from "../../api/surveySetup/FormCustomization";
import { useDispatch } from "react-redux";
import { formBaseCase } from "reduxSetup/SurveySetupPages";

export default function FormCustomizationModal({open, onClose, editData = null, onSaveVariety}) {


    const storedCompanyInfo = JSON.parse(localStorage.getItem('companyInfo'));
    const dispatch = useDispatch();
    const [fieldInfo, setFieldInfo] = useState({
        name: '',
        type: '',
        dropdownOptions: [],
        // is_required: false,
    });
    const [dropValue, setDropValue] = useState('');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const typeOptions = [
        {value: 'text', label: 'Text'},
        {value: 'number', label: 'Number'},
        {value: 'date', label: 'Date'},
        {value: 'dropdown', label: 'DropDown'},
    ];
    useEffect(() => {
        if (editData !== null) {
            setFieldInfo({
                name: editData?.name || '',
                type: editData?.type || '',
                dropdownOptions: editData?.data || [],
                // is_required: editData?.is_required || false,
            });
        }
    }, [editData]);

    const handleSaveForm =async () => {
        try {
            if (editData === null) {
                const payload =   {
                    name: fieldInfo.name,
                    type: fieldInfo.type,
                    data: fieldInfo.type === 'dropdown' ? fieldInfo.dropdownOptions : [],
                    admin: editData?.admin || storedCompanyInfo?.id
                }
                await createField(payload)
                dispatch(formBaseCase())
                window.location.reload();
            }else {
                const payload =   {
                    name: fieldInfo.name,
                    type: fieldInfo.type,
                    data: fieldInfo.type === 'dropdown' ? fieldInfo.dropdownOptions : [],
                    admin: editData?.admin || storedCompanyInfo?.id
                }
                await updateField(editData?.id, payload)
                window.location.reload();
            }
        }catch (error) {
            console.error(error);
            alert('An error occurred, Kindly try again.')
        }

    }

    const handleDeleteField =async()=>{
        await deleteField(editData?.id);
        window.location.reload();
    }

    const handleAddOptions = () => {
        const newOption = { id: String(Date.now()), name: dropValue };
        setFieldInfo({
            ...fieldInfo,
            dropdownOptions: [...fieldInfo.dropdownOptions, newOption]
        });
        setDropValue('');
    };

    const handleDelete = (optionId) => () => {
        setFieldInfo({
            ...fieldInfo,
            dropdownOptions: fieldInfo.dropdownOptions.filter((option) => option.id !== optionId)
        });
    };

    const groupedOptions = useMemo(() => {
        return fieldInfo.dropdownOptions.reduce((acc, option, index) => {
            const groupIndex = Math.floor(index / 3);
            if (!acc[groupIndex]) {
                acc[groupIndex] = [];
            }
            acc[groupIndex].push(option);
            return acc;
        }, []);
    }, [fieldInfo.dropdownOptions]);



    return (
        <div>
            <Dialog open={open} onClose={onClose} sx={{'& .MuiDialog-paper': {height: '370px', width: '600px'}}}>
                <DialogTitle sx={{background: `${colors.customColor[500]}`, fontSize: '20px', padding: '20px'}}>Add New Field</DialogTitle>
                <DialogContent
                    sx={{
                        background: `${colors.customColor[500]}`,
                    }}
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <TextField
                                label="Title"
                                value={fieldInfo.name}
                                onChange={(e) => setFieldInfo({...fieldInfo, name: e.target.value})}
                                sx={{
                                    position: 'relative',
                                    top: '9px',
                                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: `${colors.customColor[100]}`},
                                    '& label.Mui-focused': {color: `${colors.customColor[100]} !important`},
                                    '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {borderColor: `${colors.customColor[100]} !important`},
                                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                        borderColor: `${colors.customColor[100]} !important`,
                                        borderRadius: '15px !important'
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                select
                                label='Type'
                                value={fieldInfo.type}
                                onChange={(e) => setFieldInfo({...fieldInfo, type: e.target.value})}
                                sx={{
                                    width: '80%',
                                    position: 'relative',
                                    top: '9px',
                                    left: '20px',
                                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderColor: `${colors.customColor[100]}`},
                                    '& label.Mui-focused': {color: `${colors.customColor[100]} !important`},
                                    '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {borderColor: `${colors.customColor[100]} !important`},
                                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                        borderColor: `${colors.customColor[100]} !important`,
                                        borderRadius: '15px !important'
                                    },
                                }}
                            >
                                {typeOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {/*<Grid item xs={4} >*/}
                        {/*    <FormGroup >*/}
                        {/*        <FormControlLabel*/}
                        {/*            // required*/}
                        {/*            control={*/}
                        {/*                <Switch*/}
                        {/*                    checked={fieldInfo.is_required}*/}
                        {/*                    onChange={(e) => setFieldInfo({...fieldInfo, is_required: e.target.checked})}*/}
                        {/*                    name="is_required"*/}
                        {/*                    sx={{*/}
                        {/*                        '& .Mui-checked': {*/}
                        {/*                            color: `${colors.customColor[100]} !important`*/}
                        {/*                        },*/}
                        {/*                        '& .MuiSwitch-track': {*/}
                        {/*                            color: `${colors.customColor[100]} !important`*/}
                        {/*                        },*/}
                        {/*                        '& .Mui-checked + .MuiSwitch-track': {*/}
                        {/*                            opacity: 1,*/}
                        {/*                            color: `${colors.customColor[100]} !important`*/}
                        {/*                        },*/}
                        {/*                    }}*/}
                        {/*                />}*/}
                        {/*            label="Required" />*/}
                        {/*    </FormGroup>*/}
                        {/*</Grid>*/}
                    </Grid>
                    <Grid container spacing={2} justifyContent="center" marginTop={1}>
                        {
                            fieldInfo.type === 'dropdown' &&
                            <>
                                <FormControl
                                    sx={{
                                        m: 1,
                                        width: '25ch',
                                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.customColor[100]}` },
                                        '& label.Mui-focused': { color: `${colors.customColor[100]} !important` },
                                        '& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.customColor[100]} !important` },
                                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                            borderColor: `${colors.customColor[100]} !important`,
                                            borderRadius: '15px !important'
                                        },
                                    }}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="outlined-adornment-option">Option</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-option"
                                        type={'text'}
                                        value={dropValue}
                                        onChange={(e) => setDropValue(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="add new option"
                                                    onClick={handleAddOptions}
                                                    edge="end"
                                                    sx={{ color: `${colors.customColor[100]}`, fontSize: '0.8rem' }}
                                                >
                                                    {<Add />}
                                                    Add new
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Options"
                                    />
                                </FormControl>
                            </>
                        }
                    </Grid>
                    <Grid container spacing={2} justifyContent="center" marginTop={1}>
                        {
                            fieldInfo.type === 'dropdown' &&
                            <Stack direction="column" spacing={2} marginTop={1}>
                                {groupedOptions &&  groupedOptions.map((group, groupIndex) => (
                                    <Stack direction="row" spacing={2} key={groupIndex}>
                                        {group.map((option) => (
                                            <Chip
                                                label={option.name}
                                                sx={{ backgroundColor: `${colors.customColor[100]}` }}
                                                key={option.id}
                                                onDelete={handleDelete(option.id)}
                                            />
                                        ))}
                                    </Stack>
                                ))}
                            </Stack>
                        }
                    </Grid>

                </DialogContent>
                <DialogActions sx={{background: `${colors.customColor[500]}`, width: '96%'}}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        sx={{color: '#F46464', border: '2px solid #F46464 !important'}}
                    >
                        Cancel
                    </Button>
                    {
                        editData &&
                        <Button
                            variant="outlined"
                            onClick={() => handleDeleteField()}
                            sx={{
                                color: `${colors.customColor[800]}`,
                                border: `2px solid ${colors.customColor[800]}`
                            }}
                        >
                            Delete
                        </Button>
                    }
                    <Button
                        variant="outlined"
                        onClick={() => handleSaveForm()}
                        sx={{
                            color: `${colors.customColor[100]}`,
                            border: `2px solid ${colors.customColor[100]}`
                        }}
                    >
                        Save
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    )
}
