import axios from "axios";
const BASEURL = process.env.REACT_APP_BASEURL;
// const MOCKURL = process.env.REACT_APP_MOCKURL;



//function to get batches
export const getBatches = async () => {
    try {
        const response = await axios.get(`${BASEURL}/api/batch/?status=not_started&status=in_progress`);
        return response?.data;
    } catch (error) {
        console.log('Server Error getting Batches \n', error);
        return false;
    }
}

//function to get completed batches
export const getCompletedBatches = async () => {
    try {
        const response = await axios.get(`${BASEURL}/api/batch/?status=completed`);
        return response?.data;
    } catch (error) {
        console.error('Server Error getting Completed Batches \n', error);
        return false;
    }
}


//function to create batch
export const createBatch = async (data) => {
    try {
        const response = await axios.post(`${BASEURL}/api/batch/`, data);
        return response?.data;
    } catch (error) {
        console.error('Server Error creating Batch \n', error);
        return false;
    }
}


//function to create batch qrCode by sending batch ID in parametre
export const createBatchQrCode = async (batch) => {
    try {
        const response = await axios.get(`${BASEURL}/api/batch/generate_qr/?batch_id=${batch}`, { responseType: 'arraybuffer' });
        const blob = new Blob([response?.data], { type: 'image/png' });
        const imageURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = imageURL;
        downloadLink.download = 'batch' + batch + '.png' || 'image.png';
        return { imageURL : imageURL, downloadLink : downloadLink};
    } catch (error) {
        console.error('Server Error creating Batch QrCode \n', error);
        return false;
    }

}

//function to update Batch
export const updateBatch = async (data, id) => {
    try {
        const response = await axios.patch(`${BASEURL}/api/batch/${id}/`, data);
        return response?.data;
    } catch (error) {
        console.error('Server Error updating Batch \n', error);
        return false;
    }
}

export const deleteBatch = async (id) => {
    try {
        const response = await axios.delete(`${BASEURL}/api/batch/${id}/`);
        if (response?.status === 204) {
            return true;
        }else {
            return false;
        }

    } catch (error) {
        console.error('Server Error deleting Batch \n', error);
        return false;
    }
}