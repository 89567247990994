import { createSlice } from "@reduxjs/toolkit";

// Gate Circle Slice
export const gateCircleSlice = createSlice({
  name: "gate",
  initialState: { page: 1 },
  reducers: {
    incrementPage: (state) => {
      state.page += 1;
    },
    decrementPage: (state) => {
      state.page = Math.max(1, state.page - 1);
    },
    clearPage: (state) => {
      state.page = 1;
    },
  },
});
export const fieldsSlice = createSlice({
  name: "fields",
  initialState: { fieldPage: 1 },
  reducers: {
    incrementPage: (state) => {
      state.fieldPage += 1;
    },
    decrementPage: (state) => {
      state.fieldPage = Math.max(1, state.fieldPage - 1);
    },
    clearPage: (state) => {
   state.fieldPage= 1 }
    
  },
});
export const rowsIdsSlice = createSlice({
  name: "rowsIds",
  initialState: [],
  reducers: {
    addRowId: (state, action) => {
      state.push(action.payload);
    },
    removeRowId: (state, action) => {
      const index = state.indexOf(action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    clearRowsIds: (state) => {
      return [];
    },
  },
});
const initialState = {
  created_at__gte: "",
  // created_at__lte: "",
  created_time__lte: "",
  status: "",
  user_id: "",
  village_name: "",
  acres__lte: "",
  acres__gte: "",
};

export const formDataSlice = createSlice({
  name: "formData",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearFormData: () => {
      setFormData(initialState);
      return initialState;
    },
  },
});

export const { setFormData, clearFormData } = formDataSlice.actions;

export default formDataSlice.reducer;

export const { addRowId, removeRowId, clearRowsIds } = rowsIdsSlice.actions;
export const {
  incrementPage: fieldIncrement,
  decrementPage: fieldDecrement,
  clearPage,
} = fieldsSlice.actions;
export const {
  incrementPage: gateIncrement,
  decrementPage: gateDecrement,
  clearPage: gatePageclear,
} = gateCircleSlice.actions;
