import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import ManageEmployees from "../pages/teamTrak/ManageEmployees";
import ManageGateCircles from "../pages/territoryManagement/ManageGateCircles";
import Dashboard from "../pages/Dashboard";
import GateCircleDetail from "../pages/GateCircleDetail";
import EmployeeDetail from "../pages/teamTrak/EmployeeDetail";
import Login from "../pages/Login";
import IQPlatformLogin from "pages/IQPlatformLogin";
import TerritoryManagement from "pages/territoryManagement";
import CreateGateCircle from "../components/territoryManagement/manageGates/CreateGateCircle";
import CreateDistrict from "../components/territoryManagement/manageDistricts/CreateDistrict";
import EditDistrict from "../components/territoryManagement/manageDistricts/EditDistrict";
import ManageDistrict from "pages/territoryManagement/MangeDistrict";
import CreateVillage from "../components/territoryManagement/manageVillage/CreateVillage";
import EditVillage from "../components/territoryManagement/manageVillage/EditVillage";
import ManageVillage from "pages/territoryManagement/ManageVillage";
import CreateDeh from "../components/territoryManagement/manageDeh/CreateDeh";
import EditDeh from "../components/territoryManagement/manageDeh/EditDeh";
import ManageDeh from "pages/territoryManagement/ManageDeh";
import ManageCompany from "pages/territoryManagement/ManageCompany";
import Settings from "pages/varietyManagement/VarietyManagement";
import EditField from "../pages/allSurveys/EditField";
import Reporting from "pages/Reporting";
import TeamTrak from "pages/teamTrak/TeamTrak";
import GinnerTraceability from "pages/GinnerTraceability";
import Projects from "pages/developro/Projects";
import ProjectDetail from "pages/developro/ProjectDetail";
import CreateTask from "pages/developro/CreateTask";
import TaskDetail from "pages/developro/TaskDetail";
import CropConditionManagement from "pages/cropConditionManagement/CropConditionManagement";
import CropStageManagement from "pages/cropStageManagement/CropStageManagement";
import CropTypeManagement from "pages/cropTypeManagement/CropTypeManagement";
import DiseaseManagement from "pages/diseaseManagement/DiseaseManagement";
import AttendanceManagement from "pages/attendaceManagement/AttendanceManagement";
import AllSurveys from "pages/allSurveys/AllSurveys";
import BoundaryDetail from "pages/allSurveys/BoundaryDetail";
import FieldDetail from "pages/allSurveys/FieldDetail";
import FormCustomization from "../pages/formCustomization/FormCustomization";
import IQPlatformRedirection from "pages/IQPlatformRedirection";
import EditGateCircle from "components/territoryManagement/manageGates/EditGateCircle";
import AllGrowers from "../pages/allGrowers/AllGrowers";
import AllEmployees from "../pages/teamTrak/AllEmployees";
import SurveyorTrak from "../components/teamTrak/SurveyorTrak";
import SurveyorDetail from "../pages/teamTrak/SurveyorDetail";

function Router({user, setUser}) {
    const queryParams = new URLSearchParams(window.location.search);
    const jwtToken = queryParams.get("jwt_token");
    const path = queryParams.get("path");
    const id = queryParams.get("id");
    return (<>
        {/* Public routes */}
        {!user.auth && (<Routes>
            <Route
                path="/user/iq-platform-login"
                element={<IQPlatformLogin setUser={setUser}/>}
            />
            <Route path="/user/login" element={<Login setUser={setUser}/>}/>
            <Route path="*" element={<Navigate to="/user/login"/>}/>
        </Routes>)}

        {/* Private routes */}
        {user.auth && user.role === '"A"' && (
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Dashboard />} />
          <Route path={"/all-surveys/bounderies"} element={<AllSurveys />} />
          <Route
                path={"/all-surveys/bounderies/:id/detail"}
                element={<BoundaryDetail/>}
            />
            <Route
                path={"/all-surveys/bounderies/field/:id"}
                element={<FieldDetail/>}
            />
            <Route
                path="/all-surveys/bounderies/field/edit/:id"
                element={<EditField/>}
            />
             <Route path="/Reporting" element={<Reporting/>}/>
        </Routes>
      )}
        {user.auth && (user.role === '"MA"' || user.role === '"GN"') && (<Routes>
            <Route
                path="/user/iq-platform-login"
                element={<IQPlatformRedirection/>}
            />
            <Route path="*" element={<Navigate to="/"/>}/>
            <Route path="/" element={<Dashboard/>}/>
            <Route
                path="/gate-circles/page/:pageNumber"
                element={<ManageGateCircles/>}
            />
            <Route path="/gate-circles/create" element={<CreateGateCircle/>}/>
            <Route
                path="/gate-circles/details/:id/page/:pageNumber"
                element={<GateCircleDetail/>}
            />
            {/* <Route
            path="/gate-circles/details/fields/:id"
            element={<FieldDetail />}
          /> */}
            {/*<Route path="/team-trak" element={<ManageEmployees />} />*/}
            {/*added line below to revamp it */}
            <Route path="/team-trak" element={<TeamTrak/>}/>
            <Route path="/team-trak/:id" element={<EmployeeDetail/>}/>
            <Route path="/Variety-management" element={<Settings/>}/>
            <Route
                path="/manage-growers"
                element={<AllGrowers/>}
            />
            <Route path="/Reporting" element={<Reporting/>}/>
            <Route path="/team-trak/activity-tracking" element={<AllEmployees/>}/>
            <Route path="/team-trak/activity-tracking/:id" element={<SurveyorDetail/>}/>

            <Route
                path={"/developro/projects/page/:pageNumber"}
                element={<Projects/>}
            />
            <Route
                path={"/developro/projects/id/:id/page/:pageNumber"}
                element={<ProjectDetail/>}
            />
            <Route
                path={"/developro/projects/id/:id/task/create"}
                element={<CreateTask/>}
            />
            <Route
                path={"/developro/projects/task/id/:id"}
                element={<TaskDetail/>}
            />
            <Route
                path={"/crop-condition-management"}
                element={<CropConditionManagement/>}
            />
            <Route
                path={"/form-customization"}
                element={<FormCustomization/>}
            />
            <Route
                path={"/crop-stage-management"}
                element={<CropStageManagement/>}
            />
            <Route
                path={"/crop-type-management"}
                element={<CropTypeManagement/>}
            />
            <Route path={"/disease-management"} element={<DiseaseManagement/>}/>
            <Route
                path={"/attendace-management"}
                element={<AttendanceManagement/>}
            />
            <Route path={"/all-surveys/bounderies"} element={<AllSurveys/>}/>
            <Route
                path={"/all-surveys/bounderies/:id/detail"}
                element={<BoundaryDetail/>}
            />
            <Route
                path={"/all-surveys/bounderies/field/:id"}
                element={<FieldDetail/>}
            />
            <Route
                path="/all-surveys/bounderies/field/edit/:id"
                element={<EditField/>}
            />
            <Route
                path="/territory-management"
                element={<TerritoryManagement/>}
            />
            <Route
                path="/territory-management/gate-circle-management"
                element={<ManageGateCircles/>}
            />
            <Route
                path="/territory-management/gate-circle-management/create"
                element={<CreateGateCircle/>}
            />
            <Route
                path="/territory-management/gate-circle-management/edit/:id"
                element={<EditGateCircle/>}
            />
            <Route
                path="/territory-management/district-management/create"
                element={<CreateDistrict/>}
            />
            <Route
                path="/territory-management/district-management/edit/:id"
                element={<EditDistrict/>}
            />
            <Route
                path="/territory-management/district-management"
                element={<ManageDistrict/>}
            />
            <Route
                path="/territory-management/village-management/create"
                element={<CreateVillage/>}
            />
            <Route
                path="/territory-management/village-management/edit/:id"
                element={<EditVillage/>}
            />
            <Route
                path="/territory-management/village-management"
                element={<ManageVillage/>}
            />{" "}
            <Route
                path="/territory-management/deh-management/create"
                element={<CreateDeh/>}
            />
            <Route
                path="/territory-management/deh-management/edit/:id"
                element={<EditDeh/>}
            />
            <Route
                path="/territory-management/deh-management"
                element={<ManageDeh/>}
            />
            <Route
                path="/territory-management/company-management"
                element={<ManageCompany/>}
            />
            <Route
                path={"/ginner-traceability"}
                element={<GinnerTraceability/>}
            />
        </Routes>)}
    </>);

}

export default Router;
