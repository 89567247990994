import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import AddIcon from "@mui/icons-material/Add";
import { TextField} from "@mui/material";
import {useState} from "react";
import {createDriver} from "../../api/ginnerTraceability/driver";
import {inputLabelStyle, textFieldStyle} from "./helpers/css/styles";
import Loading from "../BeatLoader";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function CreateDriverModal({driverOptions, setDriverOptions}) {
    const [openDriverModal, setOpenDriverModal] = useState(false);
    const handleOpen = () => setOpenDriverModal(true);
    const handleClose = () => setOpenDriverModal(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [fatherName, setFatherName] = useState('');
    const [cnic, setCNIC] = useState('');
    const [isLoading, setIsLoading] = useState(false);



    const formatCNIC = (cnic) => {
        const cleanedCNIC = cnic.replace(/\D/g, '');

        if (cleanedCNIC.length === 13) {
            return cleanedCNIC.replace(/^(\d{5})(\d{7})(\d{1})$/, '$1-$2-$3');
        }
        return new Error('Invalid CNIC');
    };
    const handleSave = () => {
        setIsLoading(true);
        try {
            if (firstName && cnic){
                const formatedCNIC = formatCNIC(cnic);
                const validCNINC = formatedCNIC instanceof Error ? false : true;
                if (validCNINC) {
                    const data = {
                        first_name: firstName,
                        last_name: lastName,
                        father_name: fatherName,
                        cnic: formatedCNIC
                    };
                    createDriver(data).then((res) => {
                        if (res){
                            const newDriver = {
                                id: res.id,
                                name: res.first_name + ' ' + res.last_name,
                                cnic: res.cnic,
                                value: res.id,
                                label: res.first_name + ' ' + res.last_name + ' ' + res.id
                            }
                            setDriverOptions([...driverOptions, newDriver]);
                            setIsLoading(false);
                            handleClose();
                        }else {
                            setIsLoading(false);
                            alert('Error Creating Driver');
                        }

                    }).catch((err) => {
                        console.error('Server Error Creating Driver \n',err);
                        setIsLoading(false);
                        alert('Error Creating Driver');
                    });
                }else {
                    setIsLoading(false);
                    alert('Invalid CNIC. Please check again.');
                }
            }
        }catch (error){
            console.error('Error Creating Driver \n',error);
            setIsLoading(false);
        }

    }



    return (
        <div>
            <Button
                variant="outlined"
                color="info"
                onClick={handleOpen}
            >
                <AddIcon sx={{ mr: 1 }} fontSize="small" />
                Create Driver
            </Button>
            <Modal
                open={openDriverModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'scroll',
                    }}
                >
                    <TextField
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        sx={textFieldStyle}
                        InputLabelProps={inputLabelStyle}
                    />

                    <TextField
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        sx={textFieldStyle}
                        InputLabelProps={inputLabelStyle}
                    />

                    <TextField
                        label="Father Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={fatherName}
                        onChange={(e) => setFatherName(e.target.value)}
                        sx={textFieldStyle}
                        InputLabelProps={inputLabelStyle}
                    />

                    <TextField
                        label="CNIC"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={cnic}
                        onChange={(e) => setCNIC(e.target.value)}
                        sx={textFieldStyle}
                        InputLabelProps={inputLabelStyle}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="outlined" color="success" onClick={handleSave} disabled={isLoading}>
                            Create
                            {
                                isLoading && (
                                    <div style={{marginTop: '5px'}}>
                                        <Loading size={13} />
                                    </div>
                                )
                            }
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}