import React, {useEffect, useState} from 'react';
import Chart from "react-apexcharts";
import { tokens } from "../assets/Theme";
import {useTheme, ToggleButtonGroup, ToggleButton, Typography, Menu, MenuItem} from "@mui/material";
import { FilterSettingIcon } from 'assets/Icons';


const MultiLineGraph = ({surveyByMonth, surveyByDay}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [alignment, setAlignment] = React.useState('android');
    const [categories, setCategories] = useState([...surveyByMonth?.month || []]);
    const [seriesData2, setSeriesData2] = useState([...surveyByMonth?.area_surveyed || []]);
    const [monthNames, setMonthNames] = useState(Object.keys(surveyByDay) || []);
    const [selectedMonth, setSelectedMonth] = useState(monthNames[0] || '');
    const [anchorEl, setAnchorEl] = useState(null);
    const [graphName, setGraphName] = useState('Month');

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);

        if (newAlignment === 'web') {
            if (surveyByDay && surveyByDay[selectedMonth]) {
                const dailyData = surveyByDay[selectedMonth];
                setCategories([...dailyData.dates || []]);
                setSeriesData2([...dailyData.area_surveyed || []]);
            } else {
                setCategories([]);
                setSeriesData2([]);
            }
        } else {

            if (surveyByMonth ) {
                setCategories([...surveyByMonth?.month || []]);
                setSeriesData2([...surveyByMonth?.area_surveyed || []]);
            } else {
                setCategories([]);
                setSeriesData2([]);
            }
        }
    };

    // Chart options
    const options = {
        chart: {
            type: 'line',
            height: 300,
            toolbar: {
                show: false
            }
        },
        stroke: {
            width: 2
        },
        colors: [`${colors.customColor[100]}`, `${colors.customColor[800]}`], // Green and blue colors for the lines
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetY: 0,
            offsetX: 0,
        },
        xaxis: {
            categories: categories,
            title: {
                text: graphName,
                offsetX: 0, 
                offsetY: -5, 
                style: {
                    color: colors.customColor[800],
                },
            },
            labels: {

                style: {
                    colors: colors.customColor[600],
                }
            }
        },
        yaxis: {
            title: {
                text: 'Acres',
                style: {
                    color: colors.customColor[800],
                },
            },
            labels: {
                style: {
                    colors: ['#000'],
                }
            }
        },
    };

    // Chart series data
    const series = [
        { name: graphName, data: seriesData2 },
    ];

    useEffect(() => {
        console.info('selectedMonth', selectedMonth, 'alignment', alignment);
        setGraphName(alignment === 'web' ? selectedMonth : 'Monthly')
        handleChange(null, alignment);
    }, [selectedMonth, alignment]);

    return (
        <div id="chart"
             style={{
                 background:`${colors.customColor[500]}`,
                 width:'98%',
                 marginRight:'2%',
                 borderRadius:'12px',
                 boxShadow: '2px 6px 12px rgba(0, 0, 0, 0.1)', }}
        >
            <div style={{width:'78%', padding:'6px', display:'flex', flexDirection:'row', justifyContent:'space-between',}}>
                <div>
                    <Typography variant='h4' fontWeight='bold'>Daily Area surveyed</Typography>
                </div>
                <div>
                    <ToggleButtonGroup

                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        sx={{position: 'relative', left: '63%', marginTop: '2%',}}

                    >
                        <ToggleButton
                            size='small'
                            sx={{
                                borderRadius: '20px',
                                height: '28px',
                                width: '80px',
                                backgroundColor: `${colors.customColor[400]}`,
                                '&.Mui-selected, &.Mui-selected:hover': {
                                    color: '#fff',
                                    backgroundColor: `${colors.customColor[100]}`,
                                    borderRadius: '20px !important',
                                    zIndex: '1000 !important',
                                    left: '9px !important'
                                }
                            }}
                            value="web"
                        >Daily
                        </ToggleButton>
                        <ToggleButton
                            size='small'
                            sx={{
                                borderRadius: '20px',
                                height: '28px',
                                width: '80px',
                                backgroundColor: `${colors.customColor[400]}`,
                                '&.Mui-selected, &.Mui-selected:hover': {
                                    color: '#fff',
                                    backgroundColor: `${colors.customColor[100]}`,
                                    borderRadius: '20px !important',
                                    zIndex: '1000 !important',
                                    right: '9px !important'
                                }
                            }}
                            value="android">Monthly
                        </ToggleButton>
                        {
                            alignment === 'web' ? (
                                <div style={{padding: '4px', marginLeft: '25px'}}
                                     onClick={(event) => setAnchorEl(event.currentTarget)}>
                                    <FilterSettingIcon/>
                                </div>
                            ) : ''
                        }

                    </ToggleButtonGroup>
                </div>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {monthNames?.map((month) => (
                        <MenuItem key={month} onClick={() => setSelectedMonth(month)}>{month}</MenuItem>
                    ))}
                </Menu>

            </div>
            <Chart options={options} series={series} type="line" height={285}/>
        </div>
    );
};

export default MultiLineGraph;
