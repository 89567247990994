import React, { useState, } from 'react';
import { tokens } from 'assets/Theme';

import { Button, Typography, TextField, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import 'jspdf-autotable';
import { useNavigate } from "react-router-dom";

// import CropTypeModal from './CroptypeModal';

export default function AllSurveyTables({
  headerNames,
  rowsData,
  title,
  headerButtonLabel,
  onActionButtonClick,
  footer,
  ModalComponent,
  onSearch,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const role = localStorage.getItem('role');
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch(searchQuery);
    }
  };

  const handleOpenModal = (varietyData) => {
    setEditData(varietyData);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleActionClick = (variety) => {
    onActionButtonClick(variety);
    handleCloseModal();
  };

  console.log("on", 100 / headerNames?.length);
  return (
    <div
      style={{
        width: "100%",
        border: "1px solid #000",
        borderRadius: "12px",
        boxShadow: "4px 9px 16px rgba(0, 0, 0, 0.25)",
        background: `${colors.customColor[500]}`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "97%",
        }}
      >
        <Typography
          variant="h3"
          fontWeight="bold"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          {title}
        </Typography>
        <div>
          <TextField
            label="Search here"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            variant="outlined"
            size="small"
            sx={{
              position: "relative",
              marginTop: "20px",
              marginBottom: "20px",
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: `${colors.customColor[800]} !important`,
                borderRadius: "15px !important",
              },
              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: `${colors.customColor[100]} !important`,
                },
              "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "transparent !important",
                },
            }}
          />
          {headerButtonLabel && (
            <Button
              variant="contained"
              size="small"
              disabled={role === '"A"'}
              startIcon={<AddIcon fontSize="small" />}
              onClick={() => handleOpenModal(null)}
              sx={{
                background: `${colors.customColor[100]}`,
                color: `#fff`,
                height: "40px",
                marginTop: "20px",
                borderRadius: "15px",
                marginLeft: "10px",
                "&:hover": {
                  background: `green`,
                },
              }}
            >
              {headerButtonLabel}
            </Button>
          )}
          <Button
            variant="contained"
            size="small"
            disabled={role === '"A"'}
            onClick={() => navigate("/territory-management")}
            sx={{
              background: `${colors.customColor[100]}`,
              color: `#fff`,
              height: "40px",
              marginTop: "20px",
              borderRadius: "15px",
              marginLeft: "10px",
              "&:hover": {
                background: `green`,
              },
            }}
          >
            Base Map View
          </Button>
        </div>
      </div>
      <table>
        <thead>
          <tr
            style={{
              color: `${colors.customColor[100]}`,
              height: "30px",
              backgroundColor: "#ECF1FA",
            }}
          >
            {headerNames.map((header, index) => (
              <th
                key={index}
                style={{
                  width: `${100 / headerNames.length}%`,
                  padding: "8px",
                }}
              >
                {header?.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowsData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headerNames.map((header, headerIndex) => (
                <td
                  key={headerIndex}
                  style={{
                    width: `${100 / headerNames?.length}vw`,
                    padding: "8px",
                    borderBottom: "0.5px #5A6975 dotted",
                    height: "40px",
                    textAlign: "center",
                  }}
                >
                  {typeof header?.value === "function"
                    ? header?.value(row)
                    : row[header?.value]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {footer}
      {ModalComponent && (
        <ModalComponent
          open={openModal}
          onClose={handleCloseModal}
          onSaveVariety={handleActionClick}
          editData={editData}
        />
      )}
    </div>
  );
}
