import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import AddIcon from "@mui/icons-material/Add";
import { TextField} from "@mui/material";
import {useState} from "react";
import {createBroker} from "../../api/ginnerTraceability/broker";
import {inputLabelStyle, textFieldStyle} from "./helpers/css/styles";
import Loading from "../BeatLoader";

export default function AddBrokerModal({brokerOptions, setBrokerOptions}) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [name, setName] = useState('');
    const [cnic, setCNIC] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const formatCNIC = (cnic) => {
        const cleanedCNIC = cnic.replace(/\D/g, '');

        if (cleanedCNIC.length === 13) {
            return cleanedCNIC.replace(/^(\d{5})(\d{7})(\d{1})$/, '$1-$2-$3');
        }

        return new Error('Invalid CNIC');
    };
    const handleSave = () => {
        setIsLoading(true);
        try {
            if (name && cnic){
                const formatedCNIC = formatCNIC(cnic);
                const validCNINC = !(formatedCNIC instanceof Error);
                if (validCNINC) {
                    const data = {
                        name: name,
                        cnic: formatedCNIC
                    };
                    createBroker(data).then((res) => {
                        const newBroker = {
                            id: res.id,
                            name: res.name,
                            cnic: res.cnic,
                            value: res.id,
                            label: res.name + ' - ' + res.cnic
                        }
                        setBrokerOptions([...brokerOptions, newBroker]);
                        setOpen(false);
                        setIsLoading(false);
                    }).catch((err) => {
                        console.error('Server Error Creating Broker \n',err);
                        setIsLoading(false);
                        alert('Server Error Creating Broker');
                    });
                }else {
                    alert('Invalid CNIC. Please check again.');
                    setIsLoading(false);
                }
            }
        }catch (error){
            console.error('Error Creating Broker \n',error);
            setIsLoading(false);
            alert('Error Creating Broker');
        }

    }



    return (
        <div>
            <Button
                variant="outlined"
                color="success"
                onClick={handleOpen}
            >
                <AddIcon sx={{ mr: 1 }} fontSize="small" />
                Create Broker
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'scroll',
                    }}
                >
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={textFieldStyle}
                        InputLabelProps={inputLabelStyle}
                    />
                    <TextField
                        label="CNIC"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={cnic}
                        onChange={(e) => setCNIC(e.target.value)}
                        sx={textFieldStyle}
                        InputLabelProps={inputLabelStyle}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="outlined" color="success" onClick={handleSave} disabled={isLoading}>
                            Create
                            {
                                isLoading && (
                                    <div style={{marginTop: '5px'}}>
                                        <Loading size={13} />
                                    </div>
                                )
                            }
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}