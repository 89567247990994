import {createSlice} from "@reduxjs/toolkit";


export const teamTrakSlice = createSlice({
    name: "teamTrak",
    initialState: { page: 1 },
    reducers: {
        incrementPage: (state) => {
            state.page += 1;
        },
        decrementPage: (state) => {
            state.page = Math.max(1, state.page - 1);
        },
        clearPage: (state) => {
            state.page= 1
        }
    },
});


export const {
    incrementPage : teamTrakIncrement ,
    decrementPage: teamTrakDecrement,
    clearPage: teamTrakClear
} = teamTrakSlice.actions;
