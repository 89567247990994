import React, { useState, useEffect } from "react";
import axios from "axios";

const baseurl = process.env.REACT_APP_BASEURL;
console.log("base url in iqplatform: " + baseurl);

function IQPlatformLogin({ setUser }) {
  const [formData, setFormData, queryParam] = useState({
    mobile_number: "",
    password: "",
    showPassword: false,
  });
  useEffect(() => {
    handleLogin();
  }, []);
  const handleLogin = async () => {
    // event.preventDefault();
    console.log("handleLoginForIQPlatformLogin");

    const queryParams = new URLSearchParams(window.location.search);

    // Get the value of the 'jwt_token' query parameter
    const jwtToken = queryParams.get("jwt_token");
    const path = queryParams.get("path");
    const id = queryParams.get("id");

    try {
      let res = await axios.post(baseurl + "/user/iq-auth/login/", {
        user_jwt_token: jwtToken,
      });
      console.log("LOGIN res:", res);
      const token = res?.data?.token;
      // if (!token) {
      //     localStorage.clear();
      //     window.location.href = "/";
      //     return;
      // }
      setUser({
        token: token,
        mobile_number: res.data.mobile_number,
        username: res.data.username,
        role: res.data.role,
      });
      if (!id) {
        window.location.href = `/agrichain/${path}`;
      } else {
        window.location.href = `/agrichain/${path}/${id}`;
      }
    } catch (error) {
      console.log(error);
      // else if (error?.response?.data){
      //     alert(JSON.stringify(error?.response?.data));
      // } else (alert(error))
    }
  };
}

export default IQPlatformLogin;
