
import {Box, Button, Stack, Typography, useTheme} from "@mui/material";
import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {tokens} from "../../assets/Theme";
import {CrossIcon} from "../../assets/Icons";
import {makeRequest} from "../../api/makeRequest";


export default function SAPModal({open, onClose, sapData, extraHeaders}) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const headerNames = [
        {header: 'Serial Number', value: 'serial_number'},
        {header:'Vendor Code',value:'vendor_code'},
        {header:'Vendor Name',value:'vendor_name'},
        {header:'Location',value:'location'},
        {header: 'Zone', value: 'zone'},
        {header: 'Material', value: 'material_number'},
        {header: 'Lot Number', value: 'lot_number'},
        {header:'created at',value:'created_at'},
        {header:'Field ID',value:'field_id'},
        {header: 'Year', value: 'year'},
        ...extraHeaders
    ];

    const [rowsData, setRowsData] = useState([...sapData.results]);
    const [page, setPage] = useState(parseInt((sapData?.count/10) +1));
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const getNestedValue = (obj, path) => {
        const parts = path.split('.');
        let value = obj;
        for (const part of parts) {
            if (value[part] === undefined) return '';
            value = value[part];
        }
        return value;
    };



    const handleNextPage = () => {
        if (nextPage === null){
            alert('No pages forward this is all you got!');
        }else {
            fetchData(nextPage);
            setPage(page + 1);
        }
    }

    const handlePreviousPage = () => {
        if (previousPage === null || page === 1){
            alert('No pages behind this is all you got!')
        }else {
            fetchData(previousPage);
            setPage(page - 1 === 0 ? 1 : page - 1);
        }
    }

    async function fetchData(url){
        const response = await makeRequest(url, 'get');

        if (response?.results){
            response?.results?.map( (entry) => {
                entry.created_at = entry?.created_at.split('T')[0];
            });
            setRowsData(response?.results);
            setNextPage(response?.next);
            setPreviousPage(response?.previous);
        }else {
            alert('No more data to show!');
        }
    }

    return(
        <div>
            <Dialog open={open} onClose={onClose} sx={{'& .MuiDialog-paper': {height: '370px', maxWidth: '1000px !important'}}}>

                <Stack direction="row" justifyContent="space-between">
                    <Typography className="modal-title"></Typography>
                    <Button
                        size="small"
                        sx={{top: "5px"}}
                        onClick={onClose}
                    >
                        <CrossIcon style={{width: "25px", height: "25px"}}/>
                    </Button>
                </Stack>
                <DialogContent
                    sx={{
                        background: `${colors.customColor[500]}`,
                    }}
                >
                    <div style={{width: '100%', marginTop: '10px'}}>
                        <table width={'100%'}>
                            <thead>
                            <tr
                                style={{
                                    color: `${colors.customColor[100]}`,
                                    height: "40px",
                                    backgroundColor: "#ECF1FA",
                                    textWrap: "nowrap"
                                }}
                            >
                                {headerNames.map((header, index) => (
                                    <th key={index}>{header?.header}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {rowsData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {headerNames.map((header, headerIndex) => (
                                        <td
                                            key={headerIndex}
                                            style={{
                                                width: "430px",
                                                borderBottom: "0.5px #5A6975 dotted",
                                                height: "40px",
                                                textAlign: "center",
                                            }}
                                        >
                                            {getNestedValue(row, header.value)}
                                        </td>
                                    ))}

                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </DialogContent>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                    marginTop: '5%',
                    marginRight: '7%',
                    justifyContent: 'space-between'
                }}
                    >
                <Box
                    sx={{
                        bgcolor: 'white',
                        boxShadow: 'none',
                        marginBottom: '3%',
                        flexGrow: 1,
                    }}
                >
                    <Typography variant="h5" padding="2%" sx={{color: '#0375c5'}}>
                        {page} - {parseInt(sapData.count/ 10) + 1 } of pages
                    </Typography>
                </Box>
                <Box
                    sx={{
                        bgcolor: 'white',
                        boxShadow: 'none',
                        marginBottom: '3%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Button
                        className='footerbtn-variety'
                        variant='outlined'
                        disabled={previousPage === null || page === 1}
                        onClick={handlePreviousPage}
                        sx={{ marginRight: '10px' }}
                    >
                        Previous
                    </Button>
                    <Button
                        className='footerbtn-variety'
                        variant='outlined'
                        disabled={nextPage === null}
                        onClick={handleNextPage}
                    >
                        Next
                    </Button>
                </Box>
            </Stack>

        </Dialog>

        </div>
    )
}